import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from '@/config/i18n';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import ButtonCustom from '@/components/common/ui/button';
import { IconKey } from '@/components/icons';
import PATH from '@/routes/path';
import { InputOtpCustomForm } from '@/components/common/ui/input/InputOtp';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResetPasswordFormType, resetPasswordSchema } from '../schema';
import { apiRequest } from '@/services';
import { mailForgot } from '@/lib/axiosCustom';
import useScrollToError from '@/hooks/useScrollToError';

const ResetPasswordForm = () => {
  const [t] = useTranslation('');
  const [loading, setLoading] = useState(false);
  const [loadingReSend, setLoadingReSend] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { apiService } = apiRequest();
  const { scrollToError } = useScrollToError();

  const formMethods = useForm<ResetPasswordFormType>({
    mode: 'onChange',
    resolver: yupResolver(resetPasswordSchema),
  });

  const { handleSubmit, watch, getValues } = formMethods;

  watch(() => {
    setError('');
  });

  useEffect(() => {
    if (!mailForgot.value) {
      navigate(PATH.login)
    }
  }, [mailForgot.value])

  async function onSubmit() {
    if (loading) return;
    setLoading(true);
    const { data: user, error: errorUser } = await apiService.forgotPassword.create({ ...getValues(), email: mailForgot.value });
    if (errorUser) {
      setLoading(false);
      if (errorUser.status_code === 400) {
        setError(errorUser.message);
        scrollToError();
      }
      return;
    }

    if (user) {
      navigate(PATH.reset_password_success);
      setLoading(false);
    }
  }

  async function onResendOtp() {
    if (loadingReSend) return;
    setLoadingReSend(true);
    const { error: errorUser } = await apiService.forgotPassword.collection('otp', {
      method: 'POST',
      body: {
        email: mailForgot.value
      }
    });

    if (errorUser) {
      setError(errorUser.message);
      scrollToError();
    }

    setLoadingReSend(false);
  }

  return (
    <FormProvider {...formMethods}>
      <div className="error-message text-primary-400 mb-[32px] flex-full-center text-[16px] leading-[24px] font-normal">
        {error ? error : t('reset_password.description')}
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full bg-white py-[20px] px-[40px] rounded-[24px] shadow-[0_4px_4px_0_rgba(0,0,0,0.3)]"
      >
        <div className="logo flex-full-center mb-[27px]">
          <div className="p-[9px] text-primary-700 rounded-full bg-primary-100 border border-[10px] border-primary-50">
            <IconKey />
          </div>
        </div>

        <div className="form-inner">
          <InputTextCustomForm
            wrapClass="mt-[20px]"
            label={t('reset_password.password')}
            labelClass="!text-[14px]"
            placeholder={t('common.form.placeholder.input', {
              label_name: t('reset_password.password'),
            })}
            name="password"
            requiredFlag
            isHideValue
          />
          <p className='text-[#757575] text-[12px] mt-2'>
            {t('user.password_hint')}
          </p>
          <InputTextCustomForm
            wrapClass="mt-[20px]"
            label={t('reset_password.confirm_password')}
            labelClass="!text-[14px]"
            placeholder={t('common.form.placeholder.input', {
              label_name: t('reset_password.confirm_password'),
            })}
            name="password_confirmation"
            requiredFlag
            isHideValue
          />
          <p className='text-[#757575] text-[12px] mt-2'>
            {t('user.password_hint')}
          </p>
          <InputOtpCustomForm
            wrapClass="mt-[20px]"
            label={t('reset_password.otp_code')}
            labelClass="!text-[14px]"
            name="otp"
            length={6}
            requiredFlag
          />
        </div>

        <div className="flex-full-center mt-[24px]">
          <ButtonCustom
            type="submit"
            disabled={loadingReSend}
            loading={loading}
            className="w-full !rounded-[16px]"
          >
            {t('reset_password.button')}
          </ButtonCustom>
        </div>

        <div className="flex-full-center mt-[32px]">
          <div className="text-gray-700 text-[14px] leading-[20px] cursor-pointer">
            {t('reset_password.do_not_receive_mail')}
          </div>
          <div onClick={() => {onResendOtp()}} className="text-primary-500 flex items-center text-[14px] leading-[20px] cursor-pointer hover:opacity-80">
            <span>{t('reset_password.resend')}</span>
            { loadingReSend && <span className='ml-1 pi pi-spinner-dotted'></span> }
          </div>
        </div>

        <div className="flex-full-center mt-[32px]">
          <div
            className="text-gray-700 text-[16px] leading-[20px] cursor-pointer hover:opacity-80"
            onClick={() => navigate(PATH.login)}
          >
            <span className="pi pi-arrow-left mr-[8px] text-[16px]"></span>
            {t('forgot_password.return_login')}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default ResetPasswordForm;
