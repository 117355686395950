/* eslint @typescript-eslint/no-explicit-any: 0 */
import cx from 'classnames';
import { type FocusEvent, type KeyboardEvent } from 'react';
import { Checkbox, CheckboxProps } from 'primereact/checkbox';
import { useController, useFormContext } from 'react-hook-form';

interface Props extends CheckboxProps {
  label?: string;
  wrapClass?: string;
  name: string;
  onChange?: (e: any) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  className?: string;
  fullWidthFlag?: boolean;
}
export const CheckBoxCustomForm: React.FC<Props> = ({
  label,
  name,
  wrapClass,
  className,
  fullWidthFlag,
  onChange,
  onBlur,
  onKeyDown,
  errorMessage,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    control,
    name,
  });

  return (
    <div className={cx(wrapClass, 'checkbox-custom flex items-center')}>
      <Checkbox
        {...rest}
        {...field}
        className={cx('border border-gray-300 rounded-[4px] overflow-hidden', className, {
          ['p-invalid']: !!errors && errors[name],
          ['w-full']: fullWidthFlag,
        })}
        name={name}
        value={field?.value}
        onChange={(e: any) => {
          field?.onChange(e.target.value);
          onChange && onChange(e);
        }}
        onBlur={(e: any) => {
          onBlur && onBlur(e);
        }}
        onKeyDown={(e: any) => {
          onKeyDown && onKeyDown(e);
        }}
      />
      {label && <label htmlFor={rest.inputId} className="ml-2">{label}</label>}
      {!!errors && (errors[name]?.message || errorMessage) && (
        <div
          className={cx('error-message', 'p-error mt-2', {
            ['is-label']: label,
          })}
        >
          {errors[name]?.message?.toString() || errorMessage}
        </div>
      )}
    </div>
  );
};
