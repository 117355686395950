import cx from 'classnames';
import { useContext, useMemo, useRef, useState } from 'react';
import { FileUpload, FileUploadProps } from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';

import { byteConverter, toastErrorMessage, toastMessage } from '@/lib/utils';
import { useTranslation } from '@/config/i18n';
import { IconTrash } from '@/components/icons';
import { ToastContext } from '../../CommonToast';
import { apiRequest } from '@/services';

interface Props extends FileUploadProps {
  idChooseBtn: string;
  defaultFileName?: string;
  defaultFileSize?: number;
  handleUploaded?: (id?: number | string, file?: string, size?: number, url?: string) => void;
  acceptedTypes?: string[];
  errors?: any;
  errorMessage?: string;
  maxSize?: number;
  isHideFileSize?: boolean;
  handleSetUploading?: (name: string, value: boolean) => void;
}

export default function UploadFile({
  idChooseBtn,
  handleUploaded,
  defaultFileName,
  defaultFileSize,
  acceptedTypes,
  errors,
  errorMessage,
  maxSize,
  isHideFileSize = false,
  handleSetUploading,
  ...rest
}: Props) {
  const { apiService } = apiRequest();
  const [t] = useTranslation('');
  const { toast } = useContext(ToastContext);
  const [isUploading, setIsUploading] = useState(false);
  const [currentFile, setCurrentFile] = useState<File>();
  const fileUploadRef = useRef(null);

  const onFileSelect = async (e: any) => {
    const file = e.files?.[0];
    if (!file) {
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
      return;
    }
    const MAX_SIZE_MB = maxSize || 100;
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

    if (file.size > MAX_SIZE_BYTES) {
      toast?.current?.show(
        toastErrorMessage(t('common.file.pdf_max_size', { size: MAX_SIZE_MB })),
      );
      return;
    }

    if (acceptedTypes && !acceptedTypes.includes(file.type)) {
      if (acceptedTypes.includes('application/pdf')) {
        toast?.current?.show(toastErrorMessage(t('common.file.format_pdf')));
      } else {
        toast?.current?.show(
          toastErrorMessage('Please upload the file in the correct format.'),
        );
      }
      return;
    }
    await uploadPdfToServer(file);
  };

  const uploadPdfToServer = async (file: any) => {
    setIsUploading(true);
    handleSetUploading?.(rest?.name || '', true);
    const image = await preLoadImage(file);
    if (!image?.data) {
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
      setIsUploading(false);
      handleSetUploading?.(rest?.name || '', false);
      return;
    }

    const { data } = image;
    const formData = new FormData();

    Object.entries(data.formInputs).forEach(([key, value]) => {
      formData.append(key, value as string);
    });
    formData.append('file', file);
    try {
      const pdfFromS3 = await fetch(data.formAttributes.action, {
        method: 'POST',
        body: formData,
      });
      if (!pdfFromS3.ok) {
        setIsUploading(false);
        handleSetUploading?.(rest?.name || '', false);
        toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
        throw 'your file have a problem';
      }
      toast?.current?.show(toastMessage(t('common.toast.import_pdf_success')));
      const urlPdfFile = URL.createObjectURL(file);
      handleUploaded?.(data.id as number, file?.name, file?.size, urlPdfFile);
      setCurrentFile(file);
    } catch {
      // do nothing
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
    } finally {
      setIsUploading(false);
      handleSetUploading?.(rest?.name || '', false);
    }
  };

  const preLoadImage = async (file: File) => {
    const { data: image } = await apiService.files.collection(
      'pre-signed-url',
      {
        method: 'POST',
        body: {
          name: file.name,
          original_name: file.name,
          mime_type: file.type,
          content_length: file.size,
        },
      },
    );

    return image;
  };

  const onTemplateUpload = () => {
    // TODO
  };

  const onTemplateClear = () => {};

  const headerTemplate = (options: any) => {
    const { className, chooseButton } = options;
    return (
      <div
        className={cx(
          'left-1/2 transform -translate-x-1/2 top-[15px] absolute z-40 p-0',
          className,
        )}
        style={{
          background: 'transparent',
        }}
      >
        {chooseButton}
      </div>
    );
  };

  const disabledChoose = useMemo(() => {
    return isUploading ? 'disabled-custom' : '';
  }, [isUploading]);

  const chooseOptions = {
    icon: 'pi pi-fw pi-cloud-upload text-gray-600 text-[22px]',
    iconOnly: true,
    className: `choose-button border-gray-50 border-8 bg-gray-100 w-[50px] h-[50px] rounded-full hidden ${disabledChoose} ${idChooseBtn}`,
  };

  const handleTriggerUpload = () => {
    const el = document.querySelector(`.${idChooseBtn}`) as HTMLElement;
    if (el) {
      el?.click();
    }
  };

  const emptyTemplate = () => {
    return (
      <div className="w-[100%] flex justify-between items-center text-[14px]">
        <div className="flex items-center">
          <i className="pi pi-file text-[18px] mr-[8px]" />
          <span
            onClick={() => handleTriggerUpload()}
            className={cx(
              `line-clamp-1 text-left cursor-pointer mr-[4px] ${disabledChoose}`,
              { 'text-primary-700': !(currentFile?.name || defaultFileName) },
            )}
          >
            {currentFile?.name || defaultFileName
              ? currentFile?.name || defaultFileName
              : `クリックしてアップロード`}
          </span>{' '}
          {!!(currentFile?.name || defaultFileName) &&
            !isHideFileSize &&
            !isUploading && (
            <span className="flex-auto mr-[4px] text-nowrap">{` - ${byteConverter(currentFile?.size || defaultFileSize || 0, 2, 'MB')}`}</span>
          )}
          {!(currentFile?.name || defaultFileName) && !isUploading && (
            <span className="flex-auto mr-[4px]">{`PDF max ${maxSize || 100}MB`}</span>
          )}
        </div>
        <div>
          {!isUploading && (currentFile?.name || defaultFileName) && (
            <span
              onClick={() => {
                handleUploaded?.(undefined, undefined, undefined, undefined);
                setCurrentFile(undefined);
                (fileUploadRef?.current as any)?.clear?.();
              }}
              className="cursor-pointer text-error-500"
            >
              <IconTrash />
            </span>
          )}
          {isUploading && (
            <i className="pi pi-spin pi-spinner-dotted text-[18px] !text-primary-500" />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="w-[100%]">
      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
      <div className="flex w-[100%]">
        <FileUpload
          {...rest}
          className={cx(
            'upload-file-custom rounded-[8px] w-[100%]',
            rest.className,
          )}
          ref={fileUploadRef}
          onUpload={onTemplateUpload}
          onSelect={onFileSelect}
          onError={onTemplateClear}
          onClear={onTemplateClear}
          headerTemplate={headerTemplate}
          chooseOptions={chooseOptions}
          emptyTemplate={emptyTemplate}
          itemTemplate={emptyTemplate}
          contentClassName="bg-transparent"
        />
      </div>
      {!!errors && (errors[rest?.name || '']?.message || errorMessage) && (
        <div className={cx('error-message', 'p-error mt-2')}>
          {errors[rest?.name || '']?.message?.toString() || errorMessage}
        </div>
      )}
    </div>
  );
}
