import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import { User } from 'firebase/auth';
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';

import {
  DATE_TIME_FORMAT,
  JAPANESE_FULL_STOP,
  TOAST_LIFE,
} from '@/shared/constants';
import { MenuItem } from '@/types/menu';
import {
  ReadStatusType,
  InviteStatusType,
  ConversationType,
  MessageType,
} from '@/types/chat';
import { INVITE_STATUS, MESSAGE_TYPE } from '@/shared/constants/chat';
import { db } from './firebase';
import i18n from '@/config/i18n';

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

export const getVisibleMenu = (menuItems: MenuItem[], userRoles: string[]) => {
  return (menuItems || []).filter(
    (menu) =>
      !(
        menu.hidden ||
        !userRoles?.some((role: string) => menu?.roles?.includes(role))
      ),
  );
};

export function sleep(ms: number): Promise<number> {
  return new Promise((resolve) => {
    const timeoutId = setTimeout(() => {
      resolve(Number(timeoutId));
    }, ms);
  });
}

export const fetchAddressByZipcode = async (zipCode: string) => {
  const url = `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zipCode}`;

  try {
    const response = await fetch(url);
    const data = (await response.json()) as {
      results?: any[];
    };
    return data.results?.[0] ?? null;
  } catch (error) {
    return null;
  }
};

export const standardizeSentence = (str: string) =>
  !str || str.endsWith(JAPANESE_FULL_STOP) ? str : str + JAPANESE_FULL_STOP;

const formatMessage = (message: any) =>
  typeof message === 'string' ? standardizeSentence(message) : message;

export const toastMessage = (message: string) => {
  return {
    severity: 'success',
    detail: formatMessage(message),
    life: TOAST_LIFE,
  };
};

export const toastErrorMessage = (message: any) => {
  return {
    severity: 'error',
    detail: formatMessage(message),
    life: TOAST_LIFE,
  };
};

export const toastInfoMessage = (message: string) => {
  return {
    severity: 'info',
    detail: formatMessage(message),
    life: TOAST_LIFE,
  };
};

export const serializeFormQuery = (values: any) => {
  if (!values) return {};

  return Object.fromEntries(
    Object.entries(values || {}).filter(
      ([, value]) => value !== undefined && value !== null && value !== '',
    ),
  ) as unknown as any;
};

/* eslint-disable no-bitwise */
export function randomUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
/* eslint-enable no-bitwise */

export function download(source: Blob | string, filename: string) {
  const url = typeof source === 'string' ? source : URL.createObjectURL(source);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  typeof source !== 'string' && window.URL.revokeObjectURL(url);
  a.remove();
}

export function toCsv(data: string | (string | number)[][]) {
  const csv =
    typeof data === 'string'
      ? data
      : data.map((row) => row.join(',')).join('\n');
  return new Blob([csv], { type: 'text/csv;charset=utf-8' });
}

export function getVideoCover(file: any, seekTo = 0.0): Promise<any> {
  return new Promise((resolve, reject) => {
    const videoPlayer = document.createElement('video');
    const videoUrl = URL.createObjectURL(file);
    videoPlayer.setAttribute('src', videoUrl);
    videoPlayer.load();

    videoPlayer.addEventListener('error', () => {
      reject('Error when loading video file');
    });

    videoPlayer.addEventListener('loadedmetadata', () => {
      if (videoPlayer.duration < seekTo) {
        reject('Video is too short.');
        return;
      }

      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);

      videoPlayer.addEventListener('seeked', () => {
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              const imageUrl = URL.createObjectURL(blob);
              const imageFile: any = new File([blob], file.name.split('.')[0], {
                type: 'image/jpeg',
              });
              imageFile.objectURL = imageUrl;
              resolve({
                file: imageFile,
                url: imageUrl,
                video_duration: videoPlayer.duration,
              });
            } else {
              reject('Error creating image blob');
            }
          },
          'image/jpeg',
          0.75,
        );
      });
    });
  });
}

export function fileToObject(file: any) {
  return {
    name: file.name,
    type: file.type,
    size: file.size,
    objectURL: file.objectURL,
  };
}

export function getValueFromSelect(values: any, keys: string[]) {
  const newValues: Record<string, any> = {};
  keys.forEach((key) => {
    if (values[key]) {
      newValues[key] = values[key].value;
    }
  });
  return newValues;
}

export function byteConverter(
  bytes: number,
  decimals: number | undefined,
  only: string,
) {
  const K_UNIT = 1024;
  const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  if (bytes == 0) return '0 Byte';

  if (only === 'MB')
    return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + ' MB';

  const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  const resp =
    parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) +
    ' ' +
    SIZES[i];

  return resp;
}

export function byteToMb(sizeInBytes: number) {
  if (!sizeInBytes) return;
  const sizeInMB = sizeInBytes / (1024 * 1024);
  return sizeInMB.toFixed(2);
}

export function utf8ToBase64(str: string) {
  const encoder = new TextEncoder();
  const bytes = encoder.encode(str);
  const binaryString = String.fromCharCode(...(bytes as unknown as number[]));
  return btoa(binaryString);
}

export function base64ToUtf8(base64: string) {
  const binaryString = atob(base64);
  const bytes = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
  const decoder = new TextDecoder();
  return decoder.decode(bytes);
}

type TranslationFnc = (key: string) => string;

export const formatTimestamp = (
  t: TranslationFnc,
  timestamp?: Timestamp,
): string => {
  if (!timestamp || !(timestamp instanceof Timestamp)) {
    return '';
  }

  const now = dayjs();
  const messageTime = dayjs(timestamp.toMillis());

  if (now.isSame(messageTime, 'day')) {
    return messageTime.format('HH:mm');
  }
  if (now.subtract(1, 'day').isSame(messageTime, 'day')) {
    return t('chat.yesterday');
  }
  return messageTime.format(DATE_TIME_FORMAT.DATE_TIME);
};

export const formatFullTimestamp = (timestamp?: Timestamp) => {
  if (!timestamp) return '';
  const messageTime = dayjs(timestamp.toMillis());
  return messageTime.format(DATE_TIME_FORMAT.DATE_TIME);
};

export const generateConversationData = (
  id: string,
  currentUser: User | null,
  isGroupChat: boolean,
  memberIds: number[],
  name?: string,
  image?: string,
) => {
  const timestamp = serverTimestamp();
  const read_statuses: { [key: number]: ReadStatusType } = memberIds.reduce(
    (obj, memberId) => {
      obj[memberId] = {
        last_read_at: null,
        last_read_message_id: '',
      };
      return obj;
    },
    {} as { [key: number]: ReadStatusType },
  );

  const invitation_statuses: { [key: number]: InviteStatusType } =
    memberIds.reduce(
      (obj, memberId) => {
        obj[memberId] = {
          status: isGroupChat
            ? Number(currentUser?.uid) !== memberId
              ? INVITE_STATUS.PENDING
              : INVITE_STATUS.ACCEPT
            : INVITE_STATUS.ACCEPT,
        };
        return obj;
      },
      {} as { [key: number]: InviteStatusType },
    );

  return {
    id: id,
    admin_id: !isGroupChat ? -1 : Number(currentUser?.uid),
    updated_at: timestamp,
    image: image || '',
    created_at: timestamp,
    name: name || '',
    is_group_chat: isGroupChat,
    member_ids: memberIds,
    read_statuses,
    invitation_statuses,
    last_message: {
      message: '',
      sent_at: timestamp,
      message_type: MESSAGE_TYPE.TEXT,
      sender_id: Number(currentUser?.uid),
      message_id: '',
    },
  };
};

export const getSystemMessage = (sender_id: number, message: string) => {
  return {
    message: message,
    created_at: serverTimestamp(),
    message_type: MESSAGE_TYPE.SYSTEM,
    sender_id: sender_id,
  };
};

export const getDateLabel = (date: Date, t: TranslationFnc) => {
  if (dayjs(date).isToday()) {
    return t('chat.today');
  }
  if (dayjs(date).isYesterday()) {
    return t('chat.yesterday');
  }
  return dayjs(date).format(DATE_TIME_FORMAT.DATE_ONLY);
};

export const convertToDate = (dateField: Timestamp): Date => {
  return dateField instanceof Date
    ? dateField
    : dateField?.toDate?.() || new Date();
};

export const checkConversationExists = async (
  chatPartnerId: number,
  currentUser: User | null,
) => {
  if (!currentUser) return null;

  try {
    const chatRoomsRef = collection(db, 'conversations');
    const q = query(chatRoomsRef, where('is_group_chat', '==', false));
    const querySnapshot = await getDocs(q);

    const existingConversation = querySnapshot.docs.find((doc) => {
      const members = doc.data().member_ids || [];
      return [chatPartnerId, Number(currentUser.uid)].every((id) =>
        members.includes(id),
      );
    });

    return existingConversation?.id || null;
  } catch (error) {
    return null;
  }
};

export const countMember = (conversation?: ConversationType | null) => {
  return (
    Object.values(conversation?.invitation_statuses || {}).filter(
      (item) => item.status === INVITE_STATUS.ACCEPT,
    ).length || 0
  );
};

export const sendMessage = async (
  content: MessageType,
  currentUser: User | null,
  selectedConversation: ConversationType,
) => {
  if (!currentUser) return;
  const messagesRef = collection(
    db,
    'conversations',
    String(selectedConversation?.id),
    'messages',
  );
  const response = doc(messagesRef);
  await setDoc(response, { id: response.id, ...content });
};

export const sendUpdateNameNotification = (
  selectedConversation: ConversationType,
  group_name: string,
  groupOwner: string,
  currentUser: User | null,
) => {
  if (!selectedConversation) return;

  const messageText = i18n.t('chat.change_name_group', {
    owner_name: groupOwner,
    group_name: group_name,
  });

  const systemMessage = getSystemMessage(Number(currentUser?.uid), messageText);
  sendMessage(systemMessage, currentUser, selectedConversation);
};

export const findPreviousNonDeletedMessage = async (
  conversationId: string,
  currentUserId: number,
): Promise<MessageType | null> => {
  const messagesRef = collection(
    db,
    'conversations',
    conversationId,
    'messages',
  );

  const messagesQuery = query(
    messagesRef,
    where('deleted_by', '!=', currentUserId),
    where('is_unsent', '==', false),
    where('message_type', '>', MESSAGE_TYPE.SYSTEM),
    orderBy('created_at', 'desc'),
    limit(1),
  );

  const querySnapshot = await getDocs(messagesQuery);

  if (!querySnapshot.empty) {
    const doc = querySnapshot.docs[0];
    return doc.data() as MessageType;
  }

  return null;
};

export const findPreviousNonUnsentMessage = async (
  conversationId: string,
): Promise<MessageType> => {
  const messagesRef = collection(
    db,
    'conversations',
    conversationId,
    'messages',
  );

  const messagesQuery = query(
    messagesRef,
    where('is_unsent', '==', false),
    where('message_type', '>', MESSAGE_TYPE.SYSTEM),
    orderBy('created_at', 'desc'),
    limit(1),
  );

  const querySnapshot = await getDocs(messagesQuery);

  if (!querySnapshot.empty) {
    const doc = querySnapshot.docs[0];
    return doc.data() as MessageType;
  }

  return {} as MessageType;
};
