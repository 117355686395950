import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  FileUpload,
  FileUploadHeaderTemplateOptions,
  FileUploadProps,
  FileUploadSelectEvent,
} from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';
import { Skeleton } from 'primereact/skeleton';
import cx from 'classnames';

import { apiRequest } from '@/services';
import { toastErrorMessage, toastMessage } from '@/lib/utils';
import { useTranslation } from '@/config/i18n';
import { ToastContext } from '@/components/common/CommonToast';
import { IMG_DOMAIN } from '@/shared/constants/chat';
import { IconGroupUsers } from '@/components/icons';

interface Props extends FileUploadProps {
  defaultUrl?: string;
  idChooseBtn: string;
  maxSize?: number;
  textUpload?: string;
  textSubUpload?: string;
  handleUploaded?: (imgUrl: string) => void;
  isAdminGroup?: boolean;
  isUploading: boolean;
  setIsUploading: (value: boolean) => void;
}

type FileWithObjectURLType = File & { objectURL?: string };

export const UploadAvatar = ({
  defaultUrl,
  handleUploaded,
  idChooseBtn,
  maxSize,
  isAdminGroup,
  isUploading,
  setIsUploading,
  ...rest
}: Props) => {
  const [t] = useTranslation('');
  const { toast } = useContext(ToastContext);
  const { apiService } = apiRequest();
  const [currentImage, setCurrentImage] = useState(defaultUrl);
  const fileUploadRef = useRef(null);

  const onFileSelect = async (e: FileUploadSelectEvent) => {
    const file: FileWithObjectURLType = e.files?.[0];
    const validFormats = ['image/svg+xml', 'image/jpeg', 'image/png'];

    const MAX_SIZE_MB = maxSize || 100;
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

    if (file.size > MAX_SIZE_BYTES) {
      toast?.current?.show(
        toastErrorMessage(
          t('common.file.image_max_size', { size: MAX_SIZE_MB }),
        ),
      );
      return;
    }

    if (!validFormats.includes(file.type)) {
      toast?.current?.show(toastErrorMessage(t('common.file.format_image')));
      return;
    }
    if (!file) {
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
      return;
    }
    setIsUploading(true);
    setCurrentImage('');
    const image = await preLoadImage(file);
    if (!image?.data) {
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
      setIsUploading(false);
      return;
    }
    uploadImgToS3(image, file);
  };

  const uploadImgToS3 = async (image: any, file: FileWithObjectURLType) => {
    const { data } = image;
    const formData = new FormData();

    Object.entries(data.formInputs).forEach(([key, value]) => {
      formData.append(key, value as string);
    });
    formData.append('file', file);
    try {
      const imageFromS3 = await fetch(data.formAttributes.action, {
        method: 'POST',
        body: formData,
      });
      if (!imageFromS3.ok) {
        toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
        setIsUploading(false);
        return;
      }
      setCurrentImage(file.objectURL);
      handleUploaded?.(data.formInputs.key);
      toast?.current?.show(toastMessage(t('chat.update_avatar_success')));
    } catch {
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
    } finally {
      setIsUploading(false);
    }
  };

  const preLoadImage = async (file: File) => {
    const { data: image } = await apiService.files.collection(
      'pre-signed-url',
      {
        method: 'POST',
        body: {
          name: file.name,
          original_name: file.name,
          mime_type: file.type,
          content_length: file.size,
        },
      },
    );

    return image;
  };

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton } = options;
    return (
      <div className={cx('p-0 bg-transparent', className)}>{chooseButton}</div>
    );
  };

  const disabledChoose = useMemo(() => {
    return isUploading ? 'disabled-custom' : '';
  }, [isUploading]);

  const chooseOptions = {
    icon: 'pi pi-fw pi-pencil text-white text-[12px]',
    iconOnly: true,
    className: `choose-button border-white border-[1px] bg-primary-500 w-[24px] h-[24px] rounded-full m-0 shadow-none ${disabledChoose} ${idChooseBtn}`,
  };

  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
    className:
      'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined',
  };

  useEffect(() => {
    if (!defaultUrl) return;
    setCurrentImage(defaultUrl);
  }, [defaultUrl]);

  return (
    <div>
      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
      <div className="flex relative">
        {!currentImage ? (
          <div>
            {!isUploading ? (
              <div className="h-[82px] w-[82px] rounded-[29px] flex justify-center items-center bg-gradient-to-b from-[#DC6803] to-[#FDB022] overflow-hidden">
                <IconGroupUsers className="h-[50px] w-[60px] text-white size-large" />
              </div>
            ) : (
              <Skeleton
                width="82px"
                height="82px"
                className="mb-2"
                borderRadius="29px"
              />
            )}
          </div>
        ) : (
          <div className="w-[82px] h-[82px]">
            <img
              src={`${IMG_DOMAIN}/${currentImage}`}
              className="w-full h-full object-cover rounded-[29px]"
            />
          </div>
        )}

        {isAdminGroup && (
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <FileUpload
              {...rest}
              className={cx('avatar-upload-container', rest.className)}
              ref={fileUploadRef}
              onSelect={onFileSelect}
              headerTemplate={headerTemplate}
              chooseOptions={chooseOptions}
              cancelOptions={cancelOptions}
              contentClassName="bg-transparent"
            />
          </div>
        )}
      </div>
    </div>
  );
};
