export default function IconPurchase() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6079 6.95996C11.1309 7.30004 10.5472 7.50008 9.9167 7.50008C8.30587 7.50008 7.00003 6.19425 7.00003 4.58341C7.00003 2.97258 8.30587 1.66675 9.9167 1.66675C10.9609 1.66675 11.8769 2.21543 12.3921 3.0402M5.33336 16.7394H7.50861C7.79222 16.7394 8.0741 16.7731 8.34904 16.8406L10.6474 17.3991C11.1462 17.5206 11.6657 17.5325 12.1696 17.4346L14.7108 16.9402C15.3821 16.8094 15.9997 16.488 16.4836 16.0172L18.2816 14.2682C18.7951 13.7695 18.7951 12.9604 18.2816 12.461C17.8193 12.0113 17.0873 11.9607 16.5643 12.342L14.4689 13.8708C14.1688 14.0902 13.8036 14.2083 13.4281 14.2083H11.4046L12.6926 14.2082C13.4185 14.2082 14.0066 13.6362 14.0066 12.93V12.6744C14.0066 12.088 13.5963 11.5767 13.0118 11.435L11.0239 10.9515C10.7003 10.8731 10.369 10.8334 10.036 10.8334C9.23197 10.8334 7.77661 11.4991 7.77661 11.4991L5.33336 12.5208M17 5.41675C17 7.02758 15.6942 8.33342 14.0834 8.33342C12.4725 8.33342 11.1667 7.02758 11.1667 5.41675C11.1667 3.80592 12.4725 2.50008 14.0834 2.50008C15.6942 2.50008 17 3.80592 17 5.41675ZM2.00003 12.1667L2.00003 17.0001C2.00003 17.4668 2.00003 17.7001 2.09086 17.8784C2.17075 18.0352 2.29824 18.1627 2.45504 18.2426C2.6333 18.3334 2.86665 18.3334 3.33336 18.3334H4.00003C4.46674 18.3334 4.7001 18.3334 4.87836 18.2426C5.03516 18.1627 5.16264 18.0352 5.24254 17.8784C5.33336 17.7001 5.33336 17.4668 5.33336 17.0001V12.1667C5.33336 11.7 5.33336 11.4667 5.24254 11.2884C5.16264 11.1316 5.03516 11.0041 4.87836 10.9242C4.7001 10.8334 4.46674 10.8334 4.00003 10.8334L3.33336 10.8334C2.86665 10.8334 2.6333 10.8334 2.45504 10.9242C2.29824 11.0041 2.17075 11.1316 2.09086 11.2884C2.00003 11.4667 2.00003 11.7 2.00003 12.1667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
