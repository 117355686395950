const common = {
  "form": {
    "required": "必須",
    "placeholder": {
      "input": "{{label_name}}を入力してください。",
      "select": "選択してください。",
      "default": "入力してください。",
    },
    "field_required": "{{label_name}}を入力してください。",
    "email_invalid": "メールアドレスの形式が間違っています。",
    "phone_invalid": "電話番号が必要です。",
    "password_invalid": "パスワードには「半角英数字(大文字・小文字・数字)」「半角記号（!\"#$%&'()*+,-.\\/|:;<=>?@[]^_`{}~）」を含む8～32字で設定してください。",
    "password_confirmation_invalid": "パスワードが一致しません。",
    "kanji_kana_hira_invalid": "{{label_name}}は漢字、全角ひらかな、全角カタカナで入力してください。",
    "katakana_invalid": "{{label_name}}は全角カタカナで入力してください。",
    "password_length": "パスワードは8文字以上32文字以内で設定してください。",
    "post_code_length": "郵便番号は5文字以上32文字以内の半角整数で入力してください。",
    "post_code_invalid": "郵便番号の形式が正しくありません。",
    "phone_number_length": "電話番号が10桁から15桁以内で入力してください。",
    "graduation_terms_invalid": "卒業期が1桁から999桁以内で入力してください。",
    "graduation_terms_number_invalid": "卒業期は整数で入力してください。",
    "number_invalid": "数字を入力してください。",
    "graduation_invalid": "卒業期を入力してください。",
    "login": "メールアドレスまたはパスワードが正しくありません。",
    "login_inactive": "アカウントが無効です。管理者へお問い合わせください。",
    "empty": "データがありません。",
    "create": "作成",
    "clear": "クリア",
    "save": "保存",
    "new": "追加",
    "cancel": "キャンセル",
  },
  "dialog": {
    "ok": "はい",
    "cancel": "いいえ",
    "header": "確認",
    "error": "エラー",
  },
  "toast": {
    "success": "操作に成功しました。",
    "fail": "操作に失敗しました。",
    "import_fail": "インポートに失敗しました。",
    "import_success": "インポートに成功しました。",
    "import_no_record": "データがありません。",
    "no_internet": "ネットワーク接続に問題が発生しました。再度お試しください。",
    "too_many_requests": "一定時間内のアクション許容数を超えました。時間をあけてから再度お試しください。",
    "import_video_image_success": "画像・動画をアップロードしました。",
    "import_pdf_success": "PDFをアップロードしました。",
  },
  "gender": {
    "male": "男性",
    "female": "女性",
    "other": "その他"
  },
  "status": {
    "active": "有効",
    "inactive": "無効",
    "all": "すべて",
  },
  "status_other": {
    "active": '公開',
    "inactive": '非公開'
  },
  "table": {
    "empty": "データがありません。"
  },
  "csv": {
    "format_file": "CSVファイルをアップロードしてください。",
    "already_used": "このファイルはすでに使用されています。",
  },
  "no_data": "データが存在しません。",
  "loading": "読み込み中...",
  "file": {
    "image_max_20MB": '最大アップロードサイズを超過しています。写真サイズの上限は20MBです。',
    "pdf_max_size": 'PDFイメージは{{size}}MB以内でなければなりません。',
    "csv_max_10MB": 'CSVファイルは10MB以下に取り込みしてください。',
    "csv_max_100KB": 'CSVファイルは100KB以下に取り込みしてください。',
    "format_image": "画像はSVG, PNG, JPG, jpeg SVGのうちいずれかの形式のファイルを指定してください。",
    "format_mp4": "動画はMP4の形式のファイルを指定してください。",
    "format_pdf": "PDFファイルはPDFの形式のファイルを指定してください。",
    "image_max_size": "最大アップロードサイズを超過しています。写真サイズの上限は{{size}}MBです。"
  },
  "logout": {
    "header": "ログアウト確認",
    "description": "ログアウトします。 よろしいでしょうか。"
  },
  "system_title": "Art Shift Training",
}

export default common;