import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { apiRequest } from '@/services';
import { CustomDataTable } from '@/components/common/ui/table/CustomDataTable';
import { Pagination } from '@/types/pagination';
import { useTranslation } from '@/config/i18n';
import PATH from '@/routes/path';
import { useLoading } from '@/components/common/loader/LoadingContext';
import DialogCustom from '@/components/common/ui/dialog';
import ButtonCustom from '@/components/common/ui/button';
import { ToastContext } from '@/components/common/CommonToast';
import { toastErrorMessage, toastMessage } from '@/lib/utils';
import { LessonListColumns } from '@/pages/lesson/table/LessonListColumns';
import { LessonItemType } from '@/types/lesson';
import { validatePriorities } from './utils';
import { NO_PUBLIC_UNIT } from '@/shared/constants';
import { useSelectorUserInfo } from '@/components/auth/AuthContext';
import { ROLE } from '@/shared/constants/user';

interface ILessonListProps {
  paginationComputed: any;
  currentPagination: any;
  lessons: any;
  setLessons: React.Dispatch<any>;
  getLessons: () => void;
  setPagination: React.Dispatch<any>;
  isDisabledEdit: boolean;
  isTeacher: boolean;
  isSupperAdmin: boolean;
  activeIndex: number;
}

const LessonList: React.FC<ILessonListProps> = ({
  lessons,
  paginationComputed,
  currentPagination,
  isDisabledEdit,
  isTeacher,
  isSupperAdmin,
  activeIndex,
  setLessons,
  getLessons,
  setPagination,
}) => {
  const [t] = useTranslation('');
  const { apiService } = apiRequest();
  const { toast } = useContext(ToastContext);
  const navigate = useNavigate();
  const { setLoadingGlobal } = useLoading();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState<any>();
  const [isEditPriority, setIsEditPriority] = useState(false);
  const [lessonErrors, setLessonErrors] = useState<Record<number, string>>({});
  const { currentUser } = useSelectorUserInfo();

  function handleEditLesson(
    id: number,
    isDisabledEditTeacher: boolean,
    mentorId: number,
  ) {
    if (isDisabledEditTeacher) {
      navigate(`${PATH.lesson}/${PATH.lesson_edit}/${id}?canEdit=0`);
      return;
    }
    if (isDisabledEdit) {
      navigate(
        `${PATH.lesson}/${PATH.lesson_edit}/${id}?canEdit=0&mentorId=${mentorId}`,
      );

      return;
    }
    const url = mentorId
      ? `${PATH.lesson}/${PATH.lesson_edit}/${id}/?mentorId=${mentorId}`
      : `${PATH.lesson}/${PATH.lesson_edit}/${id}`;
    navigate(url);
  }

  async function handleEditStatusLesson(
    isActive: boolean,
    rowData: LessonItemType,
  ) {
    setSelectedLesson({ isActive, rowData });
    const nextDialogVisibleValue = !dialogVisible;
    setDialogVisible(nextDialogVisibleValue);
    if (nextDialogVisibleValue) return;
    setLoadingGlobal(true);
    const { data: lessonData, error: lessonError } =
      await apiService.lessons.update(rowData.id, {
        status: isActive ? 'active' : 'inactive',
      });
    setLoadingGlobal(false);
    if (lessonError) {
      const errorPublicUnit = lessonError?.errors.find(
        (item: any) => item.error_code === NO_PUBLIC_UNIT,
      );
      if (!isEmpty(errorPublicUnit)) {
        toast?.current?.show(toastErrorMessage(errorPublicUnit.message));
        return;
      }
      return;
    }

    // update status button
    const indexUpdate = lessons.data.findIndex(
      (lesson: LessonItemType) => lesson.id === rowData.id,
    );
    if (indexUpdate !== -1) {
      lessons.data[indexUpdate].status = lessonData.data.status;
    }
  }

  function onPaginationChanged(paginationState: Pagination) {
    setPagination(paginationState);
  }

  const handleOpenProgress = (rowData: any) => {
    if (rowData.created_by_teacher) {
      navigate(
        `${PATH.lesson}/${rowData.id}/progress?created_by=${ROLE.TEACHER}`,
      );
      return;
    }
    navigate(`${PATH.lesson}/${rowData.id}/progress`);
  };

  const onEditPriority = () => {
    if (lessons?.data?.length) {
      setIsEditPriority(true);
    }
  };

  const onSavePriority = async () => {
    if (!isEmpty(lessonErrors)) return;

    setLoadingGlobal(true);
    const lessonsReq = lessons.data
      ?.map((lesson: any) => {
        return {
          lesson_id: lesson?.id,
          priority: lesson?.priority,
          ...(isSupperAdmin && activeIndex
            ? { mentor_id: lesson?.owner?.id }
            : {}),
        };
      })
      .filter((item: any) => item.priority);

    const { error } = await apiService.lessons.collection(`priorities`, {
      method: 'POST',
      body: {
        lessons: lessonsReq,
        ...(isSupperAdmin
          ? { target_role: activeIndex ? ROLE.TEACHER : ROLE.ADMIN }
          : {}),
      },
    });

    if (error) {
      setLoadingGlobal(false);
      setIsEditPriority(false);
      const { error_code, message } = error || {};

      toast?.current?.show(
        toastErrorMessage(
          error_code === 'MFS-E-LEARNING-ADMIN-LESSON-26'
            ? message
            : t('lesson.noti.update_priority_unit_fail'),
        ),
      );
      return;
    }

    await getLessons();

    toast?.current?.show(
      toastMessage(t('lesson.noti.update_priority_unit_success')),
    );
    setIsEditPriority(false);
    setLoadingGlobal(false);
  };

  const handlePriorityChange = (id: number, newPriority: number) => {
    const updatedLessons = lessons.data?.map((lesson: any) =>
      lesson.id === id ? { ...lesson, priority: newPriority } : lesson,
    );
    setLessons({ data: updatedLessons, pagination: currentPagination });
    validatePriorities(updatedLessons, id, t, toast, setLessonErrors);
  };

  useEffect(() => {
    if (activeIndex) {
      setIsEditPriority(false);
    }
  }, [activeIndex]);

  return (
    <>
      <CustomDataTable
        wrapClassName="mt-[20px]"
        values={lessons?.data ?? []}
        colums={LessonListColumns(
          t,
          handleEditLesson,
          handleEditStatusLesson,
          handleOpenProgress,
          isDisabledEdit,
          isEditPriority,
          isTeacher,
          onSavePriority,
          onEditPriority,
          handlePriorityChange,
          lessonErrors,
          currentUser?.id,
          activeIndex,
        )}
        pagination={paginationComputed}
        onPaginationChanged={onPaginationChanged}
      />
      <DialogCustom
        header={t('common.dialog.header')}
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        footer={
          <div className="field">
            <div className="flex justify-end">
              <ButtonCustom
                type="button"
                className="cancle-button p-button-sm mr-2"
                severity="secondary"
                onClick={() => setDialogVisible(false)}
              >
                {t('common.dialog.cancel')}
              </ButtonCustom>
              <ButtonCustom
                type="button"
                onClick={() =>
                  handleEditStatusLesson(
                    selectedLesson.isActive,
                    selectedLesson.rowData,
                  )
                }
              >
                {t('common.dialog.ok')}
              </ButtonCustom>
            </div>
          </div>
        }
      >
        {selectedLesson?.isActive
          ? t('lesson.message_on')
          : t('lesson.message_off')}
      </DialogCustom>
    </>
  );
};

export default LessonList;
