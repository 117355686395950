interface Step {
  title: string;
  borderColor: string;
  backgroundColor: string;
  textColor: string;
}

interface Steps {
  [key: number]: Step;
}

export const STEPS: Steps = {
  1: {
    title: '1周目　動画閲覧',
    borderColor: 'border-warning-200',
    backgroundColor: 'bg-warning-50',
    textColor: 'text-warning-500',
  },
  2: {
    title: '1周目　ワーク',
    borderColor: 'border-warning-200',
    backgroundColor: 'bg-warning-50',
    textColor: 'text-warning-500',
  },
  3: {
    title: '1周目　気づき',
    borderColor: 'border-warning-200',
    backgroundColor: 'bg-warning-50',
    textColor: 'text-warning-500',
  },
  4: {
    title: '2周目　動画閲覧',
    borderColor: 'border-warning-200',
    backgroundColor: 'bg-warning-50',
    textColor: 'text-warning-500',
  },
  5: {
    title: '2周目　ワーク',
    borderColor: 'border-warning-200',
    backgroundColor: 'bg-warning-50',
    textColor: 'text-warning-500',
  },
  6: {
    title: '2周目　気づき',
    borderColor: 'border-warning-200',
    backgroundColor: 'bg-warning-50',
    textColor: 'text-warning-500',
  },
  7: {
    title: 'テスト',
    borderColor: 'border-warning-200',
    backgroundColor: 'bg-warning-50',
    textColor: 'text-warning-500',
  },
  8: {
    title: 'テスト合格',
    borderColor: 'border-green-200',
    backgroundColor: 'bg-green-50',
    textColor: 'text-green-500',
  },
};

export const FIRST_TIME = 1
export const SECOND_TIME = 2
export const STUDENT = 'STUDENT'
export const TEACHER = 'TEACHER'