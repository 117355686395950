export default function IconCheckboxEmpty() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="1" width="23" height="23" rx="5.5" fill="white" />
      <rect x="0.5" y="1" width="23" height="23" rx="5.5" stroke="#98A2B3" />
    </svg>
  );
}
