import { useRef, useCallback } from 'react';

const useDebounceFn = (func: (...args: any[]) => any, wait: number) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const debouncedFunction = useCallback((...args: any[]) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      func(...args);
    }, wait);
  }, [func, wait]);

  return debouncedFunction;
};

export default useDebounceFn;