import { useCallback, useEffect, useRef, useState } from 'react';
import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';
import { Galleria } from 'primereact/galleria';

import { useTranslation } from '@/config/i18n';
import DialogCustom from '@/components/common/ui/dialog';
import ButtonCustom from '@/components/common/ui/button';
import { IconEyesCircle, IconImg } from '@/components/icons';
import { byteToMb } from '@/lib/utils';
import { apiRequest } from '@/services';
import { FIRST_TIME, SECOND_TIME } from '@/shared/constants/progress';
import Loader from '@/components/common/loader/Loader';
import { ProgressDataType } from '@/types/progress';
import { tabTemplate } from './LessonProgress';

interface WorkItem {
  original_name: string;
  file_size: number;
  url: string;
}

interface IGalleryItem {
  itemImageSrc: string;
  thumbnailImageSrc: string;
  alt?: string;
  title?: string;
}

interface IDialogWorkProps {
  unitId: number;
  rowData: ProgressDataType | null;
  isOpenWorkModal: boolean;
  setIsOpenWorkModal: (value: boolean) => void;
}

const WorksDialog: React.FC<IDialogWorkProps> = ({
  unitId,
  rowData,
  isOpenWorkModal,
  setIsOpenWorkModal,
}) => {
  const [t] = useTranslation('');
  const { apiService } = apiRequest();
  const [worksData, setWorksData] = useState([]);
  const [listImgOrigin, setListImgOrigin] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const galleria = useRef(null);

  const getAttachments = async (type: number) => {
    try {
      setIsLoading(true);
      const { data } = await apiService.units.collection(
        `${unitId}/users/${rowData?.id}/works?times=${type}`,
      );
      setWorksData(data?.data?.attachments || []);

      const listImgOrigin = data?.data?.attachments.map((item: WorkItem) => {
        return {
          itemImageSrc: item.url,
          thumbnailImageSrc: item.url,
          alt: item.original_name,
          title: item.original_name,
        };
      });

      setListImgOrigin(listImgOrigin);
    } catch (error) {
      // TODO: Handle when get error
    } finally {
      setIsLoading(false);
    }
  };

  const onBeforeTabChange = (e: TabViewTabChangeEvent) => {
    const type = e.index === 0 ? FIRST_TIME : SECOND_TIME;
    getAttachments(type);
  };

  const itemTemplate = (item: IGalleryItem) => {
    return (
      <div className="h-[420px]">
        <img
          src={item.itemImageSrc}
          alt={item.alt}
          className="w-full h-full overflow-hidden block object-contain"
        />
      </div>
    );
  };

  const renderWorks = useCallback(() => {
    return worksData.map((item: WorkItem, index) => (
      <div
        key={index}
        className="flex p-[12px] justify-between items-center mt-[8px] rounded-[12px] bg-white"
      >
        <div className="flex items-center">
          <div className="rounded-full w-[40px] h-[40px] bg-primary-100 flex justify-center items-center">
            {item.url ? (
              <img
                className="w-[40px] h-[40px] min-w-[40px] rounded-full object-cover"
                src={item.url}
                alt={item.original_name}
              />
            ) : (
              <IconImg />
            )}
          </div>
          <div className="flex flex-col ml-[12px]">
            <p className="text-[16px]">{item.original_name}</p>
            <p className="text-[14px] text-gray-500">
              {byteToMb(item.file_size)} MB
            </p>
          </div>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setActiveIndex(index);
            setIsGalleryOpen(true);
          }}
        >
          <IconEyesCircle />
        </div>
      </div>
    ));
  }, [worksData]);

  useEffect(() => {
    if (rowData?.id && unitId) {
      getAttachments(FIRST_TIME);
    }
  }, [rowData?.id, unitId]);

  return (
    <>
      <DialogCustom
        header={`${rowData?.full_name_kanji} ${t('lesson_progress.modal.title_work')}`}
        visible={isOpenWorkModal}
        onHide={() => setIsOpenWorkModal(false)}
        headerClassName="border-b border-gray-200"
        contentClassName="border-b border-gray-200"
        blockScroll
        footer={
          <div className="field">
            <div className="flex">
              <ButtonCustom
                type="button"
                className="cancle-button w-full"
                severity="secondary"
                onClick={() => setIsOpenWorkModal(false)}
              >
                {t('lesson_progress.close')}
              </ButtonCustom>
            </div>
          </div>
        }
      >
        <TabView
          activeIndex={0}
          className="mt-[20px]"
          panelContainerClassName="bg-gray-50"
          onBeforeTabChange={onBeforeTabChange}
        >
          {Array.from({ length: 2 }, (_, index) => (
            <TabPanel
              key={index}
              header={t(
                `lesson_progress.modal.${index === 0 ? 'first_time' : 'second_time'}_work`,
              )}
              headerTemplate={tabTemplate}
            >
              <div className="flex flex-col gap-3 w-[688px] min-h-32">
                {!isLoading ? (
                  <>
                    {worksData.length > 0 ? (
                      <>
                        <div className="flex justify-end">
                          <p className="text-[14px] text-gray-500">
                            {worksData.length}{' '}
                            {t('lesson_progress.modal.sheet')}
                          </p>
                        </div>
                        {renderWorks()}
                      </>
                    ) : (
                      <div className="flex flex-col justify-center items-center w-[688px] min-h-32">
                        {t('common.table.empty')}
                      </div>
                    )}
                  </>
                ) : (
                  <Loader isLoading={isLoading} />
                )}
              </div>
            </TabPanel>
          ))}
        </TabView>
      </DialogCustom>
      {isGalleryOpen && (
        <DialogCustom
          header={t('lesson_progress.modal.title_preview')}
          visible={isGalleryOpen}
          onHide={() => setIsGalleryOpen(false)}
          className="w-[568px] h-[500px]"
        >
          <Galleria
            ref={galleria}
            value={listImgOrigin}
            activeIndex={activeIndex}
            onItemChange={(e) => setActiveIndex(e.index)}
            circular
            showItemNavigators
            item={itemTemplate}
            showThumbnails={false}
            showItemNavigatorsOnHover
          />
        </DialogCustom>
      )}
    </>
  );
};

export default WorksDialog;
