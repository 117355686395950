import { useCallback, useEffect, useRef, useState } from 'react';
import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';
import dayjs from 'dayjs';
import classNames from 'classnames';

import { useTranslation } from '@/config/i18n';
import DialogCustom from '@/components/common/ui/dialog';
import ButtonCustom from '@/components/common/ui/button';
import { FIRST_TIME, SECOND_TIME } from '@/shared/constants/progress';
import { apiRequest } from '@/services';
import Loader from '@/components/common/loader/Loader';
import { ProgressDataType } from '@/types/progress';
import { tabTemplate } from './LessonProgress';
import { DEFAULT_PAGE } from '@/shared/constants/pagination';
import { IconAvatarComment } from '@/components/icons';

interface Comment {
  id: number;
  content: string;
  created_at: string;
  full_name_kanji: string;
  profile_image?: string;
}

interface IDialogWorkProps {
  unitId: number;
  rowData: ProgressDataType | null;
  isOpenCommentModal: boolean;
  setIsOpenCommentModal: (value: boolean) => void;
  isSelectCoachTab: boolean;
}

const CommentsDialog: React.FC<IDialogWorkProps> = ({
  unitId,
  rowData,
  isOpenCommentModal,
  setIsOpenCommentModal,
  isSelectCoachTab,
}) => {
  const [t] = useTranslation('');
  const [commentData, setCommentData] = useState<Comment[]>([]);
  const { apiService } = apiRequest();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const [hasLoadMore, setHasLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const commentsContainerRef = useRef<HTMLDivElement | null>(null);

  const fetchComments = async (type: number, page: number) => {
    try {
      const { data } = await apiService.units.collection(
        `${unitId}/users/${rowData?.id}/comments?times=${type}&paginate=${page}&limit=10`,
      );
      setCommentData((prev) => [...prev, ...data.data]);
      setHasLoadMore(data.pagination.has_more);
    } catch (error) {
      // TODO: handle error properly
    } finally {
      setIsLoading(false);
      setIsLoadMore(false);
    }
  };

  const handleLoadMore = () => {
    const type = activeIndex === 0 ? FIRST_TIME : SECOND_TIME;
    setCurrentPage((prev) => prev + 1);
    setIsLoadMore(true);
    fetchComments(type, currentPage + 1);
  };

  const onBeforeTabChange = (e: TabViewTabChangeEvent) => {
    const type = e.index === 0 ? FIRST_TIME : SECOND_TIME;
    setActiveIndex(e.index);
    resetComments();
    fetchComments(type, DEFAULT_PAGE);
  };

  const resetComments = () => {
    setIsLoading(true);
    setCurrentPage(DEFAULT_PAGE);
    setCommentData([]);
  };
  const renderComments = useCallback(
    (comments: Comment[]) => {
      return (
        <>
          <div className="flex justify-end">
            <p className="text-[14px] text-gray-500">
              {comments.length} {t('lesson_progress.modal.comment')}
            </p>
          </div>
          {comments.map((item, index) => (
            <CommentItem key={index} item={item} />
          ))}
          {isLoadMore && (
            <div className="flex space-x-2 justify-center items-center">
              <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce"></div>
              <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:0.15s]"></div>
              <div className="h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:0.3s]"></div>
            </div>
          )}
        </>
      );
    },
    [commentData, isLoadMore],
  );

  const CommentItem: React.FC<{ item: Comment }> = ({ item }) => (
    <div className="flex mt-[16px]">
      <div className="w-[40px] h-[40px]">
        {rowData?.profile_image ? (
          <img
            className="w-[100%] h-[100%] object-cover rounded-[16px] min-w-[40px]"
            src={rowData?.profile_image}
            alt={rowData?.full_name_kanji}
          />
        ) : (
          <IconAvatarComment
            containerClassName={classNames(
              'bg-gradient-to-b rounded-[16px] h-[40px] w-[40px] border-white border', {
                'from-[#185DD4] to-[#01DF9C]': !isSelectCoachTab,
                'from-[#DC6803] to-[#FDB022]': isSelectCoachTab,
              }
            )}
          />
        )}
      </div>
      <div className="flex flex-col ml-[12px] w-full max-w-[688px]">
        <div className="flex justify-between">
          <p className="text-[16px] font-semibold">
            {rowData?.full_name_kanji}
          </p>
          <p className="text-[12px] text-gray-500">
            {dayjs(item.created_at).format('YYYY/MM/DD - HH:mm')}
          </p>
        </div>
        <div className="px-[14px] py-[10px] bg-white border border-gray-200 rounded-tr-lg rounded-br-lg rounded-bl-lg break-words">
          {item.content}
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (rowData?.id && unitId) {
      setIsLoading(true);
      fetchComments(FIRST_TIME, 1);
    }
  }, [rowData?.id, unitId]);

  useEffect(() => {
    const handleScroll = () => {
      if (commentsContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          commentsContainerRef.current;
        if (
          scrollTop + clientHeight >= scrollHeight &&
          hasLoadMore &&
          !isLoadMore
        ) {
          handleLoadMore();
        }
      }
    };

    const container = commentsContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasLoadMore, isLoadMore]);

  return (
    <DialogCustom
      header={`${rowData?.full_name_kanji} ${t('lesson_progress.modal.title_comment')}`}
      visible={isOpenCommentModal}
      onHide={() => setIsOpenCommentModal(false)}
      headerClassName="border-b border-gray-200"
      contentClassName="border-b border-gray-200"
      blockScroll
      footer={
        <div className="field">
          <div className="flex">
            <ButtonCustom
              type="button"
              className="cancle-button w-full"
              severity="secondary"
              onClick={() => setIsOpenCommentModal(false)}
            >
              {t('lesson_progress.close')}
            </ButtonCustom>
          </div>
        </div>
      }
    >
      <TabView
        activeIndex={activeIndex}
        className="mt-[20px]"
        panelContainerClassName="bg-gray-50"
        onBeforeTabChange={onBeforeTabChange}
      >
        {Array.from({ length: 2 }, (_, index) => (
          <TabPanel
            key={index}
            header={t(
              `lesson_progress.modal.${index === 0 ? 'first_time' : 'second_time'}_comment`,
            )}
            headerTemplate={tabTemplate}
          >
            <div
              ref={commentsContainerRef}
              className="flex flex-col gap-3 min-w-[688px] min-h-32 overflow-y-auto max-h-96"
            >
              {!isLoading ? (
                commentData.length > 0 ? (
                  renderComments(commentData)
                ) : (
                  <div className="flex justify-center items-center w-[688px] min-h-32">
                    {t('common.table.empty')}
                  </div>
                )
              ) : (
                <Loader isLoading={isLoading && !isLoadMore} />
              )}
            </div>
          </TabPanel>
        ))}
      </TabView>
    </DialogCustom>
  );
};

export default CommentsDialog;
