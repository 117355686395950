import React from 'react';
import LoginForm from './components/loginForm';
import Logo from '@/components/common/logo';
import { useTranslation } from 'react-i18next';

const LoginPage: React.FC = () => {
  const [t] = useTranslation('');
  return (
    <div className="flex-full-center h-screen bg-primary-900">
      <div className="w-[440px]">
        <div className='flex-full-center'>
          <Logo />
        </div>
        <h2 className="title text-white text-[30px] flex-full-center mt-[28px] mb-[30px]">{t('login.title')}</h2>
        <LoginForm />
      </div>
    </div>
  )
}

export default LoginPage;