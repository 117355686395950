import { useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { UserType } from '@/types/chat';
import { UserFormSearchType, userSearchFormSchema } from '@/shared/schema/user';
import { UserFormSearch, UserItem, UserItemSkeleton } from '.';
import { ObjectLiteral } from '@/types/object';
import { useTranslation } from '@/config/i18n';
import { ROLE } from '@/shared/constants/user';
import useDebounceFn from '@/hooks/useDebounceFn';

type Props = {
  selectedUsers?: UserType[];
  handleUserSelect: (value: UserType) => void;
  contacts: UserType[];
  isFetching: boolean;
  fetchContacts: (query?: ObjectLiteral) => Promise<void>;
  resetContacts: () => void;
};

export const ListContactCreateGroup: React.FC<Props> = ({
  selectedUsers,
  handleUserSelect,
  contacts,
  isFetching,
  fetchContacts,
  resetContacts,
}) => {
  const [t] = useTranslation('');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const formMethods = useForm<UserFormSearchType>({
    mode: 'onChange',
    resolver: yupResolver(userSearchFormSchema),
    shouldFocusError: false,
    defaultValues: {
      keyword: '',
    },
  });
  const { handleSubmit } = formMethods;

  const debouncedSearch = useDebounceFn((keyword: string) => {
    resetContacts();
    fetchContacts({ keyword, paginate: 1 });
    const container = containerRef.current;
    if (container) {
      container.scrollTop = 0;
    }
  }, 300);

  const onSubmit = (value: UserFormSearchType) => {
    debouncedSearch(value.keyword);
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  const filterContacts = useMemo(() => {
    return contacts.filter((item) => {
      const hasValidRole = item?.current_roles?.some(
        (role) => ![ROLE.ADMIN, ROLE.SUPPER_ADMIN].includes(role),
      );

      return hasValidRole;
    });
  }, [contacts]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = 0;
    }
  }, [filterContacts]);

  useEffect(() => {
    fetchContacts();
  }, []);

  return (
    <FormProvider {...formMethods}>
      <div className="flex flex-col">
        <div className="my-[16px]">
          {t('chat.member')}: {(selectedUsers?.length || 0) + 1}
        </div>

        <div className="flex flex-col p-[16px] pb-0 rounded-[16px] rounded-b-none bg-white">
          <UserFormSearch onSubmit={handleSubmit(onSubmit)} />

          <div
            className="flex flex-col w-full overflow-y-auto max-h-[460px] min-h-[460px]"
            ref={containerRef}
          >
            {!!filterContacts.length &&
              !isFetching &&
              filterContacts.map((user: UserType) => (
                <UserItem
                  key={user.id}
                  shouldSelect
                  onChange={() => handleUserSelect(user)}
                  isChecked={selectedUsers?.some(
                    (selectedUser) => selectedUser.id === user.id,
                  )}
                  user={user}
                />
              ))}
            {!filterContacts.length && !isFetching && (
              <div className="h-full w-full flex justify-center items-center mt-[30px]">
                {t('chat.no_data')}
              </div>
            )}
            {isFetching && (
              <>
                {Array.from({ length: 10 }).map((_, index) => (
                  <UserItemSkeleton key={index} />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </FormProvider>
  );
};
