export default function IconEyesCircle() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1776 13.9999C18.1776 16.3099 16.3109 18.1766 14.0009 18.1766C11.6909 18.1766 9.82422 16.3099 9.82422 13.9999C9.82422 11.6899 11.6909 9.82324 14.0009 9.82324C16.3109 9.82324 18.1776 11.6899 18.1776 13.9999Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9998 23.6482C18.1182 23.6482 21.9565 21.2215 24.6282 17.0215C25.6782 15.3765 25.6782 12.6115 24.6282 10.9665C21.9565 6.76651 18.1182 4.33984 13.9998 4.33984C9.88148 4.33984 6.04315 6.76651 3.37148 10.9665C2.32148 12.6115 2.32148 15.3765 3.37148 17.0215C6.04315 21.2215 9.88148 23.6482 13.9998 23.6482Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
