import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

import DialogCustom from '@/components/common/ui/dialog';
import ButtonCustom from '@/components/common/ui/button';
import { ConversationType, UserType } from '@/types/chat';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import {
  GroupChatSchema,
  GroupChatType,
} from '@/shared/schema/create-group-chat';
import { auth, db } from '@/lib/firebase';
import { ListContactInviteMember } from './ListContactInviteMember';
import { UploadAvatar } from '@/components/common/ui/upload';
import {
  IMG_DOMAIN,
  INVITE_STATUS,
  NOTIFICATION_TYPE,
} from '@/shared/constants/chat';
import { IconGroupUsers } from '@/components/icons';
import { apiRequest } from '@/services';
import { sendUpdateNameNotification } from '@/lib/utils';
import { useSelectorUserInfo } from '@/components/auth/AuthContext';
import { useFetchContacts } from '../aside';
import { ROLE } from '@/shared/constants/user';
import { confirmDialog } from 'primereact/confirmdialog';

type Props = {
  openPopup?: boolean;
  setOpenPopup: (value: boolean) => void;
  selectedConversation?: ConversationType | null;
  users?: UserType[];
  handleLeaveGroup: () => void;
};

export const AddMemberGroupDialog: React.FC<Props> = ({
  openPopup,
  setOpenPopup,
  selectedConversation,
  users,
  handleLeaveGroup,
}) => {
  const [t] = useTranslation('');
  const currentUser = auth.currentUser;
  const {
    id,
    admin_id,
    invitation_statuses,
    member_ids,
    name,
    image,
    read_statuses,
  } = selectedConversation || {};
  const [isUploading, setIsUploading] = useState(false);
  const { apiService } = apiRequest();
  const groupOwner = useSelectorUserInfo();
  const [selectedUsers, setSelectedUsers] = useState<UserType[]>([]);
  const [selectedUsersTemp, setSelectedUsersTemp] = useState<UserType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAvatar, setShowAvatar] = useState(false);
  const [isDisableSelectAll, setIsDisableSelectAll] = useState(true);
  const formMethods = useForm<GroupChatType>({
    mode: 'onChange',
    resolver: yupResolver(GroupChatSchema),
    defaultValues: {
      group_name: name || '',
      group_avatar: image || '',
      group_members:
        member_ids?.map((id) => ({
          id,
        })) || [],
    },
  });

  const { contacts, isFetching, fetchContacts, resetContacts } =
    useFetchContacts();
  const { setValue, watch, reset, formState } = formMethods;
  const groupAvatar = watch('group_avatar');
  const groupName = watch('group_name')?.trim();

  const initialGroupName = selectedConversation?.name || '';

  const isDirty = useMemo(() => {
    const filteredSelectedUsers = selectedConversation?.member_ids?.filter(
      (user) => {
        return invitation_statuses?.[user]?.status !== INVITE_STATUS.REMOVED;
      },
    );
    const initialMemberIds = filteredSelectedUsers || [];
    const currentMemberIds = selectedUsers.map((member) => member.id);
    const initialAvatar = selectedConversation?.image || '';
    const hasAvatarChanged = initialAvatar !== groupAvatar;
    const hasMemberChanged =
      JSON.stringify(initialMemberIds.sort()) !==
      JSON.stringify(currentMemberIds.sort());
    return formState.isDirty || hasMemberChanged || hasAvatarChanged;
  }, [
    formState.isDirty,
    selectedConversation,
    selectedUsers,
    groupAvatar,
    invitation_statuses,
  ]);

  const groupMembers = watch('group_members');

  const newMemberIds = useMemo(() => {
    const { member_ids = [], invitation_statuses } = selectedConversation || {};
    return selectedUsers
      ?.filter((user) => {
        const status = invitation_statuses?.[user.id]?.status;
        return !member_ids.includes(user.id) || status === INVITE_STATUS.REMOVED;
      })
      ?.map((user) => user.id);
  }, [selectedConversation, selectedUsers]);

  const combinedUsers = useMemo(() => {
    return groupMembers?.map((selectedUser) => {
      const fullInfoUser = users?.find((user) => user.id === selectedUser.id);
      return fullInfoUser ? fullInfoUser : selectedUser;
    }) as UserType[];
  }, [groupMembers, users]);

  const isAdminGroup = useMemo(() => {
    return admin_id === Number(currentUser?.uid);
  }, [selectedConversation, currentUser]);

  const allMembers = useMemo(() => {
    if (!invitation_statuses || !users) return [];

    return invitation_statuses
      ? (Object.keys(invitation_statuses)
        .map((userId) => {
          const user = users.find((user) => user.id.toString() === userId);
          return user &&
            invitation_statuses[userId as unknown as number]?.status !== INVITE_STATUS.REMOVED
            ? user
            : null;
        })
        .filter((user) => user !== null) as UserType[])
      : [];
  }, [selectedConversation, users]);

  const contactOrg = useMemo(() => {
    const hasValidRole = (item: UserType) => {
      return item?.current_roles?.some(
        (role: string) => ![ROLE.ADMIN, ROLE.SUPPER_ADMIN].includes(role),
      );
    };

    const isMember = (item: UserType) => {
      return !allMembers?.some((item2) => item.id === item2.id);
    };

    return contacts.filter(
      (item) => hasValidRole(item) && isMember(item),
    );
  }, [contacts, allMembers]);

  const isSelectedAll = useMemo(() => {
    return contactOrg.length + 1 === selectedUsers.length;
  }, [contactOrg, selectedUsers]);

  const onSelectAll = () => {
    const currentSearchResults = contactOrg.filter(
      (user) =>
        !combinedUsers.some((existingUser) => existingUser.id === user.id)
    );
  
    const allSelected = currentSearchResults.every((user) =>
      selectedUsers.some((selectedUser) => selectedUser.id === user.id)
    );
  
    if (allSelected) {
      setSelectedUsers((prevSelected) =>
        prevSelected.filter(
          (user) => !currentSearchResults.some((searchUser) => searchUser.id === user.id)
        )
      );
    } else {
      setSelectedUsers((prevSelected) => {
        const newUsers = currentSearchResults.filter(
          (user) => !prevSelected.some((selected) => selected.id === user.id)
        );
        return [...prevSelected, ...newUsers];
      });
    }
  
    setSelectedUsersTemp((prevSelectedTemp) => {
      if (allSelected) {
        return prevSelectedTemp.filter(
          (user) => !currentSearchResults.some((searchUser) => searchUser.id === user.id)
        );
      } else {
        const newUsers = currentSearchResults.filter(
          (user) => !prevSelectedTemp.some((selected) => selected.id === user.id)
        );
        return [...prevSelectedTemp, ...newUsers];
      }
    });
  };

  const updateInvitationStatuses = () => {
    if (!selectedConversation) return;

    const updatedInvitationStatuses = { ...invitation_statuses };

    selectedUsers.forEach((user) => {
      if (!updatedInvitationStatuses[user.id]) {
        updatedInvitationStatuses[user.id] = { status: 0 };
      }
    });

    for (const userId in updatedInvitationStatuses) {
      const userStatus = updatedInvitationStatuses[userId].status;

      if (userStatus === INVITE_STATUS.REMOVED) {
        updatedInvitationStatuses[userId].status = INVITE_STATUS.PENDING;
      }

      if (!selectedUsers.some((user) => user.id === parseInt(userId))) {
        delete updatedInvitationStatuses[userId];
      }
    }

    return updatedInvitationStatuses;
  };

  const updateReadStatuses = () => {
    if (!selectedConversation) return;

    const updatedReadStatuses = { ...read_statuses };

    selectedUsers.forEach((user) => {
      if (!updatedReadStatuses[user.id]) {
        updatedReadStatuses[user.id] = {
          last_read_at: null,
          last_read_message_id: '',
        };
      }
    });

    Object.keys(updatedReadStatuses).forEach((userId: string) => {
      if (!selectedUsers.find((user) => user.id === Number(userId))) {
        delete updatedReadStatuses[Number(userId)];
      }
    });

    return updatedReadStatuses;
  };

  const sendNotificationToServer = useCallback(
    (receiverIds: number[], groupName: string) => {
      const paramsPushApp = {
        notification_type: NOTIFICATION_TYPE.NEW_MEMBER,
        receiver_ids: receiverIds,
        content: groupName,
      };

      apiService.notifications.collection('send', {
        method: 'POST',
        body: paramsPushApp,
      });
    },
    [],
  );

  const handleClickSubmit = () => {
    
    if (selectedUsersTemp.length > 0) {
      confirmDialog({
        message: t('chat.confirm_add_new_member_msg'),
        header: t('chat.confirm_add_new_member_title'),
        accept: formMethods.handleSubmit(handleUpdateGroup),
        footer: ({ accept, reject }) => (
          <div className="field">
            <div className="flex justify-end">
              <ButtonCustom
                className="mr-3 w-full rounded-2xl"
                type="button"
                severity="secondary"
                onClick={reject}
              >
                {t('chat.cancel')}
              </ButtonCustom>
              <ButtonCustom
                type="button"
                className="w-full rounded-2xl"
                onClick={accept}
              >
                {t('chat.add_new_member')}
              </ButtonCustom>
            </div>
          </div>
        ),
      });
      return;
    }
    handleUpdateGroup();
  };

  const updateConversation = async () => {
    setIsLoading(true);
    if (!selectedConversation) return;
    const docRef = doc(db, 'conversations', String(id));
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.error('No such document!');
      return;
    }

    const groupUpdateData = {
      name: groupName,
      image: groupAvatar,
      member_ids: selectedUsers?.map((user) => user.id),
    };

    const updatedInviteStatuses = updateInvitationStatuses();
    const updatedReadStatuses = updateReadStatuses();
    const updatedData = {
      ...groupUpdateData,
      invitation_statuses: updatedInviteStatuses,
      read_statuses: updatedReadStatuses,
    };

    if (newMemberIds?.length) {
      sendNotificationToServer(newMemberIds, groupName || '');
    }

    try {
      if (groupName && groupName !== initialGroupName) {
        sendUpdateNameNotification(
          selectedConversation,
          groupName,
          groupOwner.currentUser.full_name_kanji,
          currentUser,
        );
      }
      setOpenPopup(false);
      setIsLoading(false);
      await updateDoc(docRef, updatedData);
      resetForm();
      resetContacts();
    } catch (error) {
      console.error('Error updating group:', error);
      setIsLoading(false);
    }
  };

  const handleUpdateGroup = async () => {
    if (combinedUsers && combinedUsers.length < 1) {
      return;
    }
    if (selectedConversation) {
      await updateConversation();
    }
  };

  const resetForm = () => {
    reset({
      group_name: '',
      group_avatar: '',
      group_members: [],
    });
    setSelectedUsers([...allMembers]);
    setSelectedUsersTemp([]);
  };

  const onHidePopup = () => {
    setOpenPopup(false);
    setTimeout(() => {
      resetForm();
    }, 200);
    resetContacts();
  };

  const handleUserSelect = (userParam: UserType) => {
    setSelectedUsers((prev: UserType[]) => {
      const updatedSelectedUsers = prev.map((user) => user.id);
      if (updatedSelectedUsers.includes(userParam.id)) {
        return prev.filter((user) => user.id !== userParam.id);
      }
      return [...prev, userParam];
    });

    setSelectedUsersTemp((prev: UserType[]) => {
      const updatedSelectedUsers = prev.map((user) => user.id);
      if (updatedSelectedUsers.includes(userParam.id)) {
        return prev.filter((user) => user.id !== userParam.id);
      }
      return [...prev, userParam];
    });
  };

  useEffect(() => {
    if (selectedConversation) {
      reset({
        group_avatar: image || '',
        group_name: name || '',
        group_members:
          member_ids?.map((id) => ({
            id,
          })) || [],
      });
      setSelectedUsers(allMembers);
      setValue(
        'group_members',
        allMembers.map((user) => ({ id: user.id })),
      );
      setIsDisableSelectAll(true);
    }
  }, [selectedConversation, openPopup, reset, allMembers]);

  useEffect(() => {
    if (contactOrg.length > 0) {
      setIsDisableSelectAll(false);
    }
  }, [contactOrg]);

  return (
    <DialogCustom
      header={t('chat.add_member_title')}
      visible={openPopup}
      onHide={onHidePopup}
      className="min-w-[668px] max-w-[668px] dialog-create-group"
      dismissableMask={false}
      blockScroll
      footer={
        <div className="field">
          <div className="flex justify-center gap-[20px]">
            <ButtonCustom
              className="w-full"
              type="button"
              severity="secondary"
              onClick={onHidePopup}
            >
              {t('common.form.cancel')}
            </ButtonCustom>
            {!isAdminGroup && (
              <ButtonCustom
                className="w-full bg-error-500 text-white"
                type="button"
                severity="danger"
                onClick={handleLeaveGroup}
              >
                {t('chat.leave_group')}
              </ButtonCustom>
            )}
            {isAdminGroup && (
              <ButtonCustom
                className="w-full flex gap-3"
                type="button"
                severity="secondary"
                onClick={onSelectAll}
                disabled={isDisableSelectAll}
              >
                {t(isSelectedAll ? 'chat.clear_all' : 'chat.select_all')}
              </ButtonCustom>
            )}
            {isAdminGroup && (
              <ButtonCustom
                type="button"
                className="w-full"
                onClick={handleClickSubmit}
                loading={isLoading}
                disabled={!isDirty || !formState.isValid}
              >
                {t('chat.add_new_member')}
              </ButtonCustom>
            )}
          </div>
        </div>
      }
    >
      <FormProvider {...formMethods}>
        <form>
          <div className="bg-gray-100 p-[16px] pb-0">
            <div className="flex items-center">
              <div className="flex justify-center">
                <div
                  className="relative cursor-pointer"
                  onClick={() => setShowAvatar(true)}
                >
                  {isAdminGroup ? (
                    <UploadAvatar
                      idChooseBtn="choose-image"
                      name="group_avatar"
                      accept=".svg,.jpg,.jpeg,.png"
                      maxSize={5}
                      handleUploaded={(imgUrl: string) => {
                        setValue('group_avatar', imgUrl);
                      }}
                      defaultUrl={groupAvatar}
                      isAdminGroup={true}
                      isUploading={isUploading}
                      setIsUploading={setIsUploading}
                    />
                  ) : (
                    <>
                      {!groupAvatar ? (
                        <div className="h-[82px] w-[82px] rounded-[29px] flex justify-center items-center bg-gradient-to-b from-[#DC6803] to-[#FDB022] overflow-hidden">
                          <IconGroupUsers className="h-[50px] w-[60px] text-white size-large" />
                        </div>
                      ) : (
                        <div className="h-[82px] w-[82px] rounded-[29px] flex justify-center items-center bg-gradient-to-b from-[#DC6803] to-[#FDB022] overflow-hidden">
                          <img
                            src={`${IMG_DOMAIN}/${groupAvatar}`}
                            className="w-full h-full object-cover rounded-[8px]"
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-1 flex-col ml-[16px]">
                <InputTextCustomForm
                  name="group_name"
                  placeholder={t('chat.plh_group_name')}
                  className="border rounded-lg p-2 w-full"
                  disabled={!isAdminGroup}
                  maxLength={200}
                  label={t('chat.group_name')}
                  requiredFlag
                />
              </div>
            </div>

            <ListContactInviteMember
              handleUserSelect={handleUserSelect}
              selectedUsers={selectedUsers}
              fetchContacts={fetchContacts}
              contacts={contactOrg}
              isFetching={isFetching}
              resetContacts={resetContacts}
            />
            <DialogCustom
              visible={showAvatar}
              onHide={() => setShowAvatar(false)}
              className="w-[568px] h-[500px]"
              contentClassName="flex justify-center items-center"
            >
              <div className="w-full h-[400px] flex justify-center items-center">
                {!groupAvatar ? (
                  <div className="w-full h-full flex justify-center items-center bg-gradient-to-b from-[#DC6803] to-[#FDB022] overflow-hidden">
                    <IconGroupUsers className="h-full w-full text-white size-large" />
                  </div>
                ) : (
                  <div className="w-full h-full flex justify-center items-center bg-gradient-to-b from-[#DC6803] to-[#FDB022] overflow-hidden">
                    <img
                      src={`${IMG_DOMAIN}/${groupAvatar}`}
                      alt="Group Avatar"
                      className="w-full h-full object-cover"
                    />
                  </div>
                )}
              </div>
            </DialogCustom>
          </div>
        </form>
      </FormProvider>
    </DialogCustom>
  );
};
