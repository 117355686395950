/* eslint @typescript-eslint/no-explicit-any: 0 */
import { MAX_INPUT } from '@/config/baseYup';
import cx from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import {
  useState,
  type ChangeEvent,
  type FocusEvent,
  type KeyboardEvent,
} from 'react';
import { useController, useFormContext } from 'react-hook-form';

type Props = {
  id?: string;
  name: string;
  label?: string;
  defaultValue?: number | string | null;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  requiredFlag?: boolean;
  fullWidthFlag?: boolean;
  wrapClass?: string;
  labelClass?: string;
  type?: 'text' | 'email' | 'tel' | 'password' | 'hidden' | 'number';
  value?: number | null | string;
  disabled?: boolean;
  isLoading?: boolean;
  inputStyle?: React.CSSProperties;
  min?: number;
  max?: number;
  locale?: string;
  mode?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  suffix?: string;
  prefix?: string;
  errorMessage?: string;
  maxLength?: number | undefined;
  isHideValue?: boolean;
  isNumber?: boolean;
};

export const InputTextCustomForm: React.FC<Props> = ({
  name,
  label,
  placeholder,
  className,
  requiredFlag,
  fullWidthFlag,
  wrapClass,
  labelClass,
  disabled,
  inputStyle,
  min,
  value = null,
  type = 'text',
  isLoading,
  onChange,
  onBlur,
  onKeyDown,
  errorMessage,
  maxLength = MAX_INPUT,
  id,
  isHideValue,
  isNumber,
}) => {
  const [changeType, setChangeType] = useState(isHideValue);

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    control,
    name,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      isNumber
        ? e.target.value.replace(/[^0-9]/g, '')
        : e.target.value;

    if (!maxLength || value.length <= maxLength) {
      field?.onChange(value);
      onChange?.(e);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-') && isNumber) {
      e.preventDefault();
    }
    onKeyDown?.(e);
  };

  return (
    <>
      <div className={cx(wrapClass, 'input')}>
        {label && (
          <div className={cx('label-custom', labelClass)}>
            <label>{label}</label>
            {requiredFlag && (
              <span className={cx('p-error', 'required')}> *</span>
            )}
          </div>
        )}
        {isLoading ? (
          <Skeleton />
        ) : (
          <div className="relative">
            <InputText
              autoComplete="new-password"
              id={id ?? field?.name ?? ''}
              placeholder={placeholder}
              className={cx('input-custom', className, {
                ['p-invalid']: !!errors && errors[name],
                ['w-full']: fullWidthFlag,
              })}
              style={inputStyle}
              {...field}
              name={name}
              value={value ?? field?.value}
              type={changeType ? 'password' : type}
              disabled={disabled}
              min={min}
              onChange={handleChange}
              onBlur={(e: any) => {
                onBlur && onBlur(e);
              }}
              onKeyDown={handleKeyDown}
              maxLength={maxLength}
            />

            {isHideValue && (
              <span
                onClick={() => setChangeType(!changeType)}
                className={cx(
                  'cursor-pointer absolute right-[10px] top-[15px] pi',
                  { 'pi-eye-slash': changeType, 'pi-eye': !changeType },
                )}
              ></span>
            )}
          </div>
        )}

        {!!errors && (errors[name]?.message || errorMessage) && (
          <div
            className={cx('error-message', 'p-error mt-2', {
              ['is-label']: label,
            })}
          >
            {errors[name]?.message?.toString() || errorMessage}
          </div>
        )}
      </div>
    </>
  );
};
