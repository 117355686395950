import { useState, useEffect, useCallback } from 'react';
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  limit,
  startAfter,
  QueryDocumentSnapshot,
  getDocs,
} from 'firebase/firestore';

import { db } from '@/lib/firebase';
import { MessageType } from '@/types/chat';

const MESSAGE_LIMIT = 15;

const useFetchMessages = (conversationId: string | null) => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot | null>(
    null,
  );
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [messagesLimit, setMessagesLimit] = useState(MESSAGE_LIMIT);
  const [hasMore, setHasMore] = useState(true);

  const fetchMessages = useCallback(
    (
      id: string,
      limitCount: number,
      callback: (
        messages: MessageType[],
        lastDoc: QueryDocumentSnapshot,
      ) => void,
    ) => {
      if (!id) return;
      setHasMore(true);
      const messagesRef = query(
        collection(db, 'conversations', id, 'messages'),
        orderBy('created_at', 'desc'),
        limit(limitCount),
      );

      return onSnapshot(messagesRef, (querySnapshot) => {
        const fetchedMessages = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return { id: doc.id, ...data } as MessageType;
        });

        if (querySnapshot.size < MESSAGE_LIMIT) {
          setHasMore(false);
        }

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        callback(fetchedMessages, lastDoc);
      });
    },
    [],
  );

  const loadMoreMessages = useCallback(
    async (containerRef: React.RefObject<HTMLDivElement>) => {
      if (!conversationId || !lastVisible || isLoadingMore || !hasMore) return;
      setIsLoadingMore(true);

      const previousScrollHeight = containerRef.current?.scrollHeight || 0;

      const messagesRef = query(
        collection(db, 'conversations', conversationId, 'messages'),
        orderBy('created_at', 'desc'),
        startAfter(lastVisible),
        limit(messagesLimit),
      );

      try {
        const querySnapshot = await getDocs(messagesRef);

        const moreMessages = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return { id: doc.id, ...data } as MessageType;
        });

        const newLastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastVisible(newLastDoc);
        setMessages((prevMessages) => [...prevMessages, ...moreMessages]);
        setMessagesLimit((prev) => prev + MESSAGE_LIMIT);
        setIsLoadingMore(false);

        if (querySnapshot.size < MESSAGE_LIMIT) {
          setHasMore(false);
        }

        setTimeout(() => {
          if (containerRef.current) {
            const newScrollHeight = containerRef.current.scrollHeight;
            containerRef.current.scrollTop =
              newScrollHeight - previousScrollHeight;
          }
        }, 0);
      } catch (error) {
        setIsLoadingMore(false);
      }
    },
    [conversationId, lastVisible, messagesLimit, isLoadingMore, hasMore],
  );

  useEffect(() => {
    if (conversationId) {
      const unsubscribe = fetchMessages(
        conversationId,
        messagesLimit,
        (fetchedMessages, lastDoc) => {
          setMessages(fetchedMessages);
          setLastVisible(lastDoc);
        },
      );

      return () => unsubscribe && unsubscribe();
    }
  }, [conversationId, fetchMessages, messagesLimit]);

  return { messages, setMessages, loadMoreMessages, isLoadingMore, hasMore };
};

export default useFetchMessages;
