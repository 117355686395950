import { Button } from 'primereact/button';
import dayjs from 'dayjs';
import Select from 'react-select';
import { Tooltip } from 'primereact/tooltip';
import classNames from 'classnames';
import { Avatar } from 'primereact/avatar';

import { ColumnDescription } from '@/components/common/ui/table/ColumnDescription';


export const LessonEditStudentColumns = (
  t: any,
  onDeleteStudent?: (rowData: any) => void,
  onEditQualityVideos?: () => void,
  onSaveQualityVideos?: () => void,
  isEditQualityVideos?: boolean,
  videosPublic?: any[],
  isDisabledEdit?: boolean,
): ColumnDescription[] => {
  const customStyles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 999999999999 }),
    control: (baseStyles: any) => ({
      ...baseStyles,
      flex: 1,
    }),
  };

  const videoOptions = [
    { value: 'All', label: t('lesson_student.all') },
    { value: 0, label: '0' },
    ...Array.from(
      { length: videosPublic ? videosPublic.length - 1 : 0 },
      (_, i) => ({
        value: i + 1,
        label: `${i + 1}`,
      }),
    ),
  ];


  return [
    {
      dataField: 'full_name_kanji',
      text: t('lesson_student.table.name'),
      classname: 'min-w-[180px] text-gray-600',
      formatter: (rowData) => {
        return (
          <div className="flex gap-3 items-center">
            {rowData.profile_image ? (
              <div className="h-[40px] flex-[0_0_40px] flex-full-center text-primary-700 rounded-full">
                <img
                  src={rowData.profile_image}
                  className="object-cover rounded-full h-[100%] w-[100%]"
                />
              </div>
            ) : (
              <Avatar
                icon="pi pi-user text-[20px] ml-[1.5px] mb-[2px]"
                style={{
                  width: '40px',
                  height: '40px',
                }}
                className="rounded-full"
              />
            )}
            <div>
              <Tooltip target={`.tooltip-name-${rowData.id}`}>
                {rowData.full_name_kanji}
              </Tooltip>
              <div
                className={classNames(
                  'line-clamp-1 text-gray-900 break-all',
                  `tooltip-name-${rowData.id}`,
                )}
              >
                {rowData.full_name_kanji}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'email',
      text: t('lesson_student.table.email'),
      classname: 'min-w-[180px]',
      formatter: (rowData) => {
        return (
          <div className="flex">
            <Tooltip target={`.tooltip-email-${rowData.id}`}>
              {rowData.email}
            </Tooltip>
            <div
              className={classNames(
                'line-clamp-1 text-gray-900 break-all',
                `tooltip-email-${rowData.id}`,
              )}
            >
              {rowData.email}
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'phone_number',
      text: t('lesson_student.table.phone_number'),
      classname: 'min-w-[150px] text-gray-600',
    },
    {
      dataField: 'quality_unit',
      headerClassname: 'text-center',
      header: (
        <div className="header-content whitespace-pre">
          {t('lesson_student.table.total_video')}{' '}
          {!isDisabledEdit && (!isEditQualityVideos ? (
            <span
              onClick={() => onEditQualityVideos?.()}
              className="cursor-pointer pi pi-pencil text-primary-700"
            />
          ) : (
            <span
              onClick={() => onSaveQualityVideos?.()}
              className="cursor-pointer pi pi-save text-primary-700"
            />
          ))}
        </div>
      ),
      classname:
        'min-w-[140px] text-gray-600 text-center',
      formatter: (rowData) => {
        const label =
          !rowData.quality_unit && rowData.quality_unit != 0
            ? t('lesson_student.all')
            : rowData.quality_unit;
        const value =
          !rowData.quality_unit && rowData.quality_unit != 0
            ? 'All'
            : rowData.quality_unit;
        const currentSelect = { label, value };

        return (
          <div>
            {!isEditQualityVideos ? (
              label === 'All' ? t('lesson_student.all') : label
            ) : (
              <>
                <Select
                  className="h-[48px] w-full flex items-center justify-start text-start"
                  options={videoOptions}
                  defaultValue={currentSelect}
                  onChange={(selectedOption: any) => {
                    rowData.quality_unit = selectedOption.value;
                  }}
                  menuPortalTarget={document.body}
                  styles={customStyles}
                  placeholder=""
                />
              </>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'mentor',
      text: t('lesson_student.table.mentor_email'),
      classname: 'min-w-[180px] text-gray-600',
    },
    {
      dataField: 'created_at',
      text: t('lesson_student.table.created_date'),
      classname: 'min-w-[110px]',
      formatter: (rowData) => (
        <div>
          {rowData.created_at
            ? dayjs(rowData.created_at.toString()).format('YYYY/MM/DD')
            : ''}
        </div>
      ),
    },
    {
      dataField: 'action',
      text: '',
      sort: false,
      classname:
        'text-center [&>div]:justify-center w-[100px]',
      headerClassname: '[&>div]:justify-center',
      formatter: (rowData) => (
        <div className="flex flex-row h-[36px] items-center">
          <Button
            type="button"
            className="mx-3 p-[10px]"
            onClick={() => onDeleteStudent?.(rowData)}
            disabled={isDisabledEdit}
          >
            <span className="pi pi-trash" />
          </Button>
        </div>
      ),
    },
  ];
};
