import classNames from 'classnames';

import { IconAvatarAdmin, IconAvatarComment } from '@/components/icons';
import { ROLE } from '@/shared/constants/user';

type AvatarProps = {
  role: string;
  height?: number | string;
  width?: number | string;
  className?: string;
};

const roleStyles = {
  [ROLE.STUDENT]: {
    gradientFrom: 'from-blue-300',
    gradientTo: 'to-green-300',
    bgGradientTo: 'bg-gradient-to-b',
    Component: IconAvatarComment,
  },
  [ROLE.TEACHER]: {
    gradientFrom: 'from-orange-800',
    gradientTo: 'to-orange-100',
    bgGradientTo: 'bg-gradient-to-b',
    Component: IconAvatarComment,
  },
  [ROLE.ADMIN]: {
    gradientFrom: 'from-green-300',
    gradientTo: 'to-blue-300',
    bgGradientTo: 'bg-gradient-to-br',
    Component: IconAvatarAdmin,
  },
  [ROLE.SUPPER_ADMIN]: {
    gradientFrom: 'from-green-300',
    gradientTo: 'to-blue-300',
    bgGradientTo: 'bg-gradient-to-br',
    Component: IconAvatarAdmin,
  },
};

export const DefaultAvatar = ({
  role,
  height = 40,
  width = 40,
  className,
}: AvatarProps) => {
  const roleStyle = roleStyles[role];
  if (!roleStyle) return null;

  const { gradientFrom, gradientTo, bgGradientTo, Component } = roleStyle;

  return (
    <Component
      containerClassName={classNames(
        `${bgGradientTo} rounded-full border-white border`,
        `${gradientFrom} ${gradientTo}`,
        `h-[${height}px] w-[${width}px] min-h-[${height}px] min-w-[${width}px] flex justify-center items-center`,
        className,
      )}
    />
  );
};
