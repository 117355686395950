import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ConversationType, MessageType, UserType } from '@/types/chat';
import { UserFormSearchType, userSearchFormSchema } from '@/shared/schema/user';
import { useTranslation } from '@/config/i18n';
import { DefaultAvatar } from '@/components/common/ui/defaultAvatar';
import ButtonCustom from '@/components/common/ui/button';
import DialogCustom from '@/components/common/ui/dialog';
import { UserFormSearch, UserItemSkeleton } from '../aside';
import { useSelectorUserInfo } from '@/components/auth/AuthContext';
import { auth, db } from '@/lib/firebase';
import { getSystemMessage } from '@/lib/utils';
import {
  arrayRemove,
  collection,
  deleteField,
  doc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { Badge } from 'primereact/badge';
import { STATUS } from '@/shared/constants/user';
import { INVITE_STATUS } from '@/shared/constants/chat';
import useDebounceFn from '@/hooks/useDebounceFn';

type Props = {
  selectedConversation?: ConversationType | null;
  isAdminGroup: boolean;
  setIsDeletedUser: (val: boolean) => void;
  users?: UserType[];
  allMembers?: UserType[];
  initializeUsers: (memberIds: number[], keyword?: string) => Promise<void>;
  isLoadingUsers: boolean;
  setUsers: (users: UserType[]) => void;
};

export const ListMember: React.FC<Props> = ({
  selectedConversation,
  isAdminGroup,
  setIsDeletedUser,
  users,
  allMembers,
  initializeUsers,
  isLoadingUsers,
}) => {
  const [t] = useTranslation('');
  const groupOwner = useSelectorUserInfo();
  const currentUser = auth.currentUser;
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const formMethods = useForm<UserFormSearchType>({
    mode: 'onChange',
    resolver: yupResolver(userSearchFormSchema),
    shouldFocusError: false,
    defaultValues: {
      keyword: '',
    },
  });
  const { handleSubmit } = formMethods;

  const countMember = useMemo(() => {
    if (!selectedConversation?.invitation_statuses || !users) return 0;

    return Object.keys(selectedConversation?.invitation_statuses).filter(
      (userId) =>
        selectedConversation?.invitation_statuses?.[parseInt(userId)]?.status !== INVITE_STATUS.REMOVED,
    ).length || 0;
  }, [selectedConversation?.invitation_statuses, selectedConversation?.member_ids]);

  const filterContacts = useMemo(() => {
    return allMembers?.sort((a, b) => {
      if (a.id === selectedConversation?.admin_id) return -1;
      if (b.id === selectedConversation?.admin_id) return 1;
      return 0;
    });
  }, [allMembers]);

  const getNameUser = useCallback(
    (userId: number) => {
      const user = users?.find((item) => item.id === userId);
      return user?.full_name_kanji;
    },
    [users],
  );

  const handleTrashClick = (user: UserType) => {
    setSelectedUser(user);
    setConfirmPopup(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const conversationRef = doc(
        db,
        'conversations',
        String(selectedConversation?.id),
      );
      if (selectedUser?.accepted) {
        await updateDoc(conversationRef, {
          [`read_statuses.${Number(selectedUser?.id)}`]: deleteField(),
          [`invitation_statuses.${Number(selectedUser?.id)}.status`]: INVITE_STATUS.REMOVED,
        });
        sendRemoveNotification();
      } else {
        await updateDoc(conversationRef, {
          member_ids: arrayRemove(Number(selectedUser?.id)),
          [`invitation_statuses.${Number(selectedUser?.id)}`]: deleteField(),
          [`read_statuses.${Number(selectedUser?.id)}`]: deleteField(),
        });
      }
      setConfirmPopup(false);
      setIsDeletedUser(true);
    } catch (error) {
      console.error('Error updating group:', error);
    }
  };

  const sendMessage = useCallback(
    async (content: MessageType) => {
      if (!currentUser) return;
      const messagesRef = collection(
        db,
        'conversations',
        String(selectedConversation?.id),
        'messages',
      );
      const response = doc(messagesRef);
      await setDoc(response, { id: response.id, ...content });
    },
    [selectedConversation, currentUser],
  );

  const sendRemoveNotification = () => {
    if (!selectedConversation) return;

    const messageText = t('chat.remove_to_group', {
      owner_name: groupOwner.currentUser.full_name_kanji,
      member_name: getNameUser(selectedUser?.id || 0),
    });
    const systemMessage = getSystemMessage(
      Number(currentUser?.uid),
      messageText,
    );
    sendMessage(systemMessage);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = 0;
    }
  }, [filterContacts]);

  const debouncedSearch = useDebounceFn((keyword: string) => {
    initializeUsers(selectedConversation?.member_ids || [], keyword);
    const container = containerRef.current;
    if (container) {
      container.scrollTop = 0;
    }
  }, 500);

  const onSubmit = (value: UserFormSearchType) => {
    debouncedSearch(value.keyword);
  };

  return (
    <FormProvider {...formMethods}>
      <div className="flex flex-col">
        <div className="my-[16px]">
          {t('chat.member')}: {countMember}
        </div>

        <div className="flex flex-col p-[16px] pb-0 rounded-[16px] rounded-b-none bg-white">
          <UserFormSearch 
            onSubmit={handleSubmit(onSubmit)}
          />

          <div
            className="flex flex-col w-full overflow-y-auto max-h-[460px] min-h-[460px]"
            ref={containerRef}
          >
            {!!filterContacts?.length && !isLoadingUsers &&
              filterContacts?.map((user: UserType) => (
                <div
                  key={user.id}
                  className="w-full flex items-center py-4 px-4 cursor-pointer border-b-gray-200 border-b"
                >
                  {user?.profile_image ? (
                    <div className="min-w-[40px] w-[40px] h-[40px] rounded-full relative flex-shrink-0">
                      <img
                        src={user?.profile_image}
                        alt="avatar"
                        className="h-full w-full rounded-full object-cover"
                      />
                      {user.accepted && (
                        <Badge
                          value={
                            <i className="pi pi-check text-[8px] text-white" />
                          }
                          severity="success"
                          className="absolute -top-[5px] -right-[5px] flex items-center justify-center w-[20px] h-[20px] rounded-full text-sm" // Đặt kích thước và căn giữa
                        />
                      )}
                    </div>
                  ) : (
                    user.current_roles?.[0] && (
                      <div className="relative">
                        <DefaultAvatar
                          role={user.current_roles?.[0]}
                          className="flex-shrink-0"
                        />
                        {user.accepted && (
                          <Badge
                            value={
                              <i className="pi pi-check text-[8px] text-white" />
                            }
                            severity="success"
                            className="absolute -top-[5px] -right-[5px] flex items-center justify-center w-[20px] h-[20px] rounded-full text-sm" // Đặt kích thước và căn giữa
                          />
                        )}
                      </div>
                    )
                  )}
                  <div className="flex-1 min-w-0 ml-3">
                    <p className="text-[14px] text-gray-700 font-bold truncate">
                      {user?.full_name_kanji}{' '}
                      {user?.status === STATUS.INACTIVE && (
                        <span className='text-gray-400 text-sm'>{'(' + t('chat.inactive') + ')'}</span>
                      )}
                    </p>
                  </div>
                  <div className="flex-shrink-0 overflow-hidden ml-3">
                    {user.id !== selectedConversation?.admin_id &&
                    isAdminGroup && (
                      <i
                        className="pi pi-trash text-error-500 w-[24px] h-[24px] text-[24px]"
                        onClick={() => handleTrashClick(user)}
                      />
                    )}
                  </div>
                </div>
              ))}
            {!filterContacts?.length && !isLoadingUsers && (
              <div className="h-full w-full flex justify-center items-center mt-[30px]">
                {t('chat.no_data')}
              </div>
            )}
            {isLoadingUsers && (
              <>
                {Array.from({ length: 10 }).map((_, index) => (
                  <UserItemSkeleton key={index} />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <DialogCustom
        header={t('chat.delete_user')}
        visible={confirmPopup}
        onHide={() => setConfirmPopup(false)}
        className="min-w-[396px] max-w-[396px]"
        contentClassName="border-b"
        headerClassName="border-b"
        footer={
          <div className="field">
            <div className="flex justify-end">
              <ButtonCustom
                className="mr-3 w-full rounded-2xl"
                type="button"
                severity="secondary"
                onClick={() => setConfirmPopup(false)}
              >
                {t('chat.cancel')}
              </ButtonCustom>
              <ButtonCustom
                type="button"
                className="w-full rounded-2xl"
                onClick={handleDeleteConfirm}
              >
                {t('chat.delete')}
              </ButtonCustom>
            </div>
          </div>
        }
      >
        <div className="flex justify-center items-center flex-col mt-[28px]">
          <p className="text-gray-700 text-base">
            {t('chat.confirm_delete_user', {
              user_name: getNameUser(selectedUser?.id || 0),
            })}
          </p>
        </div>
      </DialogCustom>
    </FormProvider>
  );
};
