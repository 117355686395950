import { download, toCsv } from "@/lib/utils";

export function useTableToCsv(data: any) {
  function buildHeaderRow() {
    return data.headers.map((header: any) => header).join(',');
  }

  function buildBodyRows() {
    return data.body.map((body: any) => body).join(',');
  }

  function buildCsvContent() {
    return [buildHeaderRow(), buildBodyRows()].join('\n');
  }

  function saveAs(filename = 'untitled.csv') {
    const file = toCsv(buildCsvContent());
    download(file, filename);
  }

  return {
    buildHeaderRow,
    buildBodyRows,
    buildCsvContent,
    saveAs,
  };
}
