import cx from 'classnames';
import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import Select, {
  InputActionMeta,
  SingleValue,
  components,
  SingleValueProps,
  OptionProps,
} from 'react-select';
import { uniqBy } from 'lodash';
import { useController, useFormContext } from 'react-hook-form';

import { useTranslation } from '@/config/i18n';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@/shared/constants/pagination';
import { ApiResponse, OptionType, QuerySetting } from '@/types/api';
import useDebounceFn from '@/hooks/useDebounceFn';

type InfinitySelectProps = {
  label?: string;
  service: (params: QuerySetting) => Promise<ApiResponse<any>>;
  defaultValue?: OptionType;
  isDisable?: boolean;
  fieldLabels?: any;
  fieldValue?: string;
  onChange: (val: SingleValue<OptionType>) => void;
  defaultOption?: OptionType[];
  [key: string]: any;
  requiredFlag?: boolean;
  wrapClass?: string;
  className?: string;
  fullWidthFlag?: boolean;
  errorMessage?: string;
  name: string;
  isClearable?: boolean;
  isNormalizeValue?: boolean;
};

const defaultQuery: QuerySetting = {
  limit: DEFAULT_PER_PAGE,
  keyword: '',
  paginate: DEFAULT_PAGE,
};

interface SingleValuePropsType extends SingleValueProps<OptionType> {}

interface OptionPropsType extends OptionProps<OptionType> {}

const SingleValueComponent: React.FC<SingleValuePropsType> = (props) => {
  const { label, subLabel } = props.getValue()[0];

  return (
    <components.SingleValue {...props}>
      <span>{label}</span> <span style={{ color: 'darkgray' }}>{subLabel}</span>
    </components.SingleValue>
  );
};

const OptionComponent: React.FC<OptionPropsType> = (props) => {
  const { label, subLabel } = props.data;

  return (
    <components.Option {...props}>
      <span>{label}</span>
      <span
        style={{
          color: 'darkgray',
        }}
        className='block whitespace-nowrap overflow-hidden text-ellipsis'
      >
        {subLabel}
      </span>
    </components.Option>
  );
};

const InfinitySelect = forwardRef((props: InfinitySelectProps, ref) => {
  const {
    service,
    isDisable,
    onChange,
    fieldLabels,
    requiredFlag,
    className,
    wrapClass,
    fullWidthFlag,
    name,
    label,
    errorMessage,
    defaultOption,
    isClearable,
    isNormalizeValue,
    ...rest
  } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    control,
    name,
  });

  const [t] = useTranslation('');
  const [querySetting, setQuerySetting] = useState<QuerySetting>(defaultQuery);
  const [selectOptions, setSelectOptions] = useState<OptionType[]>([]);
  const [selected, setSelected] = useState<SingleValue<OptionType>>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasLoadMore] = useState<boolean>(false);

  const getSelectOptions = async (params = querySetting) => {
    setIsLoading(true);
    try {
      if (!service) return [];
      const res = await service(params);
      const tempOptions = res.data?.data || selectOptions || [];
      const pagination = res.data?.pagination as any;
      setHasLoadMore(
        pagination?.current_page * pagination?.per_page <
          pagination?.total_item,
      );
      const newOptions = tempOptions.map((m: any) => {
        const label = m[fieldLabels?.label];
        const subLabel = m[fieldLabels?.subLabel];
        const value = m[fieldLabels?.value];
        return {
          label,
          subLabel,
          value: String(value),
        };
      });
      return newOptions as OptionType[];
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToSelectedOption = () => {
    setTimeout(() => {
      const selectedOption = document.querySelector('.select__option--is-selected');
      const optionsContainer = document.querySelector('.select__menu-list');

      if (selectedOption && optionsContainer) {
        const containerTop = optionsContainer.getBoundingClientRect().top;
        const optionTop = selectedOption.getBoundingClientRect().top;
  
        optionsContainer.scrollTop += optionTop - containerTop;
      }
    }, 100);
  };

  const handleFilter = (key: string, inputAction: InputActionMeta) => {
    const allowSearch =
      inputAction.action === 'input-change' ||
      (inputAction.action === 'menu-close' && inputAction.prevInputValue);
    if (!allowSearch) return;
    const params = {
      ...querySetting,
      keyword: key,
      paginate: DEFAULT_PAGE,
    };
    setQuerySetting(params);
    getSelectOptions(params).then((res) => setSelectOptions(res));
  };

  const debouncedHandleFilter = useDebounceFn(
    (key: string, inputAction: InputActionMeta) => {
      handleFilter(key, inputAction);
    },
    300,
  );

  const handleLoadMore = async () => {
    if (!hasMore) return;
    const params = { ...querySetting, paginate: querySetting.paginate + 1 };
    setQuerySetting(params);
    const res = await getSelectOptions(params);
    setSelectOptions((prev) => [...prev, ...res]);
  };

  useEffect(() => {
    getSelectOptions().then((res) => {
      setSelectOptions(res);
    });
  }, []);

  const handleSelected = (val: SingleValue<OptionType>) => {
    field?.onChange(val);
    setSelected(val);
    onChange?.(val);
  };

  const reloadData = async (params?: QuerySetting) => {
    const newQuery = { ...defaultQuery, ...params };
    setQuerySetting(newQuery);
    const res = await getSelectOptions(newQuery);
    setSelectOptions(res);
  };

  useImperativeHandle(ref, () => ({
    reloadOptions: (params?: QuerySetting) => reloadData(params),
  }));

  const valueMemo: OptionType | OptionType[] = useMemo(() => {
    return isNormalizeValue ? field?.value || null : field?.value || selected;
  }, [selected, field]);

  const options = useMemo(() => {
    const combinedOptions = defaultOption?.length
      ? [...defaultOption, ...selectOptions]
      : [...selectOptions];
  
    return uniqBy(combinedOptions, 'value');
  }, [defaultOption, selectOptions]);

  return (
    <div className={cx(wrapClass, 'input')}>
      {label && (
        <div className="label-custom">
          <label>{label}</label>
          {requiredFlag && <span className={cx('p-error', 'required')}>*</span>}
        </div>
      )}
      <Select
        className={cx('infinity-select', '!py-0', className, {
          ['p-invalid']: !!errors && errors[name],
          ['w-full']: fullWidthFlag,
        })}
        classNamePrefix="select"
        isLoading={isLoading}
        isDisabled={isDisable}
        {...field}
        name={name}
        value={valueMemo}
        isClearable={isClearable ?? true}
        onInputChange={debouncedHandleFilter}
        onChange={(val) => handleSelected(val)}
        onMenuScrollToBottom={handleLoadMore}
        noOptionsMessage={() => <>{t('common.no_data')}</>}
        loadingMessage={() => <>{t('common.loading')}</>}
        onMenuOpen={scrollToSelectedOption}
        menuShouldScrollIntoView={true}
        components={
          fieldLabels?.subLabel && {
            SingleValue: SingleValueComponent,
            Option: OptionComponent,
          }
        }
        {...rest}
        options={options}
        styles={{
          placeholder: (base) => ({
            ...base,
            textWrap: 'nowrap',
          }),
        }}
      />

      {!!errors && (errors[name]?.message || errorMessage) && (
        <div
          className={cx('error-message', 'p-error mt-2', {
            ['is-label']: label,
          })}
        >
          {errors[name]?.message?.toString() || errorMessage}
        </div>
      )}
    </div>
  );
});
InfinitySelect.displayName = 'InfinitySelect';
export default InfinitySelect;
