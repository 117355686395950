import { useMemo, useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useTranslation } from '@/config/i18n';
import { Tooltip } from 'primereact/tooltip';

import { ConversationType, UserType } from '@/types/chat';
import { IMG_DOMAIN } from '@/shared/constants/chat';
import { IconGroupUsers } from '@/components/icons';
import { countMember } from '@/lib/utils';
import { auth } from '@/lib/firebase';
import { DefaultAvatar } from '@/components/common/ui/defaultAvatar';

type Props = {
  selectedConversation?: ConversationType | null;
  selectedUser?: UserType | null;
  setIsAddMemberVisible: (val: boolean) => void;
  setIsDeleteMemberVisible: (val: boolean) => void;
  users?: UserType[];
};

export const Header: React.FC<Props> = ({
  selectedConversation,
  selectedUser,
  setIsAddMemberVisible,
  users,
  setIsDeleteMemberVisible,
}) => {
  const currentUser = auth.currentUser;
  const [t] = useTranslation('');
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const { name, image, is_group_chat, member_ids, admin_id } =
    selectedConversation || {};

  const avatarOrg = useMemo(() => {
    return is_group_chat
      ? image !== ''
        ? `${IMG_DOMAIN}/${image}`
        : null
      : selectedUser?.profile_image
        ? selectedUser?.profile_image
        : null;
  }, [selectedUser, selectedConversation]);

  const filteredUser = useMemo(() => {
    return users
      ?.filter((user) => member_ids?.includes(Number(user.id)))
      .find((user) => user.id !== Number(currentUser?.uid));
  }, [users, member_ids, currentUser]);

  const countMemberAccepted = useMemo(() => {
    return countMember(selectedConversation);
  }, [selectedConversation?.invitation_statuses]);

  const role = filteredUser?.current_roles?.[0];

  return (
    <>
      <div className="border-b-gray-300 border-b py-3 px-6">
        <div className="flex items-center justify-between w-full py-3">
          <div className="flex">
            <div className="w-[56px] h-[56px] ">
              {avatarOrg ? (
                <img
                  className="rounded-full w-full h-full object-cover"
                  src={avatarOrg}
                  alt="avatar_conversation"
                />
              ) : (
                <div>
                  {!is_group_chat && role && (
                    <DefaultAvatar height={56} width={56} role={role} />
                  )}
                  {is_group_chat && (
                    <div className="min-w-[56px] w-[56px] h-[56px] rounded-full bg-gradient-to-b from-[#DC6803] to-[#FDB022] flex justify-center items-center">
                      <IconGroupUsers className="h-[24px] w-[20px] text-white" />
                    </div>
                  )}
                </div>
              )}
            </div>
            <Tooltip target=".custom-target-tooltip" mouseTrack />
            <div
              className="custom-target-tooltip flex flex-col ml-[16px] justify-center"
              data-pr-tooltip={
                is_group_chat ? name : selectedUser?.full_name_kanji
              }
            >
              <div className="flex text-[18px] items-center">
                <span className="truncate max-w-[400px] font-semibold">
                  {is_group_chat ? name : selectedUser?.full_name_kanji}
                </span>
                {is_group_chat && (
                  <span className="ml-2">({countMemberAccepted})</span>
                )}
              </div>
            </div>
          </div>
          {selectedConversation && is_group_chat && (
            <>
              <span
                className="text-xl cursor-pointer border px-3 py-1 rounded-lg border-gray-300"
                onClick={(e) =>
                  admin_id === Number(currentUser?.uid)
                    ? overlayPanelRef?.current?.toggle(e)
                    : setIsDeleteMemberVisible(true)
                }
              >
                <i className="pi pi-ellipsis-v text-lg" />
              </span>
              <OverlayPanel
                ref={overlayPanelRef}
                className="p-1 bg-white z-10 border rounded-lg shadow-sm w-auto mt-2"
                unstyled
              >
                <ul className="flex flex-col">
                  {
                    <li
                      className="py-[12px] px-[16px] cursor-pointer hover:bg-gray-200 border-b"
                      onClick={() => setIsAddMemberVisible(true)}
                    >
                      <i className="pi pi-user-plus"></i>
                      <span className="ml-[12px]">
                        {t('chat.memu_add_member')}
                      </span>
                    </li>
                  }
                  <li
                    className="py-[12px] px-[16px] cursor-pointer hover:bg-gray-200"
                    onClick={() => setIsDeleteMemberVisible(true)}
                  >
                    <i className="pi pi-cog"></i>

                    <span className="ml-[12px]">
                      {t('chat.memu_remove_member')}
                    </span>
                  </li>
                </ul>
              </OverlayPanel>
            </>
          )}
        </div>
      </div>
    </>
  );
};
