import UserFormSearch from '@/components/user/UserFormSearch';
import UserList from '@/components/user/UserList';
import UserTopHeader from '@/components/user/UserTopHeader';
import React, { useState } from 'react';

const StudentManagementPage: React.FC = () => {
  const [uploadCsv, setUploadCsv] = useState('');

  return (
    <>
      <UserTopHeader setUploadCsv={setUploadCsv} />
      <UserFormSearch />
      <UserList uploadCsv={uploadCsv} />
    </>
  )
}

export default StudentManagementPage;