const forgot_password = {
  title: 'パスワードをお忘れですか?',
  email: 'メールアドレス',
  email_placeholder: '登録したメールアドレスを入力下さい。',
  description: 'パスワード再設定リンクをお送りします。',
  send_mail_succeed: '{{email}}にパスワード再設定リンクをお送りしました。ご確認ください。',
  button: 'パスワードをリセット',
  return_login: 'ログイン画面へ',
  mail_incorrect: 'メールアドレスが正しくありません。',
  otp_time: 'OTPコードが送信されていますので、少々お待ちください。'
};

export default forgot_password;
