import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from '@/config/i18n';
import LessonEditForm from '@/pages/lesson/components/LessonEditForm'
import { UploadVideoProvider } from '@/pages/lesson/context/UploadVideoContext';

const LessonEditPage: React.FC = () => {
  const [t] = useTranslation('');
  const { id } = useParams<{ id: string | undefined }>();
  const navigate = useNavigate();
  const [isAddOrEditUnit, setIsAddOrEditUnit] = useState(false);


  const handleBack = () => {
    if (isAddOrEditUnit) {
      setIsAddOrEditUnit(false)
      return;
    }

    navigate(-1);
  }

  return (
    <UploadVideoProvider>
      <div className="header-top">
        <h2 className='title flex'>
          <span className='pi pi-arrow-left text-[20px] text-gray-500 cursor-pointer' onClick={handleBack}></span>
          <span className='ml-3'>
            {t(isAddOrEditUnit ? 'lesson_list.edit_unit' : 'lesson.edit_title')}
          </span>
        </h2>
      </div>
      <LessonEditForm id={id} isAddOrEditUnit={isAddOrEditUnit} setIsAddOrEditUnit={setIsAddOrEditUnit} />
    </UploadVideoProvider>
  )
}

export default LessonEditPage;