import classNames from 'classnames';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { useState } from 'react';
import { signOut } from 'firebase/auth';

import { useSelectorUserInfo } from '@/components/auth/AuthContext';
import { apiRequest } from '@/services';
import PATH from '@/routes/path';
import DialogCustom from '@/components/common/ui/dialog';
import ButtonCustom from '@/components/common/ui/button';
import { useTranslation } from '@/config/i18n';
import { auth } from '@/lib/firebase';
import { DefaultAvatar } from '@/components/common/ui/defaultAvatar';

interface SidebarFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  collapsed?: boolean;
  toggleSidebar?: () => void;
}

export const SidebarFooter: React.FC<SidebarFooterProps> = ({
  collapsed,
  toggleSidebar,
}) => {
  const [t] = useTranslation('');
  const [dialogVisible, setDialogVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { apiService } = apiRequest();
  const navigate = useNavigate();
  const { currentUser } = useSelectorUserInfo();
  const { profile_image, current_roles } = currentUser || {};
  const role = current_roles?.[0];

  const onLogout = async () => {
    setIsLoading(true);
    try {
      await Promise.all([apiService.auth.logout(), signOut(auth)]);
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('tokenExpiresAt');
      setIsLoading(false);
      navigate(PATH.login);
    }
  };

  return (
    <div>
      <div className="flex mb-[24px] transition-all duration-300">
        <div
          className={classNames(
            'flex-[0_0_200px] transition-all duration-300',
            {
              '!flex-[0_0_0px] w-0': !collapsed,
            },
          )}
        ></div>
        <Button
          className="mx-[16px] w-[48px] h-[48px] rounded-[16px] bg-primary-700 flex-full-center transition-all duration-300"
          onClick={toggleSidebar}
        >
          <span
            className={classNames('pi text-white', {
              'pi-arrow-left': collapsed,
              'pi-arrow-right': !collapsed,
            })}
          ></span>
        </Button>
      </div>

      <div className="max-w-[280px] max-h-[99px] transition-all duration-300">
        <div className="flex mx-[16px] pt-[24px] pb-[32px] border-t border-primary-700 transition-all duration-300">
          <div
            className={classNames(
              'flex-[0_0_40px] transition-all duration-300',
              {
                'flex-[0_0_48px]': !collapsed,
              },
            )}
          >
            {profile_image ? (
              <div
                style={{
                  width: collapsed ? '40px' : '48px',
                  height: collapsed ? '40px' : '48px',
                }}
                className="transition-all duration-300"
              >
                <img
                  src={profile_image}
                  className="w-[100%] h-[100%] object-cover rounded-[9999px]"
                />
              </div>
            ) : (
              role && (
                <DefaultAvatar
                  role={role}
                  width={collapsed ? 40 : 48}
                  height={collapsed ? 40 : 48}
                  className="transition-all duration-300"
                />
              )
            )}
          </div>

          <div
            className={classNames(
              'min-w-0 flex-1 px-[12px] transition-all duration-300',
              { 'opacity-0 w-0 !p-0 flex-[0_0_0px]': !collapsed },
            )}
          >
            <div
              className={classNames(
                'text-white text-[14px] font-medium tooltip-name text-nowrap',
                {
                  'w-0 opacity-0': !collapsed,
                  truncate: collapsed,
                },
              )}
            >
              <Tooltip target={'.tooltip-name'} position="top">
                {currentUser?.full_name_kanji || ''}
              </Tooltip>
              {currentUser?.full_name_kanji || ''}
            </div>
            <div
              className={classNames(
                'text-primary-100 text-[14px] font-normal truncate',
                { 'w-0 opacity-0': !collapsed, truncate: collapsed },
              )}
            >
              {currentUser?.email || ''}
            </div>
          </div>

          <div
            className={classNames(
              'flex-[0_0_20px] transition-all duration-300',
              {
                'opacity-0 w-0 flex-[0_0_0px]': !collapsed,
              },
            )}
          >
            <i
              className={classNames(
                'pi pi-sign-out text-[18px] text-primary-300 cursor-pointer',
                { 'text-[0px] w-0': !collapsed },
              )}
              onClick={() => setDialogVisible(true)}
            />
          </div>
        </div>
      </div>

      <DialogCustom
        header={t('common.logout.header')}
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        className="dialog-logout w-[400px]"
        footer={
          <div className="field">
            <div className="flex gap-[20px]">
              <ButtonCustom
                type="button"
                className="cancle-button p-button-sm ml-2 flex-1"
                severity="secondary"
                onClick={() => setDialogVisible(false)}
              >
                {t('common.form.cancel')}
              </ButtonCustom>
              <ButtonCustom
                type="button"
                className="flex-1"
                onClick={onLogout}
                loading={isLoading}
              >
                {t('common.dialog.ok')}
              </ButtonCustom>
            </div>
          </div>
        }
      >
        <div className="text-center">{t('common.logout.description')}</div>
      </DialogCustom>
    </div>
  );
};
