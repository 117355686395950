import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ConversationType, IsNeedConfirmMap, IsNotSeenMap } from '@/types/chat';

interface IChatContext {
  conversations: ConversationType[];
  setConversations: Dispatch<SetStateAction<ConversationType[]>>;
  isNotSeenMap: IsNotSeenMap;
  setIsNotSeenMap: Dispatch<SetStateAction<IsNotSeenMap>>;
  isNeedConfirmMap: IsNeedConfirmMap;
  setIsNeedConfirmMap: Dispatch<SetStateAction<IsNeedConfirmMap>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  messageStatus: string;
  setMessageStatus: Dispatch<SetStateAction<string>>;
  hasNewMessage: boolean;
}

const ChatContext = createContext<IChatContext>({
  conversations: [],
  setConversations: () => {},
  isNotSeenMap: {},
  setIsNotSeenMap: () => {},
  isNeedConfirmMap: {},
  setIsNeedConfirmMap: () => {},
  loading: true,
  setLoading: () => {},
  messageStatus: '',
  setMessageStatus: () => {},
  hasNewMessage: false,
});

export function ChatProvider({ children }: { children: ReactNode }) {
  const [conversations, setConversations] = useState<ConversationType[]>([]);
  const [isNotSeenMap, setIsNotSeenMap] = useState<IsNotSeenMap>({});
  const [isNeedConfirmMap, setIsNeedConfirmMap] = useState<IsNeedConfirmMap>(
    {},
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [messageStatus, setMessageStatus] = useState<string>('');

  const hasNewMessage = useMemo(() => {
    return (
      Object.values(isNotSeenMap).some((value) => value === true) ||
      Object.values(isNeedConfirmMap).some((value) => value === true)
    );
  }, [isNotSeenMap, isNeedConfirmMap]);

  const value = {
    conversations,
    setConversations,
    isNotSeenMap,
    setIsNotSeenMap,
    isNeedConfirmMap,
    setIsNeedConfirmMap,
    loading,
    setLoading,
    messageStatus,
    setMessageStatus,
    hasNewMessage,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}

export function useChat() {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within ChatProvider');
  }
  return context;
}
