import { useEffect } from 'react';
import {
  onSnapshot,
  collection,
  where,
  orderBy,
  query,
  doc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';

import { db } from '@/lib/firebase';
import { ConversationType, IsNeedConfirmMap, IsNotSeenMap } from '@/types/chat';
import { useChat } from '@/components/chat/ChatContext';
import { useTranslation } from '@/config/i18n';
import { INVITE_STATUS } from '@/shared/constants/chat';

type Props = {
  currentUserId?: string;
  selectedConversationId?: string;
};

const useConversationsWithMembers = ({
  currentUserId,
  selectedConversationId,
}: Props) => {
  const [t] = useTranslation('');
  const {
    conversations,
    setConversations,
    isNotSeenMap,
    setIsNotSeenMap,
    loading,
    setLoading,
    messageStatus,
    setMessageStatus,
    setIsNeedConfirmMap,
  } = useChat();

  const chatRoomsRef = collection(db, 'conversations');

  const fetchConversationsQuery = () => {
    const baseQuery = query(
      chatRoomsRef,
      where('member_ids', 'array-contains', Number(currentUserId)),
      orderBy('updated_at', 'desc'),
    );

    return baseQuery;
  };

  useEffect(() => {
    const q = fetchConversationsQuery();

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        if (querySnapshot.empty) {
          setConversations([]);
          setLoading(false);
          return;
        }

        const newConversations = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as ConversationType[];

        const updatedIsNotSeenMap: IsNotSeenMap = {};
        const updatedIsNeedToConfirmMap: IsNeedConfirmMap = {};

        newConversations.forEach((conversation) => {
          const readStatuses = conversation.read_statuses || {};
          const memberStatus = readStatuses[currentUserId as unknown as number];
          const isReadBaseOnTime =
            (memberStatus?.last_read_at?.toMillis() || 0) >
            (conversation.last_message?.sent_at?.toMillis() || 0);

          if (memberStatus) {
            updatedIsNotSeenMap[conversation.id] =
              conversation.id === selectedConversationId
                ? false
                : memberStatus?.last_read_message_id !==
                    conversation?.last_message?.message_id &&
                  !isReadBaseOnTime &&
                  typeof conversation?.last_message?.message_id !== 'undefined';
          } else {
            updatedIsNotSeenMap[conversation.id] = true;
          }

          const isNeedToConfirm =
            conversation?.invitation_statuses?.[Number(currentUserId)]
              ?.status === INVITE_STATUS.PENDING && conversation?.is_group_chat;
          updatedIsNeedToConfirmMap[conversation.id] = Boolean(isNeedToConfirm);
        });

        setIsNotSeenMap(updatedIsNotSeenMap);
        setIsNeedConfirmMap(updatedIsNeedToConfirmMap);
        setConversations(newConversations);
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching conversations:', error);
        setLoading(false);
      },
    );

    return () => unsubscribe();
  }, [db, currentUserId, selectedConversationId]);

  useEffect(() => {
    if (!selectedConversationId || !currentUserId) return;

    const conversationDocRef = doc(db, 'conversations', selectedConversationId);

    const unsubscribe = onSnapshot(
      conversationDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const conversationData = docSnapshot.data();
          const lastReadMessageId = conversationData.last_message.message_id;
          const readStatuses = conversationData.read_statuses || {};
          const partnerId = conversationData.member_ids.find(
            (item: number) => item !== Number(currentUserId),
          );

          setMessageStatus(
            readStatuses[partnerId]?.last_read_message_id !== lastReadMessageId
              ? t('chat.sent')
              : t('chat.seen'),
          );

          if (
            lastReadMessageId &&
            readStatuses[currentUserId]?.last_read_message_id !==
              lastReadMessageId
          ) {
            try {
              updateDoc(conversationDocRef, {
                [`read_statuses.${currentUserId}.last_read_message_id`]:
                  lastReadMessageId,
                [`read_statuses.${currentUserId}.last_read_at`]:
                  serverTimestamp(),
              });
            } catch (error) {
              console.error('Error updating read_statuses:', error);
            }
          }
        }
      },
      (error) => {
        console.error('Error listening to conversation:', error);
      },
    );

    return () => unsubscribe();
  }, [currentUserId, selectedConversationId]);

  return {
    conversations,
    isNotSeenMap,
    loading,
    messageStatus,
  };
};

export default useConversationsWithMembers;
