import { useState } from 'react';

import { CreateGroupDialog, DialogContact, UserFormSearch } from '.';
import { IconGroupUsers } from '@/components/icons';

type Props = {
  children: React.ReactNode;
  title: string;
  onSubmit?: () => void;
  initializeUsers: (memberIds: number[]) => Promise<void>;
};

export const ChatAside: React.FC<Props> = ({ children, title, onSubmit, initializeUsers }) => {
  const [openPopupCreateGroup, setOpenPopupCreateGroup] = useState(false);
  const [openPopupContact, setOpenPopupContact] = useState(false);

  return (
    <>
      <div className="w-1/3 border-r">
        <div className="px-4 pt-[8px]">
          <div className="flex justify-between items-center mb-[20px]">
            <p className="text-[18px] font-semibold text-gray-900">
              {title}
            </p>
            <div className="flex h-fit gap-[16px]">
              <span
                className="text-xl cursor-pointer border h-[40px] w-[40px] flex justify-center items-center rounded-lg border-gray-300"
                onClick={() => setOpenPopupContact(true)}
              >
                <i className="pi pi-user text-[18px]" />
              </span>
              <span
                className="text-xl cursor-pointer border h-[40px] w-[40px] flex justify-center items-center rounded-lg border-gray-300"
                onClick={() => setOpenPopupCreateGroup(true)}
              >
                <IconGroupUsers className="text-gray-700 h-[18px] w-[24px] stroke-width-2" />
              </span>
            </div>
          </div>
          <UserFormSearch onSubmit={onSubmit} isSearchMain={true} />
        </div>
        <div className="mt-[18px]">{children}</div>
      </div>
      <CreateGroupDialog
        openPopup={openPopupCreateGroup}
        setOpenPopup={setOpenPopupCreateGroup}
      />
      {openPopupContact && (
        <DialogContact
          openPopup={openPopupContact}
          onHidePopup={setOpenPopupContact}
          initializeUsers={initializeUsers}
        />
      )}
    </>
  );
};
