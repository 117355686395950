import { useContext, useState } from 'react';
import { FileUploadSelectEvent } from 'primereact/fileupload';

import { apiRequest } from '@/services';
import { ImgType } from '@/types/chat';
// import { LIMIT_IMG } from '@/shared/constants/chat';
import { ToastContext } from '@/components/common/CommonToast';
import { toastErrorMessage } from '@/lib/utils';
import { useTranslation } from '@/config/i18n';

const MAX_SIZE_MB = 5;
const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

const useImagesUpload = () => {
  const [t] = useTranslation('');
  const { toast } = useContext(ToastContext);
  const { apiService } = apiRequest();
  const [isUploading, setIsUploading] = useState(false);
  const [selectedImages, setSelectedImages] = useState<ImgType[]>([]);

  const preLoadImage = async (file: File) => {
    const { data: image } = await apiService.files.collection(
      'pre-signed-url',
      {
        method: 'POST',
        body: {
          name: file.name,
          original_name: file.name,
          mime_type: file.type,
          content_length: file.size,
          file_context: 'chat',
        },
      },
    );
    return image;
  };

  const createFormData = (data: any, file: File): FormData => {
    const formData = new FormData();
    Object.entries(data.formInputs).forEach(([key, value]) => {
      formData.append(key, value as string);
    });
    formData.append('file', file);
    return formData;
  };

  const handleImageUpload = async (e: FileUploadSelectEvent) => {
    const files = e.files;
    if (files) {
      const newImages: ImgType[] = [];
      setIsUploading(true);

      files.slice(0, 10 - selectedImages?.length).forEach((file) => {
        const imageDetails: ImgType = {
          name: file.name,
          path: '',
          length: file.size,
          width: 0,
          height: 0,
          status: 'loading',
          error: false,
        };
        newImages.push(imageDetails);
      });

      setSelectedImages((prevImages) => [...prevImages, ...newImages]);

      const uploadPromises = files.slice(0, 10).map(async (file) => {
        try {
          const validFormats = ['image/svg+xml', 'image/jpeg', 'image/png'];
          if (!validFormats.includes(file.type)) {
            updateImageStatus(file, true, t('chat.img_format_err'));
            toast?.current?.show(toastErrorMessage(t('chat.img_format_err')));
            return;
          }
          const image = await preLoadImage(file);
          if (!image?.data) {
            toast?.current?.show(toastErrorMessage(t('chat.img_upload_err')));
            updateImageStatus(file, true, t('chat.img_upload_err'));
            return;
          }

          const { data } = image;
          const formData = createFormData(data, file);

          const imageFromS3 = await fetch(data.formAttributes.action, {
            method: 'POST',
            body: formData,
          });

          if (!imageFromS3.ok) {
            toast?.current?.show(toastErrorMessage(t('chat.img_upload_err')));
            updateImageStatus(file, true, t('chat.img_upload_err'));
            return;
          }

          const reader = new FileReader();
          reader.onloadend = () => {
            const imageDataUrl = data.formInputs.key;
            const url = URL.createObjectURL(file);
            const img = new Image();
            img.src = url;

            img.onload = () => {
              const updatedImage = {
                name: file.name,
                path: imageDataUrl,
                length: file.size,
                width: img.width,
                height: img.height,
                status: file.size > MAX_SIZE_BYTES ? 'error' : 'completed',
              };

              setSelectedImages((prevImages) =>
                prevImages.map((img) =>
                  img.name === file.name ? { ...img, ...updatedImage } : img,
                ),
              );

              if (file.size > MAX_SIZE_BYTES) {
                toast?.current?.show(
                  toastErrorMessage(t('chat.img_size_error')),
                );
                updateImageStatus(file, true, t('chat.img_size_error'));
              }
            };
          };
          reader.readAsDataURL(file);
        } catch (error) {
          console.error('Error uploading image:', error);
          toast?.current?.show(toastErrorMessage(t('chat.img_upload_err')));
          updateImageStatus(file, true, t('chat.img_upload_err'));
        }
      });

      await Promise.all(uploadPromises);
      setIsUploading(false);
    }
  };

  const updateImageStatus = (
    file: File,
    isError: boolean,
    errorMessage: string,
  ) => {
    setSelectedImages((prevImages) =>
      prevImages.map((img) =>
        img.name === file.name
          ? {
            ...img,
            status: 'error',
            error: isError,
            errorMessage: errorMessage,
          }
          : img,
      ),
    );
  };

  const handleRemoveImage = (image: ImgType) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((img) => img !== image),
    );
  };

  return {
    selectedImages,
    isUploading,
    handleImageUpload,
    handleRemoveImage,
    setSelectedImages,
  };
};

export default useImagesUpload;
