import { Button } from 'primereact/button';
import dayjs from 'dayjs';
import { InputSwitch } from 'primereact/inputswitch';
import cx from 'classnames';
import { Tooltip } from 'primereact/tooltip';
import classNames from 'classnames';

import { ColumnDescription } from '@/components/common/ui/table/ColumnDescription';
import { IconBook } from '@/components/icons';
import { LessonItemType } from '@/types/lesson';
import LessonDefaultImg from '@/assets/images/lesson-default-image.png';
import { createPriorityFormatter } from './utils';

export const LessonListColumns = (
  t: any,
  handleEditLesson: (
    id: number,
    isDisabledEditTeacher: boolean,
    mentorId: number,
  ) => void,
  handleEditStatusLesson: (isActive: boolean, rowData: LessonItemType) => void,
  handleOpenProgress: (rowData: any) => void,
  isDisabledEdit: boolean,
  isEditPriority: boolean,
  isTeacher: boolean,
  onSavePriority: () => void,
  onEditPriority: () => void,
  handlePriorityChange: (id: number, newPriority: number) => void,
  lessonErrors: any,
  currentUserId: number,
  activeIndex: number,
): ColumnDescription[] => [
  {
    dataField: 'priority',
    classname: 'w-[80px] text-center',
    headerClassname: 'text-center w-[80px]',
    header: (
      <div className="header-content whitespace-pre">
        {t('lesson_video.table.no')}{' '}
        {!isDisabledEdit &&
          (!isEditPriority ? (
            <span
              onClick={() => onEditPriority?.()}
              className="cursor-pointer pi pi-pencil text-primary-700"
            />
          ) : (
            <span
              onClick={() => onSavePriority?.()}
              className="cursor-pointer pi pi-save text-primary-700"
            />
          ))}
      </div>
    ),
    formatter: createPriorityFormatter(
      isEditPriority as boolean,
      handlePriorityChange as (id: number, newPriority: number) => void,
      lessonErrors,
    ),
  },
  {
    dataField: 'name',
    text: t('lesson.name'),
    classname: 'min-w-[200px]',
    formatter: (rowData) => (
      <>
        <Tooltip target={`.tooltip-lesson-name-${rowData.id}`}>
          {rowData.name}
        </Tooltip>
        <div
          className={cx(
            'line-clamp-2 break-word',
            `tooltip-lesson-name-${rowData.id}`,
          )}
        >
          {rowData.name}
        </div>
      </>
    ),
  },
  {
    dataField: 'thumbnail_url',
    text: t('lesson.thumbnail'),
    classname: 'min-w-[115px]',
    formatter: (rowData) => (
      <div className="w-[42px] h-[42px]">
        <img
          className="w-[100%] h-[100%] object-cover rounded-[8px]"
          src={rowData.thumbnail_url || LessonDefaultImg}
        ></img>
      </div>
    ),
  },
  {
    dataField: 'description',
    text: t('lesson.description'),
    classname: 'min-w-[200px] w-[460px]',
    headerClassname: 'min-w-[200px] w-[460px]',
    formatter: (rowData) => (
      <>
        <Tooltip target={`.tooltip-description-${rowData.id}`}>
          {rowData.description}
        </Tooltip>
        <div
          className={cx(
            'line-clamp-2 break-word',
            `tooltip-description-${rowData.id}`,
          )}
        >
          {rowData.description}
        </div>
      </>
    ),
  },
  {
    dataField: 'created_at',
    text: t('lesson.create_date'),
    classname: 'w-[140px]',
    sort: true,
    formatter: (rowData) => (
      <div>
        {rowData.created_at
          ? dayjs(rowData.created_at.toString()).format('YYYY/MM/DD')
          : ''}
      </div>
    ),
  },
  {
    dataField: 'full_name_kanji',
    text: t('lesson.creator'),
    classname: 'min-w-[110px] w-[200px]',
    formatter: (rowData: any) => (
      <>
        <Tooltip target={`.tooltip-creator-${rowData.id}`}>
          <div>{rowData?.creator?.full_name_kanji || ''}</div>
        </Tooltip>
        <div
          className={cx(
            'line-clamp-2 break-word',
            `tooltip-creator-${rowData.id}`,
          )}
        >
          <div>{rowData?.creator?.full_name_kanji || ''}</div>
        </div>
      </>
    ),
  },
  ...(isTeacher || (!isTeacher && !!activeIndex)
    ? [
      {
        dataField: 'full_name_kanji',
        text: t('lesson.pic'),
        classname: 'min-w-[110px] w-[200px]',
        formatter: (rowData: any) => (
          <>
            <Tooltip target={`.tooltip-owner-${rowData.id}`}>
              {rowData?.owner?.full_name_kanji}
            </Tooltip>
            <div
              className={cx(
                'line-clamp-2 break-word',
                `tooltip-owner-${rowData.id}`,
              )}
            >
              <div>{rowData?.owner?.full_name_kanji || ''}</div>
            </div>
          </>
        ),
      },
    ]
    : []),
  {
    dataField: '',
    text: t('lesson.progress'),
    classname: 'min-w-[80px]',
    headerClassname: 'text-center',
    formatter: (rowData) => (
      <div
        className="flex justify-center w-full text-primary-500 cursor-pointer"
        onClick={() => handleOpenProgress?.(rowData)}
      >
        <IconBook />
      </div>
    ),
  },
  {
    dataField: 'action',
    text: '',
    sort: false,
    classname: 'text-center [&>div]:justify-center w-[160px]',
    headerClassname: '[&>div]:justify-center',
    formatter: (rowData) => {
      const isDisabledEditTeacher =
        isTeacher && rowData?.creator?.id !== currentUserId;
      return (
        <div className="flex flex-row h-[36px] items-center">
          <InputSwitch
            checked={rowData.status === 'active' ? true : false}
            onChange={(e) => handleEditStatusLesson?.(e.value, rowData)}
            disabled={isDisabledEdit || isDisabledEditTeacher}
            className={classNames({
              'opacity-30': isDisabledEdit || isDisabledEditTeacher,
            })}
          />
          <Button
            className="mx-3 p-[10px]"
            onClick={() =>
              handleEditLesson?.(
                rowData.id,
                isDisabledEditTeacher,
                rowData?.owner?.id,
              )
            }
          >
            <span
              className={classNames('pi', {
                'pi-pencil': !(isDisabledEdit || isDisabledEditTeacher),
                'pi-eye text-[18px]': isDisabledEdit || isDisabledEditTeacher,
              })}
            />
          </Button>
        </div>
      );
    },
  },
];
