import { useState, useEffect, useCallback } from 'react';
import { isEmpty } from 'lodash';

import { useSelectorUserInfo } from '@/components/auth/AuthContext';
import { apiRequest } from '@/services';
import useAuth from './useAuth';

const useUserRole = () => {
  const [role, setRole] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<any>({});
  const isAuthenticated = useAuth();
  const { currentUser, setCurrentUser } = useSelectorUserInfo();
  const { apiService } = apiRequest();

  const handleFetchUser = useCallback(async () => {
    if (!isAuthenticated) return;

    if (isEmpty(currentUser)) {
      const { data: userInfo, error: userError } = await apiService.auth.getMe();

      if (userError) {
        setError(userError);
        setLoaded(true);
        return;
      }

      setRole(userInfo.data.current_roles[0] || '');
      setCurrentUser(userInfo.data);
      setLoaded(true);
    } else {
      setRole(currentUser.current_roles[0] || '');
      setLoaded(true);
    }
  }, [currentUser, isAuthenticated]);

  useEffect(() => {
    handleFetchUser();
  }, [handleFetchUser]);

  return {
    role,
    loaded,
    error,
  };
};

export default useUserRole;
