import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Galleria } from 'primereact/galleria';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { LinkItUrl, LinkItEmail } from 'react-linkify-it';
import { Timestamp } from 'firebase/firestore';
import { Tooltip } from 'primereact/tooltip';

import { ImgType, MessageType, UserType } from '@/types/chat';
import {
  DELETE_MESSAGE_TYPE,
  LIMIT_SHOW_IMG,
  MESSAGE_TYPE,
} from '@/shared/constants/chat';
import { formatFullTimestamp, formatTimestamp } from '@/lib/utils';
import { DefaultAvatar } from '@/components/common/ui/defaultAvatar';

type Props = {
  content: MessageType;
  isSender: boolean;
  time: string;
  dropdownVisible: boolean;
  editingMessageIndex: string | null;
  hoveredMessageIndex: number | null;
  index: number;
  handleDropdownToggle(val: string): void;
  handleDeleteMessage(val: string, type: string): void;
  handleEditMessage(val: MessageType): void;
  users?: UserType[] | null;
  imgDomain?: string;
  isGroup?: boolean;
  messageStatus?: string;
  isSendingMessage?: boolean;
  messagesContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  isLastMessage?: boolean;
};

type GalleryItemType = {
  itemImageSrc: string;
  thumbnailImageSrc: string;
  alt?: string;
  title?: string;
};

const itemTemplate = (item: GalleryItemType) => {
  return (
    <div className="h-[420px]">
      <img
        src={item.itemImageSrc}
        alt={item.alt}
        className="w-full h-full overflow-hidden block object-contain"
      />
    </div>
  );
};

export const BubbleItem: React.FC<Props> = ({
  content,
  isSender,
  time,
  dropdownVisible,
  editingMessageIndex,
  hoveredMessageIndex,
  index,
  handleDropdownToggle,
  handleDeleteMessage,
  handleEditMessage,
  users,
  imgDomain,
  isGroup,
  messageStatus,
  isSendingMessage,
  messagesContainerRef,
  isLastMessage,
}) => {
  const [t] = useTranslation();
  const galleria = useRef(null);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isDropdownReverse, setIsDropdownReverse] = useState(false);

  const shouldShowIndicator =
    isSender && !content.is_unsent && hoveredMessageIndex === index;

  const filteredUser = useMemo(() => {
    return users?.find((item) => item.id === Number(content.sender_id));
  }, [users, content]);

  const numberImgsLeft = useMemo(() => {
    return content.files && `+${content.files?.length - LIMIT_SHOW_IMG}`;
  }, [content.files]);

  const messageImgs = useMemo(() => {
    return content.files?.map((item: ImgType) => {
      return {
        itemImageSrc: `${imgDomain}/${item.path}`,
        thumbnailImageSrc: `${imgDomain}/${item.path}`,
        alt: item.name,
        title: item.name,
      };
    });
  }, [content.files]);

  const handleClickImg = (index: number) => {
    setActiveIndex(index);
    setIsGalleryOpen(true);
  };

  const role = filteredUser?.current_roles?.[0];

  useEffect(() => {
    if (dropdownVisible && editingMessageIndex === content.id) {
      const container = messagesContainerRef?.current;
      const element = document.querySelector(
        `.date_time_right_${index}`,
      ) as HTMLElement;

      if (container && element) {
        const containerRect = container.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();

        const dropdownBottom = elementRect.bottom + 50;
        setIsDropdownReverse(dropdownBottom > containerRect.bottom);
      }
    }
  }, [dropdownVisible, editingMessageIndex, index, messagesContainerRef]);

  return (
    <>
      {content.message_type === MESSAGE_TYPE.SYSTEM ? (
        <div className="w-full flex justify-center">
          <div className="text-gray-500 bg-gray-50 border border-gray-200 px-4 py-2 rounded-lg flex flex-col items-center">
            <span>{formatTimestamp(t, content.created_at as Timestamp)}</span>
            <span>{content.message}</span>
          </div>
        </div>
      ) : (
        <div
          className={classNames('flex mb-3 w-full', {
            'justify-start': !isSender,
            'justify-end': isSender,
          })}
        >
          <div className="flex">
            {isSender && (
              <>
                <Tooltip target={`.date_time_right_${index}`} mouseTrack />
                <span
                  className={`date_time_right_${index} flex items-end text-gray-400 text-xs mr-1`}
                  data-pr-tooltip={formatFullTimestamp(
                    content.created_at as Timestamp,
                  )}
                  data-pr-position="left"
                >
                  {time} {content.is_edited ? t('chat.edited_message') : ''}
                </span>
              </>
            )}
            {!isSender && (
              <>
                {filteredUser?.profile_image ? (
                  <div className="flex justify-center items-center bg-blue-25 rounded-full h-[40px] w-[40px] mr-[12px]">
                    <img
                      src={filteredUser?.profile_image}
                      alt=""
                      className="w-full h-full rounded-full"
                    />
                  </div>
                ) : (
                  role && (
                    <div className="mr-[12px]">
                      <DefaultAvatar role={role} />
                    </div>
                  )
                )}
              </>
            )}
            <div>
              {isGroup && !isSender && (
                <div className="truncate max-w-[100px]">
                  <Tooltip target={`.name_${index}`} mouseTrack />
                  <span className={`text-gray-500 text-sm name_${index}`}
                    data-pr-tooltip={filteredUser?.full_name_kanji}
                  >
                    {filteredUser?.full_name_kanji}
                  </span>
                </div>
              )}
              <div className="flex">
                <div
                  className={classNames('break-word p-3 max-w-[500px] w-fit', {
                    'rounded-br-lg rounded-tl-lg rounded-bl-lg bg-primary-50 border border-primary-100':
                      isSender,
                    'rounded-bl-lg rounded-br-lg rounded-tr-lg bg-gray-50 border border-gray-200':
                      !isSender,
                    'text-gray-400': content.is_unsent,
                    'min-w-[200px]': content.message_type === MESSAGE_TYPE.FILE,
                  })}
                >
                  <div className="relative">
                    <>
                      {content.is_unsent ? (
                        t('chat.deleted_message')
                      ) : (
                        <>
                          {content.message_type === MESSAGE_TYPE.TEXT && (
                            <p className="whitespace-break-spaces">
                              <LinkItUrl className="text-primary-700 underline">
                                <LinkItEmail className="text-primary-700 underline">
                                  {content.message}
                                </LinkItEmail>
                              </LinkItUrl>
                            </p>
                          )}
                          {content.message_type === MESSAGE_TYPE.FILE &&
                          content?.files && (
                            <div
                              className={classNames('grid gap-2', {
                                'grid-cols-2': content?.files.length !== 1,
                              })}
                            >
                              {content.files
                                .slice(0, 4)
                                .map((item: ImgType, index: number) => (
                                  <div
                                    className="relative bg-gray-200 max-w-[200px] min-h-[200px] h-auto"
                                    key={index}
                                  >
                                    <img
                                      src={`${imgDomain}/${item.path}`}
                                      alt="Uploaded"
                                      className={classNames(
                                        'w-full max-h-[200px] min-h-[200px] h-auto object-contain cursor-pointer',
                                        {
                                          'opacity-50':
                                            item.status === 'error',
                                        },
                                      )}
                                      onClick={() => handleClickImg(index)}
                                    />

                                    {item.status === 'error' && (
                                      <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                                        <i className="pi pi-exclamation-circle text-error-500 text-2xl"></i>
                                      </div>
                                    )}

                                    {index === LIMIT_SHOW_IMG && (
                                      <div
                                        className="absolute top-0 bottom-0 left-0 right-0 bg-black opacity-50 flex justify-center items-center cursor-pointer"
                                        onClick={() => handleClickImg(index)}
                                      >
                                        <p className="text-5xl text-white">
                                          {numberImgsLeft}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          )}
                        </>
                      )}
                    </>
                    {shouldShowIndicator && (
                      <div className="absolute top-1/2 -left-10 p-1 -translate-y-1/2 z-[9999]">
                        <i
                          className="pi pi-ellipsis-h cursor-pointer"
                          onClick={() =>
                            handleDropdownToggle(String(content.id))
                          }
                        />
                        {dropdownVisible &&
                        editingMessageIndex === content.id && (
                          <div
                            className={classNames(
                              'absolute  bg-white shadow-md mt-1 rounded-lg min-w-[180px]',
                              {
                                'left-0': !isSender,
                                'right-0': isSender,
                                'mt-1': !isDropdownReverse,
                                '-mb-1 bottom-full': isDropdownReverse,
                              },
                            )}
                            onMouseLeave={() => handleDropdownToggle('')}
                          >
                            {content.message_type !== MESSAGE_TYPE.FILE && (
                              <div
                                className="block px-4 py-2 hover:bg-gray-200 w-full cursor-pointer border-b"
                                onClick={() => handleEditMessage(content)}
                              >
                                <i className="pi-pen-to-square pi mr-2"></i>
                                <span>{t('chat.edit')}</span>
                              </div>
                            )}
                            <div
                              className="block px-4 py-2 hover:bg-gray-200 w-full cursor-pointer border-b"
                              onClick={() =>
                                handleDeleteMessage(
                                  String(content.id),
                                  DELETE_MESSAGE_TYPE.DELETE,
                                )
                              }
                            >
                              <i className="pi pi-trash mr-2"></i>
                              <span>{t('chat.delete')}</span>
                            </div>
                            <div
                              className="block px-4 py-2 hover:bg-gray-200 w-full cursor-pointer"
                              onClick={() =>
                                handleDeleteMessage(
                                  String(content.id),
                                  DELETE_MESSAGE_TYPE.UNSENT,
                                )
                              }
                            >
                              <i className="pi pi-trash mr-2"></i>
                              <span>{t('chat.unsent')}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {isSender && isLastMessage && (
                      <span className="absolute -bottom-9 right-0 text-xs text-gray-400 whitespace-nowrap">
                        {isSendingMessage
                          ? t('chat.sending')
                          : !isGroup
                            ? messageStatus
                            : messageStatus !== t('chat.seen') &&
                              t('chat.sent')}
                      </span>
                    )}
                  </div>
                </div>
                {!isSender && (
                  <>
                    <Tooltip target={`.date_time_left_${index}`} mouseTrack />
                    <span
                      className={`date_time_left_${index} flex items-end text-gray-400 text-xs ml-[6px]`}
                      data-pr-tooltip={formatFullTimestamp(
                        content.created_at as Timestamp,
                      )}
                      data-pr-position="right"
                    >
                      {content.is_edited ? t('chat.edited_message') : ''} {time}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isGalleryOpen && (
        <Dialog
          visible={isGalleryOpen}
          onHide={() => setIsGalleryOpen(false)}
          className="w-[800px] h-[530px]"
          draggable={false}
        >
          <Galleria
            ref={galleria}
            value={messageImgs}
            activeIndex={activeIndex}
            onItemChange={(e) => setActiveIndex(e.index)}
            circular
            showItemNavigators
            item={itemTemplate}
            showThumbnails={false}
            showItemNavigatorsOnHover
          />
        </Dialog>
      )}
    </>
  );
};
