import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'primereact/tooltip';

import useSearch from '@/hooks/useSearch';
import { ColumnDescription } from '@/components/common/ui/table/ColumnDescription';
import {
  IconEyes,
  IconComment,
  IconImg,
  IconLock,
  IconPurchase,
  IconEyesSlash,
} from '@/components/icons';
import { STEPS } from '@/shared/constants/progress';
import { ProgressDataType } from '@/types/progress';

const STATUS_OF_UNIT = {
  PUBLIC: 'public',
  DRAFT: 'draft',
};

const MAX_STEP = 8;

export const ProgressStudentColumns = (
  t: (key: string) => string,
  progressData: ProgressDataType[],
  setIsOpenCommentModal: (value: boolean) => void,
  setIsOpenWorkModal: (value: boolean) => void,
  setRowData: (value: ProgressDataType) => void,
  setUnitId: (value: number) => void,
  paginate: number,
  limit: number,
  totalRecords: number,
): ColumnDescription[] => {
  const { query } = useSearch();
  const [isSortASC, setIsSortASC] = useState(true);

  ((data, currentPage, perPage, isSortASC) => {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    const paginatedData = data.slice(
      startIndex,
      Math.min(endIndex, data.length),
    );

    return paginatedData.sort((a, b) => {
      if (isSortASC) {
        return a.id - b.id;
      }
      return b.id - a.id;
    });
  })(progressData, paginate, limit, isSortASC);

  const getStepStatusClassNames = (stepStyles: (typeof STEPS)[1]) => {
    return `
    ${stepStyles?.backgroundColor} 
    ${stepStyles?.textColor} 
    ${stepStyles?.borderColor} 
    justify-center 
    font-bold 
    text-[12px] 
    py-[4px] 
    rounded 
    flex 
    border
  `;
  };

  const renderUnitContent = (
    status: string,
    rowData: ProgressDataType,
    index: number,
  ) => {
    const unit = rowData.units[index];
    const prevUnit = rowData.units[index - 1];
    const allUnitNeedPurchase = rowData.quality_unit === 0;
    const unitPublics = rowData.units
      .filter((item) => item.status === STATUS_OF_UNIT.PUBLIC)
      .map((item) => item.priority)
      .slice(0, rowData.quality_unit);
    const maxPriority = Math.max(...unitPublics);
    const isFirstUnit = index === 0;
    const handleModalOpen = (typeModal: string) => {
      setUnitId(unit?.id);
      setRowData(rowData);
      typeModal === 'works'
        ? setIsOpenWorkModal(true)
        : setIsOpenCommentModal(true);
    };

    const currentStep = (() => {
      const { current_step: currentStep } = unit;

      if (isFirstUnit) {
        if (currentStep === null) return 1;
        return currentStep + 1;
      }

      return currentStep + 1;
    })();

    const renderDraftContent = () => (
      <div className="flex justify-center items-center">
        <IconEyesSlash />
      </div>
    );

    const renderPurchaseContent = () => (
      <div className="text-gray-500 px-[24px] py-[10px] flex justify-center">
        <IconPurchase />
      </div>
    );

    const renderLockedContent = () => (
      <div className="flex justify-center items-center">
        <IconLock />
      </div>
    );

    const renderPublicContent = () => (
      <div>
        <div className={getStepStatusClassNames(STEPS[currentStep])}>
          {STEPS[currentStep]?.title}
        </div>
        <div className="mt-[8px] flex bg-gray-50 text-gray-700 border border-gray-300 justify-center font-bold text-[12px] py-[4px] rounded">
          {t('lesson_progress.viewed')} {unit.user_total_viewed ?? 0}
        </div>
        <div className="flex justify-between mt-[8px]">
          <div
            className="w-1/2 mr-[4px] bg-primary-50 border border-primary-200 rounded py-[4px] flex justify-center cursor-pointer text-primary-500"
            onClick={() => handleModalOpen('works')}
          >
            <IconImg />
          </div>
          <div
            className="w-1/2 ml-[4px] bg-primary-50 border border-primary-200 rounded py-[4px] flex justify-center cursor-pointer text-primary-500"
            onClick={() => handleModalOpen('comments')}
          >
            <IconComment />
          </div>
        </div>
      </div>
    );

    if (status === STATUS_OF_UNIT.DRAFT) return renderDraftContent();
    if ((unit.priority && unit.priority > maxPriority) || allUnitNeedPurchase)
      return renderPurchaseContent();
    if (status === STATUS_OF_UNIT.PUBLIC) {
      let shouldLock = false;
      for (let i = 0; i < index; i++) {
        const currentUnit = rowData.units[i];
        if (
          currentUnit.status !== STATUS_OF_UNIT.DRAFT &&
          currentUnit.current_step < MAX_STEP - 1
        ) {
          shouldLock = true;
          break;
        }
      }
      if (
        unit.current_step < MAX_STEP - 1 &&
        prevUnit?.status !== STATUS_OF_UNIT.DRAFT &&
        prevUnit?.current_step < MAX_STEP - 1
      ) {
        shouldLock = true;
      }
      if (shouldLock && currentStep !== MAX_STEP) {
        return renderLockedContent();
      }
      return renderPublicContent();
    }

    return null;
  };

  useEffect(() => {
    const sortColumn = query['sort_columns'];
    setIsSortASC(!String(sortColumn)?.includes('-'));
  }, [query]);

  return [
    {
      dataField: 'no',
      text: t('lesson_progress.no'),
      classname: 'min-w-[40px] text-center',
      headerClassname: 'min-w-[40px] text-center',
      sort: true,
      frozen: progressData?.length > 0,
      sortField: 'id',
      formatter: (_, columnProps) => {
        const startIndex = (paginate - 1) * limit;
        return isSortASC
          ? startIndex + columnProps.rowIndex + 1
          : totalRecords - startIndex - columnProps.rowIndex;
      },
    },
    {
      dataField: 'full_name_kanji',
      text: t('lesson_progress.name'),
      headerClassname: 'min-w-[200px]',
      frozen: progressData?.length > 0,
      formatter: (rowData: any) => {
        return (
          <div>
            <Tooltip target={`.tooltip-name-${rowData.id}`}>
              {rowData.full_name_kanji}
            </Tooltip>
            <div
              className={classNames(
                'line-clamp-1 text-gray-900 break-all',
                `tooltip-name-${rowData.id}`,
              )}
            >
              {rowData.full_name_kanji}
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'email',
      text: t('lesson_progress.email'),
      headerClassname: 'min-w-[180px]',
      frozen: progressData?.length > 0,
    },
    {
      dataField: 'progress',
      text: t('lesson_progress.progress'),
      classname: 'text-center',
      headerClassname: 'text-center min-w-[100px]',
      sort: true,
      frozen: progressData?.length > 0,
      formatter: (rowData: any) => {
        const qualityUnits = rowData.quality_unit;
        const totalUnitsViewed = rowData.total_unit_completed;
        if (qualityUnits > 0) {
          let percent = (totalUnitsViewed / qualityUnits) * 100;
          percent = Math.min(percent, 100);
          if (percent % 1 === 0) {
            return `${percent}%`;
          }
          return `${percent.toFixed(1)}%`;
        }
        return '0%';
      },
    },
    ...Array.from({ length: progressData?.[0]?.units.length }, (_, i) => ({
      dataField: `units${i + 1}`,
      header: (table: any) => {
        const { units = [] } = table.props?.value?.[0] || {};
        const unit = units[i] || {};
        const {
          id: unitId,
          name: unitName,
          unit_total_viewed: unitTotalViewed = 0,
        } = unit;

        return (
          <div className="min-w-[150px] flex justify-between">
            <Tooltip target={`.tooltip-${unitId}`} position="top">
              {unitName}
            </Tooltip>
            <div
              className={classNames(
                'w-[70px] truncate text-ellipsis',
                `tooltip-${unitId}`,
              )}
            >
              <span>{unitName}</span>
            </div>
            <span>/</span>
            <span className="flex">
              {unitTotalViewed} <IconEyes />
            </span>
          </div>
        );
      },
      classname:
        'min-w-[120px] text-center border-l border-r !px-[16px] !py-[10px] w-[183px] h-[127px]',
      headerClassname: 'text-center border-l border-r',
      formatter: (rowData: any) => {
        if (!rowData.units.length) return;
        const unit = rowData.units[i];
        const key =
          unit.status === STATUS_OF_UNIT.DRAFT
            ? STATUS_OF_UNIT.DRAFT
            : STATUS_OF_UNIT.PUBLIC;
        return <>{renderUnitContent(key, rowData, i)}</>;
      },
    })),
  ];
};
