import { useCallback, KeyboardEvent, LegacyRef } from 'react';
import { FileUpload, FileUploadSelectEvent } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';

import ButtonCustom from '@/components/common/ui/button';
import IconSendMessage from '@/components/icons/IconSendMessage';
import IconUploadImg from '@/components/icons/IconUploadImg';
import { useTranslation } from '@/config/i18n';
import { IMG_DOMAIN, LIMIT_IMG } from '@/shared/constants/chat';
import { ConversationType, ImgType, MessageType } from '@/types/chat';

type Props = {
  inputRef?: LegacyRef<HTMLTextAreaElement>;
  inputMessage: string;
  setInputMessage: (val: string) => void;
  selectedConversation?: ConversationType | null;
  handleImageUpload: (val: FileUploadSelectEvent) => void;
  selectedImages: ImgType[];
  handleRemoveImage: (val: ImgType) => void;
  isUploading: boolean;
  handleSendMessage: () => void;
  handleSendImage: () => void;
  handleCancelEditMessage: () => void;
  editingMessage?: MessageType | null;
};

export const InputMessagePart: React.FC<Props> = ({
  inputRef,
  inputMessage,
  setInputMessage,
  selectedConversation,
  handleImageUpload,
  selectedImages,
  handleRemoveImage,
  handleSendMessage,
  handleSendImage,
  isUploading,
  handleCancelEditMessage,
  editingMessage,
}) => {
  const [t] = useTranslation('');
  const hasInvalidImage = selectedImages.some(
    (image) => image.status === 'error',
  );
  const onKeyUp = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage],
  );

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
    }
  }, []);

  return (
    <div className="flex flex-col w-full border rounded-lg">
      {selectedImages.length < 1 && (
        <InputTextarea
          ref={inputRef}
          placeholder={t('chat.plh_input_message')}
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          className="flex-1 p-2 rounded-lg focus:outline-none focus:border-blue-500 resize-none"
          rows={2}
          disabled={Boolean(!selectedConversation) || selectedImages.length > 0}
          unstyled
          maxLength={3000}
        />
      )}
      <div className="flex bg-gray-100 items-center p-3 justify-between">
        <div className="flex items-center">
          <FileUpload
            mode="basic"
            accept=".svg,.jpg,.jpeg,.png"
            onSelect={handleImageUpload}
            chooseOptions={{
              icon: <IconUploadImg />,
              iconOnly: true,
              className:
                'text-primary-600 flex justify-center w-fit bg-white border rounded-lg p-1 mr-4',
            }}
            auto
            multiple
            disabled={
              selectedImages.length === LIMIT_IMG || inputMessage.length > 0
            }
          />
          {Boolean(selectedConversation) && (
            <>
              <div className="flex flex-wrap gap-2">
                {selectedImages.map((item: ImgType, index: number) => (
                  <div className="flex" key={index}>
                    <div className="w-16 h-16 mr-2 relative border">
                      {item.status === 'loading' ? (
                        <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50">
                          <div className="spinner-border animate-spin border-2 border-t-4 border-blue-500 w-6 h-6 rounded-full"></div>
                        </div>
                      ) : item.status === 'error' && item.error ? (
                        <div
                          className={`tooltip-${index} relative w-full h-full`}
                          data-pr-tooltip={item.errorMessage}
                          data-pr-position="top"
                        >
                          <img
                            src={`${IMG_DOMAIN}/${item.path}`}
                            alt="Error"
                            className={`w-full h-full object-cover rounded opacity-50`}
                          />
                          <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50">
                            <i className="pi pi-exclamation-circle text-error-500 text-xl" />
                          </div>
                          <Tooltip target={`.tooltip-${index}`} />
                        </div>
                      ) : (
                        <img
                          src={`${IMG_DOMAIN}/${item.path}`}
                          alt="Selected"
                          className="w-full h-full object-cover rounded"
                        />
                      )}
                      <button
                        onClick={() => handleRemoveImage(item)}
                        className="absolute top-0 right-0 h-[18px] w-[18px] bg-error-500 text-white p-1 rounded-full flex justify-center items-center"
                      >
                        <i className="pi pi-times text-xs" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        {editingMessage && (
          <div className="flex gap-2">
            <ButtonCustom
              onClick={handleSendMessage}
              className="max-w-[50px] bg-success-500"
              severity="success"
              disabled={
                (inputMessage.length < 1 && selectedImages.length < 1) ||
                isUploading ||
                hasInvalidImage
              }
              type="button"
            >
              <i className="pi pi-check text-white"></i>
            </ButtonCustom>
            <ButtonCustom
              onClick={handleCancelEditMessage}
              className="max-w-[50px] bg-error-500"
              severity="danger"
              disabled={
                (inputMessage.length < 1 && selectedImages.length < 1) ||
                isUploading ||
                hasInvalidImage
              }
              type="button"
            >
              <i className="pi pi-times text-white"></i>
            </ButtonCustom>
          </div>
        )}
        {!editingMessage && (
          <ButtonCustom
            onClick={
              inputMessage.length > 0 ? handleSendMessage : handleSendImage
            }
            className="max-w-[50px]"
            disabled={
              (inputMessage.length < 1 && selectedImages.length < 1) ||
              isUploading ||
              hasInvalidImage
            }
            type="button"
          >
            <IconSendMessage />
          </ButtonCustom>
        )}
      </div>
    </div>
  );
};
