const lesson = {
  name: '講座名',
  edit_title: '講座編集',
  thumbnail: 'イメージ',
  create_date: '作成日',
  progress: '進捗',
  code: '講座コード',
  status: 'ステータス',
  description: '概要',
  description_placeholder: '紹介するために、レッスンの概要を書いてください。',
  description_max: '300文字以内',
  image: '講座のイメージ',
  student: '受講生',
  video: '動画',
  unit: 'ユニット',
  message_on: '講座をオン状態に切り替えると、アプリ上も表示になります。',
  message_off: '講座をオフ状態に切り替えると、アプリ上も非表示になります。',
  create_success: "講座を追加しました。",
  edit_success: "講座を更新しました。",
  edit_fail: "講座情報を更新できませんでした。",
  create_fail: '講座の作成が失敗しました。',
  update_fail: '講座の更新が失敗しました。',
  active_success: '講座を公開しました。',
  inactive_success: '講座を下書き保存しました。',
  noti: {
    student_delete_success: '受講権限を解除しました。',
    student_delete_fail: '受講生を削除できませんでした。',
    student_new_success: '受講権限を付与しました。',
    student_new_fail: '受講生を追加できませんでした。',
    video_new_success: '動画を追加しました。',
    video_public_success: '動画を公開しました。',
    video_draft_success: 'ニットを下書き保存しました。',
    video_new_fail: '動画を追加できませんでした。',
    video_edit_success: '動画を更新しました。',
    video_edit_fail: '動画情報を更新できませんでした。',
    update_quality_video_success: 'ユニットの割り当てを更新しました。',
    update_quality_video_fail: '閲覧できるユニット数を更新できません。再度お試しください。',
    update_quality_video_but_not_save: 'ユニットの割り当てを更新できません。再度お試しください。',
    update_priority_unit_success: '講座の表示順番を更新できました。',
    update_priority_unit_fail: '講座の表示順番を更新できません。',
    priority_must_greater_than_zero: '講座の表示順番は1から入力してください。',
    priority_cannot_duplicated: 'ご指定の順番は重複されています。再度表示順番を並べてください。',
  },
  confirm: {
    delete_header: '受講生削除の確認',
    delete_body: 'ご指定の受講生を削除すると、講座に割り当ている各ユニットを学習できません。よろしいでしょうか。',
    delete_question_header: '質問削除',
    delete_question_body: 'ご指定の質問を削除します。よろしいでしょうか。',
  },
  text_upload: 'ファイルを選択',
  text_sub_upload: 'またはここにファイルをドロップ',
  validate: {
    have_public_unit: 'ご作成の講座を保存するためには最低でも1つの公開ユニットが必要です。',
  },
  creator: '作成者',
  pic: '担当者',
  admin: '管理者',
  coach: 'アートシフトコーチ',
};

export default lesson;
