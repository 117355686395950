import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, doc, setDoc } from 'firebase/firestore';

import { UserType } from '@/types/chat';
import { auth, db } from '@/lib/firebase';
import PATH from '@/routes/path';
import { checkConversationExists, generateConversationData } from '@/lib/utils';
import { UserItem, UserItemSkeleton } from '../aside';
import { PaginationInfo } from '@/types/pagination';

type Props = {
  pagination: PaginationInfo;
  isFetching?: boolean;
  keywordFormValue?: string;
  handleFetchContacts: (val?: any) => void;
  contacts: UserType[];
  listContactRef: React.MutableRefObject<HTMLDivElement | null>;
  listConversationRef: React.MutableRefObject<HTMLDivElement | null>;
};

{
  /*
  Padding of main layout: 10px
  Padding of authen layout: 24px //top: 12px, bottom: 12px
  Header aside (Search and icon): 111px
  Margin-top all list: 18px
  Header (トーク and アカウント): 23px for each
  Margin-bottom list conversation (トーク): 14px
  =======
  Total: 223px
  */
}
const TOTAL_HEIGHT_OF_SMALL_PARTS = 223;

export const ContactSearchList: React.FC<Props> = ({
  isFetching,
  handleFetchContacts,
  contacts,
  listContactRef,
  listConversationRef,
}) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const currentUser: User | null = auth.currentUser;
  const conversationLink = `${PATH.chat}/${PATH.chat_conversation}/`;
  
  const createChatRoom = async (user: UserType) => {
    try {
      const newConversation = doc(collection(db, 'conversations'));

      await setDoc(
        doc(db, 'conversations', newConversation.id),
        generateConversationData(newConversation.id, currentUser, false, [
          Number(user.id),
          Number(currentUser?.uid),
        ]),
      );

      return newConversation.id;
    } catch (error) {
      // TODO: Handle error
      return null;
    }
  };

  const handleUserClick = async (user: UserType) => {
    const existingRoomId = await checkConversationExists(
      Number(user.id),
      currentUser,
    );

    if (existingRoomId) {
      navigate(`${conversationLink}${existingRoomId}`);
      return;
    }
    const chatRoomId = await createChatRoom(user);
    navigate(`${conversationLink}${chatRoomId}`);
  };

  useEffect(() => {
    handleFetchContacts();
  }, []);

  return (
    <>
      <div
        ref={listContactRef}
        className="flex flex-col w-full overflow-y-auto"
        style={{
          maxHeight: `${window.innerHeight - TOTAL_HEIGHT_OF_SMALL_PARTS - (listConversationRef?.current?.clientHeight || 0)}px`,
        }}
      >
        <div>
          <div className="flex flex-col w-full">
            {contacts.map((user) => (
              <UserItem
                key={user.id}
                user={user}
                onClick={() => handleUserClick(user)}
              />
            ))}
            {isFetching && (
              <>
                {Array.from({ length: 10 }).map((_, index) => (
                  <UserItemSkeleton key={index} />
                ))}
              </>
            )}
            {!contacts.length && !isFetching && (
              <div className="text-center mt-8 text-gray-400">
                {t('chat.not_found_user')}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactSearchList;
