import { useCallback, useState } from 'react';

import { apiRequest } from '@/services';
import { UserType } from '@/types/chat';
import { PaginationInfo } from '@/types/pagination';
import { ObjectLiteral } from '@/types/object';

const DEFAULT_PAGINATION: PaginationInfo = {
  current_page: 0,
  per_page: 10,
  total_page: 1,
  total_item: 1,
  has_more: true,
};

let controllerVersion = new AbortController();

export const useFetchContacts = () => {
  const [pagination, setPagination] =
    useState<PaginationInfo>(DEFAULT_PAGINATION);
  const [isFetching, setIsFetching] = useState(false);
  const [contacts, setContacts] = useState<UserType[]>([]);
  const { apiService } = apiRequest();

  const fetchContacts = useCallback(
    async (query?: ObjectLiteral) => {
      setIsFetching(true);

      if (controllerVersion.abort) controllerVersion.abort();
      controllerVersion = new AbortController();
      const { signal } = controllerVersion;

      const { data: res, error } = await apiService.contacts.query(
        '',
        {
          paginate: (pagination?.current_page || 0) + 1,
          ...query,
        },
        { signal },
      );

      if (error) {
        const isAborted = signal?.aborted && error === 'error';
        if (!isAborted) {
          setContacts([]);
        }
        return;
      }

      if (res) {
        setContacts((prevContacts) => [...prevContacts, ...res.data]);
        setPagination(res.pagination as PaginationInfo);
      }

      setIsFetching(false);
    },
    [pagination, apiService],
  );

  const resetContacts = useCallback(() => {
    setContacts([]);
    setPagination(DEFAULT_PAGINATION);
  }, []);

  return {
    contacts,
    pagination,
    isFetching,
    fetchContacts,
    resetContacts,
  };
};
