import React, { useContext, useMemo, useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { useNavigate } from 'react-router-dom';
import type { FileUpload as FileUploadType } from 'primereact/fileupload';

import { ToastContext } from '@/components/common/CommonToast';
import ButtonCustom from '@/components/common/ui/button';
import { useTranslation } from '@/config/i18n';
import { useTableToCsv } from '@/hooks/useCsv';
import usePathName from '@/hooks/usePathName';
import useSearch from '@/hooks/useSearch';
import { randomUUID, toastErrorMessage, toastMessage } from '@/lib/utils';
import PATH from '@/routes/path';
import { apiRequest } from '@/services';
import {
  coachBodyCsv,
  coachHeaderCsv,
  studentBodyCsv,
  studentHeaderCsv,
} from '@/shared/constants';
import { ROLE } from '@/shared/constants/user';
import DialogCustom from '@/components/common/ui/dialog';

type Props = {
  setUploadCsv: React.Dispatch<React.SetStateAction<string>>;
};

const CSV_ERROR_STATUS = {
  ERROR_IN_ROW: 400,
  NOT_CSV_FILE: 422,
};

const CSV_ALREADY_USED = undefined;

const UserTopHeader: React.FC<Props> = ({ setUploadCsv }) => {
  const [t] = useTranslation('');
  const navigate = useNavigate();
  const pathName = usePathName();
  const { toast } = useContext(ToastContext);
  const { apiService } = apiRequest();
  const [loadingCsv, setLoadingCsv] = useState<boolean>();
  const [errorsCsv, setErrorsCsv] = useState<string[]>();
  const [dialogVisible, setDialogVisible] = useState(false);
  const fileUploadRef = useRef<FileUploadType>(null);
  const { handleSearch } = useSearch();
  const role = pathName.includes(PATH.student_management)
    ? ROLE.STUDENT
    : ROLE.TEACHER;
  const csvTemplate = useMemo(() => {
    return {
      headers: role === ROLE.STUDENT ? studentHeaderCsv : coachHeaderCsv,
      body: role === ROLE.STUDENT ? studentBodyCsv : coachBodyCsv,
    };
  }, [role]);
  const { saveAs } = useTableToCsv(csvTemplate);

  const onUpload = async (e: any) => {
    const file = e.files[0];
    const MAX_SIZE_KB = 100;
    const MAX_SIZE_BYTES = MAX_SIZE_KB * 1024;
    fileUploadRef?.current?.clear?.();

    if (file === CSV_ALREADY_USED) {
      toast?.current?.show(toastErrorMessage(t('common.csv.already_used')));
      return;
    }

    if (file?.size > MAX_SIZE_BYTES) {
      toast?.current?.show(toastErrorMessage(t('common.file.csv_max_100KB')));
      return;
    }

    setLoadingCsv(true);
    const formData = new FormData();
    const role = pathName.includes(PATH.student_management)
      ? ROLE.STUDENT
      : ROLE.TEACHER;
    formData.append('role', role);
    formData.append('file', file);
    const { error, data: csv } = await apiService.users.collection('import', {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });

    if (error) {
      if (error.status_code === CSV_ERROR_STATUS.NOT_CSV_FILE) {
        toast?.current?.show(
          toastErrorMessage(t('common.csv.format_file')),
          setLoadingCsv(false),
        );
        return;
      }
      if (error.status_code === CSV_ERROR_STATUS.ERROR_IN_ROW) {
        handleShowErrorCsv(error.errors);
        toast?.current?.show(toastErrorMessage(t('common.toast.import_fail')));
        setLoadingCsv(false);
        return;
      }
      toast?.current?.show(toastErrorMessage(t('common.toast.import_fail')));
      setLoadingCsv(false);
      return;
    }

    if (csv.data.total_imported_records === 0) {
      toast?.current?.show(
        toastErrorMessage(t('common.toast.import_no_record')),
      );
    } else {
      toast?.current?.show(toastMessage(t('common.toast.import_success')));
    }

    handleSearch({ reset: true });
    setUploadCsv(randomUUID());
    setLoadingCsv(false);
  };

  function handleNavigateNew() {
    let pathNew = `${PATH.student_management}/${PATH.student_management_new}`;
    if (role === ROLE.TEACHER) {
      pathNew = `${PATH.coach_management}/${PATH.coach_management_new}`;
    }

    navigate(pathNew);
  }

  function downloadCsvTemplate() {
    const fileName = role === ROLE.STUDENT ? 'Add_Student' : 'Add_Coach';
    saveAs(fileName);
  }

  function handleShowErrorCsv(errors: any) {
    const errorsCustom: any[] = Object.entries(errors);
    if (!errorsCustom.length) return;
    setErrorsCsv(errorsCustom);
    setDialogVisible(true);
  }

  return (
    <>
      <div className="header-top flex items-center justify-between">
        <h2 className="title">
          {role === ROLE.STUDENT
            ? t('user_list.title')
            : t('user_list.title_coach')}
        </h2>
        <div className="action flex items-center">
          <div className="csv-upload relative overflow-hidden">
            <ButtonCustom severity="secondary" loading={loadingCsv}>
              <span className="flex relative items-center">
                <span className="pi pi-upload mr-2" />
                <span>{t('user_list.csv_upload')}</span>
              </span>
            </ButtonCustom>
            <FileUpload
              className="absolute left-0 right-0 top-0 bottom-0 z-40 opacity-0"
              name="csv"
              accept=".csv"
              customUpload
              onSelect={onUpload}
              ref={fileUploadRef}
              disabled={loadingCsv}
            />
          </div>
          <ButtonCustom
            severity="secondary"
            className="ml-5"
            onClick={downloadCsvTemplate}
          >
            <span className="flex relative items-center">
              <span className="pi pi-upload pi-download mr-2" />
              <span>{t('user_list.csv_template')}</span>
            </span>
          </ButtonCustom>
          <ButtonCustom className="ml-5" onClick={() => handleNavigateNew()}>
            <span className="flex relative items-center">
              <span className="pi pi-plus mr-2" />
              <span>{t('user_list.new')}</span>
            </span>
          </ButtonCustom>
        </div>
      </div>
      <DialogCustom
        className="w-[600px]"
        header={t('common.dialog.error')}
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        blockScroll
      >
        <div className="max-h-[300px] overflow-y-scroll">
          {errorsCsv?.map((error: any, index) => {
            const line = error[0];
            const errorsList: any[] = error[1];
            return (
              <div key={index} className="mb-1">
                {errorsList?.map((item: any, subIndex) => {
                  const { message } = item;
                  const messageSplit = message.split(' ');
                  if (messageSplit && messageSplit.length) {
                    const messageJapan = messageSplit[messageSplit.length - 1];
                    return (
                      <p
                        key={`sub${subIndex}`}
                        className="p-error mt-1 mb-2 ml-3"
                      >
                        {line}行目 {messageJapan}
                      </p>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
      </DialogCustom>
    </>
  );
};

export default UserTopHeader;
