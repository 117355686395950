import * as yup from 'yup';

import { BaseYup, MAX_INPUT } from '@/config/baseYup';
import i18n from '@/config/i18n';

export const GroupChatSchema = BaseYup.object({
  group_name: BaseYup.string().label(i18n.t('chat.group_name')).trim().required().max(MAX_INPUT),
  group_avatar: BaseYup.string().optional(),
  group_members: BaseYup.array().optional(),
});

export type GroupChatType = yup.InferType<typeof GroupChatSchema>;
