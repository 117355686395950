export default function IconSendMessage() {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.14678 1.94088C0.995548 1.48526 0.919934 1.25744 0.974972 1.11711C1.02277 0.995243 1.12506 0.902922 1.25118 0.867831C1.3964 0.827424 1.61529 0.925925 2.05308 1.12293L15.9842 7.39188C16.4128 7.58475 16.6271 7.68119 16.6933 7.81516C16.7508 7.93155 16.7508 8.06811 16.6933 8.1845C16.6271 8.31847 16.4128 8.4149 15.9842 8.60778L2.05793 14.8746C1.61882 15.0722 1.39927 15.171 1.2539 15.1304C1.12767 15.0952 1.02536 15.0026 0.977732 14.8805C0.922882 14.7399 0.999312 14.5116 1.15217 14.055L3.09794 8.24274C3.12412 8.16456 3.1372 8.12546 3.14243 8.08546C3.14707 8.04996 3.14712 8.01401 3.14257 7.9785C3.13744 7.93848 3.12446 7.89935 3.09848 7.8211L1.14678 1.94088Z"
        fill="white"
      />
      <path
        d="M7.75182 7.99982H3.16849M3.09794 8.24274L1.15217 14.055C0.999312 14.5116 0.922882 14.7399 0.977732 14.8805C1.02536 15.0026 1.12767 15.0952 1.2539 15.1304C1.39927 15.171 1.61882 15.0722 2.05793 14.8746L15.9842 8.60778C16.4128 8.4149 16.6271 8.31847 16.6933 8.1845C16.7508 8.06811 16.7508 7.93155 16.6933 7.81516C16.6271 7.68119 16.4128 7.58475 15.9842 7.39188L2.05308 1.12293C1.61529 0.925925 1.3964 0.827424 1.25118 0.867831C1.12506 0.902922 1.02277 0.995243 0.974972 1.11711C0.919934 1.25744 0.995548 1.48526 1.14678 1.94088L3.09848 7.8211C3.12446 7.89935 3.13744 7.93848 3.14257 7.9785C3.14712 8.01401 3.14707 8.04996 3.14243 8.08546C3.1372 8.12546 3.12412 8.16455 3.09794 8.24274Z"
        stroke="#175CD3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
