export default function IconEyesSlash() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1083 7.89172L7.89166 12.1084C7.35 11.5667 7.01666 10.8251 7.01666 10.0001C7.01666 8.35006 8.35 7.01672 10 7.01672C10.825 7.01672 11.5667 7.35006 12.1083 7.89172Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.85 4.80828C13.3917 3.70828 11.725 3.10828 9.99999 3.10828C7.05832 3.10828 4.31666 4.84161 2.40833 7.84161C1.65833 9.01661 1.65833 10.9916 2.40833 12.1666C3.06666 13.1999 3.83332 14.0916 4.66666 14.8083"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.01666 16.2749C7.96666 16.6749 8.975 16.8916 10 16.8916C12.9417 16.8916 15.6833 15.1583 17.5917 12.1583C18.3417 10.9833 18.3417 9.00828 17.5917 7.83328C17.3167 7.39994 17.0167 6.99161 16.7083 6.60828"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.925 10.5834C12.7083 11.7584 11.75 12.7167 10.575 12.9334"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.89169 12.1083L1.66669 18.3333"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 1.66663L12.1083 7.89163"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
