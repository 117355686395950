import React from 'react';
import { useNavigate } from 'react-router-dom';

import InternalServerErrorImg from '@/assets/images/internal-server-error-image.png';
import ButtonCustom from '@/components/common/ui/button';
import PATH from '@/routes/path';

const InternalServerErrorPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="flex-full-center flex-col h-screen bg-white">
      <div className="w-[700px] h-[417px]">
        <img
          className="w-[100%] h-[100%] object-cover"
          src={InternalServerErrorImg}
        ></img>
      </div>

      <ButtonCustom
        className="mt-[42px] w-[314px] h-[44px]"
        onClick={() => navigate(PATH.login)}
      >
        戻る
      </ButtonCustom>
    </div>
  );
};

export default InternalServerErrorPage;
