import React, { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import classNames from 'classnames';

import TopBar from './TopBar';
import LeftSideBar from './LeftSideBar';
import { useLoading } from '@/components/common/loader/LoadingContext';
import Loader from '../common/loader/Loader';
import useScrollToTop from '@/hooks/useScrollToTop';
import { auth } from '@/lib/firebase';
import { useConversationsWithMembers } from '@/hooks';
import usePathName from '@/hooks/usePathName';
import PATH from '@/routes/path';

const AuthenticatedLayouts: React.FC = () => {
  const { loadingGlobal } = useLoading();
  const pathName = usePathName();
  const currentUser = auth.currentUser;
  const { conversationId } = useParams<{ conversationId: string | undefined }>();
  const isChattingLayout = pathName.includes(PATH.chat)

  useConversationsWithMembers({
    currentUserId: currentUser?.uid,
    selectedConversationId: conversationId,
  });

  const [sidebarOpen, setSideBarOpen] = useState(true);
  const handleToggleSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };

  useScrollToTop();

  const rightSideBarClasses = classNames('flex', 'transform', 'duration-300', 'transition-all', {
    'ml-[280px] w-[calc(100vw-280px)]': sidebarOpen,
    'ml-[80px] w-[calc(100vw-80px)]': !sidebarOpen,
  });

  return (
    <div className="min-h-screen bg-primary-900 pt-[10px]">
      {loadingGlobal && <Loader isLoading={loadingGlobal} />}
      <TopBar />
      <div className="flex opacity-1 transition-opacity duration-100 ease-in" style={{ opacity: loadingGlobal ? 0.9 : 1 }}>
        <LeftSideBar isOpen={sidebarOpen} toggleSidebar={handleToggleSidebar} />
        <div className={rightSideBarClasses}>
          <div
            className={classNames(
              'py-[12px] w-full bg-white rounded-tl-[40px] min-h-[calc(100vh-10px)]',
              {
                'px-[20px]': !isChattingLayout,
                'pl-[20px]': isChattingLayout
              }
            )}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticatedLayouts;
