import { Checkbox } from 'primereact/checkbox';
import classNames from 'classnames';

import { UserType } from '@/types/chat';
import { DefaultAvatar } from '@/components/common/ui/defaultAvatar';

type Props = {
  shouldSelect?: boolean;
  onChange?: () => void;
  isChecked?: boolean;
  user?: UserType;
  isActive?: boolean;
  onClick?: () => void;
  groupMembers?: UserType[];
};

export const UserItem: React.FC<Props> = ({
  shouldSelect,
  onChange,
  isChecked,
  user,
  isActive,
  onClick,
}) => {
  const role = user?.current_roles?.[0];

  const handleItemClick = () => {
    if (onChange) onChange();
    if (onClick) onClick();
  };

  return (
    <div
      onClick={handleItemClick}
      className={classNames(
        'w-full flex items-center py-4 px-4 cursor-pointer border-b-gray-200 border-b',
        {
          'bg-primary-50': isActive,
          'bg-white hover:bg-primary-50': !isActive,
        },
      )}
    >
      {/* Avatar Div */}
      {user?.profile_image ? (
        <div className="min-w-[40px] w-[40px] h-[40px] rounded-full relative flex-shrink-0">
          <img
            src={user.profile_image}
            alt="avatar"
            className="h-full w-full rounded-full object-cover"
          />
        </div>
      ) : (
        role && <DefaultAvatar role={role} className="flex-shrink-0" />
      )}

      {/* User Name Div */}
      <div className="flex-1 min-w-0 ml-3">
        <p className="text-[14px] text-gray-700 font-bold truncate">
          {user?.full_name_kanji}
        </p>
      </div>

      {/* Checkbox Div */}
      {shouldSelect && (
        <div className="flex-shrink-0 border border-gray-300 h-[24px] w-[24px] rounded-full overflow-hidden ml-3">
          <Checkbox
            checked={!!isChecked}
            className="checkbox-circle"
          />
        </div>
      )}
    </div>
  );
};
