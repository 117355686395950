import classNames from 'classnames';
import { Dialog, DialogProps } from 'primereact/dialog';

interface IDialogCustomProps extends DialogProps {}

const DialogCustom = ({
  children,
  onHide,
  ...rest
}: IDialogCustomProps) => {
  return (
    <Dialog
      resizable={false}
      maximizable={false}
      draggable={false}
      closeOnEscape={false}
      dismissableMask={true}
      {...rest}
      onHide={onHide}
      className={classNames('dialog-custom', rest.className)}
    >
      {children}
    </Dialog>
  );
};

export default DialogCustom;