import { useLoading } from '@/components/common/loader/LoadingContext';
import { useState, useEffect } from 'react';

type Options = {
  isLoadingGlobal?: boolean;
  isScrollToTop?: boolean;
};

const useFetchClient = (
  apiRequest: any,
  dependencies?: unknown[],
  options?: Options,
) => {
  const { setLoadingGlobal } = useLoading();
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    if (options?.isLoadingGlobal) {
      setLoadingGlobal(true);
    } else {
      setLoading(true);
    }

    setError(null);
    try {
      const response = await apiRequest();
      const { error } = response;

      setData(response.data);
      if (options?.isScrollToTop) {
        window.scrollTo({ top: 0, left: 0 });
      }
      if (options?.isLoadingGlobal && error !== 'error') {
        setLoadingGlobal(false);
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      setError(error);
      if (options?.isLoadingGlobal) {
        setLoadingGlobal(false);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, dependencies);

  return { data, loading, error, fetchData };
};

export default useFetchClient;
