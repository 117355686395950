import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { TabViewTabChangeEvent } from 'primereact/tabview';

import { ObjectLiteral } from '@/types/object';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@/shared/constants/pagination';
import useSearch from '@/hooks/useSearch';
import PATH from '@/routes/path';
import { ROLE } from '@/shared/constants/user';
import usePathName from '@/hooks/usePathName';
import { useLoading } from '@/components/common/loader/LoadingContext';
import { apiRequest } from '@/services';
import { ToastContext } from '@/components/common/CommonToast';
import { toastErrorMessage } from '@/lib/utils';
import usePagination from '@/hooks/usePagination';
import useUserRole from '@/hooks/useUserRole';
import LessonTopHeader from './components/LessonTopHeader';
import LessonFormSearch from './components/LessonFormSearch';
import LessonList from './components/LessonList';
import { LessonFormSearchType, lessonSearchFormSchema } from './schema';
import { OptionType } from '@/types/api';

const LESSON_TAB_TYPE = {
  ADMIN: 'ADMIN',
  TEACHER: 'TEACHER',
};

const LESSON_TAB_KEY = {
  ADMIN: 0,
  TEACHER: 1,
};

let controllerVersion = new AbortController();

const LessonPage: React.FC = () => {
  const [t] = useTranslation('');
  const { handleSearch, query: queryFromUrl, searchParams } = useSearch();
  const { query } = Object.fromEntries(searchParams.entries());
  const pathName = usePathName();
  const { setLoadingGlobal } = useLoading();
  const { apiService } = apiRequest();
  const { toast } = useContext(ToastContext);
  const { role: userRole } = useUserRole();

  const [loading, setLoading] = useState<boolean>();
  const [activeIndex, setActiveIndex] = useState<number>(
    queryFromUrl['created_type'] === LESSON_TAB_TYPE.TEACHER
      ? LESSON_TAB_KEY.TEACHER
      : LESSON_TAB_KEY.ADMIN,
  );
  const [lessons, setLessons] = useState<any>({ data: [], pagination: null });
  const [currentPagination, setCurrentPagination] = useState<any>();
  const [mentorDefaultOptions, setMentorDefaultOptions] = useState<
    OptionType[]
  >([]);

  const { paginationComputed, setPagination } = usePagination(
    lessons?.pagination,
  );

  const formMethods = useForm<LessonFormSearchType>({
    mode: 'onChange',
    resolver: yupResolver(lessonSearchFormSchema(activeIndex)),
    shouldFocusError: false,
    defaultValues: {
      name: '',
      status: 'all',
    },
  });

  const { handleSubmit, getValues, setValue, reset, clearErrors } = formMethods;

  const role = useMemo(() => {
    return pathName.includes(PATH.student_management)
      ? ROLE.STUDENT
      : ROLE.TEACHER;
  }, []);

  const isTeacher = useMemo(
    () => [ROLE.TEACHER].includes(userRole),
    [userRole],
  );

  const isSupperAdmin = useMemo(
    () => [ROLE.SUPPER_ADMIN].includes(userRole),
    [userRole],
  );

  const isDisabledEdit = useMemo(
    () =>
      [ROLE.ADMIN].includes(userRole) && activeIndex === LESSON_TAB_KEY.TEACHER,
    [userRole, activeIndex],
  );

  const params = useMemo(() => {
    if (query) {
      return undefined;
    }

    const defaultParams = {
      limit: DEFAULT_PER_PAGE,
      paginate: DEFAULT_PAGE,
      role,
    };

    const adminCreatedType =
      typeof queryFromUrl['created_type'] === 'undefined' && !isTeacher
        ? { created_type: LESSON_TAB_TYPE.ADMIN }
        : {};

    return { ...defaultParams, ...adminCreatedType };
  }, [query, role, queryFromUrl, isTeacher]);

  const queryCustom = useMemo(() => {
    if (!query) {
      return '';
    }

    let newQuery = `${query}&role=${role}`;

    if (!query.includes('created_type') && !isTeacher) {
      newQuery += `&created_type=${LESSON_TAB_TYPE.ADMIN}`;
    }

    return newQuery;
  }, [query, role, isTeacher]);

  const onSubmit = (customParams?: Record<string, any>) => {
    const { prevent_fetch } = customParams || {};
    setLoading(true);
    let status = getValues('status');
    status = status === 'all' ? '' : status;
    const extraParams = {
      created_type:
        activeIndex === LESSON_TAB_KEY.TEACHER
          ? LESSON_TAB_TYPE.TEACHER
          : LESSON_TAB_TYPE.ADMIN,
      sort_columns:
        typeof queryFromUrl['sort_columns'] !== 'string'
          ? ''
          : queryFromUrl['sort_columns'],
    };
    const mentorData = getValues('mentor_id') as unknown as OptionType;

    if (mentorData) {
      localStorage.setItem('mentorData', JSON.stringify(mentorData));
    }

    handleSearch({
      prevent_fetch: prevent_fetch ?? 0,
      ...getValues(),
      mentor_id: mentorData?.value || undefined,
      status,
      ...(!isTeacher ? extraParams : {}),
    });
    setLoading(false);
  };

  const getAllFieldNames = () => {
    const values = getValues();
    return Object.keys(values);
  };

  const handleReset = () => {
    reset();
    if (activeIndex) {
      onSubmit({ prevent_fetch: 1 });
      setPagination({
        ...paginationComputed,
        limit: DEFAULT_PER_PAGE,
        paginate: DEFAULT_PAGE,
        sortField: undefined,
        sortOrder: undefined,
      });
      setLessons({ data: [], pagination: null });
      return;
    }
    onSubmit();
  };

  const mapValueFromQuery = () => {
    const specialField = ['mentor_id'];
    getAllFieldNames().forEach((field: any) => {
      const val = queryFromUrl[field];

      if (val && !specialField.includes(field)) {
        const decodedValue = decodeURIComponent(
          String(val).replace(/\+/g, ' ').trim(),
        );
        setValue(field, decodedValue);
      }

      if (field === 'mentor_id') {
        setValue(field, mentorDefaultOptions[0] || null);
      }
    });
  };

  const onBeforeTabChange = (event: TabViewTabChangeEvent) => {
    const { index } = event;
    setActiveIndex(index);
    const currentParams = Object.fromEntries(
      new URLSearchParams(queryFromUrl as any),
    );
    setValue('name', '');
    setValue('status', 'all');
    setValue('mentor_id', '');
    handleSearch({
      ...(currentParams as ObjectLiteral),
      prevent_fetch: index === LESSON_TAB_KEY.TEACHER ? 1 : 0,
      limit: DEFAULT_PER_PAGE,
      paginate: DEFAULT_PAGE,
      created_type:
        index === LESSON_TAB_KEY.ADMIN
          ? LESSON_TAB_TYPE.ADMIN
          : LESSON_TAB_TYPE.TEACHER,
      name: '',
      sort_columns: '',
      status: '',
      mentor_id: '',
    });
    setPagination({
      ...paginationComputed,
      limit: DEFAULT_PER_PAGE,
      paginate: DEFAULT_PAGE,
      sortField: undefined,
      sortOrder: undefined,
    });
    setLessons({ data: [], pagination: null });
    localStorage.removeItem('mentorData');
    setMentorDefaultOptions([]);
    clearErrors();
  };

  const getLessons = async () => {
    if (queryFromUrl['prevent_fetch']) return;

    setLoadingGlobal(true);
    if (controllerVersion.abort) controllerVersion.abort();
    controllerVersion = new AbortController();
    const { signal } = controllerVersion;
    const { data: res, error } = await apiService.lessons.query(
      queryCustom,
      params as any,
      { signal },
    );

    if (res) {
      setLoadingGlobal(false);
      window.scrollTo({ top: 0, left: 0 });
      setLessons({ data: res?.data, pagination: res?.pagination });
      setCurrentPagination(res?.pagination);
    }
    if (error) {
      if (signal?.aborted && error === 'error') return;
      setLoadingGlobal(false);
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
    }
  };

  useEffect(() => {
    mapValueFromQuery();
  }, [mentorDefaultOptions]);

  useEffect(() => {
    if (userRole) {
      getLessons();
    }
  }, [query, userRole]);

  useEffect(() => {
    if (!queryFromUrl?.mentor_id) return;

    const mentorDataString = localStorage.getItem('mentorData');

    if (mentorDataString !== null && mentorDataString !== '') {
      const mentorData = JSON.parse(mentorDataString);
      if (String(mentorData?.value) === String(queryFromUrl?.mentor_id)) {
        setMentorDefaultOptions([mentorData]);
      }
    }
  }, []);

  return (
    <>
      <LessonTopHeader />
      <LessonFormSearch
        t={t}
        handleReset={handleReset}
        onSubmit={handleSubmit(onSubmit)}
        onBeforeTabChange={onBeforeTabChange}
        activeIndex={activeIndex}
        loading={!!loading}
        formMethods={formMethods}
        isTeacher={isTeacher}
        mentorDefaultOptions={mentorDefaultOptions}
      />
      <LessonList
        paginationComputed={paginationComputed}
        currentPagination={currentPagination}
        lessons={lessons}
        setLessons={setLessons}
        getLessons={getLessons}
        setPagination={setPagination}
        isDisabledEdit={isDisabledEdit}
        activeIndex={activeIndex}
        isTeacher={isTeacher}
        isSupperAdmin={isSupperAdmin}
      />
    </>
  );
};

export default LessonPage;
