export default function IconSuccess() {
  return (
    <svg
      width={66}
      height={66}
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x={5} y={5} width={56} height={56} rx={28} fill="#E6F4D7" />
      <rect
        x={5}
        y={5}
        width={56}
        height={56}
        rx={28}
        stroke="#F5FBEE"
        strokeWidth={10}
      />
      <path
        d="M27.7497 32.9997L31.2497 36.4997L38.2497 29.4997M44.6663 32.9997C44.6663 39.443 39.443 44.6663 32.9997 44.6663C26.5564 44.6663 21.333 39.443 21.333 32.9997C21.333 26.5564 26.5564 21.333 32.9997 21.333C39.443 21.333 44.6663 26.5564 44.6663 32.9997Z"
        stroke="#669F2A"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
