import useFetchClient from '@/hooks/useFetchClient';
import useSearch from '@/hooks/useSearch';
import { apiRequest } from '@/services';
import { CustomDataTable } from '@/components/common/ui/table/CustomDataTable';
import { Pagination } from '@/types/pagination';
import { UserColumns } from '@/pages/student-management/table/UserColumns';
import { UserColumns as UserColumnsCoach } from '@/pages/coach-management/table/UserColumns';
import { useTranslation } from '@/config/i18n';
import usePagination from '@/hooks/usePagination';
import { useContext, useMemo, useState } from 'react';
import usePathName from '@/hooks/usePathName';
import PATH from '@/routes/path';
import { ROLE } from '@/shared/constants/user';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@/shared/constants/pagination';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '@/components/common/loader/LoadingContext';
import DialogCustom from '@/components/common/ui/dialog';
import ButtonCustom from '@/components/common/ui/button';
import { ToastContext } from '@/components/common/CommonToast';
import { toastErrorMessage } from '@/lib/utils';
import { HTTP_STATUS } from '@/shared/constants';

type Props = {
  uploadCsv: string;
};

const UserList: React.FC<Props> = ({ uploadCsv }) => {
  const [t] = useTranslation('');
  const { apiService } = apiRequest();
  const { searchParams } = useSearch();
  const { toast } = useContext(ToastContext);
  const { query } = Object.fromEntries(searchParams.entries());
  const navigate = useNavigate();
  const pathName = usePathName();
  const { setLoadingGlobal } = useLoading();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [seletedUser, setSelectedUser] = useState<any>();

  const role = useMemo(() => {
    return pathName.includes(PATH.student_management)
      ? ROLE.STUDENT
      : ROLE.TEACHER;
  }, []);

  const params = useMemo(() => {
    return query
      ? undefined
      : { limit: DEFAULT_PER_PAGE, paginate: DEFAULT_PAGE, role };
  }, [query]);

  const queryCustom = useMemo(() => {
    return query ? `${query}&role=${role}` : '';
  }, [query, role]);

  const { data: users, error } = useFetchClient(
    async () => await apiService.users.query(queryCustom, params),
    [query, uploadCsv],
    {
      isLoadingGlobal: true,
      isScrollToTop: true,
    },
  );

  if (error) {
    toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
  }

  const { paginationComputed, setPagination } = usePagination(
    users?.pagination,
  );

  function handleEditUser(id: number) {
    if (role === ROLE.TEACHER) {
      navigate(`${PATH.coach_management}/${PATH.coach_management_edit}/${id}`);
      return;
    }
    navigate(
      `${PATH.student_management}/${PATH.student_management_edit}/${id}`,
    );
  }

  const handleError = (error: any) => {
    const { status_code, errors } = error;
    if (status_code === HTTP_STATUS.FORM_ERROR) {
      const errorLimitStudent = errors?.find(
        (e: any) => e.error_code === 'MFS-E-LEARNING-ADMIN-USER-169',
      );
      if (errorLimitStudent) {
        toast?.current?.show(toastErrorMessage(errorLimitStudent?.message));
      }
    }
  };

  async function handleEditStatusUser(isActive: boolean, rowData: any) {
    setSelectedUser({ isActive, rowData });
    const nextDialogVisibleValue = !dialogVisible;
    setDialogVisible(nextDialogVisibleValue);
    if (nextDialogVisibleValue) return;
    setLoadingGlobal(true);
    const { data: user, error: userError } = await apiService.users.update(
      rowData.id,
      {
        role,
        status: isActive ? 'active' : 'inactive',
        require_password_update: false,
      },
    );
    setLoadingGlobal(false);
    if (userError) {
      handleError(userError);
      return;
    }

    // update status button
    const indexUpdate = users.data.findIndex(
      (user: any) => user.id === rowData.id,
    );
    if (indexUpdate !== -1) {
      users.data[indexUpdate].status = user.data.status;
    }
  }

  function onPaginationChanged(paginationState: Pagination) {
    setPagination(paginationState);
  }

  return (
    <>
      <CustomDataTable
        wrapClassName="mt-[20px]"
        values={users?.data ?? []}
        colums={
          role === ROLE.STUDENT
            ? UserColumns(t, handleEditUser, handleEditStatusUser)
            : UserColumnsCoach(t, handleEditUser, handleEditStatusUser)
        }
        pagination={paginationComputed}
        onPaginationChanged={onPaginationChanged}
      />

      <DialogCustom
        header={t('common.dialog.header')}
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        footer={
          <div className="field">
            <div className="flex justify-end">
              <ButtonCustom
                type="button"
                className="cancle-button p-button-sm mr-2"
                severity="secondary"
                onClick={() => setDialogVisible(false)}
              >
                {t('common.dialog.cancel')}
              </ButtonCustom>
              <ButtonCustom
                type="button"
                onClick={() =>
                  handleEditStatusUser(
                    seletedUser.isActive,
                    seletedUser.rowData,
                  )
                }
              >
                {t('common.dialog.ok')}
              </ButtonCustom>
            </div>
          </div>
        }
      >
        {seletedUser?.isActive
          ? t('user_list.message_on')
          : t('user_list.message_off')}
      </DialogCustom>
    </>
  );
};

export default UserList;
