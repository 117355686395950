import * as yup from 'yup';
import { BaseYup, versionNameRegExp } from '@/config/baseYup';
import i18n from '@/config/i18n';

export const addVersionSchema = BaseYup.object({
  version: BaseYup.string()
    .label(i18n.t('version.name'))
    .required()
    .matches(versionNameRegExp, {
      message: i18n.t('version.name_invalid'),
    }),
});

export type AddVersionFormType = yup.InferType<typeof addVersionSchema>;