import { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  setDoc,
  arrayRemove,
  collection,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
  deleteField,
  getDoc,
} from 'firebase/firestore';
import { confirmDialog } from 'primereact/confirmdialog';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  DELETE_MESSAGE_TYPE,
  IMG_DOMAIN,
  INVITE_STATUS,
  MESSAGE_TYPE,
} from '@/shared/constants/chat';
import { ConversationType, ImgType, MessageType, UserType } from '@/types/chat';
import { auth, db } from '@/lib/firebase';
import {
  convertToDate,
  findPreviousNonUnsentMessage,
  formatTimestamp,
  getDateLabel,
  getSystemMessage,
} from '@/lib/utils';
import PATH from '@/routes/path';
import {
  BubbleItem,
  Header,
  InputMessagePart,
  AddMemberGroupDialog,
  DeleteMemberGroupDialog,
} from '.';
import { useFetchMessages, useImagesUpload } from '../hooks';
import { NOTIFICATION_TYPE } from '@/shared/constants/chat';
import { apiRequest } from '@/services';
import ButtonCustom from '@/components/common/ui/button';

type Props = {
  selectedConversation?: ConversationType | null;
  selectedUser?: UserType | undefined | null;
  users?: UserType[] | [];
  messageStatus?: string;
  isAccepted?: boolean;
  isRemoved?: boolean;
  initializeUsers: (memberIds: number[]) => Promise<void>
};

let controllerSendNotification = new AbortController();

export const ChatBox: React.FC<Props> = ({
  selectedConversation,
  selectedUser,
  users,
  messageStatus,
  isAccepted,
  isRemoved,
  initializeUsers,
}) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [inputMessage, setInputMessage] = useState<string>('');
  const [editingMessage, setEditingMessage] = useState<MessageType | null>(
    null,
  );
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [lastConversationId, setLastConversationId] = useState<string | null>(
    null,
  );
  const [hoveredMessageIndex, setHoveredMessageIndex] = useState<number | null>(
    null,
  );
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [editingMessageIndex, setEditingMessageIndex] = useState<string | null>(
    null,
  );
  const [isAddMemberVisible, setIsAddMemberVisible] = useState(false);
  const [isDeleteMemberVisible, setIsDeleteMemberVisible] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [usersInChat, setUsersInChat] = useState<UserType[]>([]);
  const [prevMemberIds, setPrevMemberIds] = useState<Set<number>>(new Set());
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const currentUser = auth.currentUser;
  const conversationLink = `${PATH.chat}/${PATH.chat_conversation}`;
  const { messages, loadMoreMessages, setMessages } = useFetchMessages(
    selectedConversation?.id || null,
  );
  const {
    selectedImages,
    isUploading,
    handleImageUpload,
    handleRemoveImage,
    setSelectedImages,
  } = useImagesUpload();

  const { apiService } = apiRequest();

  const filteredUser = useMemo(() => {
    return users?.find((item) => item.id === Number(currentUser?.uid));
  }, [users, currentUser]);

  const userSendFile = useMemo(() => {
    const { last_message } = selectedConversation || {};
    return users?.find((user) => user.id === last_message?.sender_id);
  }, [users, selectedConversation?.last_message?.sender_id]);

  const getMessageRef = (messageId: string) => {
    return doc(
      db,
      'conversations',
      String(selectedConversation?.id),
      'messages',
      messageId,
    );
  };

  const updateLastReadMessage = useCallback(
    (lastReadMessageId: string, lastMessage: string, type: number) => {
      if (!selectedConversation?.id || !currentUser) return;
      try {
        const updatePromises = [
          updateDoc(doc(db, 'conversations', String(selectedConversation.id)), {
            last_message: {
              message_id: lastReadMessageId,
              message: lastMessage,
              sent_at: serverTimestamp(),
              message_type: type,
              sender_id: Number(currentUser.uid || 0),
            },
            updated_at: serverTimestamp(),
            has_deleted_message_members: deleteField(),
          }),
        ];
        Promise.all(updatePromises);
      } catch (error) {
        console.error('Error updating documents:', error);
      }
    },
    [selectedConversation?.id, currentUser],
  );

  const sendNotificationToServer = useCallback(
    (conversationId: string, content: string, receiverIds: string[]) => {
      if (controllerSendNotification.abort) controllerSendNotification.abort();
      controllerSendNotification = new AbortController();
      const { signal } = controllerSendNotification;

      const paramsPushApp = {
        notification_type: NOTIFICATION_TYPE.NEW_MESSAGE,
        conversation_id: conversationId,
        content,
        receiver_ids: receiverIds,
      };

      apiService.notifications.collection('send', {
        method: 'POST',
        body: paramsPushApp,
        signal,
      });
    },
    [],
  );

  const sendMessage = useCallback(
    async (content: MessageType) => {
      if (!selectedConversation?.id || !currentUser) return;
      setIsSendingMessage(true);
      const messagesRef = collection(
        db,
        'conversations',
        selectedConversation.id,
        'messages',
      );

      const { invitation_statuses = {} } = selectedConversation || {};

      const idsMemberApproved =
        (invitation_statuses &&
          Object.keys(invitation_statuses).filter(
            (key) =>
              invitation_statuses[key as unknown as number].status ===
                INVITE_STATUS.ACCEPT &&
              Number(key) !== Number(currentUser?.uid),
          )) ||
        [];

      const messageToServer =
        content.files && content.files.length > 0
          ? t('chat.receive_file', { user_name: userSendFile?.full_name_kanji })
          : content.message;

      const response = doc(messagesRef);
      await setDoc(response, { id: response.id, ...content });
      if (content.error !== true && idsMemberApproved.length > 0) {
        sendNotificationToServer(
          selectedConversation.id,
          messageToServer,
          idsMemberApproved,
        );
      }

      if (
        content.message_type !== MESSAGE_TYPE.SYSTEM &&
        content.error !== true
      ) {
        updateLastReadMessage(
          response.id,
          content.files && content.files.length > 0
            ? `${filteredUser?.full_name_kanji}${t('chat.send_file')}`
            : content.message,
          content.message_type,
        );
      }
      setIsSendingMessage(false);
    },
    [
      selectedConversation?.id,
      currentUser,
      selectedConversation?.invitation_statuses,
    ],
  );

  const updateMessage = useCallback(
    async (messageId: string, newContent: string) => {
      if (!selectedConversation?.id || !currentUser) return;
      const messageRef = getMessageRef(messageId);
      try {
        const docSnap = await getDoc(messageRef);
        if (!docSnap.exists()) {
          return;
        }
        const oldMessage = docSnap.data()?.message;
        if (oldMessage !== newContent) {
          await updateDoc(messageRef, {
            message: newContent,
            is_edited: true,
            updated_at: serverTimestamp(),
          });
        }
      } catch (error) {
        console.error('Error updating document:', error);
      }
    },
    [selectedConversation?.id, currentUser],
  );

  const handleEditMessage = (message: MessageType) => {
    setInputMessage(message.message);
    setEditingMessage(message);
    inputRef.current?.focus();
  };

  const handleCancelEditMessage = () => {
    setInputMessage('');
    setEditingMessage(null);
    inputRef.current?.focus();
  };

  const handleSendMessage = useCallback(() => {
    if (inputMessage.trim() !== '') {
      const newMessage: MessageType = {
        message: inputMessage,
        files: [],
        created_at: new Date(),
        message_type: MESSAGE_TYPE.TEXT,
        sender_id: Number(currentUser?.uid),
        is_unsent: false,
        deleted_by: -1,
      };

      if (editingMessage) {
        updateMessage(String(editingMessage.id), inputMessage);
        setEditingMessage(null);
      } else {
        sendMessage(newMessage);
        messagesEndRef.current?.scrollIntoView();
      }
      setInputMessage('');
      inputRef.current?.focus();
    }
  }, [inputMessage, sendMessage, editingMessage, currentUser]);

  const handleSendImage = useCallback(() => {
    if (selectedImages.length > 0) {
      const selectedImagesOrg = selectedImages.map((image: ImgType) => ({
        name: image.name,
        path: image.path,
        length: image.length,
        width: image.width,
        height: image.height,
      }));
      const newMessage: MessageType = {
        message: `${filteredUser?.full_name_kanji}${t('chat.send_file')}`,
        files: selectedImagesOrg,
        created_at: new Date(),
        message_type: MESSAGE_TYPE.FILE,
        sender_id: Number(currentUser?.uid),
        is_unsent: false,
        deleted_by: -1,
      };
      sendMessage(newMessage);
      setSelectedImages([]);
      inputRef.current?.focus();
    }
  }, [sendMessage, currentUser, selectedImages]);

  const handleDropdownToggle = useCallback(
    (index: string) => {
      setDropdownVisible((prev) => !(prev && editingMessageIndex === index));
      setEditingMessageIndex((prev) => (prev === index ? null : index));
    },
    [dropdownVisible, editingMessageIndex],
  );

  const handleDeleteMessage = (messageId: string, type: string) => {
    confirmDialog({
      draggable: false,
      message: t(
        type === DELETE_MESSAGE_TYPE.UNSENT
          ? 'chat.confirm_unsent_msg'
          : 'chat.confirm_delete_msg',
      ),
      header: t('chat.confirm_delete_msg_title'),
      className: 'max-w-[400px]',
      accept: () => handleConfirmDeleteMessage(messageId, type),
      footer: ({ accept, reject }) => (
        <div className="field">
          <div className="flex justify-end">
            <ButtonCustom
              className="mr-3 w-full rounded-2xl"
              type="button"
              severity="secondary"
              onClick={reject}
            >
              {t('chat.cancel')}
            </ButtonCustom>
            <ButtonCustom
              type="button"
              className="w-full rounded-2xl"
              onClick={accept}
            >
              {t('chat.delete')}
            </ButtonCustom>
          </div>
        </div>
      ),
    });
  };

  const handleConfirmDeleteMessage = useCallback(
    async (messageId: string, type: string) => {
      if (!selectedConversation?.id || !currentUser) return;
      const messageRef = getMessageRef(messageId);
      const conversationRef = doc(
        db,
        'conversations',
        String(selectedConversation?.id),
      );

      try {
        if (type === DELETE_MESSAGE_TYPE.UNSENT) {
          await updateDoc(messageRef, {
            updated_at: serverTimestamp(),
            is_unsent: true,
          });
          const previousNonUnsentMessage = await findPreviousNonUnsentMessage(
            String(selectedConversation?.id),
          );
          const lastMessageData = {
            message: previousNonUnsentMessage?.message ?? '',
            message_id: previousNonUnsentMessage?.id ?? '',
            sender_id: previousNonUnsentMessage?.sender_id ?? -1,
            message_type: previousNonUnsentMessage?.message_type ?? 0,
            sent_at: previousNonUnsentMessage?.created_at,
          };
          await updateDoc(conversationRef, {
            last_message: lastMessageData,
          });
          return;
        } else {
          await updateDoc(messageRef, {
            deleted_by: Number(currentUser.uid),
            updated_at: serverTimestamp(),
          });
          const hasDeletedMessageMembersField = `has_deleted_message_members.${currentUser.uid}`;
          await updateDoc(conversationRef, {
            [hasDeletedMessageMembersField]: Date.now(),
          });
        }
      } catch (error) {
        console.error(
          'Error deleting message or updating conversation:',
          error,
        );
      } finally {
        setDropdownVisible(false);
      }
    },
    [selectedConversation?.id, currentUser],
  );

  const handleScroll = () => {
    if (messagesContainerRef.current) {
      const { scrollTop } = messagesContainerRef.current;
      if (scrollTop === 0) {
        loadMoreMessages(messagesContainerRef)
      }
    }
  };

  const handleLeaveGroup = () => {
    confirmDialog({
      draggable: false,
      className: 'max-w-[400px]',
      message: t('chat.confirm_leave_group_msg'),
      header: t('chat.confirm_leave_group_title'),
      accept: () => confirmLeaveGroup(),
      footer: ({ accept, reject }) => (
        <div className="field">
          <div className="flex justify-end">
            <ButtonCustom
              className="mr-3 w-full rounded-2xl"
              type="button"
              severity="secondary"
              onClick={reject}
            >
              {t('chat.cancel')}
            </ButtonCustom>
            <ButtonCustom
              type="button"
              className="w-full rounded-2xl"
              onClick={accept}
            >
              {t('chat.leave_group')}
            </ButtonCustom>
          </div>
        </div>
      ),
    });
  };

  const confirmLeaveGroup = async () => {
    if (!selectedConversation?.id || !currentUser) return;

    try {
      const conversationRef = doc(db, 'conversations', selectedConversation.id);
      const systemMessage: MessageType = getSystemMessage(
        Number(currentUser?.uid),
        `${filteredUser?.full_name_kanji}${t('chat.has_left_group')}`,
      );
      sendMessage(systemMessage);
      await updateDoc(conversationRef, {
        member_ids: arrayRemove(Number(currentUser.uid)),
        [`invitation_statuses.${currentUser.uid}`]: deleteField(),
        [`read_statuses.${currentUser.uid}`]: deleteField(),
      });
      setMessages([]);
      navigate(conversationLink);
    } catch (error) {
      console.error('Error leaving the group:', error);
    }
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      const { scrollHeight, clientHeight } = messagesContainerRef.current;
      messagesContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };

  useEffect(() => {
    if (selectedConversation?.id !== lastConversationId) {
      setLastConversationId(selectedConversation?.id || null);

      setTimeout(() => {
        scrollToBottom();
      }, 0);
    }
  }, [selectedConversation?.id, lastConversationId]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      setIsUserScrolling(scrollTop + clientHeight < scrollHeight - 10);
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!isUserScrolling && messages.length > 0) {
      setTimeout(() => {
        scrollToBottom();
      }, 0);
    }
  }, [messages]);

  const fetchUsers = async (ids: number[]) => {
    try {
      const { data: allUsers } = await apiService.chatParticipants.create({
        user_ids: ids,
      });
      setUsersInChat((prev) => [...prev, ...allUsers.data]);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const getUserInChat = useCallback(
    async (messages: MessageType[]) => {
      try {
        const BATCH_SIZE = 1000;

        const allMemberIds = Array.from(
          new Set(messages.flatMap((conv) => conv.sender_id || [])),
        );

        const newMemberIds = allMemberIds.filter(
          (id) => !prevMemberIds.has(id),
        );
        if (newMemberIds.length === 0) {
          return;
        }
        const chunks = [];
        for (let i = 0; i < newMemberIds.length; i += BATCH_SIZE) {
          chunks.push(newMemberIds.slice(i, i + BATCH_SIZE));
        }
        await Promise.all(chunks.map((chunk) => fetchUsers(chunk)));
        setPrevMemberIds(new Set(allMemberIds));
      } catch (error) {
        console.error('Error initializing users:', error);
      }
    },
    [prevMemberIds],
  );

  useEffect(() => {
    if (isFirstLoad) {
      initializeUsers(selectedConversation?.member_ids || []);
      setIsFirstLoad(false);
    }
  }, [selectedConversation?.member_ids, isFirstLoad]);

  useEffect(() => {
    getUserInChat(messages);
  }, [messages]);

  return (
    <div className="w-2/3 flex flex-col relative">
      <Header
        selectedConversation={selectedConversation}
        selectedUser={selectedUser}
        setIsAddMemberVisible={setIsAddMemberVisible}
        setIsDeleteMemberVisible={setIsDeleteMemberVisible}
        users={users}
      />

      <div
        className="flex-1 p-4 space-y-4 overflow-auto"
        ref={messagesContainerRef}
        onScroll={handleScroll}
      >
        {!isRemoved && isAccepted && (
          <>
            {messages.length > 0 ? (
              <>
                {[...messages]
                  .reverse()
                  .filter(
                    (msg) => !(msg.deleted_by === Number(currentUser?.uid)),
                  )
                  .map((msg: MessageType, index: number, filteredMessages) => {
                    const previousMessageDate =
                      index > 0
                        ? convertToDate(
                            [...messages].reverse()[index - 1]
                              .created_at as Timestamp,
                        )
                        : null;
                    const currentMessageDate = convertToDate(
                      msg.created_at as Timestamp,
                    );
                    const showDivider =
                      index === 0 ||
                      !dayjs(previousMessageDate).isSame(
                        currentMessageDate,
                        'day',
                      );
                    const isLastMessage = index === filteredMessages.length - 1;
                    return (
                      <div
                        key={index}
                        className="flex relative flex-col"
                        onMouseEnter={() => setHoveredMessageIndex(index)}
                        onMouseLeave={() => setHoveredMessageIndex(null)}
                      >
                        {showDivider && (
                          <div className="relative flex items-center py-4">
                            <div className="flex-grow border-t border-gray-200" />
                            <span className="mx-4 text-gray-500 bg-white px-2">
                              {getDateLabel(currentMessageDate, t)}
                            </span>
                            <div className="flex-grow border-t border-gray-200" />
                          </div>
                        )}
                        <BubbleItem
                          content={msg}
                          isSender={msg.sender_id === Number(currentUser?.uid)}
                          time={formatTimestamp(t, msg.created_at as Timestamp)}
                          dropdownVisible={dropdownVisible}
                          editingMessageIndex={editingMessageIndex}
                          hoveredMessageIndex={hoveredMessageIndex}
                          index={index}
                          handleDropdownToggle={handleDropdownToggle}
                          handleDeleteMessage={handleDeleteMessage}
                          users={usersInChat}
                          handleEditMessage={handleEditMessage}
                          imgDomain={IMG_DOMAIN}
                          isGroup={selectedConversation?.is_group_chat}
                          messageStatus={messageStatus}
                          isSendingMessage={isSendingMessage}
                          messagesContainerRef={messagesContainerRef}
                          isLastMessage={isLastMessage}
                        />
                      </div>
                    );
                  })}
              </>
            ) : (
              <div className="flex flex-col justify-center items-center w-full h-full text-gray-400"></div>
            )}
          </>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="px-6 py-4 flex items-center">
        <InputMessagePart
          inputRef={inputRef}
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          selectedConversation={selectedConversation}
          handleImageUpload={handleImageUpload}
          selectedImages={selectedImages}
          handleRemoveImage={handleRemoveImage}
          isUploading={isUploading}
          handleSendMessage={handleSendMessage}
          handleSendImage={handleSendImage}
          handleCancelEditMessage={handleCancelEditMessage}
          editingMessage={editingMessage}
        />
        {isAddMemberVisible && (
          <AddMemberGroupDialog
            openPopup={isAddMemberVisible}
            setOpenPopup={setIsAddMemberVisible}
            selectedConversation={selectedConversation}
            users={users}
            handleLeaveGroup={handleLeaveGroup}
          />
        )}
        {isDeleteMemberVisible && (
          <DeleteMemberGroupDialog
            openPopup={isDeleteMemberVisible}
            setOpenPopup={setIsDeleteMemberVisible}
            selectedConversation={selectedConversation}
            handleLeaveGroup={handleLeaveGroup}
            initializeUsers={initializeUsers}
          />
        )}
      </div>
    </div>
  );
};
