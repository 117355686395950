/* eslint @typescript-eslint/no-explicit-any: 0 */
import { Toast } from 'primereact/toast';
import { createContext, ReactNode, useLayoutEffect, useRef } from 'react';

export const ToastContext = createContext<any>({ toast: null });

type Props = {
  children: ReactNode;
};

export const CommonToast = ({ children }: Props) => {
  const toastRef = useRef<Toast>(null);

  useLayoutEffect(() => {
    (window as any).PrimeToast = toastRef.current || {};
  }, []);

  return (
    <ToastContext.Provider value={{ toast: toastRef }}>
      <Toast ref={toastRef} position="top-right" />
      {children}
    </ToastContext.Provider>
  );
};