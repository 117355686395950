import login from './login';
import common from './common';
import user from './user';
import user_list from './user_list';
import menu from './menu';
import lesson_list from './lesson_list';
import lesson from './lesson';
import lesson_video from './lesson_video';
import lesson_student from './lesson_student';
import lesson_question from './lesson_question';
import forgot_password from './forgot_password';
import lesson_progress from './lesson_progress';
import reset_password from './reset_password';
import reset_password_success from './reset_password_success';
import version from './version';
import chat from './chat';

const resources = {
  common,
  login,
  user,
  user_list,
  menu,
  lesson_list,
  lesson,
  lesson_video,
  lesson_student,
  lesson_question,
  forgot_password,
  lesson_progress,
  reset_password,
  reset_password_success,
  version,
  chat
};

export default resources;
