const chat = {
  list_contact: 'アカウント',
  plh_input_message: 'メッセージを入力',
  plh_search: 'ユーザー名、メールアドレスを入力',
  plh_search_main: '検索',
  deleted_message: 'メッセージを削除しました。',
  delete: '削除',
  edit: '編集',
  reply: 'Reply',
  create: '作成',
  list_conversation: 'トーク',
  group_name: 'グループ名',
  plh_group_name: 'グループ名を入力',
  title_popup_create_group: 'グループプロフィール',
  not_found_user: 'データがありません。',
  confirm_create_group_msg:
    '同じメンバーが参加するグループが既に存在します。このグループに移動しますか。',
  confirm_create_group_title: 'グループ作成',
  create_group_btn: 'グループを作成',
  access_group_btn: 'グループに移動',
  edited_message: '編集済み',
  confirm_leave_group_msg:
    'グループを退会すると、グループ上でトークすることができません。グループを退会しますか。',
  confirm_leave_group_title: 'グループ退会',
  group_setting: 'グループプロフィール',
  leave_group: '退会',
  title_popup_edit_group: 'グループプロフィール',
  update: 'アップデート',
  member: 'メンバー',
  confirm_delete_msg:
    '選択したメッセージはこの端末上でのみ削除されます。他のチャットメンバーには削除されません。よろしいでしょうか。',
  confirm_unsent_msg:
    '選択したメッセージは全員に対して削除されます。よろしいでしょうか。',
  confirm_delete_msg_title: 'メッセージ削除',
  cancel: 'キャンセル',
  sent_a_file: 'ご指定の画像は送信済みです。',
  unsent: '送信取消',
  has_left_group: 'がグループを退会しました。',
  add_to_group: '{{owner_name}}が{{member_name}}をグループに追加しました。',
  declined_to_group: 'がグループのご招待を拒否しました。',
  remove_to_group:
    '{{owner_name}}が{{member_name}}をグループから削除しました。',
  yesterday: '昨日',
  today: '今日',
  uploading: 'アップロード中',
  close: '閉じる',
  clear_all: '全解除',
  select_all: '全選択',
  confirm_join_group_title: 'グループのご招待',
  reject: '拒否',
  accept: '承認',
  confirm_join_group_msg:
    '{{user_name}}があなたを{{group_name}}グループに招待しました。グループにご参加しますでしょうか。',
  invite_group_message: '{{user_name}}があなたを{{group_name}}に招待しました。',
  img_size_error: '写真送信に失敗しました。写真サイズの上限は5MBです。',
  sent: '送信済み',
  seen: '既読',
  sending: '送信中',
  send_file: '写真を送信しました。',
  receive_file: '{{user_name}}が写真を送信しました。',
  confirm_delete_user:
    '{{user_name}}をグループから削除しますか。',
  delete_user: 'メンバー削除',
  memu_add_member: 'メンバー招待',
  memu_remove_member: 'グループプロフィール',
  change_name_group:
    '{{owner_name}}がグループ名を{{group_name}}に変更しました。',
  no_data: 'データがありません。',
  update_avatar_success: '写真をアップロードしました。',
  img_format_err: '写真はSVG, PNG, JPG, jpeg SVGのうちいずれかの形式のファイルを指定してください。',
  add_new_member: '追加',
  add_member_title:'メンバー招待',
  title_popup_info_group_for_member: 'グループプロフィール',
  confirm_add_new_member_title: 'メンバー追加',
  confirm_add_new_member_msg:
    'ご指定のユーザーをグループに招待します。よろしいでしょうか。',
  img_upload_err: '送信に失敗しました。再送信',
  inactive: '無効済み',
  confirm_out_group_msg:
    'あなたは{{owner_name}}にグループから削除されています。トーク履歴も削除されますため、ご了承ください。',
  confirm_out_group_title: 'グループ削除',
  ok: 'OK',
};

export default chat;
