export default function IconLock() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_9474_5568" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.394 7.14751V4.8389C15.3596 2.13281 13.065 -0.0329235 10.271 0.000378975C7.53367 0.0336814 5.32004 2.16719 5.27344 4.81742V7.14751"
        />
      </mask>
      <path
        d="M13.894 7.14751C13.894 7.97594 14.5656 8.64751 15.394 8.64751C16.2224 8.64751 16.894 7.97594 16.894 7.14751H13.894ZM15.394 4.8389H16.894C16.894 4.83255 16.894 4.82619 16.8939 4.81984L15.394 4.8389ZM10.271 0.000378975L10.2531 -1.49951L10.2528 -1.49951L10.271 0.000378975ZM5.27344 4.81742L3.77367 4.79104C3.77351 4.79983 3.77344 4.80862 3.77344 4.81742H5.27344ZM3.77344 7.14751C3.77344 7.97594 4.44501 8.64751 5.27344 8.64751C6.10186 8.64751 6.77344 7.97594 6.77344 7.14751H3.77344ZM16.894 7.14751V4.8389H13.894V7.14751H16.894ZM16.8939 4.81984C16.8484 1.24004 13.8284 -1.54213 10.2531 -1.49951L10.2889 1.50027C12.3015 1.47628 13.8708 3.02558 13.8941 4.85797L16.8939 4.81984ZM10.2528 -1.49951C6.74852 -1.45688 3.83529 1.2865 3.77367 4.79104L6.77321 4.84379C6.80479 3.04787 8.31881 1.52424 10.2893 1.50027L10.2528 -1.49951ZM3.77344 4.81742V7.14751H6.77344V4.81742H3.77344Z"
        fill="currentColor"
        mask="url(#path-1-inside-1_9474_5568)"
      />
      <path
        d="M10.3331 12.2534V14.6394"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.58398 13.2622C2.58398 10.8171 3.10108 9.42555 4.15912 8.58691C5.27121 7.70543 7.16352 7.27539 10.3334 7.27539C13.5033 7.27539 15.396 7.70542 16.5084 8.58696C17.5667 9.42564 18.084 10.8172 18.084 13.2622C18.084 15.7077 17.5666 17.0995 16.5083 17.9383C15.3959 18.82 13.5033 19.25 10.3334 19.25C7.16351 19.25 5.27123 18.8199 4.15915 17.9384C3.1011 17.0996 2.58398 15.7078 2.58398 13.2622Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
