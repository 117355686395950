import { useTranslation } from 'react-i18next';

import { ConversationType, UserType } from '@/types/chat';
import { UserItemSkeleton, ConversationItem } from '../aside';
import { useChat } from '@/components/chat/ChatContext';

type Props = {
  loading?: boolean;
  loadingUsers?: boolean;
  selectedConversation?: ConversationType | null;
  handleConversationClick: (value: ConversationType) => void;
  conversationsFilted: ConversationType[];
  users: UserType[];
  listConversationRef: React.MutableRefObject<HTMLDivElement | null>;
};

export const ConversationSearchList: React.FC<Props> = ({
  loading,
  loadingUsers,
  selectedConversation,
  handleConversationClick,
  conversationsFilted,
  users,
  listConversationRef
}) => {
  const [t] = useTranslation();
  const { isNotSeenMap, isNeedConfirmMap } = useChat();

  return (
    <>
      <div
        className="flex flex-col w-full overflow-y-auto max-h-[37vh]"
        ref={listConversationRef}
      >
        {conversationsFilted.length > 0 &&
          !loading &&
          !loadingUsers &&
          conversationsFilted.map((conversation: ConversationType) => (
            <ConversationItem
              key={conversation.id}
              conversation={conversation}
              onClick={() => handleConversationClick(conversation)}
              isActive={selectedConversation?.id === conversation.id}
              users={users}
              isNotSeen={isNotSeenMap[conversation.id]}
              isNeedToConfirm={isNeedConfirmMap[conversation.id]}
            />
          ))}
        {(loading || loadingUsers) && (
          <>
            {Array.from({ length: 10 }).map((_, index) => (
              <UserItemSkeleton key={index} />
            ))}
          </>
        )}
        {!conversationsFilted.length && !loading && !loadingUsers && (
          <div className="text-center mt-8 text-gray-400">{t('chat.not_found_user')}</div>
        )}
      </div>
    </>
  );
};

export default ConversationSearchList;
