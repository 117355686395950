import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { UserFormSearchType, userSearchFormSchema } from '@/shared/schema/user';
import { auth, db } from '@/lib/firebase';
import { ConversationType, UserType } from '@/types/chat';
import { ChatBox, ConfirmJoinGroup, ConfirmOutGroup } from '../chatbox';
import {
  ChatAside,
  UserItemSkeleton,
  ConversationItem,
  SearchList,
} from '../aside';
import { INVITE_STATUS } from '@/shared/constants/chat';
import { useChat } from '@/components/chat/ChatContext';
import { ObjectLiteral } from '@/types/object';
import { apiRequest } from '@/services';
import { PaginationInfo } from '@/types/pagination';
import useDebounceFn from '@/hooks/useDebounceFn';
import PATH from '@/routes/path';
import { STATUS } from '@/shared/constants/user';
import { signOut } from '@firebase/auth';
import { DEFAULT_PER_PAGE } from '@/shared/constants/pagination';

const DEFAULT_PAGINATION = {
  current_page: 0,
  per_page: 10,
  total_page: 1,
  total_item: 1,
  has_more: true,
};

let controllerVersion = new AbortController();

const createUserLookup = (users: UserType[]): { [key: number]: UserType } => {
  return users.reduce((lookup: { [key: number]: UserType }, user: UserType) => {
    lookup[user.id] = user;
    return lookup;
  }, {});
};

const mapConversationsWithMembers = (
  conversations: ConversationType[],
  userLookup: { [key: number]: UserType },
): ConversationType[] => {
  return conversations.map((chat) => ({
    ...chat,
    members: chat.member_ids ? chat.member_ids.map((id) => userLookup[id]) : [],
  }));
};

const filterConversations = (
  conversations: ConversationType[],
  keyword: string,
): ConversationType[] => {
  return conversations.filter((item) =>
    item.name?.toLowerCase()?.includes(keyword.toLowerCase()),
  );
};

const Conversation: React.FC = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { apiService } = apiRequest();
  const [isFetching, setIsFetching] = useState(false);
  const [contacts, setContacts] = useState<UserType[]>([]);
  const [pagination, setPagination] =
    useState<PaginationInfo>(DEFAULT_PAGINATION);
  const [selectedConversation, setSelectedConversation] = useState<
    ConversationType | null | undefined
  >(null);
  const [conversationsFilted, setConversationFiltered] = useState<
    ConversationType[]
  >([]);
  const [users, setUsers] = useState<UserType[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupRemove, setOpenPopupRemove] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const listRef = useRef<HTMLDivElement>(null);
  const listContactRef = useRef<HTMLDivElement>(null);
  const currentUser = auth.currentUser;
  const { conversationId: id } = useParams<{
    conversationId: string | undefined;
  }>();

  const {
    conversations,
    isNotSeenMap,
    isNeedConfirmMap,
    loading,
    messageStatus,
  } = useChat();

  const isAccepted =
    (selectedConversation &&
      selectedConversation?.invitation_statuses?.[Number(currentUser?.uid)]
        ?.status !== INVITE_STATUS.PENDING) ||
    !selectedConversation?.is_group_chat;
  const isRemoved =
    selectedConversation &&
    selectedConversation.is_group_chat &&
    selectedConversation.invitation_statuses?.[Number(currentUser?.uid)]
      ?.status === INVITE_STATUS.REMOVED;

  const formMethods = useForm<UserFormSearchType>({
    mode: 'onChange',
    resolver: yupResolver(userSearchFormSchema),
    shouldFocusError: false,
    defaultValues: {
      keyword: '',
    },
  });

  const { handleSubmit } = formMethods;

  const handleGetConversationFilter = (keyword: string) => {
    const userLookup = createUserLookup(users);
    const conversationsWithMembers = mapConversationsWithMembers(
      conversations,
      userLookup,
    );
    const formatKeyWord = keyword?.trim()?.toLowerCase();
    const filteredConversations = filterConversations(
      conversationsWithMembers,
      formatKeyWord,
    );

    setConversationFiltered(keyword ? filteredConversations : conversations);
  };

  const handleFetchContacts = useCallback(
    async (query?: ObjectLiteral) => {
      setIsFetching(true);

      if (controllerVersion.abort) controllerVersion.abort();
      controllerVersion = new AbortController();
      const { signal } = controllerVersion;

      const { data: res, error } = await apiService.contacts.query(
        '',
        {
          paginate: (pagination?.current_page || 0) + 1,
          ...query,
          limit: DEFAULT_PAGINATION.per_page,
        },
        { signal },
      );

      if (res) {
        setContacts((prevContacts) => [...prevContacts, ...res.data]);
        setPagination(res.pagination as PaginationInfo);
        setIsFetching(false);
      }

      if (error) {
        if (signal?.aborted && error === 'error') return;
        setContacts([]);
        setIsFetching(false);
      }
    },
    [pagination, isFetching],
  );

  const debouncedSearch = useDebounceFn((keyword: string) => {
    const params = {
      keyword: keyword,
      paginate: 1,
    };
    handleFetchContacts(params);
    setContacts([]);
  }, 300);

  const onSubmit = (value: UserFormSearchType) => {
    handleGetConversationFilter(String(value.keyword));
    debouncedSearch(value.keyword);
  };

  const fetchUsers = async (ids: number[]) => {
    try {
      const { data: allUsers } = await apiService.chatParticipants.create({
        user_ids: ids,
      });
      setUsers((prev)=> [...prev, ...allUsers.data]);
    } catch (error) {
      setUsers([]);
    }
  };

  const initializeUsers = async (memberIds: number[]) => {
    try {
      const BATCH_SIZE = 1000;
      const chunks = [];
      for (let i = 0; i < memberIds.length; i += BATCH_SIZE) {
        chunks.push(memberIds.slice(i, i + BATCH_SIZE));
      }
      for (const chunk of chunks) {
        await fetchUsers(chunk);
      }
    } catch (error) {
      console.error('Error initializing users:', error);
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleConversationClick = async (conversation: ConversationType) => {
    setSelectedConversation(conversation);
    navigate(`/chat/conversation/${conversation.id}`);
  };

  const selectedUser = useMemo(() => {
    if (!id || !conversations) return null;

    const currentConversation = conversations.find((item) => item.id === id);
    const memberIds = currentConversation?.member_ids || [];

    return users?.find(
      (user) =>
        memberIds.includes(Number(user.id)) &&
        user.id !== Number(currentUser?.uid),
    );
  }, [id, conversations, users]);

  const onLogoutWhenInactive = async () => {
    try {
      await Promise.all([apiService.auth.logout(), signOut(auth)]);
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('tokenExpiresAt');
      navigate(PATH.login);
    }
  };

  const handleScroll = useCallback(() => {
    const container = listContactRef.current;
    if (!container) return;
    if (
      container.scrollHeight - container.scrollTop <=
        container.clientHeight + 100 &&
      pagination?.has_more &&
      !isFetching
    ) {
      handleFetchContacts({ keyword: formMethods.watch('keyword'), limit: DEFAULT_PER_PAGE });
    }
  }, [pagination?.has_more, isFetching]);

  useEffect(() => {
    if (isFirstLoad && conversations?.length > 0) {
      const allMemberIds = Array.from(
        new Set(conversations.filter(item => !item.is_group_chat).flatMap((conv) => conv.member_ids || [])),
      );
      initializeUsers(allMemberIds);
      setIsFirstLoad(false);
    }
    if (conversations?.length === 0) {
      setLoadingUsers(false);
    }
  }, [isFirstLoad, conversations]);

  useEffect(() => {
    setSelectedConversation(conversations.find((item) => item.id === id));
    setConversationFiltered(conversations);
    handleGetConversationFilter(String(formMethods.watch('keyword')));
  }, [conversations, id]);

  useEffect(() => {
    const shouldNeedConfirm =
      selectedConversation &&
      selectedConversation.is_group_chat &&
      selectedConversation.invitation_statuses?.[Number(currentUser?.uid)]
        ?.status === INVITE_STATUS.PENDING;
    setOpenPopup(Boolean(shouldNeedConfirm));
  }, [selectedConversation, currentUser]);

  useEffect(() => {
    const shouldOutGroup =
      selectedConversation &&
      selectedConversation.is_group_chat &&
      selectedConversation.invitation_statuses?.[Number(currentUser?.uid)]
        ?.status === INVITE_STATUS.REMOVED;
    setOpenPopupRemove(Boolean(shouldOutGroup));
  }, [selectedConversation]);

  useEffect(() => {
    if (!currentUser) return;
    const userDocRef = doc(db, 'users', currentUser.uid);
    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        if (docSnapshot.data().status === STATUS.INACTIVE) {
          onLogoutWhenInactive();
        }
      } else {
        console.error('User not found.');
      }
    });
    return () => unsubscribe();
  }, [currentUser?.uid]);

  useEffect(() => {
    const container = listContactRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <>
      <ConfirmDialog />
      <div className="h-full flex items-center justify-center overflow-hidden max-h-[calc(100vh-20px)]">
        <div className="flex w-full h-full">
          <FormProvider {...formMethods}>
            <ChatAside
              title={t('chat.list_conversation')}
              onSubmit={handleSubmit(onSubmit)}
              initializeUsers={initializeUsers}
            >
              {formMethods.watch('keyword') === '' ? (
                <div
                  className="flex flex-col w-full overflow-y-auto max-h-[calc(100dvh-150px)]"
                  ref={listRef}
                >
                  {conversationsFilted.length > 0 &&
                    !loading &&
                    !loadingUsers &&
                    conversationsFilted.map(
                      (conversation: ConversationType) => (
                        <ConversationItem
                          key={conversation.id}
                          conversation={conversation}
                          onClick={() => handleConversationClick(conversation)}
                          isActive={
                            selectedConversation?.id === conversation.id
                          }
                          users={users}
                          isNotSeen={isNotSeenMap[conversation.id]}
                          isNeedToConfirm={isNeedConfirmMap[conversation.id]}
                          initializeUsers={initializeUsers}
                        />
                      ),
                    )}
                  {(loading || loadingUsers) && (
                    <>
                      {Array.from({ length: 10 }).map((_, index) => (
                        <UserItemSkeleton key={index} />
                      ))}
                    </>
                  )}
                  {!conversationsFilted.length && !loading && !loadingUsers && (
                    <div className="text-center mt-8 text-gray-400">
                      {t('common.form.empty')}
                    </div>
                  )}
                </div>
              ) : (
                <SearchList
                  handleConversationClick={handleConversationClick}
                  conversationsFilted={conversationsFilted}
                  loading={loading}
                  loadingUsers={loadingUsers}
                  selectedConversation={selectedConversation}
                  users={users}
                  pagination={pagination}
                  isFetching={isFetching}
                  keywordFormValue={formMethods.watch('keyword')}
                  handleFetchContacts={handleFetchContacts}
                  contacts={contacts}
                  listContactRef={listContactRef}
                />
              )}
            </ChatAside>
          </FormProvider>
          {selectedConversation && (
            <ChatBox
              key={selectedConversation?.id}
              selectedConversation={selectedConversation}
              selectedUser={selectedUser}
              messageStatus={messageStatus}
              isAccepted={isAccepted}
              isRemoved={Boolean(isRemoved)}
              users={users}
              initializeUsers={initializeUsers}
            />
          )}
          {openPopup && (
            <ConfirmJoinGroup
              selectedConversation={selectedConversation}
              users={users}
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            />
          )}
          {openPopupRemove && (
            <ConfirmOutGroup
              selectedConversation={selectedConversation}
              users={users}
              openPopup={openPopupRemove}
              setOpenPopup={setOpenPopupRemove}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Conversation;
