import { useEffect, useState } from 'react';
import {
  TabPanel,
  TabPanelHeaderTemplateOptions,
  TabView,
  TabViewTabChangeEvent,
} from 'primereact/tabview';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from '@/config/i18n';
import { ROLE } from '@/shared/constants/user';
import useUserRole from '@/hooks/useUserRole';
import { ProgressDataType } from '@/types/progress';
import { CoachProgress, CommentsDialog, StudentProgress, WorksDialog } from '.';
import ProgressFormSearch from './ProgressFormSearch';
import useSearch from '@/hooks/useSearch';
import PATH from '@/routes/path';

export const tabTemplate = (options: TabPanelHeaderTemplateOptions) => {
  return (
    <div
      className="flex align-items-center cursor-pointer"
      onClick={options.onClick}
    >
      <span className="tab-label font-medium white-space-nowrap">
        {options.titleElement}
      </span>
    </div>
  );
};

const LessonProgress: React.FC = () => {
  const [t] = useTranslation('');
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isOpenCommentModal, setIsOpenCommentModal] = useState(false);
  const [isOpenWorkModal, setIsOpenWorkModal] = useState(false);
  const [unitId, setUnitId] = useState<number>(0);
  const [rowData, setRowData] = useState<ProgressDataType | null>(null);
  const { role: userRole } = useUserRole();
  const { handleSearch, searchParams } = useSearch();
  const query = Object.fromEntries(searchParams.entries());
  const {created_by} = query || {};
  const [createdBy, setCreatedBy] = useState("");
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const handleBack = () => {
    navigate(PATH.lesson);
  };

  const onBeforeTabChange = (event: TabViewTabChangeEvent) => {
    handleSearch({});
    const { index } = event;
    setActiveIndex(index);
  };

  useEffect(() => {
    if (created_by && isFirstLoad) {
      setCreatedBy(created_by);
      setIsFirstLoad(false);
    }
  }, [created_by, isFirstLoad]);

  return (
    <>
      <div className="header-top">
        <h2 className="title flex">
          <span
            className="pi pi-arrow-left text-[20px] text-gray-500 cursor-pointer"
            onClick={handleBack}
          ></span>
          <span className="ml-3">{t('lesson_progress.page_title')}</span>
        </h2>
      </div>
      <TabView
        activeIndex={activeIndex}
        className="mt-[20px]"
        onBeforeTabChange={onBeforeTabChange}
      >
        <TabPanel
          header={t('lesson_progress.student')}
          headerTemplate={tabTemplate}
        >
          <ProgressFormSearch placeholderMsg={t('user_list.keyword_student')} />
          <StudentProgress
            setIsOpenCommentModal={setIsOpenCommentModal}
            setIsOpenWorkModal={setIsOpenWorkModal}
            setRowData={setRowData}
            setUnitId={setUnitId}
          />
        </TabPanel>
        {[ROLE.ADMIN, ROLE.SUPPER_ADMIN].includes(userRole) &&
        createdBy?.toUpperCase() !== ROLE.TEACHER && (
          <TabPanel
            header={t('lesson_progress.coach')}
            headerTemplate={tabTemplate}
          >
            <ProgressFormSearch
              placeholderMsg={t('user_list.keyword_coach')}
            />
            <CoachProgress
              setIsOpenCommentModal={setIsOpenCommentModal}
              setIsOpenWorkModal={setIsOpenWorkModal}
              setRowData={setRowData}
              setUnitId={setUnitId}
            />
          </TabPanel>
        )}
      </TabView>

      {isOpenWorkModal && (
        <WorksDialog
          isOpenWorkModal={isOpenWorkModal}
          setIsOpenWorkModal={setIsOpenWorkModal}
          rowData={rowData}
          unitId={unitId}
        />
      )}
      {isOpenCommentModal && (
        <CommentsDialog
          isOpenCommentModal={isOpenCommentModal}
          setIsOpenCommentModal={setIsOpenCommentModal}
          rowData={rowData}
          unitId={unitId}
          isSelectCoachTab={!!activeIndex}
        />
      )}
    </>
  );
};

export default LessonProgress;
