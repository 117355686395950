import * as yup from 'yup';
import { BaseYup, passwordRegExp } from '@/config/baseYup';
import i18n from '@/config/i18n';

export const resetPasswordSchema = BaseYup.object({
  otp: BaseYup.string().label(i18n.t('reset_password.otp_code')).required().min(6, i18n.t('common.form.field_required', {
    label_name: i18n.t('reset_password.otp_code'),
  })),
  password: BaseYup.string().label(i18n.t('reset_password.password')).required().min(8, i18n.t('common.form.password_length')) .max(32, i18n.t('common.form.password_length')).matches(passwordRegExp, {
    message: i18n.t('common.form.password_invalid'),
  }),
  password_confirmation: BaseYup.string().label(i18n.t('reset_password.confirm_password')).required().oneOf(
    [BaseYup.ref('password')],
    i18n.t('common.form.password_confirmation_invalid'),
  ).min(8, i18n.t('common.form.password_length')).max(32, i18n.t('common.form.password_length')).matches(passwordRegExp, {
    message: i18n.t('common.form.password_invalid'),
  }),
});

export type ResetPasswordFormType = yup.InferType<typeof resetPasswordSchema>;