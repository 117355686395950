export default function IconImg() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.55943 16.2733L9.05654 10.7761C9.38656 10.4461 9.55156 10.2811 9.74184 10.2193C9.9092 10.1649 10.0895 10.1649 10.2569 10.2193C10.4471 10.2811 10.6121 10.4461 10.9422 10.7761L16.4026 16.2366M11.666 11.5L14.0565 9.10948C14.3866 8.77946 14.5516 8.61445 14.7418 8.55263C14.9092 8.49825 15.0895 8.49825 15.2569 8.55263C15.4471 8.61445 15.6121 8.77946 15.9422 9.10947L18.3327 11.5M8.33268 6.5C8.33268 7.42047 7.58649 8.16667 6.66602 8.16667C5.74554 8.16667 4.99935 7.42047 4.99935 6.5C4.99935 5.57953 5.74554 4.83333 6.66602 4.83333C7.58649 4.83333 8.33268 5.57953 8.33268 6.5ZM5.66602 16.5H14.3327C15.7328 16.5 16.4329 16.5 16.9677 16.2275C17.4381 15.9878 17.8205 15.6054 18.0602 15.135C18.3327 14.6002 18.3327 13.9001 18.3327 12.5V5.5C18.3327 4.09987 18.3327 3.3998 18.0602 2.86502C17.8205 2.39462 17.4381 2.01217 16.9677 1.77248C16.4329 1.5 15.7328 1.5 14.3327 1.5H5.66602C4.26588 1.5 3.56582 1.5 3.03104 1.77248C2.56063 2.01217 2.17818 2.39462 1.9385 2.86502C1.66602 3.3998 1.66602 4.09987 1.66602 5.5V12.5C1.66602 13.9001 1.66602 14.6002 1.9385 15.135C2.17818 15.6054 2.56063 15.9878 3.03104 16.2275C3.56582 16.5 4.26588 16.5 5.66602 16.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
