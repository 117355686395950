export default function IconManual() {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 15.6006V11.6006M11 7.60059H11.01M21 11.6006C21 17.1234 16.5228 21.6006 11 21.6006C5.47715 21.6006 1 17.1234 1 11.6006C1 6.07774 5.47715 1.60059 11 1.60059C16.5228 1.60059 21 6.07774 21 11.6006Z"
        stroke="#84CAFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
