import React, { useMemo } from 'react';

import MaintainImg from '@/assets/images/maintain-image.png';

const MaintainPage: React.FC = () => {
  const { message, time } = useMemo(() => {
    return JSON.parse(localStorage.getItem('error_503') || '') || {};
  }, []);

  return (
    <div className="flex-full-center flex-col h-screen bg-gray-100">
      <div className="w-[380px]">
        <div className="w-[128px] h-[128px] m-auto">
          <img
            className="w-[100%] h-[100%] object-cover"
            src={MaintainImg}
          ></img>
        </div>

        <div className="mt-[32px]">
          <div className="text-gray-700 text-[16px] font-medium mb-[8px]">
            現在メンテナンス中です。
          </div>

          <div className="p-[12px] bg-white text-gray-700 text-[14px] font-normal leading-[21px] border border-gray-200 rounded-[12px]">
            <div className="whitespace-pre-wrap">{message}</div>
          </div>

          <div className="text-gray-700 text-[16px] font-medium mb-[8px] mt-[16px]">
            メンテナンス期間
          </div>

          <div className="p-[12px] bg-white text-gray-700 text-[14px] font-normal leading-[21px] border border-gray-200 rounded-[12px]">
            <div className="whitespace-pre-wrap">{time}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintainPage;
