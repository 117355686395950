const useScrollToError = () => {
  function scrollToError() {
    if (document && typeof window !== 'undefined') {
      const classError = '.error-message';
      const errors = document.querySelectorAll(classError);
      if (!errors.length) return;
      const firstElement = errors[0] as HTMLElement;
      const offsetTop = firstElement.offsetTop;
      window.scrollTo({ top: offsetTop - 100, left: 0, behavior: 'smooth' });
    }
  }

  return {
    scrollToError,
  };
};

export default useScrollToError;
