const lesson_student = {
  header: '受講生追加',
  student_label: '受講生',
  video_label: '閲覧できるユニット数',
  student_required: '受講生を入力してください。',
  video_required: '閲覧できるユニット数を入力してください。',
  add: '新規追加',
  all: '全て',
  button: {
    cancel: 'キャンセル',
    add: '保存',
  },
  table: {
    name: '受講生名',
    email: 'メールアドレス',
    phone_number: '電話番号',
    total_video: '閲覧できる\nユニット数',
    mentor_email: 'アートシフトコーチ',
    created_date: '参加日'
  },
  add_student_dialog: {
    title: '受講生重複',
    content: '指定した受講生は既に指定されましたため、他の受講生を指定してください。',
  },
  add_student_warning: '一括受講生追加をご指定いただいたため、個別受講生の指定が無効となります。',
}

export default lesson_student;