export default function IconCheckboxChecked() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="1" width="23" height="23" rx="5.5" fill="#EFF8FF" />
      <rect x="0.5" y="1" width="23" height="23" rx="5.5" stroke="#2E90FA" />
      <path
        d="M17.5994 8.30005L9.89941 16L6.39941 12.5"
        stroke="#1570EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
