import * as yup from 'yup';
import { BaseYup, MAX_INPUT, MAX_LENGTH_300 } from '@/config/baseYup';
import i18n from '@/config/i18n';

export const lessonSearchFormSchema = (activeIndex: number) => BaseYup.object({
  name: BaseYup.string().max(MAX_INPUT),
  status: BaseYup.string().max(MAX_INPUT).nullable(),
  mentor_id: BaseYup.string()
    .nullable()
    .transform(function (_value, originalValue) {
      if (typeof originalValue === 'object' && originalValue !== null) {
        return originalValue.value;
      }
      return originalValue;
    })
    // eslint-disable-next-line no-empty-pattern
    .when('is-coach-tab', ([], schema) => {
      return activeIndex
        ? schema.required('アートシフトコーチを選択してください。')
        : schema.nullable();
    })
});

const lessonSearchFormSchemaOriginal = BaseYup.object({
  name: BaseYup.string().max(MAX_INPUT),
  status: BaseYup.string().max(MAX_INPUT).nullable(),
  mentor_id: BaseYup.string()
    .nullable()
    .transform(function (_value, originalValue) {
      if (typeof originalValue === 'object' && originalValue !== null) {
        return originalValue.value;
      }
      return originalValue;
    })
    // eslint-disable-next-line no-empty-pattern
    .when('is-coach-tab', ([activeIndex], schema) => {
      return activeIndex
        ? schema.required('アートシフトコーチを選択してください。')
        : schema.nullable();
    })
})

export const lessonFormSchema = BaseYup.object({
  name: BaseYup.string().label(i18n.t('lesson.name')).required().max(MAX_INPUT),
  code: BaseYup.string().label(i18n.t('lesson.code')).max(MAX_INPUT),
  status: BaseYup.string()
    .label(i18n.t('lesson.status'))
    .max(MAX_INPUT)
    .transform(function (_value, originalValue) {
      if (typeof originalValue === 'object' && originalValue !== null) {
        return originalValue.value;
      }
      return originalValue;
    }),
  description: BaseYup.string()
    .label(i18n.t('lesson.description'))
    .max(MAX_LENGTH_300),
  thumbnail_id: BaseYup.number().label(i18n.t('lesson.image')),
});

export type LessonFormType = yup.InferType<typeof lessonFormSchema>;
export type LessonFormSearchType = yup.InferType<typeof lessonSearchFormSchemaOriginal>;
