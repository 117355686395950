import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { confirmDialog } from 'primereact/confirmdialog';
import { useNavigate } from 'react-router-dom';
import { collection, doc, setDoc, getDocs } from 'firebase/firestore';

import DialogCustom from '@/components/common/ui/dialog';
import ButtonCustom from '@/components/common/ui/button';
import { ConversationType, UserType } from '@/types/chat';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import {
  GroupChatSchema,
  GroupChatType,
} from '@/shared/schema/create-group-chat';
import { auth, db } from '@/lib/firebase';
import { generateConversationData } from '@/lib/utils';
import PATH from '@/routes/path';
import { ListContactCreateGroup, useFetchContacts } from '.';
import { UploadAvatar } from '@/components/common/ui/upload';
import { NOTIFICATION_TYPE } from '@/shared/constants/chat';
import { apiRequest } from '@/services';
import { ROLE } from '@/shared/constants/user';

type Props = {
  openPopup?: boolean;
  setOpenPopup: (value: boolean) => void;
};

export const CreateGroupDialog: React.FC<Props> = ({
  openPopup,
  setOpenPopup,
}) => {
  const [t] = useTranslation('');
  const [selectedUsers, setSelectedUsers] = useState<UserType[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { apiService } = apiRequest();
  const currentUserFromFirebase = auth.currentUser;
  const navigate = useNavigate();
  const conversationLink = `${PATH.chat}/${PATH.chat_conversation}/`;

  const { contacts, isFetching, fetchContacts, resetContacts } =
    useFetchContacts();

  const contactOrg = useMemo(() => {
    return contacts.filter((item) => {
      const hasValidRole = item?.current_roles?.some(
        (role) => ![ROLE.ADMIN, ROLE.SUPPER_ADMIN].includes(role),
      );

      return hasValidRole;
    });
  }, [contacts]);

  const formMethods = useForm<GroupChatType>({
    mode: 'onChange',
    resolver: yupResolver(GroupChatSchema),
    defaultValues: {
      group_name: '',
      group_avatar: '',
      group_members: [],
    },
  });

  const { setValue, watch } = formMethods;
  const avatarGroup = watch('group_avatar') ?? '';

  const isSelectedAll = useMemo(() => {
    return contactOrg.length === selectedUsers.length;
  }, [contactOrg, selectedUsers]);

  const handleUserSelect = (userParam: UserType) => {
    setSelectedUsers((prev: UserType[]) => {
      if (prev.map((user) => user.id).includes(userParam.id)) {
        return prev.filter((user) => user.id !== userParam.id);
      } else {
        return [...prev, userParam];
      }
    });
  };

  const checkExistingGroup = async (memberIds: number[]) => {
    const conversationRef = collection(db, 'conversations');

    const querySnapshot = await getDocs(conversationRef);

    const existingGroups = querySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .filter((group: ConversationType) => {
        const groupMemberIds = group.member_ids;
        return (
          memberIds.length === groupMemberIds?.length &&
          memberIds.every((id) => groupMemberIds.includes(id))
        );
      });
    existingGroups.sort((a: any, b: any) => {
      const aTimestamp = a.created_at.seconds;
      const bTimestamp = b.created_at.seconds;
      return bTimestamp - aTimestamp;
    });

    return existingGroups[0];
  };

  const sendNotificationToServer = useCallback(
    (receiverIds: number[], groupName: string) => {
      const paramsPushApp = {
        notification_type: NOTIFICATION_TYPE.NEW_MEMBER,
        receiver_ids: receiverIds.filter(
          (id) => id !== Number(currentUserFromFirebase?.uid),
        ),
        content: groupName,
      };

      apiService.notifications.collection('send', {
        method: 'POST',
        body: paramsPushApp,
      });
    },
    [],
  );

  const createChatRoom = async (data: {
    name?: string;
    image?: string;
    memberIds: number[];
    members: UserType[];
  }) => {
    const nameOrg = data.name !== '' ? data.name : '';
    sendNotificationToServer(data.memberIds, data?.name || '');
    try {
      const newConversation = doc(collection(db, 'conversations'));
      await setDoc(
        doc(db, 'conversations', newConversation.id),
        generateConversationData(
          newConversation.id,
          currentUserFromFirebase,
          true,
          data.memberIds,
          nameOrg,
          data.image,
        ),
      );

      return newConversation.id;
    } catch (error) {
      return null;
    }
  };

  const handleConfirmExistingGroup = (
    data: {
      name?: string;
      image?: string;
      memberIds: number[];
      members: UserType[];
    },
    existingGroup: { id: string },
  ) => {
    confirmDialog({
      draggable: false,
      message: t('chat.confirm_create_group_msg'),
      header: t('chat.confirm_create_group_title'),
      accept: async () => {
        setIsLoading(true);
        const chatRoomId = await createChatRoom(data);
        if (chatRoomId) navigate(`${conversationLink}${chatRoomId}`);
        onHidePopup();
        setIsLoading(false);
      },
      reject: () => {
        navigate(`${conversationLink}${existingGroup.id}`);
        onHidePopup();
      },
      footer: ({ accept, reject }) => (
        <div className="field">
          <div className="flex justify-center">
            <ButtonCustom
              className="mr-3 w-full rounded-2xl"
              type="button"
              severity="secondary"
              onClick={reject}
            >
              {t('chat.access_group_btn')}
            </ButtonCustom>
            <ButtonCustom
              type="button"
              className="w-full rounded-2xl"
              onClick={accept}
            >
              {t('chat.create_group_btn')}
            </ButtonCustom>
          </div>
        </div>
      ),
    });
  };

  const handleCreateNewGroup = async (data: {
    name?: string;
    image?: string;
    memberIds: number[];
    members: UserType[];
  }) => {
    setIsLoading(true);
    const chatRoomId = await createChatRoom(data);
    if (chatRoomId) navigate(`${conversationLink}${chatRoomId}`);
    onHidePopup();
    setIsLoading(false);
  };

  const onSubmit = async () => {
    const formValues = formMethods.getValues();
    const { group_name, group_avatar, group_members } = formValues;

    if (group_members && group_members?.length < 1) {
      return;
    }
    const currentUserOrg = {
      id: Number(currentUserFromFirebase?.uid),
      full_name_kanji: '',
    };
    const data = {
      name: group_name,
      image: group_avatar,
      memberIds: [
        ...(group_members?.map((item) => item.id) || []),
        Number(currentUserFromFirebase?.uid),
      ],
      members: [...(group_members as UserType[]), currentUserOrg],
    };

    const existingGroup = await checkExistingGroup(data.memberIds as number[]);
    existingGroup
      ? handleConfirmExistingGroup(data, existingGroup)
      : handleCreateNewGroup(data);
  };

  const onHidePopup = () => {
    setOpenPopup(false);
    setTimeout(() => {
      formMethods.reset();
      setSelectedUsers([]);
      resetContacts();
    }, 500);
  };

  const onSelectAll = () => {
    if (contactOrg?.length) {
      setSelectedUsers((prevSelectedUsers) => {
        const allUserIds = new Set(contactOrg.map((user) => user.id));
        const selectedUserIds = new Set(prevSelectedUsers.map((user) => user.id));
  
        const isAllSelected = contactOrg.every((user) =>
          selectedUserIds.has(user.id)
        );
  
        return isAllSelected
          ? prevSelectedUsers.filter((user) => !allUserIds.has(user.id))
          : [...prevSelectedUsers, ...contactOrg.filter((user) => !selectedUserIds.has(user.id))];
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    setValue('group_members', selectedUsers);
  }, [selectedUsers]);

  return (
    <DialogCustom
      header={t('chat.title_popup_create_group')}
      visible={openPopup}
      onHide={onHidePopup}
      className="min-w-[668px] max-w-[668px] dialog-create-group"
      dismissableMask={false}
      blockScroll
      footer={
        <div className="field">
          <div className="flex justify-center gap-[20px]">
            <ButtonCustom
              className="w-full"
              type="button"
              severity="secondary"
              onClick={onHidePopup}
            >
              {t('common.form.cancel')}
            </ButtonCustom>
            <ButtonCustom
              className="w-full flex gap-3"
              type="button"
              severity="secondary"
              onClick={onSelectAll}
            >
              {t(isSelectedAll ? 'chat.clear_all' : 'chat.select_all')}
            </ButtonCustom>
            <ButtonCustom
              type="button"
              className="w-full"
              onClick={formMethods.handleSubmit(onSubmit)}
              disabled={!selectedUsers.length}
              loading={isLoading}
            >
              {t('chat.create')}
            </ButtonCustom>
          </div>
        </div>
      }
    >
      <FormProvider {...formMethods}>
        <form>
          <div className="bg-gray-100 p-[16px] pb-0">
            <div className="flex items-center">
              <div className="flex justify-center">
                <div className="relative">
                  <UploadAvatar
                    idChooseBtn="choose-image"
                    name="group_avatar"
                    accept=".svg,.jpg,.jpeg,.png"
                    maxSize={5}
                    handleUploaded={(imgUrl: string) => {
                      setValue('group_avatar', imgUrl);
                    }}
                    defaultUrl={avatarGroup}
                    isAdminGroup={true}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                  />
                </div>
              </div>
              <div className="flex flex-1 flex-col ml-[16px]">
                <div className="flex gap-[16px] items-center">
                  <InputTextCustomForm
                    name="group_name"
                    label={t('chat.group_name')}
                    placeholder={t('chat.plh_group_name')}
                    className="border rounded-lg p-2"
                    wrapClass="flex-1"
                    maxLength={200}
                    requiredFlag
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>

            <ListContactCreateGroup
              handleUserSelect={handleUserSelect}
              selectedUsers={selectedUsers}
              contacts={contactOrg}
              isFetching={isFetching}
              fetchContacts={fetchContacts}
              resetContacts={resetContacts}
            />
          </div>
        </form>
      </FormProvider>
    </DialogCustom>
  );
};
