const user_list = {
  title: '受講生管理',
  title_coach: 'アートシフトコーチ管理',
  csv_upload: 'CSVアップロード',
  csv_template: 'CSVテンプレート',
  keyword_student: 'ユーザー名、メールアドレス入力',
  keyword_coach: 'アートシフトコーチ名、メールアドレス入力',
  new: '新規追加',
  graduation_terms: '卒業期',
  date: '----/--/--',
  full_name: '受講生名',
  full_name_coach: 'アートシフトコーチ名',
  updated_at: '作成日',
  status_label: 'ステータス: ',
  status: 'ステータス',
  active: '有効',
  avatar: 'アバター',
  email: 'メールアドレス',
  phone: '電話番号',
  password: 'パスワード',
  password_confirm: '確認パスワード',
  mentor: 'アートシフトコーチ',
  kanji_name: '氏名（漢字）',
  first_name_kanji: '田中',
  last_name_kanji: '太郎',
  first_name: 'タナカ',
  last_name: 'タロウ',
  birth_date: '生年月日',
  gender: '性別',
  time_zone: 'タイムゾーン ',
  post_code: '郵便番号',
  address: '住所',
  message_off: 'ユーザーをオフ状態に切り替えると、システムにログインできなくなります。よろしいでしょうか。',
  message_on: 'ユーザーをオンに状態に切り替えると、システムにログインできるようになります。よろしいでしょうか。'
};

export default user_list;
