import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

import { UserInfoType } from '@/types/auth';

interface IAuthContext {
  currentUser: UserInfoType;
  setCurrentUser: Dispatch<SetStateAction<UserInfoType>>;
}

const AuthContext = createContext<IAuthContext>({
  currentUser: {} as UserInfoType,
  setCurrentUser: () => {},
});

export function AuthProvider({ children }: { children: ReactNode }) {
  const [currentUser, setCurrentUser] = useState<UserInfoType>(
    {} as UserInfoType,
  );
  const value = { currentUser, setCurrentUser };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useSelectorUserInfo() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useSelectorUserInfo must be used within AuthProvider');
  }
  return context;
}
