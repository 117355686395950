import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import {
  TabPanel,
  TabPanelHeaderTemplateOptions,
  TabView,
  TabViewTabChangeEvent,
} from 'primereact/tabview';
import classNames from 'classnames';
import { DataTableRowReorderEvent } from 'primereact/datatable';
import { isEmpty } from 'lodash';

import { useTranslation } from '@/config/i18n';
import { apiRequest } from '@/services';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import ButtonCustom from '@/components/common/ui/button';
import UploadImage from '@/components/common/ui/upload/uploadImage';
import {
  getValueFromSelect,
  toastErrorMessage,
  toastMessage,
} from '@/lib/utils';
import PATH from '@/routes/path';
import { HTTP_STATUS, NO_PUBLIC_UNIT } from '@/shared/constants';
import { useLoading } from '@/components/common/loader/LoadingContext';
import { ToastContext } from '@/components/common/CommonToast';
import { LessonFormType, lessonFormSchema } from '../schema';
import { TextareaCustomForm } from '@/components/common/ui/textarea';
import { CustomDataTable } from '@/components/common/ui/table/CustomDataTable';
import AddVideoForm from './AddVideoForm';
import useScrollToError from '@/hooks/useScrollToError';
import AddStudentForm from './AddStudentForm';
import { CustomPaginator } from '@/components/common/ui/table/CustomPaginator';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@/shared/constants/pagination';
import { Pagination } from '@/types/pagination';
import InfinitySelect from '@/components/common/ui/select';
import DialogCustom from '@/components/common/ui/dialog';
import { LessonEditStudentColumns } from '../table/LessonEditStudentColumns';
import useFetchClient from '@/hooks/useFetchClient';
import { LessonEditVideoColumns } from '../table/LessonEditVideoColumns';
import { ADD_VIDEO_TEMPORARY } from '@/shared/constants/video';
import PreviewVideoDialog from './PreviewVideoDialog';
import { VideoDataType, VideoItemType } from '@/types/lesson';
import { handleFormatQuestion, updatePrioritiesWhenDragging, validatePriorities } from './utils';
import { ALL_VALUE } from '@/shared/constants/lesson';
import useSearch from '@/hooks/useSearch';

type Props = {
  id?: string | number;
  isAddOrEditUnit: boolean;
  setIsAddOrEditUnit: React.Dispatch<React.SetStateAction<boolean>>;
};

const MAX_VIDEO_DISPLAY = 50;

const LessonEditForm: React.FC<Props> = ({
  id,
  isAddOrEditUnit,
  setIsAddOrEditUnit,
}) => {
  const { toast } = useContext(ToastContext);
  const [t] = useTranslation('');
  const { apiService } = apiRequest();
  const [defaultImage, setDefaultImage] = useState<string>();
  const [visibleVideo, setVisibleVideo] = useState<boolean>();
  const [visibleStudent, setVisibleStudent] = useState<boolean>();
  const [videos, setVideos] = useState<any[]>([]);
  const [videoId, setVideoId] = useState<string | number>();
  const [video, setVideo] = useState<any>();
  const [students, setStudents] = useState<any[]>([]);
  const navigate = useNavigate();
  const { scrollToError } = useScrollToError();
  const { loadingGlobal, setLoadingGlobal } = useLoading();
  const [selectedPagination, setSelectedPagination] = useState<Pagination>({
    paginate: DEFAULT_PAGE,
    limit: DEFAULT_PER_PAGE,
  });
  const [changeStudent, setChangeStudent] = useState(
    new Date().getUTCMilliseconds(),
  );
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<VideoDataType>(
    {} as VideoDataType,
  );
  const [selectedStudent, setSelectedStudent] = useState<any>();
  const [isEditQualityVideos, setIsEditQualityVideos] = useState(false);
  const [changeVideos, setChangeVideos] = useState(
    new Date().getUTCMilliseconds(),
  );
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isEditPriorityUnits, setIsEditPriorityUnits] = useState(false);
  const [videoErrors, setVideoErrors] = useState<Record<number, string>>({});

  const { searchParams } = useSearch();
  const query = Object.fromEntries(searchParams.entries());

  const { canEdit, mentorId } = query || {};
  const isDisabledEdit = useMemo(() => typeof canEdit === 'undefined' ? false : !Number(query?.canEdit),[query?.canEdit]);

  const queryFetchStudents = useMemo(() => {
    const baseQuery = `${id}/students?limit=${selectedPagination.limit}&paginate=${selectedPagination.paginate}`;
    return mentorId ? `${baseQuery}&mentor_id=${mentorId}` : baseQuery;
  }, [id, selectedPagination.limit, selectedPagination.paginate, mentorId]);

  const fetchStudents = async () => {
    return await apiService.lessons.collection(queryFetchStudents);
  };

  const { data: studentList } = useFetchClient(
    fetchStudents,
    [selectedPagination.paginate, selectedPagination.limit, changeStudent, mentorId],
    {
      isLoadingGlobal: true,
      isScrollToTop: false,
    }
  );

  const { data: videoList } = useFetchClient(
    async () =>
      await apiService.lessons.collection(
        `${id}/units?limit=${MAX_VIDEO_DISPLAY}&paginate=${1}`,
      ),
    [changeVideos],
    {
      isLoadingGlobal: true,
      isScrollToTop: false,
    },
  );

  const videosPublic = useMemo(() => {
    return videos.filter((video) => video.status === 'public') ?? [];
  }, [videos]);

  useEffect(() => {
    if (!studentList?.data) return;

    setStudents(studentList.data);
    setSelectedPagination({
      ...selectedPagination,
      total_page: studentList.pagination?.total_item ?? 1,
    });
  }, [studentList]);

  useEffect(() => {
    if (!videoList?.data) return;

    setVideos(videoList.data);
  }, [videoList]);

  const formMethods = useForm<LessonFormType>({
    mode: 'onChange',
    resolver: yupResolver(lessonFormSchema),
    shouldFocusError: false,
    defaultValues: {
      status: {
        value: 'active',
        label: t('common.status_other.active'),
      } as unknown as string,
    },
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = formMethods;

  const getAllFieldNames = () => {
    const values = getValues();
    return Object.keys(values);
  };

  const handleChangePagination = (paginationState: Pagination) => {
    setIsEditQualityVideos(false);
    setSelectedPagination((prevPagination) => ({
      ...prevPagination,
      ...paginationState,
    }));
  };

  async function getDetail(id: string | number) {
    setLoadingGlobal(true);
    const { data: lesson } = await apiService.lessons.show(id);
    if (lesson?.data) {
      setDefaultValue(lesson.data);
      setDefaultImage(lesson.data.thumbnail_url);
    }
    setLoadingGlobal(false);
  }

  function setDefaultValue(data: any) {
    const specialField = ['status'];
    getAllFieldNames().forEach((field: any) => {
      const val = data[field];

      if (val && !specialField.includes(field)) {
        setValue(field, val);
      }

      if (val && field === 'status') {
        setValue(field, { value: val, label: t(`common.status_other.${val}`) });
      }
    });
  }

  useEffect(() => {
    if (!id) return;

    getDetail(id);
  }, [id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      scrollToError();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [errors]);

  async function onSubmit() {
    setLoadingGlobal(true);
    const payload = getPayload();
    let data = null;
    let error = null;
    if (id) {
      const { data: user, error: userError } = await apiService.lessons.update(
        id,
        payload,
      );
      data = user;
      error = userError;
    }
    if (error) {
      handleError(error);
      setLoadingGlobal(false);
      return;
    }

    if (data) {
      if (isEditQualityVideos) {
        toast?.current?.show(
          toastMessage(t('lesson.noti.update_quality_video_but_not_save')),
        );
      } else if (payload.status === 'active') {
        toast?.current?.show(toastMessage(t('lesson.active_success')));
      } else if (payload.status === 'inactive') {
        toast?.current?.show(toastMessage(t('lesson.inactive_success')));
      }
      setLoadingGlobal(false);
      navigate(PATH.lesson);
    }
  }

  function getPayload() {
    const valuesSelect = getValueFromSelect(getValues(), ['status']);
    return { ...getValues(), ...valuesSelect };
  }

  function handleError(error: any) {
    const { status_code, errors } = error;

    const errorPublicUnit = errors.find((item: any) => item.error_code === NO_PUBLIC_UNIT);

    if (!isEmpty(errorPublicUnit)) {
      toast?.current?.show(toastErrorMessage(errorPublicUnit.message));
      return;
    }

    if (status_code === HTTP_STATUS.FORM_ERROR) {
      errors.forEach((e: any) => {
        const { field, message } = e;
        const messageSplit = message.split(' ');
        if (messageSplit && messageSplit.length === 3) {
          const fieldEng = messageSplit[1];
          const messageJapan = messageSplit[2];
          const messageTranslate = `${t(`user.${fieldEng}`)}${messageJapan}`;
          setError(field, { message: messageTranslate });
          return;
        }
        setError(field, { message });
      });
      return;
    }

    toast?.current?.show(toastErrorMessage(t('lesson.update_fail')));
  }

  function onFail() {
    // TODO
  }

  const onHideVideo = (unitId?: string, questions?: any) => {
    setVisibleVideo(false);
    setIsAddOrEditUnit(false);

    if (unitId) {
      setVideos(prevVideos => {
        return prevVideos.map(item =>
          item.id === unitId ? { ...item, total_questions: questions?.length || 0 } : item
        );
      });
    }
  }

  async function onSubmitVideo(videoData: any) {
    setLoadingGlobal(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { questions, questionsParam, ...rest } = videoData;
    const newVideo = { ...rest, questions: questionsParam };
    const hasNewVideo = String(newVideo.id).includes(ADD_VIDEO_TEMPORARY);
    if (hasNewVideo) {
      // case new video
      const { error } = await apiService.lessons.collection(`${id}/units`, {
        method: 'POST',
        body: newVideo,
      });
      onHideVideo();

      if (error) {
        const { errors } = error || {};
        const errorPublicUnit = errors.find((item: any) => item.error_code === NO_PUBLIC_UNIT);
        toast?.current?.show(
          toastErrorMessage(!isEmpty(errorPublicUnit) ? errorPublicUnit.message : t('lesson.noti.video_new_fail')),
        );
        setLoadingGlobal(false);
        return;
      }

      if (newVideo.status === 'public') {
        toast?.current?.show(
          toastMessage(t('lesson.noti.video_public_success')),
        );
      } else if (newVideo.status === 'draft') {
        toast?.current?.show(
          toastMessage(t('lesson.noti.video_draft_success')),
        );
      }
      setChangeVideos(new Date().getUTCMilliseconds());
    } else {
      // case update video
      const indexUpdateVideo = videos.findIndex(
        (video) => video?.id == newVideo.id,
      );
      if (indexUpdateVideo === -1) {
        toast?.current?.show(
          toastErrorMessage(t('lesson.noti.video_edit_fail')),
        );
        onHideVideo();
        setLoadingGlobal(false);
        return;
      }
      const oldVideo = videos[indexUpdateVideo];
      const attachments: any = [];
      newVideo.attachments.forEach((newAttachment: any, index: number) => {
        const oldAttachment = oldVideo?.attachments[index];
        attachments.push({
          id: oldAttachment?.id,
          name: newAttachment.name || oldAttachment?.name,
          old_attach_file_id:
            oldAttachment?.id != newAttachment.attach_file_id
              ? oldAttachment?.id
              : undefined,
          new_attach_file_id:
            oldAttachment?.id != newAttachment.attach_file_id
              ? newAttachment.attach_file_id
              : undefined,
        });
      });

      const { error } = await apiService.units.collection(`${newVideo.id}`, {
        method: 'PUT',
        body: { ...newVideo, attachments },
      });
      onHideVideo();

      if (error) {
        const { errors } = error || {};
        const errorPublicUnit = errors.find((item: any) => item.error_code === NO_PUBLIC_UNIT);
        toast?.current?.show(
          toastErrorMessage(!isEmpty(errorPublicUnit) ? errorPublicUnit.message : t('lesson.noti.video_new_fail')),
        );
        setLoadingGlobal(false);
        return;
      }

      if (newVideo.status === 'public') {
        toast?.current?.show(
          toastMessage(t('lesson.noti.video_public_success')),
        );
      } else if (newVideo.status === 'draft') {
        toast?.current?.show(
          toastMessage(t('lesson.noti.video_draft_success')),
        );
      }
      setChangeVideos(new Date().getUTCMilliseconds());
    }
  }

  const onEditVideo = async (rowData: any) => {
    setLoadingGlobal(true);
    setVideoId(rowData.id);
    const { data } = await apiService.lessonQuestion.index({
      unit_id: rowData.id,
    });
    setVideo({
      ...rowData,
      questions: handleFormatQuestion(data?.data as any, t),
    });
    setVisibleVideo(true);
    setIsAddOrEditUnit(true);
    setLoadingGlobal(false);
  };

  function onNewVideo() {
    setVideoId('');
    setVideo(undefined);
    setVisibleVideo(true);
    setIsAddOrEditUnit(true);
  }

  async function onSubmitStudent(newStudents: any[]) {
    const optionAll = newStudents.find((student) => student?.value === ALL_VALUE);

    onHideStudent();
    const convertStudents = newStudents.map((student) => {
      return {
        user_id: student.id,
        quality_unit:
          student.total_video === 'All' ? undefined : student.total_video,
      };
    });

    const isAllStudents = !isEmpty(optionAll);
    const qualityUnits = isAllStudents ? (optionAll.total_video === 'All' ? '' : optionAll.total_video) : undefined;
    const students = isAllStudents ? undefined : convertStudents;
  
    const body = {
      is_all_students: isAllStudents,
      quality_units: qualityUnits,
      students: students,
      mentor_id: isAllStudents ? mentorId : null,
    };

    setLoadingGlobal(true);
    const { error } = await apiService.lessons.collection(`${id}/students`, {
      method: 'POST',
      body,
    });

    if (error) {
      setLoadingGlobal(false);
      toast?.current?.show(
        toastErrorMessage(t('lesson.noti.student_new_fail')),
      );
      return;
    }

    setLoadingGlobal(false);
    toast?.current?.show(toastMessage(t('lesson.noti.student_new_success')));
    setChangeStudent(new Date().getUTCMilliseconds());
  }

  function onHideStudent() {
    setVisibleStudent(false);
  }

  const tabStudentTemplate = (options: TabPanelHeaderTemplateOptions) => {
    return (
      <div
        className="flex align-items-center gap-2 p-3 cursor-pointer"
        onClick={options.onClick}
      >
        <span className="tab-label font-medium white-space-nowrap">
          {t('lesson.student')}
        </span>
        <Badge value={selectedPagination.total_page || 0} />
      </div>
    );
  };

  const tabVideoTemplate = (options: TabPanelHeaderTemplateOptions) => {
    return (
      <div
        className="flex align-items-center gap-2 p-3 cursor-pointer"
        onClick={options.onClick}
      >
        <span className="tab-label font-medium white-space-nowrap">
          {t('lesson.unit')}
        </span>
        <Badge value={videos.length} />
      </div>
    );
  };

  async function handleDeleteStudent(rowData: any) {
    setSelectedStudent({ rowData });
    const nextDialogVisibleValue = !dialogVisible;
    setDialogVisible(nextDialogVisibleValue);
    if (nextDialogVisibleValue) return;
    setLoadingGlobal(true);
    const { error: userError } = await apiService.lessonStudents.delete(
      rowData.lesson_student_id,
    );
    setLoadingGlobal(false);
    setIsEditQualityVideos(false);
    if (userError) {
      toast?.current?.show(
        toastErrorMessage(t('lesson.noti.student_delete_fail')),
      );
      return;
    }

    toast?.current?.show(toastMessage(t('lesson.noti.student_delete_success')));
    setChangeStudent(new Date().getUTCMilliseconds());
  }

  function onEditQualityVideos() {
    if (students.length) {
      setIsEditQualityVideos(true);
    }
  }

  async function onSaveQualityVideos() {
    setLoadingGlobal(true);
    const lessonStudents = students.map((student: any) => {
      return {
        id: student.lesson_student_id,
        quality_unit:
          student.quality_unit === 'All' ||
          (!student.quality_unit && student.quality_unit !== 0)
            ? undefined
            : student.quality_unit,
      };
    });

    const { error } = await apiService.lessons.collection(
      `${id}/unit-students`,
      {
        method: 'PUT',
        body: {
          lesson_students: lessonStudents,
        },
      },
    );
    if (error) {
      setLoadingGlobal(false);
      setChangeStudent(new Date().getUTCMilliseconds());
      setIsEditQualityVideos(false);
      toast?.current?.show(
        toastErrorMessage(t('lesson.noti.update_quality_video_fail')),
      );
      return;
    }

    setLoadingGlobal(false);
    toast?.current?.show(
      toastMessage(t('lesson.noti.update_quality_video_success')),
    );
    setIsEditQualityVideos(false);
    setChangeStudent(new Date().getUTCMilliseconds());
  }

  const onPreviewVideo = async (rowData: any) => {
    setLoadingGlobal(true);
    const { unit_id, type } = rowData || {};
    const { error, data } = await apiService.units.collection(
      `${unit_id}/files`,
      {
        method: 'GET',
      },
    );
    setLoadingGlobal(false);
    if (error) {
      setSelectedVideo({} as VideoDataType);
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
      return;
    }
    const { data: { videos = [] } = {} } = data || {};
    const selectVideo = videos.find((item: any) => item.type === type);
    setSelectedVideo({ ...rowData, video_path: selectVideo.url });
  };

  const onPreviewPDF = async (
    rowData: VideoItemType,
    index: string | number,
  ) => {
    setLoadingGlobal(true);
    const { id: idUnit } = rowData;
    const { error, data } = await apiService.units.collection(
      `${idUnit}/files`,
      {
        method: 'GET',
      },
    );
    setLoadingGlobal(false);
    const { data: { attachments = [] } = {} } = data || {};
    const attachment = attachments[index];
    if (error || !attachment) {
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
      return;
    }
    window.open(attachment.url);
  };

  const onRowReorder = async (e: DataTableRowReorderEvent<any>) => {
    setLoadingGlobal(true);

    const newVideosData = updatePrioritiesWhenDragging(videos, e);
    setVideos(newVideosData);

    await apiService.lessons.collection(
      `${id}/unit-priorities`,
      {
        method: 'POST',
        body: {
          units: videos.map((video) => ({
            unit_id: video.id,
            priority: video.priority,
          })),
        },
      },
    );

    setLoadingGlobal(false);
  };

  const onBeforeTabChange = (event: TabViewTabChangeEvent) => {
    const { index } = event;
    setActiveIndex(index);
  };

  const onEditPriorityUnits = () => {
    if (videos.length) {
      setIsEditPriorityUnits(true);
    }
  };

  const onSavePriorityUnits = async () => {
    if (isEmpty(videoErrors)) {
      setLoadingGlobal(true);
      const { error } = await apiService.lessons.collection(
        `${id}/unit-priorities`,
        {
          method: 'POST',
          body: {
            units: videos.map((video) => ({
              unit_id: video.id,
              priority: video.priority,
            })),
          },
        },
      );
      if (error) {
        setLoadingGlobal(false);
        setChangeStudent(new Date().getUTCMilliseconds());
        setIsEditPriorityUnits(false);
        toast?.current?.show(
          toastErrorMessage(t('lesson.noti.update_priority_unit_fail')),
        );
        return;
      }

      setLoadingGlobal(false);
      toast?.current?.show(
        toastMessage(t('lesson.noti.update_priority_unit_success')),
      );
      setIsEditPriorityUnits(false);
      setVideos(videos.sort((a, b) => a.priority - b.priority));
    }
  };

  const handlePriorityChange = (id: number, newPriority: number) => {
    const updatedVideos = videos.map((video) =>
      video.id === id ? { ...video, priority: newPriority } : video,
    );
    setVideos(updatedVideos);
    validatePriorities(updatedVideos, id, t, toast, setVideoErrors);
  };

  return (
    <>
      <FormProvider {...formMethods}>
        {isAddOrEditUnit ? (
          <AddVideoForm
            visible={visibleVideo}
            onHide={onHideVideo}
            onSubmitVideo={onSubmitVideo}
            id={videoId}
            video={video}
            lessonId={id}
            isDisabledEdit={isDisabledEdit}
          />
        ) : (
          <form
            className="mt-[16px]"
            onSubmit={handleSubmit(onSubmit, onFail)}
            autoComplete="off"
          >
            <div className="form-item flex mt-[20px]">
              <div className="label w-56 flex">
                <span>{t('lesson.name')}</span>
                <span className="text-error-500">*</span>
              </div>
              <div className="w-[512px]">
                <InputTextCustomForm
                  wrapClass="w-full"
                  name="name"
                  placeholder={t('common.form.placeholder.default')}
                  disabled={isDisabledEdit}
                />
              </div>
            </div>
            <div className="form-item flex mt-[20px]">
              <div className="label w-56 flex">
                <span>{t('lesson.code')}</span>
              </div>
              <div className="w-[512px]">
                <InputTextCustomForm
                  wrapClass="w-full"
                  name="code"
                  placeholder={t('common.form.placeholder.default')}
                  disabled={isDisabledEdit}
                />
              </div>
            </div>
            <div className="form-item flex mt-[20px]">
              <div className="label w-56 flex">
                <span>{t('user.status')}</span>
                <span className="text-error-500">*</span>
              </div>
              <div className="w-[512px]">
                <InfinitySelect
                  name="status"
                  placeholder={t('common.form.placeholder.select')}
                  wrapClass="w-[512px]"
                  isClearable={false}
                  defaultOption={[
                    { value: 'active', label: t('common.status_other.active') },
                    {
                      value: 'inactive',
                      label: t('common.status_other.inactive'),
                    },
                  ]}
                  isDisabled={isDisabledEdit}
                  styles={{
                    control: (baseStyles: any) => ({
                      ...baseStyles,
                      borderColor: '#000',
                    }),
                  }}
                />
              </div>
            </div>
            <div className="form-item flex mt-[20px]">
              <div className="label w-56 flex">
                <span>{t('lesson.description')}</span>
              </div>
              <div className="w-[512px]">
                <TextareaCustomForm
                  name="description"
                  wrapClass="w-full"
                  maxLength={300}
                  disabled={isDisabledEdit}
                  placeholder={t('lesson.description_placeholder')}
                />
              </div>
            </div>

            <div className="form-item flex mt-[20px]">
              <div className="label w-56 flex">
                <span>{t('lesson.image')}</span>
              </div>
              <div className="w-[512px] flex">
                <UploadImage
                  idChooseBtn="choose-lesson-main"
                  className={classNames(
                    'relative w-[366px] h-[126px] flex-full-center border border-gray-200',
                    { 'opacity-70': isDisabledEdit },
                  )}
                  name="profile_image_id"
                  accept=".svg,.jpg,.jpeg,.png"
                  iconAvatar="pi-image"
                  defaultUrl={defaultImage}
                  maxSize={5}
                  handleUploaded={(id, imgUrl) => {
                    setValue('thumbnail_id', id);
                    setDefaultImage(imgUrl);
                  }}
                  textUpload="ファイルを選択"
                  textSubUpload="またはここにファイルをドロップ"
                  disabled={isDisabledEdit}
                />
              </div>
            </div>

            <div className="lesson-tab mt-[30px]">
              <TabView
                activeIndex={activeIndex}
                onBeforeTabChange={onBeforeTabChange}
              >
                <TabPanel header="Student" headerTemplate={tabStudentTemplate}>
                  <CustomDataTable
                    wrapClassName="mt-[20px]"
                    values={students}
                    colums={LessonEditStudentColumns(
                      t,
                      handleDeleteStudent,
                      onEditQualityVideos,
                      onSaveQualityVideos,
                      isEditQualityVideos,
                      videosPublic,
                      isDisabledEdit,
                    )}
                    extraFooter={
                      (!isDisabledEdit && <div className="px-[24px] py-[16px]">
                        <button
                          type="button"
                          className="flex gap-3 items-center"
                          onClick={() => {
                            setVisibleStudent(true);
                          }}
                        >
                          <div className="bg-primary-100 p-[10px] h-[40px] w-[40px] flex-full-center text-primary-700 rounded-full">
                            <i className="pi pi-plus !font-bold"></i>
                          </div>
                          <div className="text-primary-700">追加</div>
                        </button>
                      </div>)
                    }
                  />
                  {students.length > 0 && <CustomPaginator
                    pagination={selectedPagination}
                    onPaginationChanged={handleChangePagination}
                  />}
                  {visibleStudent && (
                    <AddStudentForm
                      videosPublic={videosPublic}
                      members={students}
                      visible={visibleStudent}
                      onHide={onHideStudent}
                      onSubmitStudent={onSubmitStudent}
                      mentorId={mentorId}
                    />
                  )}
                </TabPanel>

                <TabPanel header="Video" headerTemplate={tabVideoTemplate}>
                  <CustomDataTable
                    wrapClassName={classNames('mt-[20px]', {
                      'hide-border-bottom': isDisabledEdit,
                    })}
                    values={videos}
                    colums={LessonEditVideoColumns(
                      t,
                      onEditVideo,
                      onPreviewVideo,
                      onPreviewPDF,
                      isDisabledEdit,
                      onEditPriorityUnits,
                      onSavePriorityUnits,
                      isEditPriorityUnits,
                      videoErrors,
                      handlePriorityChange,
                    )}
                    reorderableRows={!isDisabledEdit}
                    onRowReorder={onRowReorder}
                    extraFooter={
                      (!isDisabledEdit && videos.length < MAX_VIDEO_DISPLAY) ? (
                        <div className="px-[24px] py-[16px]">
                          <div>
                            <button
                              type="button"
                              className="flex gap-3 items-center"
                              onClick={() => {
                                onNewVideo();
                              }}
                            >
                              <div className="bg-primary-100 p-[10px] h-[40px] w-[40px] flex-full-center text-primary-700 rounded-full">
                                <i className="pi pi-plus !font-bold"></i>
                              </div>
                              <div className="text-primary-700">ユニット追加</div>
                            </button>
                          </div>
                        </div>
                      ) : undefined
                    }
                  />
                </TabPanel>
              </TabView>
            </div>

            <div className="action flex justify-end mt-[25px] border-t border-gray-200 py-[18px]">
              <ButtonCustom
                className="w-[140px]"
                type="button"
                severity="secondary"
                onClick={() => navigate(PATH.lesson)}
              >
                {t('common.form.cancel')}
              </ButtonCustom>
              {!isDisabledEdit && (
                <ButtonCustom
                  className="ml-2 w-[140px]"
                  type="submit"
                  loading={loadingGlobal}
                >
                  {t('common.form.save')}
                </ButtonCustom>
              )}
            </div>
          </form>
        )}
      </FormProvider>

      <DialogCustom
        header={t('lesson.confirm.delete_header')}
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        footer={
          <div className="field">
            <div className="flex justify-end">
              <ButtonCustom
                type="button"
                className="cancle-button p-button-sm mr-2"
                severity="secondary"
                onClick={() => setDialogVisible(false)}
              >
                {t('common.dialog.cancel')}
              </ButtonCustom>
              <ButtonCustom
                type="button"
                onClick={() => handleDeleteStudent(selectedStudent.rowData)}
              >
                {t('common.dialog.ok')}
              </ButtonCustom>
            </div>
          </div>
        }
      >
        {t('lesson.confirm.delete_body')}
      </DialogCustom>

      <PreviewVideoDialog
        header="動画閲覧"
        light={selectedVideo?.thumbnail_path}
        url={selectedVideo?.video_path}
        visible={!!(selectedVideo && selectedVideo?.thumbnail_path)}
        onHide={() => setSelectedVideo({} as VideoDataType)}
      />
    </>
  );
};

export default LessonEditForm;
