import cx from 'classnames';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Ripple } from 'primereact/ripple';
import { Pagination } from '@/types/pagination';
import { DEFAULT_PER_PAGE } from '@/shared/constants/pagination';
import useSearch from '@/hooks/useSearch';

type Props = {
  pagination?: Pagination;
  onPaginationChanged?: (paginationState: Pagination) => void;
  isNoneQuery?: boolean;
};

export const CustomPaginator: React.FC<Props> = ({
  pagination,
  onPaginationChanged,
  isNoneQuery,
}) => {

  const { query } = useSearch();
  const onPage = (event: any) => {
    onPaginationChanged?.({
      limit: pagination?.limit ?? DEFAULT_PER_PAGE,
      paginate: event.page + 1,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    });
  };

  const customPaginatorTemplate = {
    layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
    PageLinks: (options: any) => {
      const currentPaginate = isNoneQuery ? pagination?.paginate : (query.paginate || pagination?.paginate || 1);
      const isCurrent = currentPaginate === options.page + 1;
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        return (
          <Button className="w-9 h-9 mx-2">
            ...
            <Ripple />
          </Button>
        );
      }
  
      return (
        <button
          type="button"
          className={cx('rounded-full w-9 h-9 mx-2 pb-[2px] text-[16px]', {
            'text-primary-700': isCurrent,
            'hover:bg-gray-20 text-gray-700': !isCurrent,
          })}
          onClick={options.onClick}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
    CurrentPageReport: (options: any) => {
      return (
        <span className='current-page-report text-nowrap text-[16px] text-gray-700' style={{ userSelect: 'none', width: 'fit-content', textAlign: 'center' }}>
          {options.first} - {options.last} / {options.totalRecords} 件
        </span>
      );
    }
  };

  return (
    <Paginator
      first={((pagination?.paginate ?? 0) - 1) * (pagination?.limit ?? 0)}
      rows={pagination?.limit ?? DEFAULT_PER_PAGE}
      totalRecords={pagination?.total_page ?? 0}
      onPageChange={onPage}
      template={customPaginatorTemplate}
    />
  );
};


