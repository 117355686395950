export const ROLE = {
  SUPPER_ADMIN: 'SUPPER_ADMIN',
  ADMIN: 'ADMIN',
  STUDENT: 'STUDENT',
  TEACHER: 'TEACHER',
}

export const STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}