import * as yup from 'yup';
import { BaseYup, emailRegExp } from '@/config/baseYup';
import i18n from '@/config/i18n';

export const ForgotPasswordSchema = BaseYup.object({
  email: BaseYup.string().label(i18n.t('login.email')).required().matches(emailRegExp, {
    message: i18n.t('common.form.email_invalid'),
    excludeEmptyString: true,
  }),
});

export type ForgotPasswordFormType = yup.InferType<typeof ForgotPasswordSchema>;