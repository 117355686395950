import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { apiRequest } from '@/services';
import { CustomDataTable } from '@/components/common/ui/table/CustomDataTable';
import { Pagination } from '@/types/pagination';
import { useTranslation } from '@/config/i18n';
import useSearch from '@/hooks/useSearch';
import usePagination from '@/hooks/usePagination';
import { useLoading } from '@/components/common/loader/LoadingContext';
import { ProgressDataType, ProgressResponseType } from '@/types/progress';
import { ToastContext } from '@/components/common/CommonToast';
import { toastErrorMessage } from '@/lib/utils';
import { ProgressStudentColumns } from '../table/ProgressStudentColumns';
import { TEACHER } from '@/shared/constants/progress';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@/shared/constants/pagination';

interface IProgressTableProps {
  setIsOpenCommentModal: (value: boolean) => void;
  setIsOpenWorkModal: (value: boolean) => void;
  setRowData: (value: ProgressDataType) => void;
  setUnitId: (value: number) => void;
}

const CoachProgress: React.FC<IProgressTableProps> = ({
  setRowData,
  setIsOpenCommentModal,
  setIsOpenWorkModal,
  setUnitId,
}) => {
  const { id } = useParams<{ id: string | undefined }>();
  const [t] = useTranslation('');
  const { apiService } = apiRequest();
  const { toast } = useContext(ToastContext);
  const [coachProgress, setCoachProgress] = useState<ProgressResponseType>({
    data: [],
    pagination: null,
  });
  const { searchParams } = useSearch();
  const { query } = Object.fromEntries(searchParams.entries());
  const { paginationComputed, setPagination } = usePagination(
    coachProgress.pagination,
  );
  const { paginate, limit } = paginationComputed;
  const { setLoadingGlobal } = useLoading();
  const customQuery = query
    ? query
    : `limit=${DEFAULT_PER_PAGE}&paginate=${DEFAULT_PAGE}`;

  const getCoachProgress = async ({
    id,
    type,
  }: {
    id: number | string;
    type: typeof TEACHER;
  }) => {
    setLoadingGlobal(true);
    const { data: coachProgressNew, error } =
      await apiService.lessons.collection(
        `${id}/progress?type=${type}&${customQuery}`,
      );
    if (coachProgressNew) {
      setLoadingGlobal(false);
      setCoachProgress(coachProgressNew);
    }
    if (error) {
      setLoadingGlobal(false);
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
    }
  };

  function onPaginationChanged(paginationState: Pagination) {
    setPagination(paginationState);
  }

  useEffect(() => {
    if (!id) return;
    getCoachProgress({ id: id, type: TEACHER });
  }, [query]);

  return (
    <CustomDataTable
      wrapClassName="mt-[20px]"
      values={coachProgress?.data}
      colums={ProgressStudentColumns(
        t,
        coachProgress?.data,
        setIsOpenCommentModal,
        setIsOpenWorkModal,
        setRowData,
        setUnitId,
        paginate,
        limit,
        coachProgress.pagination?.total_item || 0,
      )}
      scrollable
      pagination={paginationComputed}
      onPaginationChanged={onPaginationChanged}
    />
  );
};

export default CoachProgress;
