import { Button } from 'primereact/button';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Tooltip } from 'primereact/tooltip';

import { ColumnDescription } from '@/components/common/ui/table/ColumnDescription';
import { byteToMb } from '@/lib/utils';
import { VideoItemTempType, VideoDataType } from '@/types/lesson';
import { VIDEO_TYPE } from '@/shared/constants/video';
import { createPriorityFormatter, createVideoFormatter } from './utils';

export const LessonVideoColumns = (
  t: any,
  onEditVideo: (rowData: VideoItemTempType) => void,
  onPreviewVideo: (rowData: VideoDataType) => void,
  onEditPriorityUnits: () => void,
  onSavePriorityUnits: () => void,
  isEditPriorityUnits: boolean,
  videoErrors: any,
  handlePriorityChange: (id: number, newPriority: number) => void,
): ColumnDescription[] => {
  return [
    {
      dataField: 'priority',
      classname: 'min-w-[80px] text-center',
      headerClassname: 'text-center',
      header: (
        <div className="header-content whitespace-pre">
          {t('lesson_video.table.priority')}{' '}
          {!isEditPriorityUnits ? (
            <span
              onClick={() => onEditPriorityUnits()}
              className="cursor-pointer pi pi-pencil text-primary-700"
            />
          ) : (
            <span
              onClick={() => onSavePriorityUnits()}
              className="cursor-pointer pi pi-save text-primary-700"
            />
          )}
        </div>
      ),
      formatter: createPriorityFormatter(isEditPriorityUnits, handlePriorityChange, videoErrors),
    },
    {
      dataField: 'videos',
      text: t('lesson_video.table.image'),
      classname: 'min-w-[111px]',
      formatter: (rowData) => {
        const videoLearning = rowData.videos.find(
          (video: any) => video.type === VIDEO_TYPE.LEARNING,
        );
        return (
          <div className="w-[42px] h-[42px]">
            {videoLearning.thumbnail_path && (
              <img
                className="w-full h-full object-cover rounded-[8px]"
                src={videoLearning.thumbnail_path}
              ></img>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'name',
      text: t('lesson_video.table.unit_name'),
      classname: 'min-w-[150px]',
      formatter: (rowData) => (
        <>
          <Tooltip target={`.tooltip-unit-name-${rowData.id}`}>
            {rowData.name}
          </Tooltip>
          <div
            className={classNames(
              'line-clamp-2 break-word',
              `tooltip-unit-name-${rowData.id}`,
            )}
          >
            {rowData.name}
          </div>
        </>
      ),
    },
    {
      dataField: 'video_learning',
      text: t('lesson_video.table.video_learning'),
      classname: 'min-w-[150px] text-gray-600',
      formatter: createVideoFormatter(VIDEO_TYPE.LEARNING, onPreviewVideo, byteToMb)
    },
    {
      dataField: 'video_instruction',
      text: t('lesson_video.table.video_instruction'),
      classname: 'min-w-[150px] text-gray-600',
      formatter: createVideoFormatter(VIDEO_TYPE.INSTRUCTION, onPreviewVideo, byteToMb)
    },
    {
      dataField: 'attachments',
      text: t('lesson_video.table.pdf'),
      classname: 'min-w-[150px] text-gray-600',
      formatter: (rowData) =>
        rowData.attachments.length > 0 ? (
          rowData.attachments.map((item: any) => (
            <div>
              <Tooltip target={`.tooltip-pdf-name-${item.attach_file_id}`}>
                {item.name}
              </Tooltip>
              <div
                className={classNames(
                  'text-primary-500 underline cursor-pointer line-clamp-1 break-all',
                  `tooltip-pdf-name-${item.attach_file_id}`,
                )}
                onClick={() => window.open(item.file_url)}
                key={item.attach_file_id}
              >
                {item.name}
              </div>
            </div>
          ))
        ) : (
          <></>
        ),
    },
    {
      dataField: 'questions',
      text: t('lesson_video.table.questions'),
      classname: 'min-w-[140px] text-center',
      headerClassname: '[&>div]:justify-center',
      formatter: (rowData) => <div>{rowData?.questions?.length || 0}</div>,
    },
    {
      dataField: 'create_by',
      text: t('lesson_video.table.create_by'),
      classname: 'min-w-[150px] text-gray-600',
    },
    {
      dataField: 'create_date',
      text: t('lesson_video.table.create_date'),
      classname: 'min-w-[110px] text-gray-600',
      formatter: (rowData) => (
        <div>
          {rowData.create_date
            ? dayjs(rowData.create_date.toString()).format('YYYY/MM/DD')
            : ''}
        </div>
      ),
    },
    {
      dataField: 'status',
      text: t('lesson_video.table.status'),
      classname: 'min-w-[112px]',
      formatter: (rowData) => (
        <div
          className={classNames('px-[12px] py-[4px] rounded-full w-fit', {
            'bg-primary-50 text-primary-700': rowData.status === 'public',
            'bg-gray-100 text-gray-700': rowData.status !== 'public',
          })}
        >
          {t(
            rowData.status === 'public'
              ? 'lesson_video.table.public'
              : 'lesson_video.table.draft',
          )}
        </div>
      ),
    },
    {
      dataField: 'action',
      text: '',
      sort: false,
      classname: 'text-center [&>div]:justify-center w-[160px]',
      headerClassname: '[&>div]:justify-center',
      formatter: (rowData) => (
        <div className="flex flex-row h-[36px] items-center">
          <Button
            type="button"
            className="mx-3 p-[10px]"
            onClick={() => onEditVideo?.(rowData)}
          >
            <span className="pi pi-pencil" />
          </Button>
        </div>
      ),
    },
  ];
};
