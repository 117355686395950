import cx from 'classnames';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FileUpload, FileUploadProps } from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';
import { apiRequest } from '@/services';
import { Avatar } from 'primereact/avatar';
import { Skeleton } from 'primereact/skeleton';
import { toastErrorMessage, toastMessage } from '@/lib/utils';
import { useTranslation } from '@/config/i18n';
import { ToastContext } from '../../CommonToast';

interface Props extends FileUploadProps {
  defaultUrl?: string;
  idChooseBtn: string;
  iconAvatar?: string;
  maxSize?: number;
  textUpload?: string;
  textSubUpload?: string;
  handleUploaded?: (id: number, imgUrl: string) => void;
}

export default function UploadImage({
  defaultUrl,
  handleUploaded,
  idChooseBtn,
  iconAvatar,
  maxSize,
  textUpload,
  textSubUpload,
  ...rest
}: Props) {
  const [t] = useTranslation('');
  const { toast } = useContext(ToastContext);
  const { apiService } = apiRequest();
  const [isUploading, setIsUploading] = useState(false);
  const [currentImage, setCurrentImage] = useState(defaultUrl);
  const fileUploadRef = useRef(null);

  const onFileSelect = async (e: any) => {
    const file = e.files?.[0];
    const validFormats = ['image/svg+xml', 'image/jpeg', 'image/png'];

    const MAX_SIZE_MB = maxSize || 100;
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

    if (file.size > MAX_SIZE_BYTES) {
      toast?.current?.show(
        toastErrorMessage(
          t('common.file.image_max_size', { size: MAX_SIZE_MB }),
        ),
      );
      return;
    }

    if (!validFormats.includes(file.type)) {
      toast?.current?.show(toastErrorMessage(t('common.file.format_image')));
      return;
    }
    if (!file) {
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
      return;
    }
    setIsUploading(true);
    setCurrentImage('');
    const image = await preLoadImage(file);
    if (!image?.data) {
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
      setIsUploading(false);
      return;
    }

    const { data } = image;
    const formData = new FormData();

    Object.entries(data.formInputs).forEach(([key, value]) => {
      formData.append(key, value as string);
    });
    formData.append('file', file);
    try {
      const imageFromS3 = await fetch(data.formAttributes.action, {
        method: 'POST',
        body: formData,
      });
      if (!imageFromS3.ok) {
        toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
        setIsUploading(false);
        return;
      }

      setCurrentImage(file.objectURL);
      handleUploaded?.(data.id, file.objectURL);
      toast?.current?.show(toastMessage(t('common.toast.import_video_image_success')));
    } catch {
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
    } finally {
      setIsUploading(false);
    }
  };

  const preLoadImage = async (file: File) => {
    const { data: image } = await apiService.files.collection(
      'pre-signed-url',
      {
        method: 'POST',
        body: {
          name: file.name,
          original_name: file.name,
          mime_type: file.type,
          content_length: file.size,
        },
      },
    );

    return image;
  };

  const onTemplateUpload = (e: any) => {
    console.log(e.files);
  };

  const onTemplateClear = () => {};

  const headerTemplate = (options: any) => {
    const { className, chooseButton } = options;
    return (
      <div
        className={cx(
          'left-1/2 transform -translate-x-1/2 top-[15px] absolute z-40 p-0',
          className,
        )}
        style={{
          background: 'transparent',
        }}
      >
        {chooseButton}
      </div>
    );
  };

  const disabledChoose = useMemo(() => {
    return isUploading ? 'disabled-custom' : '';
  }, [isUploading]);

  const chooseOptions = {
    icon: 'pi pi-fw pi-cloud-upload text-gray-600 text-[22px]',
    iconOnly: true,
    className: `choose-button border-gray-50 border-8 bg-gray-100 w-[50px] h-[50px] rounded-full ${disabledChoose} ${idChooseBtn}`,
  };
  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
    className:
      'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined',
  };

  const handleTriggerUpload = () => {
    const el = document.querySelector(`.${idChooseBtn}`) as HTMLElement;
    if (el) {
      el?.click();
    }
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column mt-[70px] p-0 text-center">
        <div className="w-[100%]">
          <span
            onClick={() => handleTriggerUpload()}
            className={`text-primary-700 cursor-pointer ${disabledChoose}`}
          >
            {textUpload}
          </span>
          {textSubUpload}
          <br />
          SVG, PNG, JPG (max. 800x800px)
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!defaultUrl) return;
    setCurrentImage(defaultUrl);
  }, [defaultUrl]);

  return (
    <div>
      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
      <div className="flex">
        {!currentImage ? (
          <div className="mr-[20px]">
            {!isUploading ? (
              <Avatar
                icon={`pi ${iconAvatar ? `${iconAvatar}` : 'pi-user'} text-[20px]`}
                style={{ width: '126px', height: '126px' }}
                className="rounded-[8px]"
              />
            ) : (
              <Skeleton
                width="126px"
                height="126px"
                className="mb-2"
                borderRadius="8px"
              />
            )}
          </div>
        ) : (
          <div className="w-[126px] h-[126px] mr-[20px]">
            <img
              src={currentImage}
              className="w-full h-full object-cover rounded-[8px]"
            />
          </div>
        )}

        <FileUpload
          {...rest}
          className={cx('fileupload-custom rounded-[8px]', rest.className)}
          ref={fileUploadRef}
          onUpload={onTemplateUpload}
          onSelect={onFileSelect}
          onError={onTemplateClear}
          onClear={onTemplateClear}
          headerTemplate={headerTemplate}
          chooseOptions={chooseOptions}
          cancelOptions={cancelOptions}
          emptyTemplate={emptyTemplate}
          itemTemplate={emptyTemplate}
          contentClassName="bg-transparent"
        />
      </div>
    </div>
  );
}
