import axiosRequest, { AxiosOption } from '@/lib/axiosCustom';
import { ApiListResponse } from '@/types/api';

export default class RestService<T = any> {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    protected resource: string,
    protected options?: AxiosOption
  ) {}

  index<D = T, R = ApiListResponse<D>>(
    params?: Record<string, string | number>,
    extras: AxiosOption = {}
  ) {
    return axiosRequest<R>(`${this.resource}`, {
      query: params,
      method: 'GET',
      ...this.options,
      ...extras,
    });
  }

  query<D = T, R = ApiListResponse<D>>(
    query: string,
    params?: Record<string, string | number>,
    extras: AxiosOption = {}
  ) {
    return axiosRequest<R>(`${this.resource}${query ? `?${query}` : ''}`, {
      query: params,
      method: 'GET',
      ...this.options,
      ...extras,
    });
  }

  show<R = T>(
    id: number | string,
    params?: Record<string, string | number>,
    extras: AxiosOption = {}
  ) {
    return axiosRequest<R>(`${this.resource}/${id}`, {
      query: params,
      ...this.options,
      ...extras,
    });
  }

  create<R = T>(data: any, extras: AxiosOption = {}) {
    return axiosRequest<R>(`${this.resource}`, {
      body: data,
      method: 'POST',
      ...this.options,
      ...extras,
    });
  }

  update<R = T>(
    id: number | string | null,
    data: any,
    extras: AxiosOption = {}
  ) {
    const path = id ? `${this.resource}/${id}` : this.resource;
    return axiosRequest<R>(path, {
      body: data,
      method: 'PUT',
      ...this.options,
      ...extras,
    });
  }

  delete<R = T>(id?: number | string, extras: AxiosOption = {}) {
    const url = id ? `${this.resource}/${id}` : this.resource;
    return axiosRequest<R>(url, {
      method: 'DELETE',
      ...this.options,
      ...extras,
    });
  }

  collection<R = any>(
    subResource: string,
    { method = 'get', ...extras }: AxiosOption = {}
  ) {
    return axiosRequest<R>(`${this.resource}/${subResource}`, {
      method,
      ...this.options,
      ...extras,
    });
  }

  member<R = any>(
    id: number | string,
    subResource: string,
    { method = 'get', ...extras }: AxiosOption = {}
  ) {
    return axiosRequest<R>(`${this.resource}/${id}/${subResource}`, {
      method,
      ...this.options,
      ...extras,
    });
  }
}
