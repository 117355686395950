import { serializeFormQuery } from '@/lib/utils';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@/shared/constants/pagination';
import { useSearchParams } from 'react-router-dom';

interface QueryParams {
  [key: string]: string | number;
}

const useSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { query: queryString } = Object.fromEntries(searchParams.entries());

  const queryCurrent: QueryParams = queryString?.split('&')
    .reduce((acc: QueryParams, param: string) => {
      const [key, value] = param.split('=');
      acc[key] = isNaN(Number(value)) ? value : Number(value);
      return acc;
    }, {}) ?? {};

  function handleSearch(values: any) {
    //  eslint-disable-next-line
    const { limit, paginate, ...rest } = queryCurrent;
    const valuesSerialize = serializeFormQuery(values);
    if (JSON.stringify(valuesSerialize) == JSON.stringify(rest)) return;

    if (values.reset) {
      setSearchParams({});
      return;
    }
    const query = new URLSearchParams(
      serializeFormQuery({
        ...values,
        limit: DEFAULT_PER_PAGE,
        paginate: DEFAULT_PAGE,
      }),
    ).toString();
    setSearchParams({
      ...searchParams,
      query,
    });
  }

  return {
    handleSearch,
    searchParams,
    queryString,
    query: queryCurrent,
  };
};

export default useSearch;
