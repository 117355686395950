import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from '@/config/i18n';
import ButtonCustom from '@/components/common/ui/button';
import DialogCustom from '@/components/common/ui/dialog';

import Select from 'react-select';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'; // PrimeReact confirm dialog
import InfinitySelectNoForm from '@/components/common/ui/select/InfinitySelectNoForm';
import { apiRequest } from '@/services';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import {
  ALL_VALUE,
  AT_LEAST_ONE_OPTION,
  DEFAULT_ALL_OPTION,
} from '@/shared/constants/lesson';

interface IAddVideoFormProps {
  visible?: boolean;
  onHide: () => void;
  video?: any;
  members: any;
  videosPublic: any[];
  onSubmitStudent: (students: any) => void;
  mentorId?: string | number;
}

let controllerMentor = new AbortController();

const AddStudentForm = ({
  visible,
  onHide,
  onSubmitStudent,
  members,
  videosPublic,
  mentorId
}: IAddVideoFormProps) => {
  const { apiService } = apiRequest();
  const [t] = useTranslation('');
  const { id } = useParams<{ id: string | undefined }>();
  const [rowKeyAddUser, setRowKeyAddUser] = useState('');

  const videoOptions = useMemo(() => {
    return [
      { value: 'All', label: t('lesson_student.all') },
      { value: 0, label: '0' },
      ...Array.from({ length: videosPublic.length - 1 }, (_, i) => ({
        value: i + 1,
        label: `${i + 1}`,
      })),
    ];
  }, [videosPublic]);

  const [dropdowns, setDropdowns] = useState<any[]>([
    { selectValue: null, videoValue: null },
  ]);

  const hasSelectAllStudent = useMemo(() => {
    return (
      dropdowns.some(
        (dropdown) => dropdown?.selectValue?.value === ALL_VALUE,
      )
    );
  }, [dropdowns]);

  const indexOptionAllStudent = useMemo(() => {
    return dropdowns.findIndex(
      (dropdown) => dropdown?.selectValue?.value === ALL_VALUE,
    );
  }, [dropdowns]);

  const handleChange = (index: number, type: any, selectedOption: any) => {
    const updatedDropdowns = [...dropdowns];

    if (type === 'select') {
      const alreadySelected =
        dropdowns.find(
          (dropdown: any) =>
            dropdown.selectValue &&
            dropdown.selectValue?.value === selectedOption?.value,
        ) || members.find((member: any) => member.id == selectedOption?.value);
      if (alreadySelected) {
        confirmDialog({
          message: t('lesson_student.add_student_dialog.content'),
          header: t('lesson_student.add_student_dialog.title'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            updatedDropdowns[index].selectValue = null;
            setDropdowns(updatedDropdowns);
          },
        });
      } else {
        updatedDropdowns[index].selectValue = selectedOption;
      }
    } else {
      updatedDropdowns[index].videoValue = selectedOption;
    }

    setDropdowns(updatedDropdowns);
  };

  const addNewDropdown = () => {
    setDropdowns([...dropdowns, { selectValue: null, videoValue: null }]);
  };

  const deleteDropdown = (index: number) => {
    const updatedDropdowns = [...dropdowns];
    updatedDropdowns.splice(index, 1);
    setDropdowns(updatedDropdowns);
  };

  function onSubmit() {
    handleSave();
  }

  const customStyles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 999999999999 }),
  };

  const handleSave = () => {
    let allValid = true;
    const updatedDropdowns = dropdowns.map((dropdown, index) => {
      if (!dropdown.selectValue || !dropdown.videoValue) {
        const errorStudent = !dropdown.selectValue && !hasSelectAllStudent
          ? t('lesson_student.student_required')
          : undefined;
        const errorVideo = !dropdown.videoValue && (!hasSelectAllStudent || index === indexOptionAllStudent)
          ? t('lesson_student.video_required')
          : undefined;
        return { ...dropdown, errorStudent, errorVideo };
      }
      return { ...dropdown, error: undefined };
    });

    allValid = !updatedDropdowns?.some(dropdown => dropdown?.errorStudent || dropdown?.errorVideo)

    setDropdowns(updatedDropdowns);

    if (allValid) {
      const newStudents = dropdowns.filter(d => d?.selectValue && d?.videoValue).map((dropdown) => {
        return {
          ...dropdown?.selectValue?.info || {},
          total_video: dropdown.videoValue?.value,
          created_at: dayjs(new Date()).format('YYYY/MM/DD'),
        };
      });
      onSubmitStudent(newStudents);
    }
  };

  const fetchStudentOptions = useCallback(
    async (params: any, key: string) => {
      setRowKeyAddUser(key);
      if (controllerMentor.abort && key === rowKeyAddUser)
        controllerMentor.abort();
      controllerMentor = new AbortController();
      const { signal } = controllerMentor;
      return await apiService.users.index(
        {
          ...params,
          status: 'active',
          role: 'STUDENT',
          exclude_lesson_id: id,
          mentor_id: mentorId,
        },
        { signal },
      );
    },
    [rowKeyAddUser],
  );

  return (
    <DialogCustom
      visible={visible}
      onHide={onHide}
      header={t('lesson_student.header')}
      contentClassName="border-top border-bottom py-[14px]"
      blockScroll
      footer={
        <div>
          <div className="flex-full-center gap-3">
            <ButtonCustom
              type="button"
              severity="secondary"
              className="flex-full-center w-full font-medium text-[16px]"
              onClick={onHide}
            >
              {t('lesson_student.button.cancel')}
            </ButtonCustom>
            <ButtonCustom
              type="button"
              onClick={onSubmit}
              loading={false}
              className="flex-full-center w-full font-medium text-[16px]"
            >
              {t('lesson_student.button.add')}
            </ButtonCustom>
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-3 w-[688px]">
        {hasSelectAllStudent && dropdowns.length > 1 && (
          <div>
            <i className="pi pi-exclamation-triangle mr-[8px] ml-[4px]" />
            {t('lesson_student.add_student_warning')}
          </div>
        )}
        <div className="flex gap-6">
          <p className="w-[45%] text-[14px]">
            {t('lesson_student.student_label')}
            <span className="text-error-500">*</span>
          </p>
          <p className="w-[45%] text-[14px]">
            {t('lesson_student.video_label')}
            <span className="text-error-500">*</span>
          </p>
        </div>
        {dropdowns.map((dropdown, index) => (
          <div key={index}>
            <div className="flex gap-6">
              <div className="w-[45%]">
                <InfinitySelectNoForm
                  className="h-[48px]"
                  service={fetchStudentOptions}
                  value={dropdown.selectValue}
                  onChange={(selectedOption: any) => {
                    handleChange(index, 'select', selectedOption);
                    dropdowns[index].errorStudent = '';
                  }}
                  filterOption={() => true}
                  fieldLabels={{
                    label: 'full_name_kanji',
                    value: 'id',
                    subLabel: 'email',
                    isDisplayColumn: true,
                  }}
                  isFullInfo={true}
                  placeholder=""
                  menuPortalTarget={document.body}
                  styles={customStyles}
                  indexKey={index}
                  defaultOption={DEFAULT_ALL_OPTION(t)}
                  conditionShowDefaultOption={AT_LEAST_ONE_OPTION}
                  isDisabled={
                    indexOptionAllStudent >= 0 && indexOptionAllStudent !== index
                  }
                />
                {dropdown.errorStudent && (
                  <span style={{ color: 'red', marginTop: '5px' }}>
                    {dropdown.errorStudent}
                  </span>
                )}
              </div>
              <div className="w-[45%]">
                <Select
                  className="h-[48px]"
                  options={videoOptions}
                  value={dropdown.videoValue}
                  onChange={(selectedOption) => {
                    handleChange(index, 'video', selectedOption);
                    dropdowns[index].errorVideo = '';
                  }}
                  placeholder=""
                  menuPortalTarget={document.body}
                  styles={customStyles}
                  noOptionsMessage={() => <>{t('common.no_data')}</>}
                  isDisabled={
                    indexOptionAllStudent >= 0 && indexOptionAllStudent !== index
                  }
                />
                {dropdown.errorVideo && (
                  <span style={{ color: 'red', marginTop: '5px' }}>
                    {dropdown.errorVideo}
                  </span>
                )}
              </div>
              {index >= 1 && (
                <button
                  type="button"
                  onClick={() => deleteDropdown(index)}
                  style={{ marginTop: '10px', alignSelf: 'flex-start' }}
                >
                  <span className="pi pi-trash"></span>
                </button>
              )}
            </div>
          </div>
        ))}
        {indexOptionAllStudent < 0 && (
          <button
            type="button"
            className="flex gap-1 items-center"
            onClick={() => {
              addNewDropdown();
            }}
          >
            <div className="p-[10px] flex-full-center text-primary-700">
              <i className="pi pi-plus !font-bold"></i>
            </div>
            <div className="text-primary-700">{t('lesson_student.add')}</div>
          </button>
        )}
        <ConfirmDialog
          className="hide-footer-dialog min-w-[400px]"
          draggable={false}
        />
      </div>
    </DialogCustom>
  );
};

export default AddStudentForm;
