import { SetStateAction } from 'react';
import { DataTableRowReorderEvent } from 'primereact/datatable';

import { toastErrorMessage } from '@/lib/utils';

export const handleFormatQuestion = (data: any[], t: any) => {
  return data.map((item) => {
    const letters = 'ABCDE';
    const options = item.options.map((option: any, index: any) => ({
      char: letters[index],
      value: option.name,
    }));

    let results;

    if (item.type === 'checkbox') {
      results = item.options
        .map((option: any, index: any) => ({
          value: option.name,
          label: letters[index],
          is_correct: option.is_correct,
        }))
        .filter(({ is_correct }: any) => is_correct)
        .map(({ value, label }: any) => ({ value, label }));
    } else if (item.type === 'radio') {
      const correctItem = item.options.find((item: any) => item.is_correct);
      if (correctItem) {
        results = {
          value: correctItem.name,
          label: letters[item.options.indexOf(correctItem)],
        };
      }
    }

    return {
      id: item.id,
      result: results,
      options,
      note_for_correct: item.note_for_correct,
      status: item.status,
      question: item.name,
      priority: item?.priority,
      type: {
        value: item.type,
        label:
          item.type === 'checkbox'
            ? t('lesson_question.type.values.checkbox')
            : t('lesson_question.type.values.radio'),
      },
    };
  });
};

export const validatePriorities = (
  updatedData: any[],
  id: any,
  t: any,
  toast: any,
  setErrorFn: (value: SetStateAction<Record<number, string>>) => void,
) => {
  const priorityMap: Record<number, number[]> = {};
  const newErrors: Record<number, string> = {};

  updatedData.forEach((row) => {
    if (row.priority <= 0) {
      newErrors[row.id] = t('lesson.noti.priority_must_greater_than_zero');
    } else {
      if (!priorityMap[row.priority]) {
        priorityMap[row.priority] = [];
      }
      priorityMap[row.priority].push(row.id);
    }
  });

  Object.keys(priorityMap).forEach((priority: any) => {
    if (priorityMap[priority].length > 1) {
      priorityMap[priority].forEach((id: any) => {
        newErrors[id] = t('lesson.noti.priority_cannot_duplicated');
      });
    }
  });

  if (newErrors[id]) {
    toast?.current?.show(toastErrorMessage(newErrors[id]));
  }

  setErrorFn(newErrors);
};

export const updatePrioritiesWhenDragging = (
  originalData: any[],
  dragAndDropData: DataTableRowReorderEvent<any>,
) => {
  const { value: updatedList } = dragAndDropData;

  const newPriorityList = [...originalData.map((item) => item.priority)];

  for (let i = 0; i < updatedList.length; i++) {
    (updatedList[i] as any).priority = newPriorityList[i];
  }

  return updatedList;
};
