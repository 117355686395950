import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { User } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, doc, setDoc } from 'firebase/firestore';

import { UserType } from '@/types/chat';
import { UserFormSearchType, userSearchFormSchema } from '@/shared/schema/user';
import { auth, db } from '@/lib/firebase';
import PATH from '@/routes/path';
import { checkConversationExists, generateConversationData } from '@/lib/utils';
import DialogCustom from '@/components/common/ui/dialog';
import ButtonCustom from '@/components/common/ui/button';
import useDebounceFn from '@/hooks/useDebounceFn';
import { DEFAULT_PER_PAGE } from '@/shared/constants/pagination';
import {
  useFetchContacts,
  UserFormSearch,
  UserItem,
  UserItemSkeleton,
} from '../aside';

type Props = {
  openPopup?: boolean;
  onHidePopup: (value: boolean) => void;
  initializeUsers: (memberIds: number[]) => Promise<void>;
};

export const DialogContact: React.FC<Props> = ({ openPopup, onHidePopup, initializeUsers }) => {
  const [t] = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const currentUser: User | null = auth.currentUser;
  const conversationLink = `${PATH.chat}/${PATH.chat_conversation}/`;

  const formMethods = useForm<UserFormSearchType>({
    mode: 'onChange',
    resolver: yupResolver(userSearchFormSchema),
    shouldFocusError: false,
    defaultValues: {
      keyword: '',
    },
  });
  const { pagination, contacts, isFetching, fetchContacts, resetContacts } =
    useFetchContacts();
  const { watch, handleSubmit } = formMethods;

  const createChatRoom = async (user: UserType) => {
    try {
      const newConversation = doc(collection(db, 'conversations'));

      await setDoc(
        doc(db, 'conversations', newConversation.id),
        generateConversationData(newConversation.id, currentUser, false, [
          Number(user.id),
          Number(currentUser?.uid),
        ]),
      );
      initializeUsers([Number(user.id)]);
      return newConversation.id;
    } catch (error) {
      // TODO: Handle error
      return null;
    }
  };

  const handleUserClick = async (user: UserType) => {
    const existingRoomId = await checkConversationExists(
      Number(user.id),
      currentUser,
    );

    if (existingRoomId) {
      navigate(`${conversationLink}${existingRoomId}`);
      onHidePopup(false);
      resetContacts();
      return;
    }
    const chatRoomId = await createChatRoom(user);
    onHidePopup(false);
    resetContacts();
    navigate(`${conversationLink}${chatRoomId}`);
  };

  const keyword = watch('keyword')?.trim()?.toLowerCase();

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;
    if (
      container.scrollHeight - container.scrollTop <=
        container.clientHeight + 100 &&
      pagination?.has_more &&
      !isFetching
    ) {
      fetchContacts({ keyword, limit: DEFAULT_PER_PAGE });
    }
  }, [pagination?.has_more, isFetching, fetchContacts, keyword]);

  const debouncedSearch = useDebounceFn((keyword: string) => {
    resetContacts();
    fetchContacts({ keyword, paginate: 1, limit: 10 });
    const container = containerRef.current;
    if (container) {
      container.scrollTop = 0;
    }
  }, 300);

  const onSubmit = (value: UserFormSearchType) => {
    debouncedSearch(value.keyword);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    fetchContacts({ limit: DEFAULT_PER_PAGE });
  }, []);

  return (
    <>
      <DialogCustom
        header={t('chat.list_contact')}
        visible={openPopup}
        onHide={() => onHidePopup(false)}
        className="min-w-[480px] max-w-[480px] max-h-[700px] overflow-hidden"
        contentClassName="pb-0 border-b"
        headerClassName="border-b !text-gray-900 !font-semibold"
        footer={
          <div className="field">
            <div className="flex justify-center">
              <ButtonCustom
                type="button"
                className="cancle-button w-full"
                severity="secondary"
                onClick={() => onHidePopup(false)}
              >
                {t('chat.close')}
              </ButtonCustom>
            </div>
          </div>
        }
      >
        <div className="h-full flex items-center justify-center mt-4">
          <div className="bg-white w-full flex">
            <div className="flex w-full flex-col">
              <FormProvider {...formMethods}>
                <UserFormSearch onSubmit={handleSubmit(onSubmit)} />
                <div
                  ref={containerRef}
                  className="flex flex-col w-full overflow-y-auto max-h-[400px] min-h-[400px] mt-2"
                >
                  {contacts.map((user) => (
                    <UserItem
                      key={user.id}
                      user={user}
                      onClick={() => handleUserClick(user)}
                    />
                  ))}
                  {isFetching && (
                    <>
                      {Array.from({ length: 10 }).map((_, index) => (
                        <UserItemSkeleton key={index} />
                      ))}
                    </>
                  )}
                  {!contacts.length && !isFetching && (
                    <div className="text-center mt-[30px]">
                      {t('chat.not_found_user')}
                    </div>
                  )}
                </div>
              </FormProvider>
            </div>
          </div>
        </div>
      </DialogCustom>
    </>
  );
};
