import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  TabPanel,
  TabPanelHeaderTemplateOptions,
  TabView,
  TabViewTabChangeEvent,
} from 'primereact/tabview';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';

import { useTranslation } from '@/config/i18n';
import ButtonCustom from '@/components/common/ui/button';
import { CustomDataTable } from '@/components/common/ui/table/CustomDataTable';
import usePagination from '@/hooks/usePagination';
import DialogCustom from '@/components/common/ui/dialog';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import { ToastContext } from '@/components/common/CommonToast';
import { toastErrorMessage, toastMessage } from '@/lib/utils';
import useFetchClient from '@/hooks/useFetchClient';
import useSearch from '@/hooks/useSearch';
import { apiRequest } from '@/services';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@/shared/constants/pagination';
import { ObjectLiteral } from '@/types/object';
import { HTTP_STATUS } from '@/shared/constants';
import { Pagination } from '@/types/pagination';
import { VersionItemType } from '@/types/version';

import { VersionFormSearch } from './components';
import { VersionColumns } from './table/VersionColumns';
import { AddVersionFormType, addVersionSchema } from './schema';

const VERSION_TYPE = {
  ANDROID: 'android',
  IOS: 'ios',
};

const VERSION_KEY = {
  ANDROID: 0,
  IOS: 1,
};

let controllerVersion = new AbortController();

const tabTemplate = (options: TabPanelHeaderTemplateOptions) => {
  return (
    <div
      className="flex align-items-center"
      style={{ cursor: 'pointer' }}
      onClick={options.onClick}
    >
      <span className="tab-label font-medium white-space-nowrap">
        {options.titleElement}
      </span>
    </div>
  );
};

const VersionManagementPage: React.FC = () => {
  const [isOpenAddForm, setIsOpenAddForm] = useState(false);
  const [isOpenDeleteForm, setIsOpenDeleteForm] = useState(false);
  const [isOpenEditForm, setIsOpenEditForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [versionType, setVersionType] = useState('');
  const [selectedVersion, setSelectedVersion] = useState<VersionItemType>(
    {} as VersionItemType,
  );

  const [t] = useTranslation('');
  const { handleSearch, searchParams, query: queryFromUrl } = useSearch();
  const { query } = Object.fromEntries(searchParams.entries());
  const { apiService } = apiRequest();
  const { toast } = useContext(ToastContext);
  const [activeIndex, setActiveIndex] = useState<number>(
    queryFromUrl['os'] === VERSION_TYPE.IOS
      ? VERSION_KEY.IOS
      : VERSION_KEY.ANDROID,
  );

  const formMethods = useForm<any>({});
  const addFormMethods = useForm<AddVersionFormType>({
    mode: 'onChange',
    resolver: yupResolver(addVersionSchema),
  });
  const { handleSubmit, setValue, getValues } = formMethods;
  const {
    handleSubmit: handleSubmitAdd,
    setError: setErrorAdd,
    reset: resetAdd,
  } = addFormMethods;

  const params = useMemo(() => {
    return query
      ? undefined
      : {
        limit: DEFAULT_PER_PAGE,
        paginate: DEFAULT_PAGE,
        os: VERSION_TYPE.ANDROID,
      };
  }, [query]);

  const {
    data: versions,
    error,
    fetchData: fetchVersions,
  } = useFetchClient(
    async () => {
      if (controllerVersion.abort) controllerVersion.abort();
      controllerVersion = new AbortController();
      const { signal } = controllerVersion;
      return await apiService.versions.query(query, params, { signal });
    },
    [query],
    {
      isLoadingGlobal: true,
      isScrollToTop: true,
    },
  );
  const { paginationComputed, setPagination } = usePagination(
    versions?.pagination,
  );

  if (error) {
    toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
  }

  const onSubmit = (value: any) => {
    handleSearch({
      ...queryFromUrl,
      ...value,
      os:
        activeIndex === VERSION_KEY.IOS
          ? VERSION_TYPE.IOS
          : VERSION_TYPE.ANDROID,
      sort_columns:
        typeof queryFromUrl['sort_columns'] !== 'string'
          ? ''
          : queryFromUrl['sort_columns'],
    });
  };

  const handleReset = () => {
    setValue('keyword', '');
    handleSearch({
      ...queryFromUrl,
      os:
        activeIndex === VERSION_KEY.IOS
          ? VERSION_TYPE.IOS
          : VERSION_TYPE.ANDROID,
      keyword: '',
      sort_columns:
        typeof queryFromUrl['sort_columns'] !== 'string'
          ? ''
          : queryFromUrl['sort_columns'],
    });
  };

  const onPaginationChanged = (paginationState: Pagination) => {
    setPagination(paginationState);
  };

  const onBeforeTabChange = (event: TabViewTabChangeEvent) => {
    const { index } = event;
    setActiveIndex(index);
    const currentParams = Object.fromEntries(new URLSearchParams(query));
    setValue('keyword', '');
    handleSearch({
      ...(currentParams as ObjectLiteral),
      limit: DEFAULT_PER_PAGE,
      paginate: DEFAULT_PAGE,
      os: index === 0 ? VERSION_TYPE.ANDROID : VERSION_TYPE.IOS,
      keyword: '',
      sort_columns: '',
    });
    setPagination({
      ...paginationComputed,
      limit: DEFAULT_PER_PAGE,
      paginate: DEFAULT_PAGE,
      sortField: undefined,
      sortOrder: undefined,
    });
  };

  const handleSetDataFromQuery = () => {
    const values = getValues();
    Object.keys(values).forEach((field: any) => {
      const val = queryFromUrl[field] || '';
      if (queryFromUrl[field]) {
        setValue(field, val);
      }
    });
  };

  const handleError = (error: any) => {
    const { status_code, errors } = error;
    if (status_code === HTTP_STATUS.FORM_ERROR) {
      errors.forEach((e: any) => {
        const { field, message } = e;
        setErrorAdd(field, { message });
      });
      return;
    }

    toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
  };

  const onSubmitAdd = async (value: AddVersionFormType) => {
    setIsSubmitting(true);
    const payload = {
      ...value,
      os: versionType,
    };
    const { error: addVersionErr } = await apiService.versions.create(payload);

    if (addVersionErr) {
      handleError(addVersionErr);
      setIsSubmitting(false);
      return;
    }

    resetAdd();
    fetchVersions();
    setIsOpenAddForm(false);
    toast?.current?.show(toastMessage(t('version.add_name_success')));
    setIsSubmitting(false);
  };

  const onDeleteVersion = async (rowData: VersionItemType) => {
    if (!isOpenDeleteForm) {
      setIsOpenDeleteForm(true);
      setSelectedVersion(rowData);
      return;
    }

    if (isEmpty(rowData)) return;

    setIsSubmitting(true);
    const { error: deleteErr } = await apiService.versions.delete(rowData.id);
    if (deleteErr) {
      setIsSubmitting(false);
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
      return;
    }

    fetchVersions();
    setIsOpenDeleteForm(false);
    toast?.current?.show(toastMessage(t('version.delete_success')));
    setIsSubmitting(false);
  };

  const onEditStatusVersion = async (rowData: VersionItemType) => {
    if (!isOpenEditForm) {
      setIsOpenEditForm(true);
      setSelectedVersion(rowData);
      return;
    }

    setIsSubmitting(true);
    const { error: updateErr } = await apiService.versions.update(rowData.id, {
      force_update: !rowData.force_update,
    });

    if (updateErr) {
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(false);
    setIsOpenEditForm(false);
    const indexUpdate = versions.data.findIndex(
      (version: VersionItemType) => version.id === rowData.id,
    );
    if (indexUpdate !== -1) {
      versions.data[indexUpdate].force_update = !rowData.force_update;
    }
  };

  useEffect(() => {
    handleSetDataFromQuery();
  }, []);

  return (
    <>
      <div className="header-top flex items-center justify-between mb-[16px]">
        <h2 className="title">{t('version.title')}</h2>
        <div className="action flex items-center">
          <ButtonCustom
            className="ml-5"
            onClick={() => {
              setVersionType(VERSION_TYPE.ANDROID);
              setIsOpenAddForm(true);
            }}
          >
            <span className="flex relative items-center">
              <span className="pi pi-plus mr-2" />
              <span>{t('version.add_android')}</span>
            </span>
          </ButtonCustom>
          <ButtonCustom
            className="ml-5"
            onClick={() => {
              setVersionType(VERSION_TYPE.IOS);
              setIsOpenAddForm(true);
            }}
          >
            <span className="flex relative items-center">
              <span className="pi pi-plus mr-2" />
              <span>{t('version.add_ios')}</span>
            </span>
          </ButtonCustom>
        </div>
      </div>

      <FormProvider {...formMethods}>
        <TabView
          activeIndex={activeIndex}
          className="highlight-bottom"
          onBeforeTabChange={onBeforeTabChange}
        >
          <TabPanel header={t('version.android')} headerTemplate={tabTemplate}>
            <VersionFormSearch
              onSubmit={handleSubmit(onSubmit)}
              onClear={handleReset}
            />
            <CustomDataTable
              wrapClassName="mt-[20px]"
              values={versions?.data || []}
              colums={VersionColumns(t, onDeleteVersion, onEditStatusVersion)}
              pagination={paginationComputed}
              onPaginationChanged={onPaginationChanged}
            />
          </TabPanel>
          <TabPanel header={t('version.ios')} headerTemplate={tabTemplate}>
            <VersionFormSearch
              onSubmit={handleSubmit(onSubmit)}
              onClear={handleReset}
            />
            <CustomDataTable
              wrapClassName="mt-[20px]"
              values={versions?.data || []}
              colums={VersionColumns(t, onDeleteVersion, onEditStatusVersion)}
              pagination={paginationComputed}
              onPaginationChanged={onPaginationChanged}
            />
          </TabPanel>
        </TabView>
      </FormProvider>

      <FormProvider {...addFormMethods}>
        <DialogCustom
          header={t(
            versionType === VERSION_TYPE.ANDROID
              ? 'version.dialog.android_title'
              : 'version.dialog.ios_title',
          )}
          visible={isOpenAddForm}
          onHide={() => {
            setIsOpenAddForm(false);
            resetAdd();
          }}
          className="w-[688px] dialog-add-version"
          footer={
            <div className="field">
              <div className="flex gap-[20px]">
                <ButtonCustom
                  type="button"
                  className="cancle-button p-button-sm ml-2 flex-1"
                  severity="secondary"
                  onClick={() => {
                    setIsOpenAddForm(false);
                    resetAdd();
                  }}
                >
                  {t('common.form.cancel')}
                </ButtonCustom>
                <ButtonCustom
                  type="button"
                  className="flex-1"
                  onClick={handleSubmitAdd(onSubmitAdd)}
                  loading={isSubmitting}
                >
                  {t('common.form.save')}
                </ButtonCustom>
              </div>
            </div>
          }
        >
          <div className="label flex mb-[8px]">
            <span>{t('version.name')}</span>
            <span className="text-error-500">*</span>
          </div>
          <InputTextCustomForm
            name="version"
            wrapClass="w-full"
            placeholder={t('version.name_placeholder')}
          />
          <p className="text-[#757575] text-[12px] mt-2 whitespace-pre-line">
            {t('version.name_hint')}
          </p>
        </DialogCustom>
      </FormProvider>

      <DialogCustom
        header={t('version.confirm_delete_title')}
        visible={isOpenDeleteForm}
        onHide={() => setIsOpenDeleteForm(false)}
        footer={
          <div className="field">
            <div className="flex justify-end">
              <ButtonCustom
                type="button"
                className="cancle-button p-button-sm mr-2"
                severity="secondary"
                onClick={() => setIsOpenDeleteForm(false)}
              >
                {t('common.dialog.cancel')}
              </ButtonCustom>
              <ButtonCustom
                type="button"
                loading={isSubmitting}
                onClick={() => onDeleteVersion(selectedVersion)}
              >
                {t('common.dialog.ok')}
              </ButtonCustom>
            </div>
          </div>
        }
      >
        {t('version.confirm_delete_body')}
      </DialogCustom>

      <DialogCustom
        header={t('common.dialog.header')}
        visible={isOpenEditForm}
        onHide={() => setIsOpenEditForm(false)}
        className="w-[720px]"
        footer={
          <div className="field">
            <div className="flex justify-end">
              <ButtonCustom
                type="button"
                className="cancle-button p-button-sm mr-2"
                severity="secondary"
                onClick={() => setIsOpenEditForm(false)}
              >
                {t('common.dialog.cancel')}
              </ButtonCustom>
              <ButtonCustom
                type="button"
                loading={isSubmitting}
                onClick={() => onEditStatusVersion(selectedVersion)}
              >
                {t('common.dialog.ok')}
              </ButtonCustom>
            </div>
          </div>
        }
      >
        {selectedVersion?.force_update
          ? t('version.confirm_turn_off')
          : t('version.confirm_turn_on')}
      </DialogCustom>
    </>
  );
};

export default VersionManagementPage;
