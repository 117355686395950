export default function IconStudent() {
  return (
    <svg
      width={22}
      height={19}
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 7.19977V13.2109C4 13.5699 4 13.7494 4.05465 13.9078C4.10299 14.0479 4.18187 14.1756 4.28558 14.2815C4.40287 14.4012 4.5634 14.4815 4.88446 14.642L10.2845 17.342C10.5468 17.4732 10.678 17.5388 10.8156 17.5646C10.9375 17.5875 11.0625 17.5875 11.1844 17.5646C11.322 17.5388 11.4532 17.4732 11.7155 17.342L17.1155 14.642C17.4366 14.4815 17.5971 14.4012 17.7144 14.2815C17.8181 14.1756 17.897 14.0479 17.9453 13.9078C18 13.7494 18 13.5699 18 13.2109V7.19977M1 5.69977L10.6422 0.878658C10.7734 0.813066 10.839 0.780271 10.9078 0.767363C10.9687 0.755931 11.0313 0.755931 11.0922 0.767363C11.161 0.780271 11.2266 0.813066 11.3578 0.878658L21 5.69977L11.3578 10.5209C11.2266 10.5865 11.161 10.6193 11.0922 10.6322C11.0313 10.6436 10.9687 10.6436 10.9078 10.6322C10.839 10.6193 10.7734 10.5865 10.6422 10.5209L1 5.69977Z"
        stroke="#84CAFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
