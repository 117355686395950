/* eslint @typescript-eslint/no-explicit-any: 0 */
import cx from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import { useController, useFormContext } from 'react-hook-form';
import { Calendar, CalendarProps } from 'primereact/calendar';

import { useTranslation } from '@/config/i18n';
import './custom-calendar.css';

type CalendarCustomFormProps = {
  id?: string;
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  requiredFlag?: boolean;
  fullWidthFlag?: boolean;
  wrapClass?: string;
  inputStyle?: React.CSSProperties;
  isLoading?: boolean;
  errorMessage?: string;
};

export const CalendarCustomForm: React.FC<
  CalendarCustomFormProps & CalendarProps
> = ({
  name,
  label,
  placeholder,
  className,
  requiredFlag,
  fullWidthFlag,
  wrapClass,
  inputStyle,
  isLoading,
  onChange,
  onBlur,
  errorMessage,
  id,
  ...rest
}) => {
  const [t] = useTranslation('');

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    control,
    name,
  });

  return (
    <>
      <div className={cx(wrapClass, 'input')}>
        {label && (
          <div className="label-custom">
            <label>{label}</label>
            {requiredFlag && (
              <span className={cx('p-error', 'required')}>
                {t('common.form.required')}
              </span>
            )}
          </div>
        )}
        {isLoading ? (
          <Skeleton />
        ) : (
          <div className="relative">
            <Calendar
              {...rest}
              locale="ja"
              id={id ?? field?.name ?? ''}
              placeholder={placeholder}
              className={cx('input-custom', className, {
                ['p-invalid']: !!errors && errors[name],
                ['w-full']: fullWidthFlag,
              })}
              style={inputStyle}
              {...field}
              name={name}
              value={field?.value}
              onChange={(e: any) => {
                field?.onChange(e.target.value);
                onChange && onChange(e);
              }}
              onBlur={(e: any) => {
                onBlur && onBlur(e);
              }}
            />
          </div>
        )}

        {!!errors && (errors[name]?.message || errorMessage) && (
          <div
            className={cx('error-message', 'p-error mt-2', {
              ['is-label']: label,
            })}
          >
            {errors[name]?.message?.toString() || errorMessage}
          </div>
        )}
      </div>
    </>
  );
};
