import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

import { PaginationInfo } from '@/types/pagination';
import { ConversationType, UserType } from '@/types/chat';
import { ContactSearchList, ConversationSearchList } from '../aside';

type Props = {
  loading?: boolean;
  loadingUsers?: boolean;
  selectedConversation?: ConversationType | null;
  handleConversationClick: (value: ConversationType) => void;
  conversationsFilted: ConversationType[];
  users: UserType[];
  pagination: PaginationInfo;
  isFetching?: boolean;
  keywordFormValue?: string;
  handleFetchContacts: () => void;
  contacts: UserType[];
  listContactRef: React.MutableRefObject<HTMLDivElement | null>;
};

export const SearchList: React.FC<Props> = ({
  loading,
  loadingUsers,
  selectedConversation,
  handleConversationClick,
  conversationsFilted,
  users,
  pagination,
  isFetching,
  keywordFormValue,
  handleFetchContacts,
  contacts,
  listContactRef,
}) => {
  const [t] = useTranslation();
  const listConversationRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="flex flex-col w-full overflow-hidden max-h-[calc(100dvh-150px)]">
        <div className="w-full h-full">
          <div className="mb-4 h-full">
            <p className="text-[14px] text-primary-700 border-b-2 border-primary-700 w-fit">
              {t('chat.list_conversation')}: {conversationsFilted.length}
            </p>
            <ConversationSearchList
              handleConversationClick={handleConversationClick}
              conversationsFilted={conversationsFilted}
              loading={loading}
              loadingUsers={loadingUsers}
              selectedConversation={selectedConversation}
              users={users}
              listConversationRef={listConversationRef}
            />
          </div>
          <div>
            <p className="text-[14px] text-primary-700 border-b-2 border-primary-700 w-fit">
              {t('chat.list_contact')}: {pagination.total_item || 0}
            </p>
            <ContactSearchList
              pagination={pagination}
              isFetching={isFetching}
              keywordFormValue={keywordFormValue}
              handleFetchContacts={handleFetchContacts}
              contacts={contacts}
              listContactRef={listContactRef}
              listConversationRef={listConversationRef}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchList;
