import * as yup from 'yup';

import {
  BaseYup,
  MAX_INPUT,
  MAX_LENGTH_1000,
  MAX_LENGTH_500,
} from '@/config/baseYup';
import i18n from '@/config/i18n';

export const addNewQuestionLessonPublicSchema = BaseYup.object({
  question: BaseYup.string()
    .label(i18n.t('lesson_question.question.title'))
    .trim()
    .required()
    .max(MAX_LENGTH_1000),
  options: BaseYup.array()
    .of(
      BaseYup.object().shape({
        value: BaseYup.string()
          .label(i18n.t('lesson_question.option_input.title_validate'))
          .trim()
          .required()
          .max(MAX_LENGTH_500),
      }),
    )
    .min(2, i18n.t('lesson_question.option_input.min_options'))
    .test(
      'unique-values',
      i18n.t('lesson_question.option_input.duplicate_values'),
      (options) => {
        const values = options?.map((option) => option.value);
        if (!values) return false;
        if (values.some(item => item === '')) return true;
        const uniqueValues = new Set(values);
        return values.length === uniqueValues.size;
      },
    ),
  note_for_correct: BaseYup.string()
    .label(i18n.t('lesson_question.note_for_correct.title'))
    .trim()
    .required()
    .max(MAX_INPUT),
  result: BaseYup.mixed()
    .label(i18n.t('lesson_question.result.title'))
    .required()
});

export const addNewQuestionLessonDaftSchema = BaseYup.object({
  question: BaseYup.string()
    .label(i18n.t('lesson_question.question.title'))
    .trim()
    .required()
    .max(MAX_LENGTH_1000),
});

export type NewLessonQuestionFormType = yup.InferType<
  typeof addNewQuestionLessonPublicSchema
>;
