import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DataTableRowReorderEvent } from 'primereact/datatable';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';
import { isEmpty } from 'lodash';

import { useTranslation } from '@/config/i18n';
import { apiRequest } from '@/services';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import ButtonCustom from '@/components/common/ui/button';
import UploadImage from '@/components/common/ui/upload/uploadImage';
import {
  getValueFromSelect,
  toastErrorMessage,
  toastMessage,
} from '@/lib/utils';
import PATH from '@/routes/path';
import { HTTP_STATUS } from '@/shared/constants';
import { useLoading } from '@/components/common/loader/LoadingContext';
import { ToastContext } from '@/components/common/CommonToast';
import { LessonFormType, lessonFormSchema } from '../schema';
import { TextareaCustomForm } from '@/components/common/ui/textarea';
import { CustomDataTable } from '@/components/common/ui/table/CustomDataTable';
import { LessonVideoColumns } from '../table/LessonVideoColumns';
import AddVideoForm from './AddVideoForm';
import useScrollToError from '@/hooks/useScrollToError';
import AddStudentForm from './AddStudentForm';
import { LessonStudentColumns } from '../table/LessonStudentColumns';
import { CustomPaginator } from '@/components/common/ui/table/CustomPaginator';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@/shared/constants/pagination';
import { Pagination } from '@/types/pagination';
import InfinitySelect from '@/components/common/ui/select';
import DialogCustom from '@/components/common/ui/dialog';
import { VideoDataType } from '@/types/lesson';
import PreviewVideoDialog from './PreviewVideoDialog';
import { updatePrioritiesWhenDragging, validatePriorities } from './utils';
import { ALL_VALUE } from '@/shared/constants/lesson';

type Props = {
  id?: number;
  isAddOrEditUnit?: boolean;
  setIsAddOrEditUnit?: React.Dispatch<React.SetStateAction<boolean>>;
};

const MAX_VIDEO_DISPLAY = 50;

const LessonForm: React.FC<Props> = ({
  id,
  isAddOrEditUnit,
  setIsAddOrEditUnit,
}) => {
  const { toast } = useContext(ToastContext);
  const [t] = useTranslation('');
  const { apiService } = apiRequest();
  const [defaultImage, setDefaultImage] = useState<string>();
  const [visibleVideo, setVisibleVideo] = useState<boolean>();
  const [visibleStudent, setVisibleStudent] = useState<boolean>();
  const [videos, setVideos] = useState<any[]>([]);
  const [videoId, setVideoId] = useState<string | number>();
  const [video, setVideo] = useState<any>();
  const [students, setStudents] = useState<any[]>([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<string | number>(0);
  const [isEditQualityVideos, setIsEditQualityVideos] = useState(false);
  const [isEditPriorityUnits, setIsEditPriorityUnits] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<VideoDataType>(
    {} as VideoDataType,
  );
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [videoErrors, setVideoErrors] = useState<Record<number, string>>({});

  const navigate = useNavigate();
  const { scrollToError } = useScrollToError();
  const { loadingGlobal, setLoadingGlobal } = useLoading();
  const [selectedPagination, setSelectedPagination] = useState<Pagination>({
    paginate: DEFAULT_PAGE,
    limit: DEFAULT_PER_PAGE,
  });

  const videosPublic = useMemo(() => {
    return videos.filter((video) => video.status === 'public') ?? [];
  }, [videos]);

  const studentsWithPagination = useMemo(() => {
    const start =
      ((selectedPagination?.paginate || 1) - 1) *
      (selectedPagination?.limit || DEFAULT_PER_PAGE);
    const end = start + (selectedPagination?.limit || DEFAULT_PER_PAGE);
    return students.slice(start, end);
  }, [students, selectedPagination]);

  const tabStudentTemplate = (options: any) => {
    return (
      <div
        className="flex align-items-center gap-2 p-3 cursor-pointer"
        onClick={options.onClick}
      >
        <span className="tab-label font-medium white-space-nowrap">
          {t('lesson.student')}
        </span>
        <Badge value={students.length} />
      </div>
    );
  };

  const tabVideoTemplate = (options: any) => {
    return (
      <div
        className="flex align-items-center gap-2 p-3 cursor-pointer"
        onClick={options.onClick}
      >
        <span className="tab-label font-medium white-space-nowrap">
          {t('lesson.unit')}
        </span>
        <Badge value={videos.length} />
      </div>
    );
  };

  const formMethods = useForm<LessonFormType>({
    mode: 'onChange',
    resolver: yupResolver(lessonFormSchema),
    shouldFocusError: false,
    defaultValues: {
      status: {
        value: 'active',
        label: t('common.status_other.active'),
      } as unknown as string,
    },
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = formMethods;

  const getAllFieldNames = () => {
    const values = getValues();
    return Object.keys(values);
  };

  const hasSelectAllStudent = useMemo(() => {
    return students.some(student => student?.value === ALL_VALUE);
  }, [students]);

  const handleChangePagination = (paginationState: Pagination) => {
    setIsEditQualityVideos(false);
    setSelectedPagination({
      ...paginationState,
      total_page: students.length,
    });
  };

  async function getDetail(id: number) {
    setLoadingGlobal(true);
    const { data: lesson } = await apiService.lessons.show(id);

    if (lesson?.data) {
      setDefaultValue(lesson.data);
      setDefaultImage(lesson.data.profile_image);
    }
    setLoadingGlobal(false);
  }

  function setDefaultValue(data: any) {
    const specialField = [''];
    getAllFieldNames().forEach((field: any) => {
      const val = data[field];

      if (val && !specialField.includes(field)) {
        setValue(field, val);
      }
    });
  }

  async function onSubmit() {
    const payload = getPayload();
    const { status, units = [] } = payload;
    const isHavePublicUnit = units?.some((unit) => unit?.status === 'public');

    if (status === 'active' && !isHavePublicUnit) {
      toast?.current?.show(
        toastErrorMessage(t('lesson.validate.have_public_unit')),
      );
      return;
    }

    setLoadingGlobal(true);

    let data = null;
    let error = null;
    if (id) {
      const { data: user, error: userError } = await apiService.lessons.update(
        id,
        payload,
      );
      data = user;
      error = userError;
    } else {
      const { data: user, error: userError } =
        await apiService.lessons.create(payload);
      data = user;
      error = userError;
    }
    if (error) {
      handleError(error);
      setLoadingGlobal(false);
      return;
    }

    if (data) {
      if (payload.status === 'active') {
        toast?.current?.show(toastMessage(t('lesson.active_success')));
      } else if (payload.status === 'inactive') {
        toast?.current?.show(toastMessage(t('lesson.inactive_success')));
      }
      setLoadingGlobal(false);
      navigate(PATH.lesson);
    }
  }

  function getPayload() {
    const valuesSelect = getValueFromSelect(getValues(), ['status']);
    const newStudents =  hasSelectAllStudent ? undefined :  students.map((student) => {
      return {
        user_id: student.id,
        quality_unit:
          student.total_video === 'All' ? undefined : student.total_video,
      };
    });

    const optionAll = students.find(student => student?.value === ALL_VALUE);
    const qualityUnits = hasSelectAllStudent ? (optionAll?.total_video === 'All' ? '' : optionAll?.total_video) : undefined;

    const newUnits = videos.map(({ questionsParam, ...keepAttrs }) => ({
      ...keepAttrs,
      questions: questionsParam,
    }));

    return {
      ...getValues(),
      ...valuesSelect,
      ...(newUnits.length ? { units: newUnits } : {}),
      students: newStudents,
      quality_units: qualityUnits,
      is_all_students: hasSelectAllStudent,
    };
  }

  function handleError(error: any) {
    const { status_code, errors } = error;
    if (status_code === HTTP_STATUS.FORM_ERROR) {
      errors.forEach((e: any) => {
        const { field, message } = e;
        const messageSplit = message.split(' ');
        if (messageSplit && messageSplit.length === 3) {
          const fieldEng = messageSplit[1];
          const messageJapan = messageSplit[2];
          const messageTranslate = `${t(`user.${fieldEng}`)}${messageJapan}`;
          setError(field, { message: messageTranslate });
          return;
        }
        setError(field, { message });
      });
      return;
    }

    toast?.current?.show(toastErrorMessage(t('lesson.create_fail')));
  }

  function onFail() {
    // TODO
  }

  const onHideVideo = () => {
    setVisibleVideo(false);
    setIsAddOrEditUnit?.(false);
  };

  function onSubmitVideo(newVideos: any) {
    const largestPriority = videos?.length
      ? videos.reduce((max: any, item: any) => {
        return item?.priority > max?.priority ? item : max;
      }, videos[0]).priority
      : 0;
    const formatNewVideos = { ...newVideos, priority: largestPriority + 1 };
    const indexOldVideo = videos.findIndex((video) => {
      return video.id === newVideos.id;
    });

    if (indexOldVideo === -1) {
      // case new video
      setVideos([...videos, formatNewVideos]);
      onHideVideo();
      return;
    }

    // case old video
    videos[indexOldVideo] = {
      ...newVideos,
      priority: videos[indexOldVideo].priority,
    };
    setVideos([...videos]);
    onHideVideo();
  }

  function onEditVideo(rowData: any) {
    setVideoId(rowData.id);
    setVideo(rowData);
    setVisibleVideo(true);
    setIsAddOrEditUnit?.(true);
  }

  function onNewVideo() {
    setVideoId('');
    setVideo(undefined);
    setVisibleVideo(true);
    setIsAddOrEditUnit?.(true);
  }

  function onSubmitStudent(newStudents: any[]) {
    const optionAll = newStudents.find(
      (student) => student?.value === ALL_VALUE,
    );
    const isAllStudents = !isEmpty(optionAll);
    setStudents(
      isAllStudents
        ? [optionAll]
        : [
          ...newStudents,
          ...students.filter((student) => student?.value !== ALL_VALUE),
        ],
    );
    onHideStudent();
  }

  function onHideStudent() {
    setVisibleStudent(false);
  }

  function handleDeleteStudent(id: string | number) {
    setSelectedStudent(id);
    const nextDialogVisibleValue = !dialogVisible;
    setDialogVisible(nextDialogVisibleValue);
    if (nextDialogVisibleValue) return;
    onDeleteStudent(id);
  }

  function onDeleteStudent(id: number | string) {
    const indexDelete = students.findIndex((student) => student.id == id);
    if (indexDelete === -1) return;
    const updatedStudents = [...students];
    updatedStudents.splice(indexDelete, 1);
    setStudents(updatedStudents);
    setIsEditQualityVideos(false);
  }

  function onEditQualityVideos() {
    if (studentsWithPagination.length) {
      setIsEditQualityVideos(true);
    }
  }

  async function onSaveQualityVideos() {
    setIsEditQualityVideos(false);
    setVideos(videos);
  }

  const onEditPriorityUnits = () => {
    if (videos.length) {
      setIsEditPriorityUnits(true);
    }
  };

  const onSavePriorityUnits = () => {
    if (isEmpty(videoErrors)) {
      setIsEditPriorityUnits(false);
      setVideos(videos.sort((a, b) => a.priority - b.priority));
    }
  };

  const onPreviewVideo = async (rowData: VideoDataType) => {
    setSelectedVideo(rowData);
  };

  const onRowReorder = (e: DataTableRowReorderEvent<any>) => {
    const newVideosData = updatePrioritiesWhenDragging(videos, e);
    setVideos(newVideosData);
  };

  const onBeforeTabChange = (event: TabViewTabChangeEvent) => {
    const { index } = event;
    setActiveIndex(index);
  };

  const handlePriorityChange = (id: number, newPriority: number) => {
    const updatedVideos = videos.map((video) =>
      video.id === id ? { ...video, priority: newPriority } : video,
    );
    setVideos(updatedVideos);
    validatePriorities(updatedVideos, id, t, toast, setVideoErrors);
  };

  useEffect(() => {
    if (!id) return;

    getDetail(id);
  }, [id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      scrollToError();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [errors]);

  useEffect(() => {
    setSelectedPagination({
      ...selectedPagination,
      total_page: students.length,
    });
  }, [students]);

  useEffect(() => {
    const beforeUnload = (event: BeforeUnloadEvent) => {
      if (videos.length) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [videos]);

  useEffect(() => {
    return () => {
      videos.forEach((video) => {
        URL.revokeObjectURL(video.video_url);
        URL.revokeObjectURL(video.video_image);
        const { attachments } = video;
        if (attachments?.length) {
          attachments.forEach((attachment: { file_url: string }) => {
            URL.revokeObjectURL(attachment?.file_url);
          });
        }
      });
    };
  }, []);

  return (
    <>
      <FormProvider {...formMethods}>
        {isAddOrEditUnit ? (
          <AddVideoForm
            visible={visibleVideo}
            onHide={onHideVideo}
            onSubmitVideo={onSubmitVideo}
            id={videoId}
            video={video}
          />
        ) : (
          <form
            className="mt-[16px]"
            onSubmit={handleSubmit(onSubmit, onFail)}
            autoComplete="off"
          >
            <div className="form-item flex mt-[20px]">
              <div className="label w-56 flex">
                <span>{t('lesson.name')}</span>
                <span className="text-error-500">*</span>
              </div>
              <div className="w-[512px]">
                <InputTextCustomForm
                  wrapClass="w-full"
                  name="name"
                  placeholder={t('common.form.placeholder.default')}
                />
              </div>
            </div>
            <div className="form-item flex mt-[20px]">
              <div className="label w-56 flex">
                <span>{t('lesson.code')}</span>
              </div>
              <div className="w-[512px]">
                <InputTextCustomForm
                  wrapClass="w-full"
                  name="code"
                  placeholder={t('common.form.placeholder.default')}
                />
              </div>
            </div>
            <div className="form-item flex mt-[20px]">
              <div className="label w-56 flex">
                <span>{t('user.status')}</span>
                <span className="text-error-500">*</span>
              </div>
              <div className="w-[512px]">
                <InfinitySelect
                  name="status"
                  placeholder={t('common.form.placeholder.select')}
                  wrapClass="w-[512px]"
                  isClearable={false}
                  defaultOption={[
                    { value: 'active', label: t('common.status_other.active') },
                    {
                      value: 'inactive',
                      label: t('common.status_other.inactive'),
                    },
                  ]}
                />
              </div>
            </div>
            <div className="form-item flex mt-[20px]">
              <div className="label w-56 flex">
                <span>{t('lesson.description')}</span>
              </div>
              <div className="w-[512px]">
                <TextareaCustomForm
                  name="description"
                  wrapClass="w-full"
                  maxLength={300}
                  placeholder={t('lesson.description_placeholder')}
                />
              </div>
            </div>

            <div className="form-item flex mt-[20px]">
              <div className="label w-56 flex">
                <span>{t('lesson.image')}</span>
              </div>
              <div className="w-[512px] flex">
                <UploadImage
                  idChooseBtn="choose-lesson-main"
                  className="relative w-[366px] h-[126px] flex-full-center border border-gray-200"
                  name="profile_image_id"
                  accept=".svg,.jpg,.jpeg,.png"
                  iconAvatar="pi-image"
                  defaultUrl={defaultImage}
                  maxSize={5}
                  handleUploaded={(id, imgUrl) => {
                    setValue('thumbnail_id', id);
                    setDefaultImage(imgUrl);
                  }}
                  textUpload={t('lesson.text_upload')}
                  textSubUpload={t('lesson.text_sub_upload')}
                />
              </div>
            </div>

            <div className="lesson-tab mt-[30px]">
              <TabView
                activeIndex={activeIndex}
                onBeforeTabChange={onBeforeTabChange}
              >
                <TabPanel header="Student" headerTemplate={tabStudentTemplate}>
                  <CustomDataTable
                    wrapClassName="mt-[20px]"
                    scrollable
                    values={studentsWithPagination}
                    colums={LessonStudentColumns(
                      t,
                      handleDeleteStudent,
                      onEditQualityVideos,
                      onSaveQualityVideos,
                      isEditQualityVideos,
                      videosPublic,
                    )}
                    extraFooter={
                      (!hasSelectAllStudent && <div className="px-[24px] py-[16px]">
                        <button
                          type="button"
                          className="flex gap-3 items-center"
                          onClick={() => {
                            setVisibleStudent(true);
                          }}
                        >
                          <div className="bg-primary-100 p-[10px] h-[40px] w-[40px] flex-full-center text-primary-700 rounded-full">
                            <i className="pi pi-plus !font-bold"></i>
                          </div>
                          <div className="text-primary-700">追加</div>
                        </button>
                      </div>)
                    }
                  />
                  {studentsWithPagination.length > 0 && (
                    <CustomPaginator
                      pagination={selectedPagination}
                      onPaginationChanged={handleChangePagination}
                    />
                  )}
                  {visibleStudent && (
                    <AddStudentForm
                      videosPublic={videosPublic}
                      members={students}
                      visible={visibleStudent}
                      onHide={onHideStudent}
                      onSubmitStudent={onSubmitStudent}
                    />
                  )}
                </TabPanel>

                <TabPanel header="Video" headerTemplate={tabVideoTemplate}>
                  <CustomDataTable
                    wrapClassName="mt-[20px]"
                    values={videos}
                    colums={LessonVideoColumns(
                      t,
                      onEditVideo,
                      onPreviewVideo,
                      onEditPriorityUnits,
                      onSavePriorityUnits,
                      isEditPriorityUnits,
                      videoErrors,
                      handlePriorityChange,
                    )}
                    reorderableRows
                    onRowReorder={onRowReorder}
                    extraFooter={
                      videos.length < MAX_VIDEO_DISPLAY && (
                        <div className="px-[24px] py-[16px]">
                          <div>
                            <button
                              type="button"
                              className="flex gap-3 items-center"
                              onClick={() => {
                                onNewVideo();
                              }}
                            >
                              <div className="bg-primary-100 p-[10px] h-[40px] w-[40px] flex-full-center text-primary-700 rounded-full">
                                <i className="pi pi-plus !font-bold"></i>
                              </div>
                              <div className="text-primary-700">
                                ユニット追加
                              </div>
                            </button>
                          </div>
                        </div>
                      )
                    }
                  />
                </TabPanel>
              </TabView>
            </div>

            <div className="action flex justify-end mt-[25px] border-t border-gray-200 py-[18px]">
              <ButtonCustom
                className="w-[140px]"
                type="button"
                severity="secondary"
                onClick={() => navigate(PATH.lesson)}
              >
                {t('common.form.cancel')}
              </ButtonCustom>
              <ButtonCustom
                className="ml-2 w-[140px]"
                type="submit"
                loading={loadingGlobal}
              >
                {t('common.form.save')}
              </ButtonCustom>
            </div>
          </form>
        )}

        <DialogCustom
          header={t('lesson.confirm.delete_header')}
          visible={dialogVisible}
          onHide={() => setDialogVisible(false)}
          footer={
            <div className="field">
              <div className="flex justify-end">
                <ButtonCustom
                  type="button"
                  className="cancle-button p-button-sm mr-2"
                  severity="secondary"
                  onClick={() => setDialogVisible(false)}
                >
                  {t('common.dialog.cancel')}
                </ButtonCustom>
                <ButtonCustom
                  type="button"
                  onClick={() => handleDeleteStudent(selectedStudent)}
                >
                  {t('common.dialog.ok')}
                </ButtonCustom>
              </div>
            </div>
          }
        >
          {t('lesson.confirm.delete_body')}
        </DialogCustom>

        <PreviewVideoDialog
          header="動画閲覧"
          light={selectedVideo?.thumbnail_path}
          url={selectedVideo?.video_path}
          visible={!!(selectedVideo && selectedVideo?.thumbnail_path)}
          onHide={() => setSelectedVideo({} as VideoDataType)}
        />
      </FormProvider>
    </>
  );
};

export default LessonForm;
