/* eslint @typescript-eslint/no-explicit-any: 0 */
import cx from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import { type FocusEvent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { InputOtp, InputOtpChangeEvent } from 'primereact/inputotp';

type Props = {
  id?: string;
  name: string;
  label?: string;
  defaultValue?: number | string | null;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  requiredFlag?: boolean;
  fullWidthFlag?: boolean;
  wrapClass?: string;
  labelClass?: string;
  value?: number | null;
  disabled?: boolean;
  isLoading?: boolean;
  inputStyle?: React.CSSProperties;
  onChange?: (e: InputOtpChangeEvent) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  length?: number;
};

export const InputOtpCustomForm: React.FC<Props> = ({
  name,
  label,
  placeholder,
  requiredFlag,
  wrapClass,
  labelClass,
  disabled,
  inputStyle,
  value = null,
  isLoading,
  onChange,
  onBlur,
  errorMessage,
  id,
  length,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    control,
    name,
  });

  return (
    <>
      <div className={cx(wrapClass, 'input input-otp__wrapper')}>
        {label && (
          <div className={cx('label-custom', labelClass)}>
            <label>{label}</label>
            {requiredFlag && (
              <span className={cx('p-error', 'required')}> *</span>
            )}
          </div>
        )}
        {isLoading ? (
          <Skeleton />
        ) : (
          <div className="relative">
            <InputOtp
              autoComplete="new-password"
              id={id ?? field?.name ?? ''}
              placeholder={placeholder}
              style={inputStyle}
              {...field}
              name={name}
              value={value ?? field?.value}
              disabled={disabled}
              onChange={(e: InputOtpChangeEvent) => {
                field?.onChange(e.value);
                onChange?.(e);
              }}
              onBlur={(e: FocusEvent<HTMLInputElement, Element>) => onBlur?.(e)}
              length={length}
            />
          </div>
        )}

        {!!errors && (errors[name]?.message || errorMessage) && (
          <div
            className={cx('error-message', 'p-error mt-2', {
              ['is-label']: label,
            })}
          >
            {errors[name]?.message?.toString() || errorMessage}
          </div>
        )}
      </div>
    </>
  );
};
