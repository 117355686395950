const user = {
  title: 'アートシフトコーチ情報編集',
  edit_title: '受講生情報編集',
  new_student: '受講生の新規追加',
  new_coach: 'アートシフトコーチの新規追加',
  edit_student: '受講生情報編集',
  edit_coach: 'アートシフトコーチ情報編集',
  status: 'ステータス',
  active: '有効',
  in_active: '無効',
  avatar: 'アバター',
  email: 'メールアドレス',
  email_teacher: 'メールアドレス',
  phone_number: '電話番号',
  password: 'パスワード',
  password_confirm: '確認パスワード',
  mentor: 'アートシフトコーチ',
  graduation_terms: '卒業期',
  kanji_name: '氏名（漢字）',
  first_name_kanji: '名',
  last_name_kanji: '姓',
  first_name_kanji_placeholder: '太郎',
  last_name_kanji_placeholder: '田中',
  name: '氏名（フリガナ）',
  first_name_placeholder: 'タロウ',
  last_name_placeholder: 'タナカ',
  first_name: 'メイ',
  last_name: 'セイ',
  date_of_birth: '生年月日',
  gender: '性別',
  time_zone: 'タイムゾーン ',
  post_code: '郵便番号',
  address: '住所',
  line_error: 'ライン内でエラーが発生しました',
  student_fail: '受講生を追加できませんでした。',
  coach_fail: 'アートシフトコーチを追加できませんでした。',
  student_success: '受講生を追加しました。',
  coach_success: 'アートシフトコーチを追加しました。',
  update_success: '更新に成功しました。',
  update_coach_success: 'アートシフトコーチのプロフィールを更新しました。',
  update_student_success: '受講生を更新しました。',
  password_hint: "パスワードには「半角英数字(大文字・小文字・数字)」「半角記号（!\"#$%&'()*+,-.\\/|:;<=>?@[]^_`{}~）」を含む8～32字で設定してください。",
  postcode_hint: '郵便番号には〒マーク(〒)、やハイフン(-)の入力は必要ありません。',
  phone_hint: '電話番号には数字のみ入力してください。\nハイフン(-)の入力は必要ありません。',
  student_limit: '受講生追加可能人数',
  student_limit_min: '受講生追加可能人数は10以上で入力してください。',
  student_limit_max: '受講生追加可能人数は1000以内で入力してください。',
};

export default user;
