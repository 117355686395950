export const INVITE_STATUS = {
  PENDING: 0,
  ACCEPT: 1,
  REMOVED: 2,
};

export const LIMIT_SHOW_IMG = 3;

export const LIMIT_IMG = 10;

export const MESSAGE_STATUS = {
  SEEN: 'seen',
  SENT: 'sent',
};

export const MESSAGE_TYPE = {
  FILE: 1,
  TEXT: 0,
  SYSTEM: -1,
};

export const DELETE_MESSAGE_TYPE = {
  UNSENT: 'unsent',
  DELETE: 'delete',
};

export const IMG_DOMAIN = process.env.REACT_APP_BASE_IMG_HOST;

export const NOTIFICATION_TYPE = {
  NEW_MESSAGE: 'new_message',
  NEW_MEMBER: 'new_member',
}
