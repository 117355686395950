import * as yup from 'yup';

import { BaseYup, MAX_INPUT } from '@/config/baseYup';

export const progressSearchFormSchema = BaseYup.object({
  keyword: BaseYup.string().max(MAX_INPUT),
});

export type ProgressFormSearchType = yup.InferType<
  typeof progressSearchFormSchema
>;
