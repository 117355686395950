import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

interface ILoadingContext {
  loadingGlobal: boolean;
  setLoadingGlobal: Dispatch<SetStateAction<boolean>>;
}

const LoadingContext = createContext<ILoadingContext>({
  loadingGlobal: false,
  setLoadingGlobal: () => {},
});

export function LoadingProvider({ children }: { children: any }) {
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const value = { loadingGlobal, setLoadingGlobal };
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return context;
}
