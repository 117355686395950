import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { apiRequest } from '@/services';
import { CustomDataTable } from '@/components/common/ui/table/CustomDataTable';
import { Pagination } from '@/types/pagination';
import { useTranslation } from '@/config/i18n';
import useSearch from '@/hooks/useSearch';
import usePagination from '@/hooks/usePagination';
import { useLoading } from '@/components/common/loader/LoadingContext';
import { ProgressDataType, ProgressResponseType } from '@/types/progress';
import { ToastContext } from '@/components/common/CommonToast';
import { toastErrorMessage } from '@/lib/utils';
import { ProgressStudentColumns } from '../table/ProgressStudentColumns';
import { STUDENT } from '@/shared/constants/progress';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@/shared/constants/pagination';

interface IProgressTableProps {
  setIsOpenCommentModal: (value: boolean) => void;
  setIsOpenWorkModal: (value: boolean) => void;
  setRowData: (value: ProgressDataType) => void;
  setUnitId: (value: number) => void;
}

const StudentProgress: React.FC<IProgressTableProps> = ({
  setRowData,
  setIsOpenCommentModal,
  setIsOpenWorkModal,
  setUnitId,
}) => {
  const { id } = useParams<{ id: string | undefined }>();
  const [t] = useTranslation('');
  const { apiService } = apiRequest();
  const { toast } = useContext(ToastContext);
  const [studentProgress, setStudentProgress] = useState<ProgressResponseType>({
    data: [],
    pagination: null,
  });
  const { searchParams } = useSearch();
  const { query } = Object.fromEntries(searchParams.entries());
  const customQuery = query
    ? query
    : `limit=${DEFAULT_PER_PAGE}&paginate=${DEFAULT_PAGE}`;
  const { paginationComputed, setPagination } = usePagination(
    studentProgress.pagination,
  );
  const { paginate, limit } = paginationComputed;
  const { setLoadingGlobal } = useLoading();

  const getStudentProgress = async ({
    id,
    type,
  }: {
    id: number | string;
    type: typeof STUDENT;
  }) => {
    setLoadingGlobal(true);
    const { data: studentProgressNew, error } =
      await apiService.lessons.collection(
        `${id}/progress?type=${type}&${customQuery}`,
      );
    if (studentProgressNew) {
      setLoadingGlobal(false);
      setStudentProgress(studentProgressNew);
    }
    if (error) {
      setLoadingGlobal(false);
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
    }
  };

  const onPaginationChanged = (paginationState: Pagination) => {
    setPagination(paginationState);
  };

  useEffect(() => {
    if (!id) return;
    getStudentProgress({ id: id, type: STUDENT });
  }, [query]);

  return (
    <CustomDataTable
      wrapClassName="mt-[20px]"
      values={studentProgress?.data}
      colums={ProgressStudentColumns(
        t,
        studentProgress?.data,
        setIsOpenCommentModal,
        setIsOpenWorkModal,
        setRowData,
        setUnitId,
        paginate,
        limit,
        studentProgress.pagination?.total_item || 0,
      )}
      scrollable
      pagination={paginationComputed}
      onPaginationChanged={onPaginationChanged}
    />
  );
};

export default StudentProgress;
