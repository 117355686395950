const menu = {
  "coach_management": "アートシフトコーチ管理",
  "student_management": "受講生管理",
  "lesson": "講座管理",
  "setting": "設定",
  "version_management": "公開バージョン",
  "chat": "トーク",
  "manual": "マニュアル"
}

export default menu;