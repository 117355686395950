import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { DropResult } from 'react-beautiful-dnd';
import { components } from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';

import { randomUUID, toastErrorMessage, toastMessage } from '@/lib/utils';
import { useTranslation } from '@/config/i18n';
import ButtonCustom from '@/components/common/ui/button';
import DialogCustom from '@/components/common/ui/dialog';
import {
  addNewQuestionLessonDaftSchema,
  addNewQuestionLessonPublicSchema,
} from '@/shared/schema/question';
import InfinitySelect from '@/components/common/ui/select';
import { TextareaCustomForm } from '@/components/common/ui/textarea';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import {
  IconCheckboxEmpty,
  IconCheckboxChecked,
  IconRadioChecked,
  IconRadioEmpty,
  IconDrag,
} from '@/components/icons';
import { CustomDroppable } from '@/components/common/CustomDroppable';
import { OptionType } from '@/types/api';
import { MAX_LENGTH } from '@/shared/constants';
import { ToastContext } from '@/components/common/CommonToast';
import { apiRequest } from '@/services';
import { handleFormatQuestion } from './utils';
import { ADD_VIDEO_TEMPORARY } from '@/shared/constants/video';
import { ADD_QUESTION_TEMPORARY, NO_PUBLIC_QUESTION } from '@/shared/constants';

interface IAddQuestionFormProps {
  visible?: boolean;
  onHide: () => void;
  onSubmitQuestion: (question: any) => void;
  id?: string | number;
  question?: any;
  unitId?: string | number;
  mapToResults: (
    options: any[],
    dataResult: any[] | any,
    type: string,
  ) => { name: string; is_correct: boolean }[];
  setQuestions: Dispatch<SetStateAction<any[]>>;
  isDisableDraftBtn: boolean;
  isDisabledEdit?: boolean;
}
type OptionSelect = OptionType & { char: string };
const NUM_OF_QUESTION = 5;
const alphabet = 'ABCDE'.split('');

const AddQuestionForm = ({
  id,
  question,
  visible,
  onHide,
  onSubmitQuestion,
  mapToResults,
  unitId,
  setQuestions,
  isDisableDraftBtn,
  isDisabledEdit,
}: IAddQuestionFormProps) => {
  const [t] = useTranslation('');
  const formMethods = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      options: [{ char: 'A', value: '' }],
      question: '',
      type: { value: 'radio', label: t('lesson_question.type.values.radio') },
      result: null,
      note_for_correct: '',
    },
    resolver: yupResolver(addNewQuestionLessonPublicSchema),
  });
  const { apiService } = apiRequest();
  const { toast } = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    watch,
    setValue,
    reset,
    getValues,
    setError,
    formState: { errors },
  } = formMethods;
  const { fields, append, remove, move } = useFieldArray({
    name: 'options',
    control,
  });
  const addField = () => {
    if (fields.length < alphabet.length) {
      append({ char: alphabet[fields.length], value: '' });
    }
  };
  const options = watch('options') || [];
  const type = watch('type');
  const result =
    type?.value === 'checkbox'
      ? watch('result') || []
      : watch('result') || null;
  const isCheckboxType = (type: any) => type?.value === 'checkbox';
  const handleChangeType = (selectedOptions: OptionType) => {
    if (selectedOptions.value !== type.value) {
      setValue('result', [], { shouldValidate: true });
    }
  };
  const defaultOption = options
    .filter((item: OptionSelect) => item.char)
    .map((item: OptionSelect) => ({
      value: item.value,
      label: item.char,
    }));

  const CustomSelectInputComponent = (props: any) => {
    return (
      <div className="flex w-full">
        <div className="w-1/6">
          {props.data.value === 'checkbox' ? (
            <IconCheckboxChecked />
          ) : (
            <IconRadioChecked />
          )}
        </div>
        <div className="ml-2">{props.data.label}</div>
      </div>
    );
  };

  const CustomInput = (props: any) => {
    return (
      <components.Input
        {...props}
        style={{ pointerEvents: 'none', cursor: 'default' }}
      />
    );
  };

  const CustomSelectOptionComponent = (props: any) => {
    return (
      <div className="flex">
        <div className="w-[30px] flex items-center">
          {props.value === 'checkbox' ? (
            <IconCheckboxChecked />
          ) : (
            <IconRadioChecked />
          )}
        </div>
        <span className="ml-2">{props.label}</span>
      </div>
    );
  };
  const showToast = (status: string, isEdit: boolean) => {
    const successMessageKey = isEdit
      ? status === 'active'
        ? 'lesson.edit_success'
        : 'lesson.edit_success'
      : status === 'active'
        ? 'lesson.active_success'
        : 'lesson.inactive_success';

    toast?.current?.show(toastMessage(t(successMessageKey)));
  };

  const getErrorMessageKey = (error: any, isEdit: boolean) => {
    const { errors } = error;

    const errorPublicQuestion = errors.find(
      (item: any) => item.error_code === NO_PUBLIC_QUESTION,
    );
    if (isEdit) {
      return errorPublicQuestion
        ? 'lesson_video.validate.edit_draft_question'
        : 'lesson.edit_fail';
    }
    return 'lesson.create_fail';
  };

  const handleApiResponse = async (
    apiCall: () => Promise<{ data: any; error: any }>,
    status: string,
    isEdit: boolean,
  ) => {
    setIsLoading(true);
    const { data, error } = await apiCall();
    if (error) {
      const errorMessageKey = getErrorMessageKey(error, isEdit);
      toast?.current?.show(toastErrorMessage(t(errorMessageKey)));
      setIsLoading(false);
      return;
    }
    if (data) {
      showToast(status, isEdit);
      const { data } = await apiService.lessonQuestion.index({
        unit_id: unitId || 0,
      });

      const newQuestions = handleFormatQuestion(data?.data as any, t);
      setQuestions(newQuestions as any);
      setIsLoading(false);
    }
    onHide();
  };

  const submitQuestion = async (data: any, status: string, isEdit: boolean) => {
    const payload = {
      unit_id: Number(unitId),
      name: data.question,
      type: data.type.value,
      status: status,
      correct_note: data.note_for_correct,
      options: mapToResults(data.options, data.result, data.type.value),
    };
    formMethods.clearErrors();
    if (isEdit) {
      await handleApiResponse(
        () =>
          apiService.lessonQuestion.collection(`${id}`, {
            method: 'PUT',
            body: payload,
          }),
        status,
        true,
      );
    } else {
      await handleApiResponse(
        () => apiService.lessonQuestion.create(payload),
        status,
        false,
      );
    }
  };
  const onSubmit = async (status: 'active' | 'inactive') => {
    const formData = getValues();
    const handleSetError = (error: any) => {
      formMethods.clearErrors();
      error.inner?.forEach(
        ({ path, errors }: { path: string; errors: string[] }) => {
          setError(path, { type: 'validation', message: errors[0] });
        },
      );
    };
    try {
      const schema =
        status === 'active'
          ? addNewQuestionLessonPublicSchema
          : addNewQuestionLessonDaftSchema;
      await schema.validate(formData, { abortEarly: false });
      const hasNewUnits = String(unitId).includes(ADD_VIDEO_TEMPORARY);
      const submitData = {
        id: id || `${randomUUID()}_${ADD_QUESTION_TEMPORARY}`,
        ...formData,
        status,
      };
      if (unitId && !hasNewUnits) {
        await submitQuestion(formData, status, !!id);
      } else {
        onSubmitQuestion(submitData);
      }
    } catch (error: any) {
      handleSetError(error);
    }
  };

  const onSubmitDraft = () => onSubmit('inactive');
  const onSubmitPublic = () => onSubmit('active');

  const getAllFieldNames = () => [
    'type',
    'question',
    'options',
    'result',
    'note_for_correct',
  ];

  const setDefaultValue = (data: any) => {
    getAllFieldNames().forEach((field) => {
      if (data[field]) {
        setValue(field, data[field]);
      }
    });
  };

  const onInteractOption = () => {
    const updatedOptions = getValues('options');
    updatedOptions.forEach((option: OptionSelect, i: number) => {
      const char = alphabet[i] || '';
      option.char = char;
    });
    setValue('options', updatedOptions);
    setValue('result', null, { shouldValidate: true });
    formMethods.clearErrors('options');
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (source && destination) {
      move(source.index, destination.index);
    }
    onInteractOption();
  };

  const handleRemoveOption = (index: number) => {
    remove(index);
    onInteractOption();
  };

  const onInputOptionChange = () => {
    addNewQuestionLessonPublicSchema
      .validateAt('options', { options }, { abortEarly: false })
      .then(() => {
        formMethods.clearErrors('options');
      })
      .catch((err) => {
        formMethods.clearErrors('options');
        err.inner?.forEach(
          ({ path, errors }: { path: string; errors: string[] }) => {
            setError(path, { type: 'validation', message: errors[0] });
          },
        );
      });
  };

  useEffect(() => {
    formMethods.clearErrors();
    if (visible) {
      question ? setDefaultValue(question) : !id && reset();
    }
  }, [visible, id, question]);

  return (
    <FormProvider {...formMethods}>
      <DialogCustom
        visible={visible}
        onHide={onHide}
        header={t('lesson_question.header')}
        contentClassName="border-top border-bottom py-[14px] w-[816px] px-6"
        blockScroll
        footer={
          <div>
            <div className="flex-full-center gap-3">
              <ButtonCustom
                type="button"
                severity="secondary"
                className="flex-full-center w-full font-medium text-[16px]"
                onClick={onHide}
              >
                {t('lesson_question.button.cancel')}
              </ButtonCustom>
              {!isDisabledEdit && (
                <>
                  <ButtonCustom
                    type="button"
                    onClick={onSubmitDraft}
                    loading={isLoading}
                    className="flex-full-center w-full font-medium text-[16px]"
                    disabled={
                      isDisableDraftBtn && !!id && question?.status === 'active'
                    }
                  >
                    {t('lesson_question.button.save_to_draft')}
                  </ButtonCustom>
                  <ButtonCustom
                    type="button"
                    onClick={onSubmitPublic}
                    loading={isLoading}
                    className="flex-full-center w-full font-medium text-[16px]"
                  >
                    {t('lesson_question.button.add')}
                  </ButtonCustom>
                </>
              )}
            </div>
          </div>
        }
      >
        <form className="bg-white">
          <div className="flex flex-col gap-3">
            <div className="form-item flex mt-2 justify-between">
              <div>
                <div className="label w-56 flex">
                  <span>{t('lesson_question.question.title')}</span>
                  <span className="text-error-500 ml-1">*</span>
                </div>
                <TextareaCustomForm
                  name="question"
                  wrapClass="w-[512px] mt-2"
                  maxLength={MAX_LENGTH.QUESTION}
                  placeholder={t('lesson_question.question.placeholder')}
                  rows={1}
                  autoResize={true}
                  inputStyle={{ resize: 'none' }}
                  disabled={isDisabledEdit}
                />
              </div>
              <div className="ml-6">
                <div className="label w-56 flex">
                  <span>{t('lesson_question.type.title')}</span>
                  <span className="text-error-500 ml-1">*</span>
                </div>
                <InfinitySelect
                  name="type"
                  isClearable={false}
                  wrapClass="w-[232px]"
                  className="mt-2 h-[44px] text-[16px] w-[232px]"
                  onChange={handleChangeType}
                  defaultOption={[
                    {
                      value: 'checkbox',
                      label: t('lesson_question.type.values.checkbox'),
                    },
                    {
                      value: 'radio',
                      label: t('lesson_question.type.values.radio'),
                    },
                  ]}
                  placeholder={t('common.form.placeholder.select')}
                  styles={{
                    control: (baseStyles: any) => ({
                      ...baseStyles,
                      borderColor: '#000',
                      padding: '2px 5px !important',
                    }),
                    option: (baseStyles: any) => ({
                      ...baseStyles,
                      padding: '8px',
                    }),
                  }}
                  components={{
                    CustomSelectInputComponent,
                    Input: CustomInput,
                  }}
                  getOptionLabel={(option: any) =>
                    CustomSelectOptionComponent(option)
                  }
                  isDisabled={isDisabledEdit}
                />
              </div>
            </div>
            <div className="border-b boder-b-gray-300 pb-8">
              <div className="form-item flex flex-col">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <CustomDroppable droppableId="droppable" type="group">
                    {(provided) => (
                      <div ref={provided.innerRef}>
                        {fields.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                            isDragDisabled={isDisabledEdit}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="input-item flex mb-2 items-center relative"
                              >
                                <div className="absolute -left-[16px]">
                                  <IconDrag />
                                </div>
                                <div className="w-fit">
                                  {isCheckboxType(type) ? (
                                    <IconCheckboxEmpty />
                                  ) : (
                                    <IconRadioEmpty />
                                  )}
                                </div>
                                <span className="ml-4">{`${alphabet[index]}.`}</span>
                                <InputTextCustomForm
                                  name={`options.${index}.value`}
                                  errorMessage={
                                    (errors.options as any)?.[index]?.value
                                      .message
                                  }
                                  placeholder={`${t('lesson_question.option_input.placeholder')} ${index + 1}`}
                                  wrapClass="ml-4 w-full"
                                  className="focus:!border-b focus:!border-b-primary-500 outline-none !border-0 !rounded-none !px-0"
                                  maxLength={MAX_LENGTH.OPTION}
                                  onChange={onInputOptionChange}
                                  disabled={isDisabledEdit}
                                />
                                {!isDisabledEdit && options.length > 1 && (
                                  <button
                                    type="button"
                                    onClick={() => handleRemoveOption(index)}
                                    className="remove-button"
                                  >
                                    <i className="pi pi-times text-black ml-3" />
                                  </button>
                                )}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </CustomDroppable>
                </DragDropContext>
              </div>
              {!isDisabledEdit && options.length < NUM_OF_QUESTION && (
                <>
                  <button
                    type="button"
                    onClick={addField}
                    className="flex items-center input-item mt-1"
                  >
                    {isCheckboxType(type) ? (
                      <IconCheckboxEmpty />
                    ) : (
                      <IconRadioEmpty />
                    )}
                    <span className="text-[#2E90FA] ml-4">
                      {t('lesson_question.option_input.add_new_option_title')}
                    </span>
                  </button>
                </>
              )}
              {errors?.options && (
                <p className="text-error-600 mt-2">
                  {(errors?.options as any)?.message}
                </p>
              )}
            </div>

            <div className="form-item mt-4">
              <div className="label w-56 flex">
                <span>{t('lesson_question.result.title')}</span>
                <span className="text-error-500 ml-1">*</span>
              </div>
              <div className="w-full mt-2">
                <InfinitySelect
                  name="result"
                  placeholder={t('common.form.placeholder.select')}
                  wrapClass="w-full"
                  isClearable={false}
                  defaultOption={defaultOption || []}
                  value={result}
                  getOptionValue={(option: any) => option?.label}
                  styles={{
                    control: (baseStyles: any) => ({
                      ...baseStyles,
                      padding: '2px 5px',
                    }),
                    multiValue: (baseStyles: any) => ({
                      ...baseStyles,
                      backgroundColor: '#f5faff',
                      borderRadius: '4px',
                      border: '1px solid #c9e8ff',
                    }),
                    multiValueLabel: (baseStyles: any) => ({
                      ...baseStyles,
                      color: '#175CD3',
                    }),
                    multiValueRemove: (baseStyles: any) => ({
                      ...baseStyles,
                      color: '#175CD3',
                      ':hover': {
                        backgroundColor: '#d1e9ff',
                      },
                    }),
                  }}
                  isMulti={isCheckboxType(type)}
                  isDisabled={isDisabledEdit}
                />
              </div>
            </div>

            <div className="form-item mt-4">
              <div className="label w-56 flex">
                <span>{t('lesson_question.note_for_correct.title')}</span>
                <span className="text-error-500 ml-1">*</span>
              </div>
              <div className="w-full mt-2">
                <TextareaCustomForm
                  name="note_for_correct"
                  wrapClass="w-full"
                  placeholder={t(
                    'lesson_question.note_for_correct.placeholder',
                  )}
                  rows={1}
                  autoResize={true}
                  inputStyle={{ resize: 'none' }}
                  maxLength={MAX_LENGTH.NOTE_FOR_CORRECT}
                  disabled={isDisabledEdit}
                />
              </div>
            </div>
          </div>
        </form>
      </DialogCustom>
    </FormProvider>
  );
};

export default AddQuestionForm;
