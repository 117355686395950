import React from 'react';
import { useTranslation } from 'react-i18next';

import Logo from '@/components/common/logo';
import ResetPasswordForm from './components/resetPasswordForm';

const ResetPasswordPage: React.FC = () => {
  const [t] = useTranslation('');
  return (
    <div className="flex-full-center min-h-screen bg-primary-900">
      <div className="w-[440px] my-[50px]">
        <div className="flex-full-center">
          <Logo />
        </div>
        <h2 className="title text-white text-[30px] leading-[38px] font-bold flex-full-center mt-[24px] mb-[12px]">
          {t('reset_password.title')}
        </h2>
        <ResetPasswordForm />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
