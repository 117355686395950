import axiosRequest, { AxiosOption } from '@/lib/axiosCustom';
import RestService from './RestService';

export default class AuthService extends RestService {
  logout() {
    return axiosRequest('logout', {
      method: 'POST',
      ...this.options,
    });
  }

  getMe(extras: AxiosOption = {}) {
    return axiosRequest('me', {
      method: 'GET',
      ...this.options,
      ...extras,
    });
  }
}
