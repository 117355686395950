import { Button } from 'primereact/button';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { InputSwitch } from 'primereact/inputswitch';
import { Tooltip } from 'primereact/tooltip';

import { ColumnDescription } from '@/components/common/ui/table/ColumnDescription';

export const UserColumns = (
  t: any,
  onEditUser?: (id: number) => void,
  onEditStatusUser?: (isActive: boolean, rowData: any) => void,
): ColumnDescription[] => [
  {
    dataField: 'full_name_kanji',
    text: t('user_list.full_name'),
    classname: 'w-[200px]',
    formatter: (rowData: any) => {
      return (
        <div>
          <Tooltip target={`.tooltip-name-${rowData.id}`}>
            {rowData.full_name_kanji}
          </Tooltip>
          <div
            className={classNames(
              'line-clamp-1 text-gray-900 break-all',
              `tooltip-name-${rowData.id}`,
            )}
            data-pr-position="top"
          >
            {rowData.full_name_kanji}
          </div>
        </div>
      );
    },
  },
  {
    dataField: 'email',
    text: t('user_list.email'),
    classname: 'w-[300px]',
    formatter: (rowData: any) => {
      return (
        <div>
          <Tooltip target={`.tooltip-email-${rowData.id}`}>
            {rowData.email}
          </Tooltip>
          <div
            className={classNames(
              'line-clamp-1 text-gray-900 break-all',
              `tooltip-email-${rowData.id}`,
            )}
            data-pr-position="top"
          >
            {rowData.email}
          </div>
        </div>
      );
    },
  },
  {
    dataField: 'mentor',
    text: t('user_list.mentor'),
    classname: 'w-[300px]',
    formatter: (rowData: any) => {
      return (
        <div>
          <Tooltip target={`.tooltip-email-mentor-${rowData.id}`}>
            {rowData.mentor ? rowData.mentor.email : ''}
          </Tooltip>
          <div
            className={classNames(
              'line-clamp-1 text-gray-900 break-all',
              `tooltip-email-mentor-${rowData.id}`,
            )}
            data-pr-position="top"
          >
            {rowData.mentor ? rowData.mentor.email : ''}
          </div>
        </div>
      );
    },
  },
  {
    dataField: 'created_at',
    text: t('user_list.updated_at'),
    classname: 'w-[200px]',
    sort: true,
    formatter: (rowData) => (
      <div>
        {rowData.created_at
          ? dayjs(rowData.created_at.toString()).format('YYYY/MM/DD')
          : ''}
      </div>
    ),
  },
  {
    dataField: 'action',
    text: '',
    sort: false,
    classname: 'text-center [&>div]:justify-center w-[160px]',
    headerClassname: '[&>div]:justify-center',
    formatter: (rowData) => (
      <div className="flex flex-row h-[36px] items-center">
        <InputSwitch
          checked={rowData.status === 'active' ? true : false}
          onChange={(e) => onEditStatusUser?.(e.value, rowData)}
        />
        <Button
          className="mx-3 p-[10px]"
          onClick={() => onEditUser?.(rowData.id)}
        >
          <span className="pi pi-pencil" />
        </Button>
      </div>
    ),
  },
];
