/* eslint @typescript-eslint/no-explicit-any: 0 */
import cx from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import { type ChangeEvent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { RadioButton } from 'primereact/radiobutton';

import { useTranslation } from '@/config/i18n';

type Props = {
  id?: string;
  name: string;
  label?: string;
  defaultValue?: number | string | null;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  requiredFlag?: boolean;
  fullWidthFlag?: boolean;
  wrapClass?: string;
  value?: number | null;
  disabled?: boolean;
  isLoading?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  options: any[];
  radioItemClassName?: string;
  radioGroupClassName?: string;
};

export const RadioButtonCustomForm: React.FC<Props> = ({
  name,
  label,
  requiredFlag,
  wrapClass,
  isLoading,
  onChange,
  errorMessage,
  options,
  radioItemClassName,
  radioGroupClassName,
}) => {
  const [t] = useTranslation('');

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    control,
    name,
  });

  return (
    <>
      <div className={cx(wrapClass, 'input')}>
        {label && (
          <div className="label-custom">
            <label>{label}</label>
            {requiredFlag && (
              <span className={cx('p-error', 'required')}>
                {t('common.form.required')}
              </span>
            )}
          </div>
        )}
        {isLoading ? (
          <Skeleton />
        ) : (
          <div className={cx(radioGroupClassName)}>
            {options?.map((option) => {
              return (
                <div
                  key={option.value}
                  className={cx('flex items-center', radioItemClassName)}
                >
                  <RadioButton
                    inputId={option.value}
                    name={name}
                    value={option.value}
                    onChange={(e: any) => {
                      field?.onChange(e.target.value);
                      onChange?.(e);
                    }}
                    checked={option.value === field.value}
                  />
                  <label htmlFor={option.value} className="ml-2">
                    {option.label}
                  </label>
                </div>
              );
            })}
          </div>
        )}
        {!!errors && (errors[name]?.message || errorMessage) && (
          <div
            className={cx('error-message', 'p-error mt-2', {
              ['is-label']: label,
            })}
          >
            {errors[name]?.message?.toString() || errorMessage}
          </div>
        )}
      </div>
    </>
  );
};
