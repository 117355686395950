import { useEffect } from 'react';
import usePathName from './usePathName';

const useScrollToTop = () => {
  const pathname = usePathName();

  const scrollToTop = async () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  useEffect(() => {
    scrollToTop();
  }, [pathname]);
};

export default useScrollToTop;