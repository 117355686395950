const lesson_list = {
  title: '講座管理',
  new: '講座追加',
  name: '講座',
  add_unit: '講座追加/ユニット追加',
  edit_unit: '講座編集/ユニット編集',
  mentor_id_placeholder: 'アートシフトコーチ名を入力',
};

export default lesson_list;
