import cx from 'classnames';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FileUpload, FileUploadProps } from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';
import { Avatar } from 'primereact/avatar';
import { FieldErrors, FieldValues } from 'react-hook-form';

import {
  fileToObject,
  getVideoCover,
  toastErrorMessage,
  toastMessage,
} from '@/lib/utils';
import { useTranslation } from '@/config/i18n';
import { ToastContext } from '../../CommonToast';
import { apiRequest } from '@/services';

interface Props extends FileUploadProps {
  defaultUrl?: string;
  idChooseBtn: string;
  handleUploaded?: ({
    video_id,
    thumbnail_id,
    currentFile,
    video_url,
  }: {
    video_id: string | number;
    thumbnail_id: string | number;
    currentFile: any;
    video_url: string;
  }) => void;
  errors?: FieldErrors<FieldValues>;
  errorMessage?: string;
  acceptedTypes?: string[];
  handleSetUploading?: (name: string, value: boolean) => void;
  textUpload?: string;
  textSubUpload?: string;
}

export default function UploadVideo({
  defaultUrl,
  idChooseBtn,
  handleUploaded,
  errors,
  errorMessage,
  acceptedTypes,
  handleSetUploading,
  textUpload,
  textSubUpload,
  ...rest
}: Props) {
  const { apiService } = apiRequest();
  const [t] = useTranslation('');
  const { toast } = useContext(ToastContext);
  const [isUploading, setIsUploading] = useState(false);
  const [currentImage, setCurrentImage] = useState(defaultUrl);
  const [thumbnailId, setThumbnailId] = useState('');
  const [videoId, setVideoId] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [currentFile, setCurrentFile] = useState<any>();
  const fileUploadRef = useRef(null);

  useEffect(() => {
    if (!thumbnailId || !videoId || !currentFile || !videoUrl) return;

    handleUploaded?.({
      video_id: videoId,
      thumbnail_id: thumbnailId,
      currentFile,
      video_url: videoUrl,
    });
  }, [thumbnailId, videoId, currentFile]);

  const onFileSelect = async (e: any) => {
    const file = e.files?.[0];

    if (!file) {
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
      return;
    }

    if (acceptedTypes && !acceptedTypes.includes(file.type)) {
      if (acceptedTypes.includes('video/mp4')) {
        toast?.current?.show(toastErrorMessage(t('common.file.format_mp4')));
      } else {
        toast?.current?.show(
          toastErrorMessage(
            'Please upload the video file in the correct format.',
          ),
        );
      }
      return;
    }

    setIsUploading(true);
    handleSetUploading?.(rest?.name || '', true);

    try {
      const fileFromVideo = await getVideoCover(file, 1);
      await uploadImageThumbnailToServer(fileFromVideo.file);
      await uploadVideoToServer(file);
      setCurrentImage(fileFromVideo.url);
      setVideoUrl(URL.createObjectURL(file));
      const newFile = fileToObject(fileFromVideo.file);
      setCurrentFile({
        ...newFile,
        video_duration: fileFromVideo.video_duration,
      });
      toast?.current?.show(toastMessage(t('common.toast.import_video_image_success')));
    } catch {
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
    } finally {
      setIsUploading(false);
      handleSetUploading?.(rest?.name || '', false);
    }
  };

  const preLoadImage = async (file: File) => {
    const { data: image } = await apiService.files.collection(
      'pre-signed-url',
      {
        method: 'POST',
        body: {
          name: file.name,
          original_name: file.name,
          mime_type: file.type,
          content_length: file.size,
        },
      },
    );

    return image;
  };

  const uploadImageThumbnailToServer = async (file: any) => {
    setIsUploading(true);
    handleSetUploading?.(rest?.name || '', true);
    setCurrentImage('');
    const image = await preLoadImage(file);
    if (!image?.data) {
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
      setIsUploading(false);
      return;
    }

    const { data } = image;
    const formData = new FormData();

    Object.entries(data.formInputs).forEach(([key, value]) => {
      formData.append(key, value as string);
    });
    formData.append('file', file);
    try {
      const imageFromS3 = await fetch(data.formAttributes.action, {
        method: 'POST',
        body: formData,
      });
      if (!imageFromS3.ok) {
        setIsUploading(false);
        throw 'your file have a problem';
      }

      setThumbnailId(data.id);
    } catch {
      // do nothing
    } finally {
      setIsUploading(false);
      handleSetUploading?.(rest?.name || '', false);
    }
  };

  const uploadVideoToServer = async (file: any) => {
    setIsUploading(true);
    handleSetUploading?.(rest?.name || '', true);
    const image = await preLoadImage(file);
    if (!image?.data) {
      toast?.current?.show(toastErrorMessage(t('common.toast.fail')));
      setIsUploading(false);
      return;
    }

    const { data } = image;
    const formData = new FormData();

    Object.entries(data.formInputs).forEach(([key, value]) => {
      formData.append(key, value as string);
    });
    formData.append('file', file);
    try {
      const imageFromS3 = await fetch(data.formAttributes.action, {
        method: 'POST',
        body: formData,
      });
      if (!imageFromS3.ok) {
        throw 'your file have a problem';
      }

      setVideoId(data?.id);
    } catch {
      // do nothing
    } finally {
      setIsUploading(false);
      handleSetUploading?.(rest?.name || '', false);
    }
  };

  const onTemplateUpload = () => {
    // TODO
  };

  const onTemplateClear = () => {};

  const headerTemplate = (options: any) => {
    const { className, chooseButton } = options;
    return (
      <div
        className={cx(
          'left-1/2 transform -translate-x-1/2 top-[15px] absolute z-40 p-0',
          className,
        )}
        style={{
          background: 'transparent',
        }}
      >
        {chooseButton}
      </div>
    );
  };

  const disabledChoose = useMemo(() => {
    return isUploading ? 'disabled-custom' : '';
  }, [isUploading]);

  const chooseOptions = {
    icon: 'pi pi-fw pi-cloud-upload text-gray-600 text-[22px]',
    iconOnly: true,
    className: `choose-button border-gray-50 border-8 bg-gray-100 w-[50px] h-[50px] rounded-full hidden ${disabledChoose} ${idChooseBtn}`,
  };
  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
    className:
      'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined',
  };

  const handleTriggerUpload = () => {
    const el = document.querySelector(`.${idChooseBtn}`) as HTMLElement;
    if (el) {
      el?.click();
    }
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column p-0 text-center">
        <div className="w-[100%]">
          <span
            onClick={() => handleTriggerUpload()}
            className={`text-primary-700 cursor-pointer ${disabledChoose}`}
          >
            { textUpload }
          </span>{' '}
          { textSubUpload }
          <br />
          MP4のみ
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!defaultUrl) return;
    setCurrentImage(defaultUrl);
  }, [defaultUrl]);

  return (
    <div className='w-full'>
      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
      <div className="flex">
        {!currentImage ? (
          <div className="mr-[20px]">
            {!isUploading ? (
              <Avatar
                icon="pi pi-youtube text-[20px]"
                style={{ width: '74px', height: '74px' }}
                className="rounded-[8px]"
              />
            ) : (
              <div className="w-[74px] h-[74px] rounded-[8px] flex-full-center border border-gray-200">
                <i className="pi pi-spin pi-spinner-dotted text-[20px]" />
              </div>
            )}
          </div>
        ) : (
          <div className="h-[74px] w-[74px] mr-[20px]">
            <img
              src={currentImage}
              className="object-cover rounded-[8px] h-[100%] w-[100%]"
            />
          </div>
        )}

        <FileUpload
          {...rest}
          className={cx('upload-video-custom rounded-[8px]', rest.className)}
          ref={fileUploadRef}
          onUpload={onTemplateUpload}
          onSelect={onFileSelect}
          onError={onTemplateClear}
          onClear={onTemplateClear}
          headerTemplate={headerTemplate}
          chooseOptions={chooseOptions}
          cancelOptions={cancelOptions}
          emptyTemplate={emptyTemplate}
          itemTemplate={emptyTemplate}
          contentClassName="bg-transparent"
          accept={acceptedTypes?.[0] || 'video/*'}
        />
      </div>
      {!!errors && (errors[rest?.name || '']?.message || errorMessage) && (
        <div className={cx('error-message', 'p-error mt-2')}>
          {errors[rest?.name || '']?.message?.toString() || errorMessage}
        </div>
      )}
    </div>
  );
}
