/* eslint @typescript-eslint/no-explicit-any: 0 */
import cx from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import {
  type ChangeEvent,
  type FocusEvent,
  type KeyboardEvent,
} from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from '@/config/i18n';
import { InputTextarea } from 'primereact/inputtextarea';

type Props = {
  id?: string;
  name: string;
  label?: string;
  defaultValue?: number | string | null;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  requiredFlag?: boolean;
  fullWidthFlag?: boolean;
  wrapClass?: string;
  value?: number | null;
  disabled?: boolean;
  isLoading?: boolean;
  inputStyle?: React.CSSProperties;
  max?: number;
  locale?: string;
  mode?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  suffix?: string;
  prefix?: string;
  errorMessage?: string;
  maxLength?: number | undefined;
  rows?: number;
  autoResize?: boolean;
};

export const TextareaCustomForm: React.FC<Props> = ({
  name,
  label,
  placeholder,
  className,
  requiredFlag,
  fullWidthFlag,
  wrapClass,
  disabled,
  inputStyle,
  value = null,
  isLoading,
  onChange,
  onBlur,
  onKeyDown,
  errorMessage,
  maxLength,
  id,
  rows,
  autoResize
}) => {
  const [t] = useTranslation('');

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    control,
    name,
  });

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    
    if (!maxLength || value.length <= maxLength) {
      field?.onChange(value);
      onChange?.(e);
    }
  };

  return (
    <>
      <div className={cx(wrapClass, 'input')}>
        {label && (
          <div className="label-custom">
            <label>{label}</label>
            {requiredFlag && (
              <span className={cx('p-error', 'required')}>
                {t('common.form.required')}
              </span>
            )}
          </div>
        )}
        {isLoading ? (
          <Skeleton />
        ) : (
          <div className="relative">
            <InputTextarea
              id={id ?? field?.name ?? ''}
              placeholder={placeholder}
              className={cx('input-custom', className, {
                ['p-invalid']: !!errors && errors[name],
                ['w-full']: fullWidthFlag,
              })}
              style={inputStyle}
              {...field}
              name={name}
              value={value ?? field?.value}
              disabled={disabled}
              onChange={handleChange}
              onBlur={(e: any) => {
                onBlur && onBlur(e);
              }}
              onKeyDown={(e: any) => {
                onKeyDown && onKeyDown(e);
              }}
              maxLength={maxLength}
              rows={rows}
              autoResize={autoResize}
            />
          </div>
        )}

        {!!errors && (errors[name]?.message || errorMessage) && (
          <div
            className={cx('error-message', 'p-error mt-2', {
              ['is-label']: label,
            })}
          >
            {errors[name]?.message?.toString() || errorMessage}
          </div>
        )}
      </div>
    </>
  );
};
