import { serializeFormQuery } from "@/lib/utils";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@/shared/constants/pagination';

const usePagination = (dependencies?: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState<any>();

  function handlePagination(values: any) {
    const { query: queryOld } = Object.fromEntries(searchParams.entries());
    const { sortField, sortOrder, ...newValues } = values;
    if (sortField && sortOrder) {
      const type = sortOrder === -1 ? '-' : '';
      newValues['sort_columns'] = `${type}${sortField}`;
    } else {
      newValues['sort_columns'] = undefined;
    }
    const querySearchParamsNew = new URLSearchParams(serializeFormQuery(newValues));
    const queryNew = querySearchParamsNew.toString();
    const query = new URLSearchParams(queryOld);
    new URLSearchParams(queryNew).forEach((value, key) => {
      if (!querySearchParamsNew.has('sort_columns')) {
        query.set('sort_columns', '');
      }
      query.set(key, value);
    });
    setSearchParams({
      query: query.toString(),
    });
  }

  useEffect(() => {
    if (!pagination) return;
    handlePagination(pagination);
  }, [pagination])

  const paginationComputed = useMemo(() => {
    return {
      ...pagination,
      limit: dependencies?.per_page ?? DEFAULT_PER_PAGE,
      paginate: dependencies?.current_page ?? DEFAULT_PAGE,
      total_page: dependencies?.total_item ?? 0
    }
  }, [dependencies, pagination])

  return {
    paginationComputed,
    setPagination
  }
};

export default usePagination;