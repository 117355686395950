import { ReactNode } from 'react';
import cx from 'classnames';
import { Column } from 'primereact/column';
import { DataTable, DataTableProps } from 'primereact/datatable';
import { ColumnDescription } from './ColumnDescription';
import { CustomPaginator } from './CustomPaginator';
import { DEFAULT_PER_PAGE } from '@/shared/constants/pagination';
import { Pagination } from '@/types/pagination';
import { useTranslation } from '@/config/i18n';

type Props = DataTableProps<any> & {
  values: any[];
  selectedvalues?: any[];
  colums: ColumnDescription[];
  pagination?: Pagination;
  selectable?: boolean;
  onPaginationChanged?: (paginationState: Pagination) => void;
  onSelectionChange?: (values: any[]) => void;
  className?: string;
  wrapClassName?: string;
  extraFooter?: ReactNode;
  scrollable?: boolean;
};

export const CustomDataTable: React.FC<Props> = ({
  values,
  selectedvalues,
  colums,
  pagination,
  selectable,
  onPaginationChanged,
  onSelectionChange,
  className,
  wrapClassName,
  extraFooter,
  scrollable = false,
  reorderableRows,
  ...rest
}) => {
  const [t] = useTranslation('');
  const onSort = (event: any) => {
    onPaginationChanged?.({
      limit: pagination?.limit,
      total_page: pagination?.total_page,
      paginate: pagination?.paginate,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    });
  };

  const onSelectAllChange = (event: any) => {
    onSelectionChange?.(event.checked ? values : []);
  };

  const onSelectChange = (event: any) => {
    onSelectionChange?.(event.value);
  };

  return (
    <div className={cx('table-custom flex flex-col', wrapClassName)}>
      <DataTable
        className={className}
        first={((pagination?.paginate ?? 0) - 1) * (pagination?.limit ?? 0)}
        rows={pagination?.limit ?? DEFAULT_PER_PAGE}
        totalRecords={pagination?.total_page ?? values.length}
        lazy
        onSort={onSort}
        sortField={pagination?.sortField ?? undefined}
        sortOrder={pagination?.sortOrder}
        value={values}
        stripedRows
        removableSort
        tableStyle={{ minWidth: '50rem' }}
        selection={selectedvalues}
        selectAll={values == selectedvalues}
        onSelectAllChange={onSelectAllChange}
        onSelectionChange={onSelectChange}
        emptyMessage={t('common.table.empty')}
        scrollable={scrollable}
        reorderableRows={reorderableRows}
        {...rest}
      >
        {selectable && (
          <Column
            selectionMode="multiple"
            style={{
              minWidth: '2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        )}

        {reorderableRows && (
          <Column
            rowReorder
            style={{
              width: '40px',
              paddingRight: 0,
            }}
            rowReorderIcon="pi pi-drag-drop mt-[5px]"
          />
        )}

        {colums.map((column: ColumnDescription, index) => {
          return (
            <Column
              sortable={column.sort}
              key={index}
              className={column.classname ?? undefined}
              headerClassName={column.headerClassname ?? undefined}
              header={column.text ?? column.header}
              field={column.dataField}
              body={column.formatter ?? undefined}
              frozen={column.frozen ?? undefined}
              sortField={column.sortField ?? column.dataField}
            />
          );
        })}
      </DataTable>
      {extraFooter && (extraFooter)}
      {pagination && (
        <div>
          <CustomPaginator
            pagination={pagination}
            onPaginationChanged={onPaginationChanged}
          />
        </div>
      )}
    </div>
  );
};
