import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import ButtonCustom from '@/components/common/ui/button';
import useSearch from '@/hooks/useSearch';
import {
  ProgressFormSearchType,
  progressSearchFormSchema,
} from '@/shared/schema/progress';

interface IProgressFormSearchProps {
  placeholderMsg: string;
}

function ProgressFormSearch({ placeholderMsg }: IProgressFormSearchProps) {
  const [loading, setLoading] = useState<boolean>();
  const formMethods = useForm<ProgressFormSearchType>({
    mode: 'onChange',
    resolver: yupResolver(progressSearchFormSchema),
    shouldFocusError: false,
    defaultValues: {
      keyword: '',
    },
  });

  const { handleSearch, query } = useSearch();
  const { handleSubmit, getValues, setValue } = formMethods;

  const onSubmit = async () => {
    setLoading(true);
    handleSearch({ ...getValues() });
    setLoading(false);
  }

  const getAllFieldNames = () => {
    const values = getValues();
    return Object.keys(values);
  };

  const mapValueFromQuery = () => {
    getAllFieldNames().forEach((field: any) => {
      const val = query[field];
      if (query[field]) {
        setValue(field, decodeURIComponent(String(val).trim()));
      }
    });
  }

  useEffect(() => {
    mapValueFromQuery();
  }, []);

  return (
    <>
      <FormProvider {...formMethods}>
        <form className="mt-[16px] flex" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full search-input relative">
            <span className="pi pi-search absolute top-[14px] left-[14px] text-gray-500 text-[15px] z-40" />
            <InputTextCustomForm
              name="keyword"
              className="!pl-[40px]"
              placeholder={placeholderMsg}
            />
          </div>
          <div className="action flex">
            <ButtonCustom type="submit" disabled={loading} className="ml-2">
              <span className="pi pi-search" />
            </ButtonCustom>
          </div>
        </form>
      </FormProvider>
    </>
  );
}

export default ProgressFormSearch;
