import Player from 'react-player';
import { JSXElementConstructor, ReactElement } from 'react';

import DialogCustom from '@/components/common/ui/dialog';
import { IconTriangle } from '@/components/icons';

interface IPreviewVideoDialogProps {
  onHide: () => void;
  visible: boolean;
  light?: string | boolean | ReactElement<any, string | JSXElementConstructor<any>>;
  header?: string;
  url?: string | string[] | MediaStream;
}

function PreviewVideoDialog({
  onHide,
  visible,
  light,
  header,
  url,
}: IPreviewVideoDialogProps) {
  return (
    <DialogCustom
      header={header}
      visible={visible}
      onHide={onHide}
      className="dialog-preview-video"
    >
      <Player
        url={url}
        controls={true}
        playIcon={
          <div className="flex-full-center text-white size-[55px] rounded-[22px] bg-[#ffffff66]">
            <div className="ml-[3px]">
              <IconTriangle />
            </div>
          </div>
        }
        playing
        light={light}
        style={{
          objectFit: 'cover',
        }}
      />
    </DialogCustom>
  );
}

export default PreviewVideoDialog;
