export default function IconChat() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 9.7998H5.51M10 9.7998H10.01M14.5 9.7998H14.51M10 18.7998C14.9706 18.7998 19 14.7704 19 9.7998C19 4.82924 14.9706 0.799805 10 0.799805C5.02944 0.799805 1 4.82924 1 9.7998C1 10.9969 1.23374 12.1395 1.65806 13.1843C1.73927 13.3843 1.77988 13.4843 1.798 13.5651C1.81572 13.6441 1.8222 13.7027 1.82221 13.7837C1.82222 13.8665 1.80718 13.9567 1.77711 14.1372L1.18413 17.695C1.12203 18.0676 1.09098 18.2539 1.14876 18.3886C1.19933 18.5065 1.29328 18.6005 1.41118 18.651C1.54589 18.7088 1.73218 18.6778 2.10476 18.6157L5.66265 18.0227C5.84309 17.9926 5.9333 17.9776 6.01613 17.9776C6.09715 17.9776 6.15566 17.9841 6.23472 18.0018C6.31554 18.0199 6.41552 18.0605 6.61549 18.1417C7.6603 18.5661 8.80286 18.7998 10 18.7998ZM6 9.7998C6 10.0759 5.77614 10.2998 5.5 10.2998C5.22386 10.2998 5 10.0759 5 9.7998C5 9.52366 5.22386 9.2998 5.5 9.2998C5.77614 9.2998 6 9.52366 6 9.7998ZM10.5 9.7998C10.5 10.0759 10.2761 10.2998 10 10.2998C9.72386 10.2998 9.5 10.0759 9.5 9.7998C9.5 9.52366 9.72386 9.2998 10 9.2998C10.2761 9.2998 10.5 9.52366 10.5 9.7998ZM15 9.7998C15 10.0759 14.7761 10.2998 14.5 10.2998C14.2239 10.2998 14 10.0759 14 9.7998C14 9.52366 14.2239 9.2998 14.5 9.2998C14.7761 9.2998 15 9.52366 15 9.7998Z"
        stroke="#84CAFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
