const reset_password = {
  title: '新しいパスワード設定',
  description:
  '新しいパスワードは、以前に使用したパスワードとは異なる必要があります。',
  password: '新パスワード',
  confirm_password: '新パスワード（確認）',
  otp_code: '認証コード',
  button: 'パスワードを設定する',
  do_not_receive_mail: 'メールが届かない場合、',
  resend: '再送信する',
  return_login: 'ログイン画面へ',
};

export default reset_password;
