import dayjs from 'dayjs';
import { InputSwitch } from 'primereact/inputswitch';

import { ColumnDescription } from '@/components/common/ui/table/ColumnDescription';
import { IconTrash } from '@/components/icons';
import { VersionItemType } from '@/types/version';

export const VersionColumns = (
  t: any,
  onDeleteVersion: (rowData: VersionItemType) => void,
  onEditStatusVersion: (rowData: VersionItemType) => void,
): ColumnDescription[] => [
  {
    dataField: 'name',
    text: t('version.name'),
    classname: 'w-[200px]',
  },
  {
    dataField: 'updated_at',
    text: t('version.update_date'),
    classname: 'w-[140px]',
    sort: true,
    formatter: (rowData) => (
      <div>
        {rowData.updated_at
          ? dayjs(rowData.updated_at.toString()).format('YYYY/MM/DD - HH:mm:ss')
          : ''}
      </div>
    ),
  },
  {
    dataField: 'status',
    text: t('version.force_update'),
    sort: false,
    classname: 'text-center [&>div]:justify-center w-[160px]',
    headerClassname: '[&>div]:justify-center',
    formatter: (rowData) => (
      <div className="flex flex-row h-[36px] items-center">
        <InputSwitch
          checked={rowData.force_update}
          onChange={() => onEditStatusVersion?.(rowData)}
        />
      </div>
    ),
  },
  {
    dataField: 'action',
    text: '',
    sort: false,
    classname: 'text-center w-[80px] [&>div]:justify-center',
    headerClassname: '[&>div]:justify-center',
    formatter: (rowData) => (
      <div
        className="flex items-center text-error-500 cursor-pointer"
        onClick={() => onDeleteVersion?.(rowData)}
      >
        <IconTrash />
      </div>
    ),
  },
];
