export const ALL_VALUE = 'all';

export const DEFAULT_ALL_OPTION = (t: any) => [
  {
    label: t('lesson_student.all'),
    value: ALL_VALUE,
    info: {
      full_name_kanji: t('lesson_student.all'),
      email: t('lesson_student.all'),
      phone_number: t('lesson_student.all'),
      mentor: {
        email: t('lesson_student.all'),
      },
      value: ALL_VALUE,
    },
  },
];

export const AT_LEAST_ONE_OPTION = 'AT_LEAST_ONE_OPTION';
