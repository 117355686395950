export default function IconUploadImg() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.41667 1.49984H4.5C3.09987 1.49984 2.3998 1.49984 1.86502 1.77232C1.39462 2.012 1.01217 2.39446 0.772484 2.86486C0.5 3.39964 0.5 4.09971 0.5 5.49984V12.4998C0.5 13.9 0.5 14.6 0.772484 15.1348C1.01217 15.6052 1.39462 15.9877 1.86502 16.2274C2.3998 16.4998 3.09987 16.4998 4.5 16.4998H12.1667C12.9416 16.4998 13.3291 16.4998 13.647 16.4147C14.5098 16.1835 15.1836 15.5096 15.4148 14.6469C15.5 14.329 15.5 13.9415 15.5 13.1665M13.8333 5.6665V0.666504M11.3333 3.1665H16.3333M6.75 6.08317C6.75 7.00365 6.00381 7.74984 5.08333 7.74984C4.16286 7.74984 3.41667 7.00365 3.41667 6.08317C3.41667 5.1627 4.16286 4.4165 5.08333 4.4165C6.00381 4.4165 6.75 5.1627 6.75 6.08317ZM10.4917 8.93163L3.44262 15.3399C3.04614 15.7003 2.84789 15.8805 2.83036 16.0367C2.81516 16.172 2.86704 16.3062 2.96932 16.3961C3.08732 16.4998 3.35523 16.4998 3.89107 16.4998H11.7133C12.9126 16.4998 13.5123 16.4998 13.9833 16.2984C14.5745 16.0454 15.0456 15.5744 15.2985 14.9831C15.5 14.5121 15.5 13.9125 15.5 12.7132C15.5 12.3096 15.5 12.1079 15.4559 11.92C15.4005 11.6838 15.2941 11.4626 15.1444 11.2718C15.0252 11.12 14.8677 10.994 14.5526 10.7419L12.2215 8.87705C11.9062 8.62476 11.7485 8.49861 11.5748 8.4541C11.4218 8.41486 11.2607 8.41994 11.1104 8.46875C10.94 8.52412 10.7905 8.65996 10.4917 8.93163Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
