import * as yup from 'yup';

import { BaseYup, MAX_INPUT } from '@/config/baseYup';
import i18n from '@/config/i18n';

export const addNewVideoLessonSchema = BaseYup.object({
  status: BaseYup.string().label(i18n.t('lesson_video.status')).required(),
  name: BaseYup.string().label(i18n.t('lesson_video.video.title_validate')).trim().required(),
  is_new_video: BaseYup.boolean(),
  description: BaseYup.string()
    .label(i18n.t('lesson_video.description.title'))
    .trim()
    .required()
    .max(MAX_INPUT),
  video_id: BaseYup.string()
    .label(i18n.t('lesson_video.upload.title'))
    .when('is_new_video', ([is_new_video], schema) => {
      return is_new_video
        ? schema.required(i18n.t('lesson_video.upload.required'))
        : schema.optional().nullable();
    }),
  thumbnail_id: BaseYup.string()
    .label(i18n.t('lesson_video.upload.title'))
    .when('is_new_video', ([is_new_video], schema) => {
      return is_new_video
        ? schema.required(i18n.t('lesson_video.upload.required'))
        : schema.optional().nullable();
    }),
  video_guide_id: BaseYup.string()
    .label(i18n.t('lesson_video.upload_guide.title'))
    .required(i18n.t('lesson_video.upload_guide.required')),
  thumbnail_guide_id: BaseYup.string()
    .label(i18n.t('lesson_video.upload_guide.title'))
    .required(i18n.t('lesson_video.upload_guide.required')),
  pdfName1: BaseYup.string()
    .label(i18n.t('lesson_video.pdf1.title'))
    .when('pdfFile1', ([pdfFile1], schema) => {
      return !pdfFile1 ? schema.optional().nullable() : schema.trim().required();
    }),
  pdfFileName1: BaseYup.string().nullable(),
  pdfFile1: BaseYup.string()
    .label(i18n.t('lesson_video.pdf1.upload'))
    .test(
      'Require when has pdfName1',
      i18n.t('common.form.field_required', {
        label_name: i18n.t('lesson_video.pdf1.upload'),
      }),
      (value, schema) => {
        const { parent } = schema;
        if (!value && parent.pdfName1 && parent.is_new_video) return false;
        return true;
      },
    ),
  pdfName2: BaseYup.string()
    .label(i18n.t('lesson_video.pdf2.title'))
    .when('pdfFile2', ([pdfFile2], schema) => {
      return !pdfFile2 ? schema.optional().nullable() : schema.trim().required();
    }),
  pdfFileName2: BaseYup.string().nullable(),
  pdfFile2: BaseYup.string()
    .label(i18n.t('lesson_video.pdf2.upload'))
    .test(
      'Require when has pdfName2',
      i18n.t('common.form.field_required', {
        label_name: i18n.t('lesson_video.pdf2.upload'),
      }),
      (value, schema) => {
        const { parent } = schema;
        if (!value && parent.pdfName2 && parent.is_new_video) return false;
        return true;
      },
    ),
  pdfName3: BaseYup.string()
    .label(i18n.t('lesson_video.pdf3.title'))
    .when('pdfFile3', ([pdfFile3], schema) => {
      return !pdfFile3 ? schema.optional().nullable() : schema.trim().required();
    }),
  pdfFileName3: BaseYup.string().nullable(),
  pdfFile3: BaseYup.string()
    .label(i18n.t('lesson_video.pdf3.upload'))
    .test(
      'Require when has pdfName3',
      i18n.t('common.form.field_required', {
        label_name: i18n.t('lesson_video.pdf3.upload'),
      }),
      (value, schema) => {
        const { parent } = schema;
        if (!value && parent.pdfName3 && parent.is_new_video) return false;
        return true;
      },
    ),
});

export type NewVideoLessonFormType = yup.InferType<
  typeof addNewVideoLessonSchema
>;
