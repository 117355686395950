const lesson_progress = {
  no: 'No',
  page_title: '講座管理・進捗管理',
  title: '進捗管理',
  student: '受講生',
  coach: 'アートシフトコーチ',
  name: '氏名（漢字）',
  email: 'メールアドレス',
  progress: '進捗',
  video: '動画{{number}}',
  close: '閉じる',
  unit: '単元',
  viewed: '閲覧回数',
  modal: {
    title_work: '・ワーク',
    title_comment: '・気づき',
    sheet: '枚',
    comment: 'コメント',
    first_time_comment: '1周目 気づき',
    second_time_comment: '2周目 気づき',
    first_time_work: '1周目 ワーク',
    second_time_work: '2周目 ワーク',
    load_more: 'もっと見る',
    title_preview:'プレビュー'
  },
};

export default lesson_progress;
