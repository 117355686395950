export default function IconComment() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.08333 9.41667C6.08333 9.41667 7.17708 10.6667 9 10.6667C10.8229 10.6667 11.9167 9.41667 11.9167 9.41667M11.2917 5.25H11.3M6.70833 5.25H6.71667M4.83333 14V15.9463C4.83333 16.3903 4.83333 16.6123 4.92436 16.7263C5.00352 16.8255 5.12356 16.8832 5.25045 16.8831C5.39636 16.8829 5.56973 16.7442 5.91646 16.4668L7.90434 14.8765C8.31043 14.5517 8.51347 14.3892 8.73957 14.2737C8.94017 14.1712 9.15369 14.0963 9.37435 14.051C9.62306 14 9.88308 14 10.4031 14H12.5C13.9001 14 14.6002 14 15.135 13.7275C15.6054 13.4878 15.9878 13.1054 16.2275 12.635C16.5 12.1002 16.5 11.4001 16.5 10V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V10.6667C1.5 11.4416 1.5 11.8291 1.58519 12.147C1.81635 13.0098 2.49022 13.6836 3.35295 13.9148C3.67087 14 4.05836 14 4.83333 14ZM11.7083 5.25C11.7083 5.48012 11.5218 5.66667 11.2917 5.66667C11.0615 5.66667 10.875 5.48012 10.875 5.25C10.875 5.01988 11.0615 4.83333 11.2917 4.83333C11.5218 4.83333 11.7083 5.01988 11.7083 5.25ZM7.125 5.25C7.125 5.48012 6.93845 5.66667 6.70833 5.66667C6.47821 5.66667 6.29167 5.48012 6.29167 5.25C6.29167 5.01988 6.47821 4.83333 6.70833 4.83333C6.93845 4.83333 7.125 5.01988 7.125 5.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
