import cx from 'classnames';
import { Button, ButtonProps } from 'primereact/button';
import React from 'react';

const Tailwind = {
  button: {
    root: ({ props }: { props: any }) => {
      return {
        className: cx(
          'items-center justify-center cursor-pointer inline-flex overflow-hidden relative select-none text-center align-bottom px-[18px] py-[10px] rounded-lg',
          'transition duration-200 ease-in-out',
          'focus:outline-none focus:outline-offset-0',
          {
            'bg-primary-700 hover:bg-primary-700-hover text-white': !props.severity,
            'bg-white hover:bg-white-hover text-gray-700 border border-gray-300':
              props.severity === 'secondary',
          },
          { 'opacity-60 pointer-events-none cursor-default': props.disabled },
        ),
      };
    },
    label: ({ props }: { props: any }) => ({
      className: cx(
        'flex-1',
        'duration-200',
        'font-bold',
        {
          'hover:underline': props.link,
        },
        { 'invisible w-0': props.label === null },
      ),
    }),
    icon: ({ props }: { props: any }) => ({
      className: cx('mx-0', {
        'mr-2': props.iconPos === 'left' && props.label != null,
        'ml-2 order-1': props.iconPos === 'right' && props.label != null,
        'mb-2': props.iconPos === 'top' && props.label != null,
        'mt-2 order-2': props.iconPos === 'bottom' && props.label != null,
      }),
    }),
    loadingIcon: ({ props }: { props: any }) => ({
      className: cx('mx-0', {
        'mr-2': props.loading,
        'ml-2 order-1':
          props.loading && props.iconPos === 'right' && props.label != null,
        'mb-2': props.loading && props.iconPos === 'top' && props.label != null,
        'mt-2 order-2':
          props.loading && props.iconPos === 'bottom' && props.label != null,
      }),
    }),
    badge: ({ props }: { props: any }) => ({
      className: cx({
        'ml-2 w-4 h-4 leading-none flex items-center justify-center':
          props.badge,
      }),
    }),
  },
};

const ButtonCustom: React.FC<ButtonProps> = (props) => {
  const buttonStyles = cx(
    Tailwind.button.root({ props }).className,
    props.className,
  );

  const loadingIconStyles = cx(
    Tailwind.button.loadingIcon({ props }).className,
  );

  return (
    <Button {...props} className={buttonStyles}>
      {props.loading && <i className={loadingIconStyles}></i>}
      {props.children}
    </Button>
  );
};

export default ButtonCustom;
