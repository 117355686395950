import { IconVideo } from '@/components/icons';
import { VideoDataType } from '@/types/lesson';

const createVideoFormatter =
  (
    videoType: string,
    onPreviewVideo: (video: any) => void,
    byteToMb: (sizeInBytes: number) => string | undefined,
    needId?: boolean,
  ) =>
    (rowData: any) => {
      const video = rowData.videos.find(
        (video: VideoDataType) => video.type === videoType,
      );
      return video ? (
        <div className="flex gap-3">
          <div className="bg-primary-100 p-[10px] h-[40px] w-[40px] flex-full-center text-primary-700 rounded-full">
            <IconVideo />
          </div>
          <div>
            <div>
              <div
                className="line-clamp-1 break-all text-primary-500 underline cursor-pointer"
                onClick={() =>
                  needId
                    ? onPreviewVideo({ ...video, unit_id: rowData.id })
                    : onPreviewVideo(video)
                }
              >
              動画閲覧
              </div>
            </div>
            <div>{video?.size ? `${byteToMb(video?.size)} MB` : ''}</div>
          </div>
        </div>
      ) : (
        <></>
      );
    };

export default createVideoFormatter;
