import { useTranslation } from '@/config/i18n';
import PATH from '@/routes/path';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LessonForm from '@/pages/lesson/components/LessonForm';
import { UploadVideoProvider } from '@/pages/lesson/context/UploadVideoContext';

const LessonNewPage: React.FC = () => {
  const [t] = useTranslation('');
  const navigate = useNavigate();
  const [isAddOrEditUnit, setIsAddOrEditUnit] = useState(false);

  const handleBack = () => {
    if (isAddOrEditUnit) {
      setIsAddOrEditUnit(false);
      return;
    }

    navigate(PATH.lesson);
  };

  return (
    <UploadVideoProvider>
      <div className="header-top">
        <h2 className="title flex">
          <span
            className="pi pi-arrow-left text-[20px] text-gray-500 cursor-pointer"
            onClick={handleBack}
          ></span>
          <span className="ml-3">
            {t(isAddOrEditUnit ? 'lesson_list.add_unit' : 'lesson_list.new')}
          </span>
        </h2>
      </div>
      <LessonForm
        isAddOrEditUnit={isAddOrEditUnit}
        setIsAddOrEditUnit={setIsAddOrEditUnit}
      />
    </UploadVideoProvider>
  );
};

export default LessonNewPage;
