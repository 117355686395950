export default function IconCoach() {
  return (
    <svg
      width={22}
      height={21}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 19.3999V5.3999C7 4.46993 7 4.00494 7.10222 3.62345C7.37962 2.58817 8.18827 1.77953 9.22354 1.50212C9.60504 1.3999 10.07 1.3999 11 1.3999C11.93 1.3999 12.395 1.3999 12.7765 1.50212C13.8117 1.77953 14.6204 2.58817 14.8978 3.62345C15 4.00494 15 4.46993 15 5.3999V19.3999M4.2 19.3999H17.8C18.9201 19.3999 19.4802 19.3999 19.908 19.1819C20.2843 18.9902 20.5903 18.6842 20.782 18.3079C21 17.8801 21 17.32 21 16.1999V8.5999C21 7.4798 21 6.91974 20.782 6.49192C20.5903 6.1156 20.2843 5.80964 19.908 5.61789C19.4802 5.3999 18.9201 5.3999 17.8 5.3999H4.2C3.07989 5.3999 2.51984 5.3999 2.09202 5.61789C1.71569 5.80964 1.40973 6.1156 1.21799 6.49192C1 6.91974 1 7.4798 1 8.5999V16.1999C1 17.32 1 17.8801 1.21799 18.3079C1.40973 18.6842 1.71569 18.9902 2.09202 19.1819C2.51984 19.3999 3.0799 19.3999 4.2 19.3999Z"
        stroke="#84CAFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
