import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

import DialogCustom from '@/components/common/ui/dialog';
import ButtonCustom from '@/components/common/ui/button';
import { ConversationType, UserType } from '@/types/chat';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import {
  GroupChatSchema,
  GroupChatType,
} from '@/shared/schema/create-group-chat';
import { auth, db } from '@/lib/firebase';
import { UploadAvatar } from '@/components/common/ui/upload';
import { IMG_DOMAIN, INVITE_STATUS } from '@/shared/constants/chat';
import { IconGroupUsers } from '@/components/icons';
import { useSelectorUserInfo } from '@/components/auth/AuthContext';
import { sendUpdateNameNotification } from '@/lib/utils';
import { ListMember } from '.';
import { apiRequest } from '@/services';

type Props = {
  openPopup?: boolean;
  setOpenPopup: (value: boolean) => void;
  selectedConversation?: ConversationType | null;
  handleLeaveGroup: () => void;
  initializeUsers: (memberIds: number[]) => Promise<void>;
};

let controllerVersion = new AbortController();

export const DeleteMemberGroupDialog: React.FC<Props> = ({
  openPopup,
  setOpenPopup,
  selectedConversation,
  handleLeaveGroup,
}) => {
  const [t] = useTranslation('');
  const currentUser = auth.currentUser;
  const groupOwner = useSelectorUserInfo();
  const { apiService } = apiRequest();
  const { id, admin_id, invitation_statuses, member_ids, name, image } =
    selectedConversation || {};
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletedUser, setIsDeletedUser] = useState(false);
  const [showAvatar, setShowAvatar] = useState(false);
  const [groupMembers, setGroupMembers] = useState<UserType[]>([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [users, setUsers] = useState<UserType[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const formMethods = useForm<GroupChatType>({
    mode: 'onChange',
    resolver: yupResolver(GroupChatSchema),
    defaultValues: {
      group_name: name || '',
      group_avatar: image || '',
      group_members:
        member_ids?.map((id) => ({
          id,
        })) || [],
    },
  });

  const { setValue, watch, reset, formState } = formMethods;
  const groupAvatar = watch('group_avatar');
  const groupName = watch('group_name')?.trim();
  const initialGroupName = selectedConversation?.name || '';

  const isAdminGroup = useMemo(() => {
    return admin_id === Number(currentUser?.uid);
  }, [selectedConversation, currentUser]);

  const isDirty = useMemo(() => {
    const initialAvatar = selectedConversation?.image || '';
    const hasAvatarChanged = initialAvatar !== groupAvatar;

    return formState.isDirty || hasAvatarChanged;
  }, [formState.isDirty, selectedConversation, groupMembers, groupAvatar]);

  const allMembers = useMemo(() => {
    if (!member_ids || !users) return [];    
    return member_ids
      .map((userId) => users.find((user) => user.id === userId))
      .filter((user) => user !== undefined) as UserType[];
  }, [selectedConversation, users]);

  const acceptedMembers = useMemo(() => {
    if (!invitation_statuses || !users) return [];

    return Object.keys(invitation_statuses).filter(
      (userId) =>
        invitation_statuses[parseInt(userId)]?.status === INVITE_STATUS.ACCEPT,
    );
  }, [selectedConversation, users]);

  const mergedMembers = useMemo(() => {
    return allMembers
      .map((member) => ({
        ...member,
        accepted: acceptedMembers.includes(member.id.toString()),
      }))
      .filter(
        (member) =>
          selectedConversation?.invitation_statuses?.[member.id]?.status !== INVITE_STATUS.REMOVED,
      )
  }, [acceptedMembers, groupMembers]);

  const updateConversation = async () => {
    setIsLoading(true);
    if (!selectedConversation) return;
    const docRef = doc(db, 'conversations', String(id));
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.error('No such document!');
      return;
    }

    const groupUpdateData = {
      name: groupName,
      image: groupAvatar,
    };

    try {
      await updateDoc(docRef, groupUpdateData);
      if (groupName && groupName !== initialGroupName) {
        sendUpdateNameNotification(
          selectedConversation,
          groupName,
          groupOwner.currentUser.full_name_kanji,
          currentUser,
        );
      }
      resetForm();
      setOpenPopup(false);
      setIsLoading(false);
    } catch (error) {
      console.error('Error updating group:', error);
      setIsLoading(false);
    }
  };

  const handleUpdateGroup = async () => {
    if (selectedConversation) {
      await updateConversation();
    }
  };

  const resetForm = () => {
    reset({
      group_name: '',
      group_avatar: '',
      group_members: [],
    });
  };

  const onHidePopup = () => {
    setOpenPopup(false);
    setIsDeletedUser(false);
    setTimeout(() => {
      reset({
        group_name: selectedConversation?.name || '',
        group_avatar: selectedConversation?.image || '',
        group_members: (selectedConversation?.member_ids || []).map((id) => ({
          id,
        })),
      });

      setGroupMembers(allMembers);
    }, 200);
  };

  const fetchAndInitializeUsers = async (
    memberIds: number[],
    keyword?: string,
  ) => {
    setIsLoadingUsers(true);
    if (controllerVersion.abort) controllerVersion.abort();
    controllerVersion = new AbortController();
    const { signal } = controllerVersion;

    try {
      const BATCH_SIZE = 1000;
      const chunks = [];

      for (let i = 0; i < memberIds.length; i += BATCH_SIZE) {
        chunks.push(memberIds.slice(i, i + BATCH_SIZE));
      }

      setUsers([]);

      await Promise.all(
        chunks.map(async (chunk) => {
          try {
            const { data: allUsers } = await apiService.chatParticipants.create(
              {
                user_ids: chunk,
                keyword: keyword?.trim(),
              },
              { signal },
            );

            if (allUsers?.data) {
              setUsers((prevUsers) => [...prevUsers, ...allUsers.data]);
            }
          } catch (error) {
            if (signal?.aborted) {
              return;
            }
            console.error('Error fetching chunk:', error);
          }
        }),
      );
    } catch (error) {
      console.error('Error initializing users:', error);
    } finally {
      if (!signal.aborted) {
        setIsLoadingUsers(false);
      }
    }
  };

  useEffect(() => {
    if (selectedConversation) {
      reset({
        group_avatar: image || '',
        group_name: name || '',
        group_members: acceptedMembers || [],
      });
    }
  }, [selectedConversation, openPopup, reset]);

  useEffect(() => {
    if (selectedConversation) {
      setValue('group_members', acceptedMembers);
    }
  }, [selectedConversation, acceptedMembers, setValue]);

  useEffect(() => {
    if (selectedConversation && users) {
      const acceptedMembers = Object.keys(
        selectedConversation.invitation_statuses || {},
      )
        .filter(
          (userId) =>
            selectedConversation.invitation_statuses?.[
              userId as unknown as number
            ]?.status === INVITE_STATUS.ACCEPT,
        )
        .map((userId) => users.find((user) => user.id.toString() === userId))
        .filter((user) => user !== undefined) as UserType[];

      setGroupMembers(acceptedMembers);
    }
  }, [selectedConversation, users]);

  useEffect(() => {
    if (isFirstLoad) {
      fetchAndInitializeUsers(selectedConversation?.member_ids || []);
      setIsFirstLoad(false);
    }
  }, [isFirstLoad, selectedConversation]);
  

  return (
    <DialogCustom
      header={
        isAdminGroup
          ? t('chat.title_popup_create_group')
          : t('chat.title_popup_info_group_for_member')
      }
      visible={openPopup}
      onHide={onHidePopup}
      className="min-w-[668px] max-w-[668px] dialog-create-group"
      dismissableMask={false}
      blockScroll
      footer={
        <div className="field">
          <div className="flex justify-center gap-[20px]">
            <ButtonCustom
              className="w-full"
              type="button"
              severity="secondary"
              onClick={onHidePopup}
            >
              {t('common.form.cancel')}
            </ButtonCustom>
            {!isAdminGroup && (
              <ButtonCustom
                className="w-full bg-error-500 text-white"
                type="button"
                severity="danger"
                onClick={handleLeaveGroup}
              >
                {t('chat.leave_group')}
              </ButtonCustom>
            )}
            {isAdminGroup && (
              <ButtonCustom
                type="button"
                className="w-full"
                onClick={formMethods.handleSubmit(handleUpdateGroup)}
                loading={isLoading}
                disabled={!isDirty && !isDeletedUser}
              >
                {t('common.form.save')}
              </ButtonCustom>
            )}
          </div>
        </div>
      }
    >
      <FormProvider {...formMethods}>
        <form>
          <div className="bg-gray-100 p-[16px] pb-0">
            <div className="flex items-center">
              <div className="flex justify-center">
                <div
                  className="relative cursor-pointer"
                  onClick={() => setShowAvatar(true)}
                >
                  {isAdminGroup ? (
                    <UploadAvatar
                      idChooseBtn="choose-image"
                      name="group_avatar"
                      accept=".svg,.jpg,.jpeg,.png"
                      maxSize={5}
                      handleUploaded={(imgUrl: string) => {
                        setValue('group_avatar', imgUrl);
                      }}
                      defaultUrl={groupAvatar}
                      isAdminGroup={true}
                      isUploading={isUploading}
                      setIsUploading={setIsUploading}
                    />
                  ) : (
                    <>
                      {!groupAvatar ? (
                        <div className="h-[82px] w-[82px] rounded-[29px] flex justify-center items-center bg-gradient-to-b from-[#DC6803] to-[#FDB022] overflow-hidden">
                          <IconGroupUsers className="h-[50px] w-[60px] text-white size-large" />
                        </div>
                      ) : (
                        <div className="h-[82px] w-[82px] rounded-[29px] flex justify-center items-center bg-gradient-to-b from-[#DC6803] to-[#FDB022] overflow-hidden">
                          <img
                            src={`${IMG_DOMAIN}/${groupAvatar}`}
                            className="w-full h-full object-cover rounded-[8px]"
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-1 flex-col ml-[16px]">
                <InputTextCustomForm
                  name="group_name"
                  placeholder={t('chat.plh_group_name')}
                  className="border rounded-lg p-2 w-full"
                  disabled={!isAdminGroup}
                  maxLength={200}
                  label={t('chat.group_name')}
                  requiredFlag
                />
              </div>
            </div>
            <ListMember
              selectedConversation={selectedConversation}
              isAdminGroup={isAdminGroup}
              setIsDeletedUser={setIsDeletedUser}
              users={users}
              allMembers={mergedMembers}
              initializeUsers={fetchAndInitializeUsers}
              isLoadingUsers={isLoadingUsers}
              setUsers={setUsers}
            />
            <DialogCustom
              visible={showAvatar}
              onHide={() => setShowAvatar(false)}
              className="w-[568px] h-[500px]"
              contentClassName="flex justify-center items-center"
            >
              <div className="w-full h-[400px] flex justify-center items-center">
                {!groupAvatar ? (
                  <div className="w-full h-full flex justify-center items-center bg-gradient-to-b from-[#DC6803] to-[#FDB022] overflow-hidden">
                    <IconGroupUsers className="h-full w-full text-white size-large" />
                  </div>
                ) : (
                  <div className="w-full h-full flex justify-center items-center bg-gradient-to-b from-[#DC6803] to-[#FDB022] overflow-hidden">
                    <img
                      src={`${IMG_DOMAIN}/${groupAvatar}`}
                      alt="Group Avatar"
                      className="w-full h-full object-cover"
                    />
                  </div>
                )}
              </div>
            </DialogCustom>
          </div>
        </form>
      </FormProvider>
    </DialogCustom>
  );
};
