import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { useTranslation } from '@/config/i18n';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import ButtonCustom from '@/components/common/ui/button';
import { IconKey } from '@/components/icons';
import PATH from '@/routes/path';
import { ForgotPasswordFormType, ForgotPasswordSchema } from '../schema';
import { apiRequest } from '@/services';
import { mailForgot } from '@/lib/axiosCustom';
import useScrollToError from '@/hooks/useScrollToError';

const ForgotPasswordForm = () => {
  const [t] = useTranslation('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { apiService } = apiRequest();
  const { scrollToError } = useScrollToError();

  const formMethods = useForm<ForgotPasswordFormType>({
    mode: 'onChange',
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const { handleSubmit, watch, getValues } = formMethods;

  watch(() => {
    setError('');
  });

  async function onSubmit() {
    if (loading) return;
    setLoading(true);
    const { data: user, error: errorUser } = await apiService.forgotPassword.collection('otp', {
      method: 'POST',
      body: getValues()
    });

    if (errorUser) {
      setError(errorUser.message);
      scrollToError();
    }

    if (user) {
      mailForgot.value = getValues('email');
      navigate(PATH.reset_password);
    }

    setLoading(false);
  }

  return (
    <FormProvider {...formMethods}>
      <div className="error-message text-primary-400 mb-[32px] flex-full-center text-[16px] leading-[24px] font-normal">
        {error ? error : t('forgot_password.description')}
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full bg-white p-[20px] rounded-[24px] shadow-[0_4px_4px_0_rgba(0,0,0,0.3)]"
      >
        <div className="logo flex-full-center mb-[27px]">
          <div className="p-[9px] text-primary-700 rounded-full bg-primary-100 border border-[10px] border-primary-50">
            <IconKey />
          </div>
        </div>

        <div className="form-inner">
          <InputTextCustomForm
            label={t('login.email')}
            labelClass="!text-[14px]"
            requiredFlag={true}
            placeholder={t('forgot_password.email_placeholder')}
            name="email"
          />
        </div>

        <div className="flex-full-center mt-[24px]">
          <ButtonCustom
            type="submit"
            loading={loading}
            className="w-full !rounded-[16px]"
          >
            {t('forgot_password.button')}
          </ButtonCustom>
        </div>

        <div className="flex-full-center mt-[32px]">
          <div
            className="text-gray-700 text-[16px] leading-[20px] cursor-pointer hover:opacity-80"
            onClick={() => navigate(PATH.login)}
          >
            <span className="pi pi-arrow-left mr-[8px] text-[16px]"></span>
            {t('forgot_password.return_login')}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default ForgotPasswordForm;
