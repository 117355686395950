import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { SignJWT, jwtVerify } from 'jose';
import { signInWithCustomToken } from "firebase/auth";

import { useTranslation } from '@/config/i18n';
import PATH from '@/routes/path';
import { apiRequest } from '@/services';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import ButtonCustom from '@/components/common/ui/button';
import useAuth from '@/hooks/useAuth';
import { CheckBoxCustomForm } from '@/components/common/ui/checkbox';
import { useSelectorUserInfo } from '@/components/auth/AuthContext';
import { ROLE } from '@/shared/constants/user';
import { CURRENT_USER_ID_KEY } from '@/shared/constants';
import { LoginFormType, loginFormSchema } from '../schema';
import { auth } from '@/lib/firebase';

const ERROR_USER_INACTIVE = 'MFS-E-LEARNING-ADMIN-USER-99';
const ERROR_USER_NOT_FIRST_LOGIN_APP = 'MFS-E-LEARNING-ADMIN-AUTH-103';

const LoginForm = () => {
  const isAuthenticated = useAuth();
  const { currentUser, setCurrentUser } = useSelectorUserInfo();
  const [t] = useTranslation('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [remmeberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const { apiService } = apiRequest();
  const secretKey: any = new TextEncoder().encode(
    process.env.REACT_APP_PASSWORD_KEY || '',
  );

  const formMethods = useForm<LoginFormType>({
    resolver: yupResolver(loginFormSchema),
    shouldFocusError: false,
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { handleSubmit, getValues, setValue, watch } = formMethods;

  watch(() => {
    setError('');
  });

  const signInWithFirebase = async (firebaseToken: string) => {
    try {
      await signInWithCustomToken(auth, firebaseToken);
    } catch (error) {
      console.error('Error signing in with Firebase token:', error);
      throw error;
    }
  };

  async function onSubmit() {
    if (loading) return;
    setLoading(true);
    const { data, error } = await apiService.auth.create({
      ...(getValues() as unknown as any),
    });
    if (error) {
      const { error_code, message } = error || {};
      switch (error_code) {
      case ERROR_USER_INACTIVE:
        setError(t('common.form.login_inactive'));
        break;
      case ERROR_USER_NOT_FIRST_LOGIN_APP:
        setError(message);
        break;
      default:
        setError(t('common.form.login'));
      }
      setLoading(false);
      return;
    }

    if (!data) return;

    if (remmeberMe) {
      await saveMeOnClient();
    }

    const { data: result } = data;
    const { access_token, refresh_token, expires_in, firebase_token } = result;
    localStorage.setItem('accessToken', access_token);
    localStorage.setItem('refreshToken', refresh_token);
    localStorage.setItem(
      'tokenExpiresAt',
      (Date.now() + expires_in * 1000).toString(),
    );

    const [signInResult] = await Promise.all([
      apiService.auth.getMe({
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }),
      signInWithFirebase(firebase_token),
    ]);

    const { data: userInfo, error: userError } = signInResult;

    if (userError) {
      setError(t('common.form.login'));
      setLoading(false);
      return;
    }

    if (!userInfo) return;
    setCurrentUser(userInfo.data);
    navigate(
      [ROLE.SUPPER_ADMIN, ROLE.ADMIN].includes(userInfo.data.current_roles[0])
        ? PATH.coach_management
        : PATH.student_management,
    );
    localStorage.setItem(CURRENT_USER_ID_KEY, JSON.stringify(userInfo.data.id));
  }

  async function saveMeOnClient() {
    const user = await converUser(getValues());
    localStorage.setItem('user', user);
  }

  async function converUser(user: any) {
    const token = await new SignJWT(user)
      .setProtectedHeader({ alg: 'HS256' })
      .sign(secretKey);

    return token;
  }

  async function getUserFromToken() {
    const token = localStorage.getItem('user');

    if (!token) {
      return null;
    }

    try {
      const { payload } = await jwtVerify(token, secretKey);
      return payload;
    } catch (err) {
      console.error('Token is invalid:', err);
      return null;
    }
  }

  const handleFetchUser = async () => {
    const access_token = localStorage.getItem('accessToken');
    const { data: userInfo, error: userError } = await apiService.auth.getMe({
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    if (userError) {
      return;
    }

    navigate(
      [ROLE.SUPPER_ADMIN, ROLE.ADMIN].includes(userInfo.data.current_roles[0])
        ? PATH.coach_management
        : PATH.student_management,
    );
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (isEmpty(currentUser)) {
        handleFetchUser();
      } else {
        navigate(
          [ROLE.SUPPER_ADMIN, ROLE.ADMIN].includes(currentUser.current_roles[0])
            ? PATH.coach_management
            : PATH.student_management,
        );
      }
    }
  }, [isAuthenticated, currentUser, navigate]);

  useEffect(() => {
    async function decodeUser() {
      const user: any = await getUserFromToken();
      if (!user) return;

      setValue('email', user.email);
      setValue('password', user.password);
    }

    decodeUser();
  }, []);

  if (isAuthenticated) return <></>;

  return (
    <FormProvider {...formMethods}>
      {error && (
        <div className="text-primary-400 mb-[20px] flex-full-center text-center text-[16px] whitespace-pre">
          {error}
        </div>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full bg-white p-[20px] rounded-[24px] shadow-[0_4px_4px_0_rgba(0,0,0,0.3)]"
      >
        <div className="logo flex-full-center"></div>
        <div className="form-inner">
          <InputTextCustomForm
            label={t('login.email')}
            labelClass="!text-[14px]"
            requiredFlag={true}
            placeholder={t('login.email')}
            name="email"
          />
          <InputTextCustomForm
            wrapClass="mt-[20px]"
            label={t('login.password')}
            labelClass="!text-[14px]"
            requiredFlag={true}
            placeholder={t('login.password')}
            name="password"
            isHideValue={true}
          />
        </div>
        <div className="mt-[20px] flex justify-between">
          <CheckBoxCustomForm
            onChange={(e) => setRememberMe(e.checked)}
            checked={remmeberMe}
            inputId="remember_me"
            name="remember_me"
            wrapClass="checkbox-sm"
            label={t('login.remember_me')}
          />
          <p
            onClick={() => navigate(PATH.forgot_password)}
            className="text-primary-500 cursor-pointer"
          >
            {t('login.forgot_pass')}
          </p>
        </div>
        <div className="flex-full-center mt-[20px]">
          <ButtonCustom
            type="submit"
            loading={loading}
            className="w-full !rounded-[16px]"
          >
            {t('login.button')}
          </ButtonCustom>
        </div>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
