/* eslint @typescript-eslint/no-explicit-any: 0 */
import * as yup from 'yup';

const LocaleJP = {
  mixed: {
    default: '${path}を入力してください。',
    required: '${path}を入力してください。',
    oneOf: '${path}は次の値のいずれかでなければなりません:${values}',
    notOneOf: '${path}は次の値のいずれかであってはなりません:${values}',
  },
  string: {
    length: '${path}は正確に${length}文字でなければなりません。',
    min: '${path}は少なくとも${min}文字でなければなりません。',
    max: '${path}は${max}文字以内で入力してください。',
    matches: '${path}は数値で入力してください。',
    email: '${path}はメールアドレス形式である必要があります。',
    url: '${path}は有効なURLでなければなりません。',
    trim: '${path}はトリミングされた文字列でなければなりません。',
    lowercase: '${path}は小文字の文字列でなければなりません。',
    uppercase: '${path}は大文字の文字列でなければなりません。',
  },
  number: {
    min: '${path}は${min}以上である必要があります',
    max: '${path}は${max}桁以内で入力してください',
    lessThan: '${path}は${less}より小さくなければなりません',
    moreThan: '${path}は${more}より大きくなければなりません',
    notEqual: '${path}は${notEqual}と等しくない必要があります',
    positive: '${path}は正の数でなければなりません',
    negative: '${path}は負の数でなければなりません',
    integer: '${path}は整数でなければなりません',
    error: '数字を入力してください',
  },
  date: {
    min: '${path}フィールドは${min}より後でなければなりません',
    max: '${path}フィールドは${max}より前でなければなりません',
  },
  object: {
    noUnknown:
      '${path}フィールドには,オブジェクトシェイプで指定されていないキーを含めることはできません',
  },
  array: {
    min: '${path}フィールドには少なくとも${min}の項目が必要です',
    max: '${path}フィールドには${max}以下の項目が必要です',
  },
};

/**
 * @param currentValue
 * @param originalValue
 * @returns {boolean}
 */
export const checkNumIsNull = (currentValue: any, originalValue: any) => {
  return originalValue === '' ? null : currentValue;
};

export const phoneRegExp = /^[+]?[0-9]{1,4}?[-]?[0-9]{1,4}[-]?[0-9]{4,6}$/;
export const emailRegExp = /[\w+\-.]+@[a-z\d\-.]+\.[a-z]+/i;
export const passwordRegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).*$/; /* eslint-disable-line */
export const kanjiRegExp = /^[\u4E00-\u9FAF]+$/;
export const katakanaRegExp = /^[\u30A0-\u30FF]+$/;
export const numberWithoutZeroRegExp = /^[1-9][0-9]*$/;
export const kanjiKanaFullHiraganaRegExp = /^[\u4E00-\u9FAF\u3040-\u309F\u30A0-\u30FF]+$/;
export const versionNameRegExp = /^(0|[1-9]\d?|100)(\.(0|[1-9]\d?|100)){2}$/;
export const postCodeRegExp = /^[a-zA-Z0-9]+$/;

export const MAX_INPUT = 255;
export const MAX_LENGTH_300 = 300;
export const MAX_LENGTH_1000 = 1000;
export const MAX_LENGTH_500 = 500;

yup.setLocale(LocaleJP);
export const BaseYup = yup;
