/* eslint @typescript-eslint/no-explicit-any: 0 */
import cx from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from '@/config/i18n';
import { TreeSelect, TreeSelectProps } from 'primereact/treeselect';
import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

interface TreeSelectCustomFormProps extends TreeSelectProps {
  id?: string;
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  requiredFlag?: boolean;
  fullWidthFlag?: boolean;
  wrapClass?: string;
  inputStyle?: React.CSSProperties;
  isLoading?: boolean;
  isFilter?: boolean;
  errorMessage?: string;
  isHideValue?: boolean;
  fetchOptions?: (keyword: string, offset: number) => Promise<any>;
  optionsStatic?: any[];
  limit?: number;
}

export const TreeSelectCustomForm: React.FC<TreeSelectCustomFormProps> = ({
  name,
  label,
  placeholder,
  className,
  requiredFlag,
  fullWidthFlag,
  wrapClass,
  disabled,
  inputStyle,
  min,
  type = 'text',
  isLoading,
  onChange,
  onBlur,
  onKeyDown,
  errorMessage,
  maxLength,
  id,
  fetchOptions,
  limit = 50,
  isFilter,
  optionsStatic,
  ...rest
}) => {
  const [t] = useTranslation('');
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { field } = useController({
    control,
    name,
  });

  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>('');
  const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const loadOptions = useCallback(
    async (keyword = '', newOffset = 0) => {
      if (!fetchOptions) {
        setOptions(optionsStatic ?? []);
        return
      }
      if (loading) return;

      setLoading(true);
      try {
        const result = await fetchOptions(keyword, newOffset);
        if (!result) return;
        if (newOffset === 0) {
          setOptions(result.slice(0, limit));
        } else {
          setOptions((prevOptions) => [
            ...prevOptions,
            ...result.slice(0, limit),
          ]);
        }
        setHasMore(result.length >= limit);
      } catch (error) {
        console.error('Error fetching options:', error);
      } finally {
        setLoading(false);
      }
    },
    [fetchOptions],
  );

  useEffect(() => {
    loadOptions();
  }, [loadOptions]);

  const debouncedLoadOptions = useCallback(
    debounce((keyword: string) => {
      setOffset(0);
      loadOptions(keyword, 0);
    }, 300),
    [loadOptions]
  );

  const handleSearch = (event: any) => {
    setFilterValue(event.value);
    debouncedLoadOptions(event.value);
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container &&
      container.scrollTop + container.clientHeight >= container.scrollHeight
    ) {
      if (hasMore && !loading) {
        const newOffset = offset + limit;
        setOffset(newOffset);
        loadOptions('', newOffset);
      }
    }
  };

  return (
    <>
      <div className={cx(wrapClass, 'input')}>
        {label && (
          <div className="label-custom">
            <label>{label}</label>
            {requiredFlag && (
              <span className={cx('p-error', 'required')}>
                *
              </span>
            )}
          </div>
        )}
        {isLoading ? (
          <Skeleton />
        ) : (
          <div className="relative">
            <div
              onScroll={handleScroll}
              ref={containerRef}
            >
              <TreeSelect
                {...rest}
                id={id ?? field?.name ?? ''}
                placeholder={placeholder}
                className={cx('input-custom', '!py-0', className, {
                  ['p-invalid']: !!errors && errors[name],
                  ['w-full']: fullWidthFlag,
                })}
                style={inputStyle}
                {...field}
                name={name}
                value={field?.value}
                type={type}
                min={min}
                maxLength={maxLength}
                options={options}
                onChange={(e: any) => {
                  field?.onChange(e.target.value);
                  onChange && onChange(e);
                }}
                onBlur={(e: any) => {
                  onBlur && onBlur(e);
                }}
                onKeyDown={(e: any) => {
                  onKeyDown && onKeyDown(e);
                }}
                filter={isFilter}
                filterBy="label"
                onFilterValueChange={handleSearch}
                filterValue={filterValue}
                disabled={disabled || loading}
                emptyMessage={t('common.form.empty')}
              />
            </div>
          </div>
        )}

        {!!errors && (errors[name]?.message || errorMessage) && (
          <div
            className={cx('error-message', 'p-error mt-2', {
              ['is-label']: label,
            })}
          >
            {errors[name]?.message?.toString() || errorMessage}
          </div>
        )}
      </div>
    </>
  );
};
