import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from '@/hooks/useAuth';
import useUserRole from '@/hooks/useUserRole';
import Loader from '@/components/common/loader/Loader';

interface RoleProtectedRouteProps {
  requiredRoles?: string[];
  children: ReactNode;
}

const RoleProtectedRoute: React.FC<RoleProtectedRouteProps> = ({
  requiredRoles,
  children,
}) => {
  const isAuthenticated = useAuth();
  const { role: userRole, loaded, error } = useUserRole();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!loaded) {
    return <Loader isLoading={!loaded}></Loader>;
  }

  if (loaded && error?.response?.status > 500 && error?.response?.status !== 503) {
    <Navigate to="/500" />
  }

  if (loaded && error?.response?.status === 503) {
    const { message, time } = error?.response?.data || {};
    const data = {
      message,
      time,
    }
    localStorage.setItem('error_503', JSON.stringify(data));
    <Navigate to="/503" />
  }

  if (loaded && requiredRoles && !requiredRoles.includes(userRole)) {
    return <Navigate to="/403" />;
  }

  return <>{children}</>;
};

export default RoleProtectedRoute;
