import { useTranslation } from '@/config/i18n';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@/shared/constants/pagination';
import { ApiResponse, OptionType, QuerySetting } from '@/types/api';
import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import Select, {
  InputActionMeta,
  SingleValue,
  components,
  SingleValueProps,
  OptionProps,
} from 'react-select';
import useDebounceFn from '@/hooks/useDebounceFn';
import { ObjectLiteral } from '@/types/object';
import { AT_LEAST_ONE_OPTION } from '@/shared/constants/lesson';

type InfinitySelectProps = {
  service: (params: QuerySetting, key: string) => Promise<ApiResponse<any>>;
  defaultValue?: OptionType;
  isDisable?: boolean;
  fieldLabels?: any;
  fieldValue?: string;
  onChange: (val: SingleValue<OptionType>) => void;
  defaultOption?: OptionType[];
  [key: string]: any;
  requiredFlag?: boolean;
  wrapClass?: string;
  className?: string;
  fullWidthFlag?: boolean;
  errorMessage?: string;
  name: string;
  value: any;
  isFullInfo?: boolean;
  conditionShowDefaultOption?: string;
};

const defaultQuery: QuerySetting = {
  limit: DEFAULT_PER_PAGE,
  keyword: '',
  paginate: DEFAULT_PAGE,
};

interface SingleValuePropsType extends SingleValueProps<OptionType> {}

interface OptionPropsType extends OptionProps<OptionType> {}

const SingleValueComponent: React.FC<SingleValuePropsType> = (props) => {
  const { label, subLabel } = props.getValue()[0];

  return (
    <components.SingleValue {...props}>
      <span>{label}</span> <span style={{ color: 'darkgray' }}>{subLabel}</span>
    </components.SingleValue>
  );
};

const OptionComponent: React.FC<OptionPropsType> = (props) => {
  const { label, subLabel, isDisplayColumn } = props.data;

  return (
    <components.Option {...props}>
      {isDisplayColumn ? (
        <div className="flex flex-col">
          <div>{label}</div>
          <div style={{ color: 'darkgray' }}>{subLabel}</div>
        </div>
      ) : (
        <>
          <span>{label}</span>
          <span style={{ color: 'darkgray' }}>{subLabel}</span>
        </>
      )}
    </components.Option>
  );
};

const InfinitySelectNoForm = forwardRef((props: InfinitySelectProps, ref) => {
  const {
    service,
    isDisable,
    onChange,
    className,
    value,
    defaultOption,
    fieldLabels,
    isFullInfo,
    indexKey,
    conditionShowDefaultOption,
    ...rest
  } = props;

  const [t] = useTranslation('');
  const [querySetting, setQuerySetting] = useState<QuerySetting>(defaultQuery);
  const [selectOptions, setSelectOptions] = useState<OptionType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasLoadMore] = useState<boolean>(false);

  const getSelectOptions = async (params = querySetting) => {
    setIsLoading(true);
    try {
      if (!service) return [];
      const res = await service(params, indexKey);
      const tempOptions = res.data?.data || selectOptions || [];
      const pagination = res.data?.pagination as ObjectLiteral;
      setHasLoadMore(
        pagination?.current_page * pagination?.per_page <
          pagination?.total_item,
      );
      const newOptions = tempOptions.map((m: ObjectLiteral) => {
        const label = m[fieldLabels?.label];
        const subLabel = m[fieldLabels?.subLabel];
        const value = m[fieldLabels?.value];
        const isDisplayColumn = fieldLabels?.isDisplayColumn;
        const info = isFullInfo ? m : undefined;
        return {
          label,
          subLabel,
          value: String(value),
          info,
          isDisplayColumn,
        };
      });
      return newOptions as OptionType[];
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilter = (key: string, inputAction: InputActionMeta) => {
    const allowSearch =
      inputAction.action === 'input-change' ||
      (inputAction.action === 'menu-close' && inputAction.prevInputValue);
    if (!allowSearch) return;
    const params = {
      ...querySetting,
      keyword: key,
      paginate: DEFAULT_PAGE,
    };
    setQuerySetting(params);
    getSelectOptions(params).then((res) => setSelectOptions(res));
  };

  const debouncedHandleFilter = useDebounceFn(
    (key: string, inputAction: InputActionMeta) => {
      handleFilter(key, inputAction);
    },
    300,
  );

  const handleLoadMore = async () => {
    if (!hasMore) return;
    const params = { ...querySetting, paginate: querySetting.paginate + 1 };
    setQuerySetting(params);
    const res = await getSelectOptions(params);
    setSelectOptions((prev) => [...prev, ...res]);
  };

  useEffect(() => {
    getSelectOptions().then((res) => {
      setSelectOptions(res);
    });
  }, []);

  const handleSelected = (val: SingleValue<OptionType>) => {
    onChange?.(val);
  };

  const reloadData = async (params?: QuerySetting) => {
    const newQuery = { ...defaultQuery, ...params };
    setQuerySetting(newQuery);
    const res = await getSelectOptions(newQuery);
    setSelectOptions(res);
  };

  useImperativeHandle(ref, () => ({
    reloadOptions: (params?: QuerySetting) => reloadData(params),
  }));

  const isShowDefaultOption = useMemo(() => {
    if (!defaultOption?.length) return false;

    if (conditionShowDefaultOption === AT_LEAST_ONE_OPTION) {
      return !!selectOptions.length;
    }
  }, [defaultOption, conditionShowDefaultOption, selectOptions]);

  return (
    <>
      <Select
        className={className}
        classNamePrefix="select"
        isLoading={isLoading}
        isDisabled={isDisable}
        value={value}
        isClearable={true}
        onInputChange={debouncedHandleFilter}
        onChange={(val) => handleSelected(val)}
        onMenuScrollToBottom={handleLoadMore}
        noOptionsMessage={() => <>{t('common.no_data')}</>}
        loadingMessage={() => <>{t('common.loading')}</>}
        components={
          fieldLabels?.subLabel && {
            SingleValue: SingleValueComponent,
            Option: OptionComponent,
          }
        }
        {...rest}
        options={
          isShowDefaultOption
            ? [...(defaultOption || []) , ...selectOptions]
            : [...selectOptions]
        }
      />
    </>
  );
});
InfinitySelectNoForm.displayName = 'InfinitySelectNoForm';
export default InfinitySelectNoForm;
