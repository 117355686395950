import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

interface IUploadVideoContext {
  videoUploading: boolean;
  setVideoUploading: Dispatch<SetStateAction<boolean>>;
  workUploading: boolean;
  setWorkUploading: Dispatch<SetStateAction<boolean>>;
  pdf1Uploading: boolean;
  setPdf1Uploading: Dispatch<SetStateAction<boolean>>;
  pdf2Uploading: boolean;
  setPdf2Uploading: Dispatch<SetStateAction<boolean>>;
  pdf3Uploading: boolean;
  setPdf3Uploading: Dispatch<SetStateAction<boolean>>;
}


const UploadVideoContext = createContext<IUploadVideoContext>({
  videoUploading: false,
  setVideoUploading: () => {},
  workUploading: false,
  setWorkUploading: () => {},
  pdf1Uploading: false,
  setPdf1Uploading: () => {},
  pdf2Uploading: false,
  setPdf2Uploading: () => {},
  pdf3Uploading: false,
  setPdf3Uploading: () => {},
});

export function UploadVideoProvider({ children }: { children: ReactNode }) {
  const [videoUploading, setVideoUploading] = useState(false);
  const [workUploading, setWorkUploading] = useState(false);
  const [pdf1Uploading, setPdf1Uploading] = useState(false);
  const [pdf2Uploading, setPdf2Uploading] = useState(false);
  const [pdf3Uploading, setPdf3Uploading] = useState(false);
  const value = {
    videoUploading,
    setVideoUploading,
    workUploading,
    setWorkUploading,
    pdf1Uploading,
    setPdf1Uploading,
    pdf2Uploading,
    setPdf2Uploading,
    pdf3Uploading,
    setPdf3Uploading,
  };
  return (
    <UploadVideoContext.Provider value={value}>
      {children}
    </UploadVideoContext.Provider>
  );
}

export function useUploadVideoInfo() {
  const context = useContext(UploadVideoContext);
  if (!context) {
    throw new Error(
      'useUploadVideoInfo must be used within UploadVideoProvider',
    );
  }
  return context;
}
