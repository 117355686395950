import React from 'react';
import { useTranslation } from 'react-i18next';

import Logo from '@/components/common/logo';
import IconSuccess from '@/components/icons/IconSuccess';
import ButtonCustom from '@/components/common/ui/button';
import { useNavigate } from 'react-router-dom';
import PATH from '@/routes/path';

const ResetPasswordSuccessPage: React.FC = () => {
  const [t] = useTranslation('');
  const navigate = useNavigate();

  return (
    <div className="flex-full-center min-h-screen bg-primary-900">
      <div className="w-[440px] my-[50px]">
        <div className="flex-full-center">
          <Logo />
        </div>
        <h2 className="title text-white text-[30px] leading-[38px] font-bold flex-full-center mt-[24px] mb-[12px]">
          {t('reset_password_success.title')}
        </h2>
        <div className="error-message text-primary-400 mb-[32px] flex-full-center text-[16px] leading-[24px] font-normal text-center">
          {t('reset_password_success.description')}
        </div>
        <div className='w-[440px] bg-white p-[20px] rounded-[24px]'>
          <div className='flex-full-center'>
            <IconSuccess />
          </div>
          <ButtonCustom
            type="submit"
            className="w-full !rounded-[16px] mt-[34px]"
            onClick={() => navigate(PATH.login)}
          >
            {t('reset_password_success.back')}
          </ButtonCustom>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccessPage;
