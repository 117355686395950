import { useCallback } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { TreeSelectProps } from 'primereact/treeselect';
import {
  TabPanel,
  TabPanelHeaderTemplateOptions,
  TabView,
  TabViewTabChangeEvent,
} from 'primereact/tabview';

import { LessonFormSearchType } from '@/pages/lesson/schema';
import { TreeNode } from 'primereact/treenode';
import ButtonCustom from '@/components/common/ui/button';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import { TreeSelectCustomForm } from '@/components/common/ui/treeSelect';
import InfinitySelect from '@/components/common/ui/select';
import { apiRequest } from '@/services';
import { OptionType } from '@/types/api';

interface ILessonFormSearchProps {
  t: any;
  formMethods: UseFormReturn<LessonFormSearchType, any, undefined>;
  activeIndex: number;
  onBeforeTabChange: (event: TabViewTabChangeEvent) => void;
  onSubmit: () => void;
  handleReset: () => void;
  loading: boolean;
  isTeacher: boolean;
  mentorDefaultOptions?: OptionType[];
}

const tabTemplate = (options: TabPanelHeaderTemplateOptions) => {
  return (
    <div
      className="flex align-items-center"
      style={{ cursor: 'pointer' }}
      onClick={options.onClick}
    >
      <span className="tab-label font-medium white-space-nowrap">
        {options.titleElement}
      </span>
    </div>
  );
};

let controllerMentor = new AbortController();

const LessonFormSearch = ({
  t,
  formMethods,
  activeIndex,
  onBeforeTabChange,
  onSubmit,
  handleReset,
  loading,
  isTeacher,
  mentorDefaultOptions,
}: ILessonFormSearchProps) => {
  const { apiService } = apiRequest();

  const customValueTemplate = (
    selectedNodes: TreeNode | TreeNode[],
    props: TreeSelectProps,
  ) => {
    if (Array.isArray(selectedNodes) && selectedNodes.length > 0) {
      return (
        <>
          {t('user_list.status_label')}
          {selectedNodes[0].label}
        </>
      );
    }

    const defaultOption = props.options?.find(
      (option) => option.key === props.value,
    );
    return (
      <>
        {t('user_list.status_label')}
        {defaultOption?.label}
      </>
    );
  };

  const fetchMentorOptions = useCallback(async (params: any) => {
    if (controllerMentor.abort) controllerMentor.abort();
    controllerMentor = new AbortController();
    const { signal } = controllerMentor;
    return await apiService.users.index(
      {
        ...params,
        status: 'active',
        role: 'TEACHER',
      },
      { signal },
    );
  }, []);

  const controlClass = {
    control: (base: any) => ({
      ...base,
      height: '44px',
    }),
    placeholder: (base: any) => ({
      ...base,
      marginTop: '3px',
    }),
    singleValue: (base: any) => ({
      ...base,
      marginTop: '3px',
    }),
  };

  return (
    <>
      <FormProvider {...formMethods}>
        {!isTeacher && (
          <TabView
            activeIndex={activeIndex}
            className="highlight-bottom mt-[16px]"
            onBeforeTabChange={onBeforeTabChange}
          >
            <TabPanel
              header={t('lesson.admin')}
              headerTemplate={tabTemplate}
            ></TabPanel>
            <TabPanel
              header={t('lesson.coach')}
              headerTemplate={tabTemplate}
            ></TabPanel>
          </TabView>
        )}

        <form className="mt-[16px] flex" onSubmit={onSubmit}>
          <div className="search-input w-full relative">
            <span className="pi pi-search absolute top-[14px] left-[14px] text-gray-500 text-[15px] z-40" />
            <InputTextCustomForm
              name="name"
              className="!pl-[40px] h-[44px]"
              placeholder={t('lesson_list.name')}
            />
          </div>
          {!!activeIndex && (
            <InfinitySelect
              placeholder={t('lesson_list.mentor_id_placeholder')}
              wrapClass="ml-4 flex-[0_0_290px]"
              className="with-form-search"
              name="mentor_id"
              service={fetchMentorOptions}
              fieldLabels={{
                label: 'full_name_kanji',
                value: 'id',
                subLabel: 'email',
              }}
              filterOption={() => true}
              styles={controlClass}
              isClearable={false}
              isNormalizeValue
              defaultOption={mentorDefaultOptions}
            />
          )}
          <TreeSelectCustomForm
            name="status"
            wrapClass="ml-4 w-[193px]"
            panelClassName="tree-hide-header tree-disabled-unselect"
            optionsStatic={[
              { key: 'all', label: t('common.status.all') },
              { key: 'active', label: t('common.status_other.active') },
              { key: 'inactive', label: t('common.status_other.inactive') },
            ]}
            valueTemplate={customValueTemplate}
          />
          <div className="action flex ml-4">
            <ButtonCustom
              type="button"
              severity="secondary"
              onClick={handleReset}
              className="max-h-[44px]"
            >
              <span className="pi pi-refresh" />
            </ButtonCustom>
            <ButtonCustom
              type="submit"
              disabled={loading}
              className="ml-2 max-h-[44px]"
            >
              <span className="pi pi-search" />
            </ButtonCustom>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default LessonFormSearch;
