import { Button } from 'primereact/button';
import classNames from 'classnames';
import { Tooltip } from 'primereact/tooltip';

import { ColumnDescription } from '@/components/common/ui/table/ColumnDescription';

export const LessonQuestionColumns = (
  t: any,
  onEditQuestion: (rowData: any) => void,
  onDeleteQuestion: (value: any) => void,
  isHaveOnlyOnePublicQuestion: boolean,
  isDisabledEdit?: boolean,
): ColumnDescription[] => [
  {
    dataField: 'index',
    text: t('lesson_question.table.no'),
    classname: 'w-[60px] text-gray-600 text-center',
    headerClassname: '[&>div]:justify-center',
    formatter: (_rowData, columnProps) => {
      return (
        <div className="flex gap-3 w-full justify-center">
          {columnProps.rowIndex + 1}
        </div>
      );
    },
  },
  {
    dataField: 'question',
    text: t('lesson_question.table.question'),
    classname: 'min-w-[111px]',
    formatter: (rowData) => (
      <>
        <Tooltip target={`.tooltip-question-${rowData.id}`}>
          {rowData.question}
        </Tooltip>
        <div
          className={classNames(
            'line-clamp-2 break-word',
            `tooltip-question-${rowData.id}`,
          )}
        >
          {rowData.question}
        </div>
      </>
    ),
  },
  {
    dataField: 'status',
    text: t('lesson_question.table.status'),
    classname: 'min-w-[130px] w-[130px] text-center',
    headerClassname: '[&>div]:justify-center',
    formatter: (rowData) => (
      <div
        className={classNames('px-[12px] py-[4px] rounded-full w-fit m-auto', {
          'bg-success-50 text-success-700': rowData.status === 'active',
          'bg-warning-50 text-warning-700': rowData.status !== 'active',
        })}
      >
        {t(
          rowData.status === 'active'
            ? 'lesson_question.table.public'
            : 'lesson_question.table.draft',
        )}
      </div>
    ),
  },
  {
    dataField: 'options',
    text: t('lesson_question.table.options'),
    classname: 'min-w-[130px] w-[130px] text-gray-600 text-center',
    headerClassname: '[&>div]:justify-center',
    formatter: (rowData) => (
      <div className="line-clamp-2 break-all">{rowData.options.length}</div>
    ),
  },
  {
    dataField: 'type',
    text: t('lesson_question.table.type'),
    classname: 'w-[200px] text-gray-600',
    formatter: (rowData) => <>{rowData.type?.label}</>,
  },
  {
    dataField: 'action',
    text: '',
    classname: 'text-center [&>div]:justify-center w-[160px]',
    headerClassname: '[&>div]:justify-center',
    formatter: (rowData) => (
      <div className="flex flex-row h-[36px] items-center">
        <Button
          disabled={isDisabledEdit || isHaveOnlyOnePublicQuestion && rowData.status === 'active'}
          type="button"
          className="mx-3 p-[10px]"
          onClick={() => onDeleteQuestion(rowData)}
        >
          <span className="pi pi-trash text-error-500 text-[16px]" />
        </Button>
        <Button
          type="button"
          className="mx-3 p-[10px]"
          onClick={() => onEditQuestion?.(rowData)}
        >
          <span
            className={classNames('pi', {
              'pi-pencil text-[16px]': !isDisabledEdit,
              'pi-eye text-[18px]': isDisabledEdit,
            })}
          />
        </Button>
      </div>
    ),
  },
];
