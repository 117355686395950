import classNames from 'classnames';
import { InputNumber } from 'primereact/inputnumber';
import { Tooltip } from 'primereact/tooltip';

const createPriorityFormatter =
  (
    isEditPriority: boolean,
    handlePriorityChange: (id: number, newPriority: number) => void,
    lessonErrors: any,
  ) =>
    (rowData: any) => {
      return (
        <div>
          {!isEditPriority ? (
            rowData.priority
          ) : (
            <>
              <Tooltip
                target={`.input-priority-${rowData.id}`}
                content="移動する前に、必ず変更を保存してください。"
              />

              <InputNumber
                key={rowData.id}
                className={classNames(
                  `input-custom bg-white input-priority-${rowData.id}`,
                  {
                    '!border-error-500': !!lessonErrors[rowData.id],
                  },
                )}
                inputClassName="shadow-none text-center w-[40px]"
                value={rowData.priority}
                onBlur={(e) => {
                  const cleanedStr = e.target.value.replace(/,/g, '');
                  handlePriorityChange?.(rowData.id, Number(cleanedStr));
                }}
              />
            </>
          )}
        </div>
      );
    };

export default createPriorityFormatter;
