import { useEffect, useMemo, useState } from 'react';
import { Badge } from 'primereact/badge';
import classNames from 'classnames';

import { ConversationType, UserType } from '@/types/chat';
import {
  countMember,
  findPreviousNonDeletedMessage,
  formatTimestamp,
} from '@/lib/utils';
import { auth } from '@/lib/firebase';
import { useTranslation } from '@/config/i18n';
import { IconGroupUsers } from '@/components/icons';
import { MESSAGE_TYPE } from '@/shared/constants/chat';
import { DefaultAvatar } from '@/components/common/ui/defaultAvatar';

type Props = {
  isNotSeen?: boolean;
  isNeedToConfirm?: boolean;
  conversation?: ConversationType;
  isActive?: boolean;
  onClick?: () => void;
  users?: UserType[];
  initializeUsers?: (memberIds: number[]) => Promise<void>;
};

export const ConversationItem: React.FC<Props> = ({
  isNotSeen,
  isNeedToConfirm,
  conversation,
  isActive,
  onClick,
  users,
  initializeUsers,
}) => {
  const currentUser = auth.currentUser;
  const [t] = useTranslation('');
  const [messageContent, setMessageContent] = useState<string | null>(null);
  const { is_group_chat, name, last_message, has_deleted_message_members } =
    conversation || {};

  const filteredUser = useMemo(() => {
    return users
      ?.filter((user) => conversation?.member_ids?.includes(Number(user.id)))
      .find((user) => user.id !== Number(currentUser?.uid));
  }, [users, conversation, currentUser]);

  const userSendFile = useMemo(() => {
    return users?.find((user) => user.id === last_message?.sender_id);
  }, [users, last_message?.sender_id]);

  const itemAvatarOrg = conversation?.is_group_chat
    ? conversation.image !== ''
      ? `${process.env.REACT_APP_BASE_IMG_HOST}/${conversation.image}`
      : null
    : filteredUser?.profile_image
      ? filteredUser?.profile_image
      : null;

  const role = filteredUser?.current_roles?.[0];

  const groupOwner = useMemo(() => {
    return users?.find((user) => user.id === conversation?.admin_id);
  }, [users, conversation, currentUser]);

  const countMemberAccepted = useMemo(() => {
    return countMember(conversation);
  }, [conversation?.invitation_statuses]);

  const getLastMessageForDelete = async () => {
    const last_message_for_delete = await findPreviousNonDeletedMessage(
      String(conversation?.id),
      Number(currentUser?.uid),
    );
    return last_message_for_delete || null;
  };

  useEffect(() => {
    const fetchMessageContent = async () => {
      if (has_deleted_message_members?.[String(currentUser?.uid)]) {
        const message = await getLastMessageForDelete();
        if (!message) {
          setMessageContent('');
          return;
        }

        if (message.message_type === MESSAGE_TYPE.TEXT) {
          setMessageContent(message.message || '');
        } else {
          const isReceiveFile = Number(currentUser?.uid) !== message?.sender_id;
          setMessageContent(
            isReceiveFile
              ? t('chat.receive_file', {
                user_name: userSendFile?.full_name_kanji,
              })
              : t('chat.send_file'),
          );
        }
      }
    };

    if (has_deleted_message_members?.[String(currentUser?.uid)]) {
      fetchMessageContent();
    } else {
      if (last_message?.message_type === MESSAGE_TYPE.TEXT) {
        setMessageContent(last_message.message || '');
      } else {
        const isReceiveFile =
          Number(currentUser?.uid) !== last_message?.sender_id;
        setMessageContent(
          isReceiveFile
            ? t('chat.receive_file', {
              user_name: userSendFile?.full_name_kanji,
            })
            : t('chat.send_file'),
        );
      }
    }
  }, [currentUser?.uid, last_message, has_deleted_message_members]);

  useEffect(() => {
    if (
      initializeUsers &&
      conversation?.admin_id !== -1 &&
      conversation?.is_group_chat
    ) {
      initializeUsers([
        Number(conversation?.admin_id),
        Number(currentUser?.uid),
      ]);
    }
  }, [conversation?.admin_id, currentUser?.uid]);

  return (
    <div
      onClick={onClick}
      className={classNames(
        'w-full flex flex-col py-4 px-4 cursor-pointer border-b-gray-200 border-b',
        {
          'bg-primary-50': isActive,
          'bg-white hover:bg-primary-50': !isActive,
        },
      )}
    >
      <div className="flex h-full items-center">
        {/* Avatar Div */}
        {itemAvatarOrg ? (
          <div className="min-w-[40px] w-[40px] h-[40px] rounded-full relative flex-shrink-0">
            <img
              src={itemAvatarOrg}
              alt="avatar"
              className="h-full w-full rounded-full object-cover"
            />
          </div>
        ) : (
          <div className="flex-shrink-0">
            {!is_group_chat && role && <DefaultAvatar role={role} />}
            {is_group_chat && (
              <div className="min-w-[40px] w-[40px] h-[40px] rounded-full bg-gradient-to-b from-[#DC6803] to-[#FDB022] flex justify-center items-center">
                <IconGroupUsers className="h-[24px] w-[20px] text-white" />
              </div>
            )}
          </div>
        )}

        {/* Conversation Name Div */}
        <div className="ml-[12px] flex flex-col justify-center flex-1 min-w-0">
          <div className="text-[14px] text-gray-700 font-bold flex">
            <div className="truncate">
              {is_group_chat ? name : filteredUser?.full_name_kanji}
            </div>
            {is_group_chat && (
              <span className="ml-2 flex-shrink-0">
                ({countMemberAccepted})
              </span>
            )}
          </div>
        </div>

        {/* Time Div */}
        <div className="flex flex-col items-end h-full flex-shrink-0 ml-[12px]">
          <div className="text-[14px] font-thin text-gray-600 whitespace-nowrap">
            {formatTimestamp(t, last_message?.sent_at)}
          </div>
          {!isNeedToConfirm ? (
            <>
              {isNotSeen && (
                <div>
                  <Badge severity="danger" />
                </div>
              )}
            </>
          ) : (
            <div>
              <Badge severity="danger" />
            </div>
          )}
        </div>
      </div>

      {isNeedToConfirm ? (
        <div className="relative px-4 mt-4 line-clamp-2">
          <p className="text-[14px] text-gray-700 font-bold break-words">
            {t('chat.invite_group_message', {
              user_name: groupOwner?.full_name_kanji || '',
              group_name: conversation?.name || '',
            })}
          </p>
        </div>
      ) : (
        <>
          {messageContent && (
            <div className="relative px-4 mt-4 line-clamp-2 break-words">
              <p
                className={classNames(
                  'text-[14px] text-gray-600 whitespace-break-spaces',
                  {
                    'font-bold': isNotSeen,
                  },
                )}
              >
                {messageContent}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
