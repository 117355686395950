import { useCallback, useMemo } from 'react';
import { arrayRemove, deleteField, doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

import ButtonCustom from '@/components/common/ui/button';
import { ConversationType, UserType } from '@/types/chat';
import { auth, db } from '@/lib/firebase';
import PATH from '@/routes/path';
import { useTranslation } from '@/config/i18n';
import DialogCustom from '@/components/common/ui/dialog';

type Props = {
  selectedConversation?: ConversationType | null;
  users: UserType[];
  openPopup: boolean;
  setOpenPopup: (val: boolean) => void;
};

export const ConfirmOutGroup: React.FC<Props> = ({
  selectedConversation,
  users,
  openPopup,
  setOpenPopup,
}) => {
  const currentUser = auth.currentUser;
  const navigate = useNavigate();
  const [t] = useTranslation('');
  const conversationLink = `${PATH.chat}/${PATH.chat_conversation}`;
  const getUserInfo = (id: number) => users?.find((item) => item.id === id);

  const partnerName = useMemo(() => {
    return getUserInfo(Number(selectedConversation?.admin_id));
  }, [users, selectedConversation]);

  const conversationDocRef = doc(
    db,
    'conversations',
    String(selectedConversation?.id),
  );

  const handleOutGroup = useCallback(() => {
    if (!selectedConversation?.id || !currentUser) return;

    try {
      updateDoc(conversationDocRef, {
        [`invitation_statuses.${currentUser.uid}`]: deleteField(),
        [`read_statuses.${currentUser.uid}`]: deleteField(),
        member_ids: arrayRemove(Number(currentUser.uid)),
      });
      navigate(conversationLink);
    } catch (error) {
      console.error('Error updating invite_statuses:', error);
    }
  }, [selectedConversation?.id, currentUser]);

  const onHidePopup = () => {
    setOpenPopup(false);
    navigate(conversationLink);
  };

  return (
    <DialogCustom
      header={t('chat.confirm_out_group_title')}
      visible={openPopup}
      onHide={onHidePopup}
      className="min-w-[396px] max-w-[396px]"
      contentClassName="border-b"
      headerClassName="border-b"
      footer={
        <div className="field">
          <div className="flex justify-end">
            <ButtonCustom
              className="mr-3 w-full rounded-2xl"
              type="button"
              severity="secondary"
              onClick={handleOutGroup}
            >
              {t('chat.ok')}
            </ButtonCustom>
          </div>
        </div>
      }
    >
      <div className="flex justify-center items-center flex-col mt-5">
        <p className="text-gray-700 text-base">
          {`${t('chat.confirm_out_group_msg', { owner_name: partnerName?.full_name_kanji })}`}
        </p>
      </div>
    </DialogCustom>
  );
};
