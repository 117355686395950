import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputTextCustomForm } from '@/components/common/ui/input/Input';

type Props = {
  onSubmit?: () => void;
  isSearchMain?: boolean;
};

export const UserFormSearch: React.FC<Props> = ({
  onSubmit,
  isSearchMain = false,
}) => {
  const [t] = useTranslation();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="flex">
        <div className="w-full search-input relative">
          <span className="pi pi-search absolute top-[14px] left-[14px] text-gray-500 text-[15px] z-40" />
          <InputTextCustomForm
            name="keyword"
            className="!pl-[40px]"
            placeholder={t(
              isSearchMain ? 'chat.plh_search_main' : 'chat.plh_search',
            )}
            onChange={onSubmit}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
    </>
  );
};
