import { UserFormSearchType, userSearchFormSchema } from '@/shared/schema/user';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import ButtonCustom from '@/components/common/ui/button';
import useSearch from '@/hooks/useSearch';
import { CalendarCustomForm } from '@/components/common/ui/calendar';
import { useTranslation } from '@/config/i18n';
import { TreeSelectCustomForm } from '../common/ui/treeSelect';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/shared/constants';
import { TreeNode } from 'primereact/treenode';
import { TreeSelectProps } from 'primereact/treeselect';
import usePathName from '@/hooks/usePathName';
import PATH from '@/routes/path';
import { ROLE } from '@/shared/constants/user';

const UserFormSearch: React.FC = () => {
  const [t] = useTranslation('');
  const pathName = usePathName();
  const [loading, setLoading] = useState<boolean>();
  const formMethods = useForm<UserFormSearchType>({
    mode: 'onChange',
    resolver: yupResolver(userSearchFormSchema),
    shouldFocusError: false,
    defaultValues: {
      keyword: '',
      status: 'all',
      role: '',
      start_date: null,
      end_date: null,
    },
  });
  const role = pathName.includes(PATH.student_management) ? ROLE.STUDENT : ROLE.TEACHER;
  const keywordMsg = role === ROLE.STUDENT ? t('user_list.keyword_student') : t('user_list.keyword_coach');

  const customValueTemplate = (selectedNodes: TreeNode | TreeNode[], props: TreeSelectProps) => {
    if (Array.isArray(selectedNodes) && selectedNodes.length > 0) {
      return (
        <>{t('user_list.status_label')}{selectedNodes[0].label}</>
      )
    }
    
    const defaultOption = props.options?.find((option) => option.key === props.value);
    return (
      <>
        {t('user_list.status_label')}{ defaultOption?.label }
      </>
    )
  };

  const { handleSearch, query } = useSearch();
  const { handleSubmit, getValues, setValue, reset, watch } = formMethods;
  const startDate = watch('start_date');
  const endDate = watch('end_date');


  async function onSubmit() {
    setLoading(true);
    let start_date = getValues('start_date');
    let end_date = getValues('end_date');
    let status = getValues('status');
    start_date = start_date ? dayjs(start_date.toString()).format('YYYY-MM-DD') : '' as unknown as any;
    end_date = end_date ? dayjs(end_date.toString()).format('YYYY-MM-DD') : '' as unknown as any;
    status = status === 'all' ? '' : status;
    handleSearch({...getValues(), start_date, end_date, status });
    setLoading(false);
  }

  function handleReset() {
    reset()
    onSubmit();
  }

  const getAllFieldNames = () => {
    const values = getValues();
    return Object.keys(values);
  };

  function mapValueFromQuery() {
    getAllFieldNames().forEach((field: any) => {
      const val = query[field];
      const valWithFormat = dayjs(val).isValid() ? new Date(val) : val;
      if(query[field]) {
        setValue(field, valWithFormat);
      }
    })
  }

  useEffect(() => {
    mapValueFromQuery()
  }, [])

  return (
    <>
      <FormProvider {...formMethods}>
        <form className="mt-[16px] flex" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full max-w-[340px] search-input relative">
            <span className='pi pi-search absolute top-[14px] left-[14px] text-gray-500 text-[15px] z-40' />
            <InputTextCustomForm name="keyword" className='!pl-[40px]' placeholder={keywordMsg} />
          </div>
          <CalendarCustomForm
            className='date-custom'
            placeholder='----/--/--'
            name="start_date"
            dateFormat={DATE_FORMAT}
            wrapClass='ml-4 w-[140px]'
            showIcon
            maxDate={endDate ? new Date(endDate ?? '') : undefined}
            icon={() => <i className="pi pi-calendar text-[18px]" />}
          />
          <div className='flex-full-center ml-4'>
            ~
          </div>
          <CalendarCustomForm
            className='date-custom'
            placeholder='----/--/--'
            name="end_date"
            dateFormat={DATE_FORMAT}
            wrapClass='ml-4 w-[140px]'
            showIcon
            minDate={startDate ? new Date(startDate ?? '') : undefined}
            icon={() => <i className="pi pi-calendar text-[18px]" />}
          />
          <TreeSelectCustomForm
            name="status"
            wrapClass="ml-4 w-[193px]"
            panelClassName='tree-hide-header tree-disabled-unselect'
            optionsStatic={[
              { key: 'all', label: t('common.status.all') },
              { key: 'active', label: t('common.status.active') },
              { key: 'inactive', label: t('common.status.inactive') },
            ]}
            valueTemplate={customValueTemplate}
          />
          <div className="action flex ml-4">
            <ButtonCustom
              type="button"
              severity="secondary"
              onClick={() => handleReset()}
            >
              <span className='pi pi-refresh' />
            </ButtonCustom>
            <ButtonCustom type="submit" disabled={loading} className="ml-2">
              <span className='pi pi-search' />
            </ButtonCustom>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default UserFormSearch;
