import ButtonCustom from '@/components/common/ui/button';
import { InputTextCustomForm } from '@/components/common/ui/input/Input';
import { useTranslation } from '@/config/i18n';

interface IVersionFormSearchProps {
  onSubmit: () => void;
  onClear: () => void;
}

const VersionFormSearch = ({ onSubmit, onClear }: IVersionFormSearchProps) => {
  const [t] = useTranslation('');

  return (
    <form className="mt-[16px] flex" onSubmit={onSubmit}>
      <div className="search-input w-full relative">
        <span className="pi pi-search absolute top-[14px] left-[14px] text-gray-500 text-[15px] z-40" />
        <InputTextCustomForm
          name="keyword"
          wrapClass="w-full"
          className="!pl-[40px]"
          placeholder={t('version.search_placeholder')}
        />
      </div>
      <div className="action flex ml-4">
        <ButtonCustom type="button" severity="secondary" onClick={onClear}>
          <span className="pi pi-refresh" />
        </ButtonCustom>
        <ButtonCustom type="submit" className="ml-2">
          <span className="pi pi-search" />
        </ButtonCustom>
      </div>
    </form>
  );
};

export default VersionFormSearch;
