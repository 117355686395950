
export const UserItemSkeleton: React.FC = () => {
  return (
    <div className="w-full flex items-center py-4 px-4 cursor-pointer border-t-gray-200 border-b-gray-200 border-t bg-gray-100 animate-pulse">
      <div className="min-w-[40px] w-[40px] h-[40px] rounded-full bg-gray-300" />

      <div className="w-full ml-2">
        <div className="flex justify-between items-center">
          <div className="w-full h-4 bg-gray-300 rounded" />
        </div>
        <div className="mt-2 w-3/4 h-3 bg-gray-300 rounded" />
        <div className="mt-2 w-3/4 h-3 bg-gray-300 rounded" />
      </div>
    </div>
  );
};
