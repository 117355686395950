const lesson_question = {
  header: '質問追加',
  question: {
    title: '質問',
    placeholder: '質問',
  },
  type: {
    title: '種類',
    values: {
      checkbox: 'チェックボックス',
      radio: 'ラジオ'
    }
  },
  option_input: {
    title: 'オプション',
    title_validate: '回答',
    placeholder:'オプション',
    add_new_option_title:'回答追加',
    duplicate_values:'回答内容は重複できませんので、再度ご確認ください。',
    min_options: '質問には最低でも2回答が必要です。'
  },
  result: {
    title:'正解回答',
  },
  note_for_correct: {
    title: '解説文',
    placeholder:'入力してください。'
  },
  button: {
    cancel: 'キャンセル',
    add: '保存',
    save_to_draft: '上書き保存'
  },
  add: '新規追加',
  table: {
    no: 'No',
    question: '質問',
    status: 'ステータス',
    options: '選択肢',
    type: '種類',
    results: '回答',
    public: '公開',
    draft: '非公開',
  },
};

export default lesson_question;
