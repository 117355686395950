import UserForm from '@/components/user/UserForm';
import { useTranslation } from '@/config/i18n';
import PATH from '@/routes/path';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const StudentManagementNewPage: React.FC = () => {
  const [t] = useTranslation('');
  const navigate = useNavigate();

  return (
    <>
      <div className="header-top">
        <h2 className='title flex'>
          <span className='pi pi-arrow-left text-[20px] text-gray-500 cursor-pointer' onClick={() => navigate(PATH.student_management)}></span>
          <span className='ml-3'>
            {t('user.new_student')}
          </span>
        </h2>
      </div>
      <UserForm />
    </>
  )
}

export default StudentManagementNewPage;