import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from './pagination';

export * from './question';

export const HTTP_STATUS = {
  SUCCESS: 200,
  AUTHENTICATION_ERROR: 401,
  AUTHENTICATION_TIMEOUT: 419,
  FORM_ERROR: 422,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  SERVER_ERROR: 500,
};

export const TOAST_LIFE = 5000;

export const JAPANESE_FULL_STOP = '。';

export const DATE_FORMAT = 'yy/mm/dd';

export const defaultPayload = {
  params: {
    page: DEFAULT_PAGE,
    page_size: DEFAULT_PER_PAGE,
  },
};

export const studentHeaderCsv = [
  '姓(*)',
  '名(*)',
  'セイ(*)',
  'メイ(*)',
  '生年月日',
  '電話番号(*)',
  'メールアドレス(*)',
  '郵便番号(*)',
  '住所(*)',
  'アートシフトコーチのメールアドレス(*)',
];

export const studentBodyCsv = [
  '花城',
  '美羽',
  'ハナシロ',
  'ミウ',
  '1996/02/25',
  '0932008304',
  'tamldm+02@company.com',
  '600000',
  'ベトナム、ダナン市ハイチャウ区ホアホアクオンナム町ab通り',
  'anhntl+coach10@company.com',
];

export const coachHeaderCsv = [
  '姓(*)',
  '名(*)',
  'セイ(*)',
  'メイ(*)',
  '生年月日',
  '電話番号(*)',
  'メールアドレス(*)',
  '郵便番号(*)',
  '住所(*)',
  '卒業期(*)',
];

export const coachBodyCsv = [
  '西村',
  '大河',
  'ニシムラ',
  'タイガ',
  '1990/01/01',
  '08085450854',
  'abc@xyz.com',
  '7300803',
  '広島県広島市中区北町1-1-25',
  '1',
];

export const MAX_LENGTH = {
  PHONE_NUMBER: 15,
  PASSWORD: 32,
  ADDRESS: 255,
  POSTCODE: 32,
  NOTE_FOR_CORRECT: 255,
  QUESTION: 1000,
  OPTION: 500
}

export const CURRENT_USER_ID_KEY = 'currentUserId';

export const DATE_TIME_FORMAT = {
  DATE_ONLY: 'YYYY/MM/DD',
  DATE_TIME: 'YYYY/MM/DD HH:mm',
};
