const version = {
  title: '公開バージョンの設定',
  add_android: 'Android追加',
  add_ios: 'IOS追加',
  android: 'Android',
  ios: 'IOS',
  name: 'バージョン名',
  name_placeholder: '入力してください。',
  search_placeholder: 'バージョン名...',
  update_date: '更新日',
  force_update: '強制アップデート',
  dialog: {
    android_title: '新しいバージョン追加_Android',
    ios_title: '新しいバージョン追加_IOS',
  },
  name_invalid: 'バージョン名の形式が正しくありません。',
  name_hint: 'バージョン名はX.Y.Zとして数字のみ入力してください。',
  add_name_success: 'バージョンを追加しました。',
  confirm_turn_off:
    '強制アップデートをオフ状態に切り替えると、ユーザーがアプリバージョンをアップデートしなくても、アプリを引き続き利用できます。よろしいでしょうか。',
  confirm_turn_on:
    '強制アップデートをオン状態に切り替えると、ユーザーがアプリバージョンをアップデートしなかったら、アプリを引き続き利用できなくなります。よろしいでしょうか。',
  confirm_delete_title: 'バージョン削除',
  confirm_delete_body: 'ご指定のバージョン名を削除すると、利用しているユーザーに公開しているバージョンのアップデートを強制します。よろしいでしょうか。',
  delete_success: 'ご指定のバージョンを解除しました。',
};

export default version;
